const colors = {
  text: {
    general: {
      primary: "#000000",
      secondary: "#666666",
      disabled: "#939393",
      heading: "#96A0B5",
      button: "#1e293b",
    },
    navigation: {
      active: "#32334A",
      inactive: "#96A0B5",
    },
  },

  background: {
    default: "#d7d7d7",
    secondary: "lightgray",
  },

  transparent: {
    main: "transparent",
  },

  white: {
    main: "#ffffff",
    secondary: "#f8fafc",
    focus: "#ffffff",
  },

  black: {
    light: "#000000",
    main: "#000000",
    focus: "#000000",
  },

  primary: {
    main: "#2B337A",
    focus: "#2B337A",
  },
  success: {
    main: "#1ABC9C",
    focus: "1abc9cb5",
  },
  secondary: {
    main: "#7b809a",
    focus: "#8f93a9",
  },

  table: {
    background: {
      primary: "#fff",
      alternate: "#EDF2F7",
    },
  },

  blue: {
    main: "#3B6CF8",
  },

  divider: "#e2e8f0",

  info: {
    main: "#4f46e5",
    focus: "#1662C4",
  },

  // success: {
  //   main: "#4CAF50",
  //   focus: "#67bb6a",
  // },

  warning: {
    main: "#F1556C",
  },

  error: {
    main: "#E82C2C",
    focus: "#f65f53",
  },

  status: {
    stocked: "#46B573",
    restock: "#D6A804",
    out: "#99082D",
  },

  icon: {
    active: "#4195D9",
    inactive: "#96A0B5",
  },

  light: {
    main: "#f0f2f5",
    focus: "#f0f2f5",
  },

  dark: {
    main: "#344767",
    focus: "#2c3c58",
  },

  grey: {
    main: "#e5e5e5",
    focus: "#eeeeee",
    100: "#e5e5e5",
    200: "#eeeeee",
  },

  gradients: {
    primary: {
      main: "#EC407A",
      state: "#D81B60",
    },

    secondary: {
      main: "#747b8a",
      state: "#495361",
    },

    info: {
      main: "#49a3f1",
      state: "#1A73E8",
    },

    success: {
      main: "#66BB6A",
      state: "#43A047",
    },

    warning: {
      main: "#FFA726",
      state: "#FB8C00",
    },

    error: {
      main: "#EF5350",
      state: "#E53935",
    },

    light: {
      main: "#EBEFF4",
      state: "#CED4DA",
    },

    dark: {
      main: "#42424a",
      state: "#191919",
    },
  },

  socialMediaColors: {
    facebook: {
      main: "#3b5998",
      dark: "#344e86",
    },

    twitter: {
      main: "#55acee",
      dark: "#3ea1ec",
    },

    instagram: {
      main: "#125688",
      dark: "#0e456d",
    },

    linkedin: {
      main: "#0077b5",
      dark: "#00669c",
    },

    pinterest: {
      main: "#cc2127",
      dark: "#b21d22",
    },

    youtube: {
      main: "#e52d27",
      dark: "#d41f1a",
    },

    vimeo: {
      main: "#1ab7ea",
      dark: "#13a3d2",
    },

    slack: {
      main: "#3aaf85",
      dark: "#329874",
    },

    dribbble: {
      main: "#ea4c89",
      dark: "#e73177",
    },

    github: {
      main: "#24292e",
      dark: "#171a1d",
    },

    reddit: {
      main: "#ff4500",
      dark: "#e03d00",
    },

    tumblr: {
      main: "#35465c",
      dark: "#2a3749",
    },
  },

  badgeColors: {
    primary: {
      background: "#f8b3ca",
      text: "#cc084b",
    },

    secondary: {
      background: "#d7d9e1",
      text: "#6c757d",
    },

    info: {
      background: "#aecef7",
      text: "#095bc6",
    },

    success: {
      background: "#bce2be",
      text: "#339537",
    },

    warning: {
      background: "#ffd59f",
      text: "#c87000",
    },

    error: {
      background: "#fcd3d0",
      text: "#f61200",
    },

    light: {
      background: "#ffffff",
      text: "#c7d3de",
    },

    dark: {
      background: "#8097bf",
      text: "#1e2e4a",
    },
  },

  coloredShadows: {
    primary: "#e91e62",
    secondary: "#110e0e",
    info: "#00bbd4",
    success: "#4caf4f",
    warning: "#ff9900",
    error: "#f44336",
    light: "#adb5bd",
    dark: "#404040",
  },

  inputBorderColor: "#d2d6da",

  tabs: {
    indicator: { boxShadow: "#ddd" },
  },
};

export default colors;
