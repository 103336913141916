import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormDatePicker } from "@components/FormDatePicker";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import {
  ModelSeason,
  useAdminSeasonSeasonIdDelete,
  useAdminSeasonSeasonIdGet,
  useAdminSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

export const SeasonView = () => {
  const navigate = useNavigate();
  const { seasonId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [toDelete, setToDelete] = useState<ModelSeason | null>(null);
  const [permissions, setPermissions] = useState({
    delete: false
  });

  const organizationId = useRecoilValue(organizationAtom);
  const {
    data: season,
    isFetching: isSeasonFetching,
    error: error
  } = useAdminSeasonSeasonIdGet(seasonId as string);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error]);
  const { data: sports, isLoading: isSportLoading } = useAdminSportGet({
    organizationId: organizationId!
  });
  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId!
      })) || [],
    [sports]
  );
  const { control, reset } = useForm();

  useEffect(() => {
    if (!isSportLoading && !isSeasonFetching) {
      reset(
        {
          name: season?.data?.name,
          sportId: season?.data?.sportId,
          startDate: season?.data?.startDate
            ? new Date(season?.data?.startDate)
            : "",
          endDate: season?.data?.endDate ? new Date(season?.data?.endDate) : ""
        },
        {
          keepDirtyValues: true
        }
      );
    }
  }, [season, sportOptions]);

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminSeasonSeasonIdDelete();

  const onConfirmDelete = async () => {
    if (!toDelete?.seasonId) return;
    try {
      await deleteAsync({ seasonId: toDelete.seasonId });
      enqueueSnackbar("Season deleted successfully", {
        variant: "success"
      });
      setToDelete(null);
      navigate("/seasons");
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete the season.", {
        variant: "error"
      });
      setToDelete(null);
    }
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission("general.seasons", "DELETE");
      setPermissions({
        delete: del
      });
    };
    fetchPermissions();
  }, []);
  return (
    <Container>
      <Toolbar
        title="View Season"
        backBtnClick={() => navigate("/seasons")}
        editBtnClick={() => navigate(`/seasons/${seasonId}/edit`)}
        {...(permissions.delete && {
          deleteBtnClick: () => setToDelete(season!.data)
        })}
      />
      <Form>
        <Loader isLoading={isSeasonFetching}>
          <Grid container spacing={3}>
            <Grid xs={12} md={6} data-testid="SEASON_VIEW_NAME">
              <FormInput
                disabled
                control={control}
                name="name"
                type="text"
                label="Name"
                required={true}
                rules={{
                  required: "Name is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="SEASON_VIEW_SPORT">
              <FormSelect
                disabled
                control={control}
                name="sportId"
                label="Sport"
                required={true}
                isLoading={isSportLoading}
                options={sportOptions}
                rules={{
                  required: "Sport is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="SEASON_VIEW_START_DATE">
              <FormDatePicker
                disabled
                control={control}
                name="startDate"
                label="Start Date"
                required={true}
                rules={{
                  required: "Start Date is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="SEASON_VIEW_END_DATE">
              <FormDatePicker
                disabled
                control={control}
                name="endDate"
                label="End Date"
                required={true}
                rules={{
                  required: "End Date is required"
                }}
              />
            </Grid>
          </Grid>
        </Loader>
      </Form>
      <ConfirmationDialog
        open={!!toDelete}
        title="Delete Season"
        body={`Are you sure you want to delete ${toDelete?.name}?`}
        close={() => setToDelete(null)}
        onConfirm={onConfirmDelete}
        onCancel={() => setToDelete(null)}
        isConfirming={isDeleting}
        confirmBtnVariant="admin-warning"
        icon="warning"
      />
    </Container>
  );
};
