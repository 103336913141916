import "./calendar.css";
import { Button } from "@components/Button";
import { AddIcon, DownloadIcon } from "@components/Icons";
import { SearchInput } from "@components/SearchInput";
import {
  LinearProgress,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
  linearProgressClasses,
  styled,
  Button as MuiButton
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useRef, useState } from "react";
import type {
  EventObject,
  ExternalEventTypes,
  Options
} from "@toast-ui/calendar";
import { TZDate } from "@toast-ui/calendar";
import { theme } from "./theme";
import Calendar from "@toast-ui/react-calendar";
import "@toast-ui/calendar/dist/toastui-calendar.min.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { CalendarSearch } from "./CalendarSearch";
import { Tooltip as ReactTooltip } from "react-tooltip";
import liveStreamIcon from "@assets/icons/liveIconRed.svg";
import { useNavigate } from "react-router-dom";
import { Container } from "@components/crud/Container";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { CalendarEventCreateUpdate } from "./CalendarEventCreateUpdate";
import { CalendarEventView } from "./CalendarEventView";
import {
  ModelSetting,
  useAdminCalendarGet,
  useAdminCalendarUploadStatusGet,
  useAdminSettingsGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { RRule } from "rrule";
import { hasPermission } from "@services/Casbin";
import { useQueryClient } from "@tanstack/react-query";

const StyledButtonGroup = styled(ToggleButtonGroup)(() => ({
  borderRadius: "99px",
  width: "304px",
  height: "40px",
  backgroundColor: "#E5E5E5",
  padding: "4px"
}));
const StyledButton = styled(ToggleButton)(() => ({
  borderRadius: "99px",
  width: "101px",
  border: "none",
  backgroundColor: "#E5E5E5",
  fontSize: "14px",
  cursor: "pointer",
  fontWeight: 600,
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#FFF",
    borderRadius: "99px !important"
  },
  "&.Mui-selected": {
    backgroundColor: "#FFF !important",
    borderRadius: "99px !important",
    color: "#000"
  }
}));
const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 12,
  borderRadius: 8,
  marginTop: "8px",
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    background: "linear-gradient(90deg, #1ABC9C 0%, #00987A 100%)"
  }
}));
const StyledMuiButton = styled(MuiButton)(({ theme }) => ({
  borderRadius: "99px",
  height: "40px",
  minWidth: "100px",
  fontSize: "14px",
  fontWeight: 600,
  border: "2px solid",
  borderColor: theme.palette.primary.main,
  textTransform: "capitalize",
  "&:hover": {
    opacity: 0.8
  }
}));
const today = new TZDate();

export const CalendarView = () => {
  const organizationId = useRecoilValue(organizationAtom);
  const navigate = useNavigate();
  const [view, setView] = useState("month");
  const [searchOpen, setSearchOpen] = useState(false);
  const [eventDialogOpen, setEventDialogOpen] = useState(false);
  const [eventViewDialogOpen, setEventViewDialogOpen] = useState(false);
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    delete: false,
    import: false
  });
  const [eventDialogDetails, setEventDialogDetails] = useState({
    title: "",
    action: "",
    id: "",
    recurrenceId: "",
    isLiveStream: false,
    startDate: new Date(),
    endDate: new Date()
  });
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== null) setView(newAlignment);
  };

  const calendarRef = useRef<typeof Calendar>(null);
  const [selectedDateRangeText, setSelectedDateRangeText] = useState("");
  const [rangeStart, setRangeStart] = useState(today);
  const [rangeEnd, setRangeEnd] = useState(today);
  const [selectedView, setSelectedView] = useState(view);
  const initialCalendars: Options["calendars"] = [
    {
      id: "0",
      name: "Private",
      backgroundColor: "#1ABC9C",
      borderColor: "#1ABC9C",
      dragBackgroundColor: "#9e5fff"
    }
  ];
  const { data: status, refetch: statusRefetch } =
    useAdminCalendarUploadStatusGet({
      organizationId: organizationId!
    });
  useEffect(() => {
    let timeoutId;

    const checkStatus = () => {
      if (
        status?.data?.status === "PROCESSING" ||
        status?.data?.status === "PENDING"
      ) {
        statusRefetch();
        timeoutId = setTimeout(checkStatus, 5000);
      }
    };
    checkStatus();
    return () => clearTimeout(timeoutId);
  }, [status?.data]);

  const getCalInstance = useCallback(
    () => calendarRef.current?.getInstance?.(),
    []
  );
  const [initialEvents, setInitialEvents] = useState([]);

  const queryClient = useQueryClient();
  const preFetchedCalendarEvents = queryClient.getQueryData([
    "CalendarEvents",
    organizationId
  ]);
  useEffect(() => {
    //@ts-ignore
    if (
      preFetchedCalendarEvents &&
      preFetchedCalendarEvents?.data &&
      preFetchedCalendarEvents?.status == 200
    ) {
      const events: Partial<EventObject>[] = [];
      //@ts-ignore
      preFetchedCalendarEvents.data.map((event) => {
        event.recurrences!.map((recurrence) => {
          let rule = "Does Not Repeat";
          if (event.repeat) {
            rule = RRule.fromString(event.repeat).toText();
            rule = rule.charAt(0).toUpperCase() + rule.slice(1);
          }
          events.push({
            id: event.eventId,
            calendarId: 0,
            title: event.name,
            category: "time",
            start: new Date(recurrence.start!),
            end: new Date(recurrence.end!),
            isReadOnly: true,
            isVisible: true,
            // using raw for timezone
            raw: event.timezone,
            recurrenceRule: rule,
            // using isFocoused for livestream flag
            isFocused: recurrence.streamId ? true : false,
            //using dueDateClass for recurrenceId
            dueDateClass: recurrence.recurrenceId
          });
        });
      });
      //@ts-ignore
      setInitialEvents(events);
    }
  }, []);
  const startDate = new Date(rangeStart);
  startDate.setUTCDate(1);
  startDate.setUTCHours(0, 0, 0, 0);
  startDate.setUTCMonth(startDate.getUTCMonth() - 3);

  const endDate = new Date(rangeEnd);
  endDate.setUTCHours(23, 59, 59, 0);
  endDate.setUTCMonth(endDate.getUTCMonth() + 3);

  const { data: calendar, refetch: refetchCalendar } = useAdminCalendarGet({
    organizationId: organizationId!,
    startDate: encodeURI(startDate.toISOString()),
    endDate: encodeURI(endDate.toISOString())
  });
  useEffect(() => {
    if (calendar?.data) {
      const events: Partial<EventObject>[] = [];
      calendar.data.forEach((event) => {
        event.recurrences!.forEach((recurrence) => {
          let rule = "Does Not Repeat";
          if (event.repeat) {
            rule = RRule.fromString(event.repeat).toText();
            rule = rule.charAt(0).toUpperCase() + rule.slice(1);
          }
          const newEvent: Partial<EventObject> = {
            id: event.eventId,
            calendarId: 0,
            title: event.name,
            category: "time",
            start: new Date(recurrence.start!),
            end: new Date(recurrence.end!),
            isReadOnly: true,
            isVisible: true,
            raw: event.timezone, // using raw for timezone
            recurrenceRule: rule,
            isFocused: recurrence.streamId ? true : false, // using isFocused for livestream flag
            dueDateClass: recurrence.recurrenceId // using dueDateClass for recurrenceId
          };
          events.push(newEvent);
        });
      });
      setInitialEvents(events);
    }
  }, [calendar?.data]);

  const eventTemplate = (schedule) => {
    const startDate = new Date(schedule.start);
    const endDate = new Date(schedule.end);
    const isSunday = startDate.getDay() === 0;
    return `
    <div
      style="overflow:hidden;text-overflow:ellipsis"
      data-tooltip-id="tooltip"
      data-title-attr="${schedule.title}"
      data-startDate-attr="${startDate}"
      data-endDate-attr="${endDate}"
      data-timeZone-attr="${schedule.raw}"
      data-recurrence-attr="${schedule.recurrenceRule}"
      data-tooltip-place=${
        isSunday && selectedView == "month" ? "right" : "top"
      }
      data-isLive-attr=${schedule.isFocused}
    >
      ${schedule.title}
    </div>
  `;
  };
  const template = {
    time(schedule) {
      return eventTemplate(schedule);
    },
    weekDayName(model) {
      const isToday =
        model.dateInstance.d.d.getDate() == today.getDate() &&
        model.dateInstance.d.d.getMonth() == today.getMonth() &&
        model.dateInstance.d.d.getFullYear() == today.getFullYear();
      if (isToday)
        return `<div style="height: 40px; width:76px; text-align:center;font-size:14px; background-color:#4f46e5; cursor:pointer; padding:3px 5px 3px 5px; color: #fff; border-radius:99px"><span style="font-size:14px,">${model.dayName}</span>&nbsp;&nbsp;<span style="font-size:14px">${model.date}</span></div>`;
      else
        return `<div style="cursor:pointer;"><span style="font-size:14px">${model.dayName}</span>&nbsp;&nbsp;<span style="font-size:14px">${model.date}</span></div>`;
    },
    monthDayName(model) {
      return `<div><span style="font-size:14px;">${model.label}</span></div>`;
    },
    monthGridHeaderExceed(hiddenEvents) {
      if (hiddenEvents == 1)
        return <div className="more">{hiddenEvents} more event</div>;
      if (hiddenEvents > 1)
        return <div className="more">{hiddenEvents} more events</div>;
    }
  };
  const updateRenderRangeText = useCallback(() => {
    const calInstance = getCalInstance();
    if (!calInstance) {
      setSelectedDateRangeText("");
    }

    const viewName = calInstance.getViewName();
    const calDate = calInstance.getDate();
    const rangeStart = calInstance.getDateRangeStart();
    const rangeEnd = calInstance.getDateRangeEnd();
    setRangeStart(calInstance.getDateRangeStart());
    setRangeEnd(calInstance.getDateRangeEnd());
    let year = calDate.getFullYear();
    let date = calDate.getDate();
    let dateRangeText: string;

    switch (viewName) {
      case "month": {
        dateRangeText = `${new Intl.DateTimeFormat("en-US", {
          month: "short"
        }).format(calDate)} ${year}`;
        break;
      }
      case "week": {
        year = rangeStart.getFullYear();
        date = rangeStart.getDate();
        const endDate = rangeEnd.getDate();

        const start = `${new Intl.DateTimeFormat("en-US", {
          month: "short"
        }).format(rangeStart)} ${date < 10 ? "0" : ""}${date}, ${year}`;
        const end = `${new Intl.DateTimeFormat("en-US", {
          month: "short"
        }).format(rangeEnd)} ${endDate < 10 ? "0" : ""}${endDate}, ${year}`;
        dateRangeText = `${start} - ${end}`;
        break;
      }
      default:
        dateRangeText = `${new Intl.DateTimeFormat("en-US", {
          month: "short"
        }).format(calDate)} ${date}, ${year}`;
    }

    setSelectedDateRangeText(dateRangeText);
  }, [getCalInstance]);

  useEffect(() => {
    setSelectedView(view);
  }, [view]);

  const { data: settingTimeInterval } = useAdminSettingsGet({
    organizationId: organizationId!,
    parentId: "general"
  });

  const convertView = (period) => {
    switch (period) {
      case "DAILY":
        return "day";
      case "WEEKLY":
        return "week";
      case "MONTHLY":
        return "month";
      default:
        return "month";
    }
  };
  useEffect(() => {
    if (settingTimeInterval?.data) {
      const settingsOrg = settingTimeInterval.data as ModelSetting[];
      const calendarViewSettings = settingsOrg.find(
        (s) => s.settingId === "general.default-calendar-view"
      );
      if (calendarViewSettings) {
        const orgSetting =
          calendarViewSettings.organizationSettings &&
          calendarViewSettings.organizationSettings.length > 0
            ? calendarViewSettings.organizationSettings.find(
                (o) => o.organizationId === organizationId
              )?.value
            : calendarViewSettings.value || calendarViewSettings.default;
        if (orgSetting) {
          setView(convertView(orgSetting)!);
        }
      }
    }
  }, [settingTimeInterval]);

  useEffect(() => {
    updateRenderRangeText();
  }, [selectedView, updateRenderRangeText]);

  const onAfterRenderEvent: ExternalEventTypes["afterRenderEvent"] = (res) => {
    const eleme = document.querySelector(`[data-event-id="${res.id}"]`);
    if (eleme) {
      const startDate = new Date(res.start);
      const isSunday = startDate.getDay() === 0;
      eleme.setAttribute("data-tooltip-id", "tooltip");
      eleme.setAttribute(
        "data-tooltip-place",
        isSunday && selectedView == "month" ? "right" : "top"
      );
      eleme.setAttribute("data-title-attr", res.title);
      eleme.setAttribute("data-startDate-attr", res.start);
      eleme.setAttribute("data-endDate-attr", res.end);
      eleme.setAttribute("data-timeZone-attr", res.raw);
      eleme.setAttribute("data-recurrence-attr", res.recurrenceRule);
      eleme.setAttribute("data-isLive-attr", res.isFocused);
    }
  };

  const onClickDayName: ExternalEventTypes["clickDayName"] = (res) => {
    setEventDialogOpen(false);
    getCalInstance().setDate(new Date(res.date));
    updateRenderRangeText();
    setView("day");
  };

  const onClickNavi = (actionName) => {
    getCalInstance()[actionName]();
    updateRenderRangeText();
  };

  const onClickEvent: ExternalEventTypes["clickEvent"] = (res) => {
    setEventDialogDetails({
      action: "view",
      id: res.event.id,
      recurrenceId: res.event.dueDateClass,
      title: "View Event",
      isLiveStream: res.event.isFocused,
      startDate: res.event.start,
      endDate: res.event.end
    });
    setEventViewDialogOpen(true);
  };

  const onClickSchedule: ExternalEventTypes["selectDateTime"] = (res) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    const resStart = new Date(res.start);
    const resStartDate = new Date(
      resStart.getFullYear(),
      resStart.getMonth(),
      resStart.getDate()
    );

    if (resStartDate >= today) {
      if (permissions.create) {
        setEventDialogDetails({
          action: "create",
          id: "",
          recurrenceId: "",
          title: "Add Event",
          isLiveStream: false,
          //@ts-ignore
          startDate:
            selectedView == "month"
              ? res.start.setHours(now.getHours())
              : res.start,
          //@ts-ignore
          endDate:
            selectedView == "month" ? res.end.setHours(now.getHours()) : res.end
        });
        setEventDialogOpen(true);
      }
    }
  };
  const handleMonthDateClick = (e) => {
    setEventDialogOpen(false);
    const targetElement = e.target.parentNode;
    const calInstance = getCalInstance();
    const computedStyle = window.getComputedStyle(targetElement);
    const date = targetElement.innerText;
    if (computedStyle.color === "rgb(187, 187, 187)") {
      if (date < 18) {
        const newDate = new Date(calInstance.getDate());
        newDate.setMonth(newDate.getMonth() + 1);
        newDate.setDate(date);
        calInstance.setDate(newDate);
        updateRenderRangeText();
        setView("day");
      }
      if (date > 18) {
        const newDate = new Date(calInstance.getDate());
        newDate.setMonth(newDate.getMonth() - 1);
        newDate.setDate(date);
        calInstance.setDate(newDate);
        updateRenderRangeText();
        setView("day");
      }
    }
    if (
      computedStyle.color === "rgb(51, 51, 51)" ||
      computedStyle.color === "rgb(0, 150, 136)"
    ) {
      const newDate = new Date(calInstance.getDate());
      newDate.setDate(date);
      calInstance.setDate(newDate);
      updateRenderRangeText();
      setView("day");
    }
  };
  const element = document.querySelectorAll(
    ".toastui-calendar-grid-cell-header"
  );
  useEffect(() => {
    if (element) {
      element.forEach((element) => {
        element.addEventListener("dblclick", handleMonthDateClick);
      });

      return () => {
        element.forEach((element) => {
          element.removeEventListener("dblclick", handleMonthDateClick);
        });
      };
    }
  }, [element]);

  const eventDialogClose = () => {
    setEventDialogOpen(false);
    setEventViewDialogOpen(false);
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("general.calendar", "ADD");
      const edit = await checkPermission("general.calendar", "EDIT");
      const del = await checkPermission("general.calendar", "DELETE");
      const importPermission = await checkPermission(
        "general.calendar-import",
        "ON"
      );
      setPermissions({
        create,
        edit,
        delete: del,
        import: importPermission
      });
    };
    fetchPermissions();
  }, [organizationId]);

  const checkTodayButtonDisabled = () => {
    // instead od doing this check if today lies between ranfeStart and rangeEnd
    const today = new Date();
    if (!getCalInstance()) return false;
    const startDate = new Date(getCalInstance().getDateRangeStart());
    const endDate = new Date(getCalInstance().getDateRangeEnd());
    if (today >= startDate && today <= endDate) {
      return true;
    }
  };
  return (
    <>
      <Toolbar
        sx={{
          width: "100%",
          justifyContent: "space-between",
          marginTop: "32px"
        }}
      >
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <StyledButtonGroup
            color="primary"
            value={view}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <StyledButton value="day" data-testid="dayBtn">
              Day
            </StyledButton>
            <StyledButton value="week" data-testid="weekBtn">
              Week
            </StyledButton>
            <StyledButton value="month" data-testid="monthBtn">
              Month
            </StyledButton>
          </StyledButtonGroup>
          <Box sx={{ marginLeft: "16px" }}>
            <StyledMuiButton
              data-testid="todayBtn"
              disabled={checkTodayButtonDisabled()}
              variant="outlined"
              onClick={() => {
                getCalInstance().today();
                updateRenderRangeText();
              }}
            >
              Today
            </StyledMuiButton>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap={2}
        >
          <SearchInput
            data-testid="searchBtn"
            placeholder="Search"
            required={false}
            onClick={() => setSearchOpen(true)}
            sx={{
              maxWidth: 230,
              borderRadius: "99px",
              height: "40px"
            }}
          />{" "}
          {permissions.import && (
            <StyledMuiButton
              onClick={() => navigate("/calendar/import-events")}
              variant="outlined"
              data-testId="importBtn"
              startIcon={<DownloadIcon />}
            >
              Import
            </StyledMuiButton>
          )}
          {permissions.create && (
            <Button
              onClick={() => {
                setEventDialogDetails({
                  action: "create",
                  id: "",
                  recurrenceId: "",
                  title: "Add Event",
                  isLiveStream: false,

                  startDate: new Date(),
                  endDate: new Date()
                });
                setEventDialogOpen(true);
              }}
              variant="admin-primary"
              startIcon={<AddIcon />}
            >
              Add
            </Button>
          )}
        </Box>
      </Toolbar>
      <div style={{ width: "100%" }} data-testId="eventCreateUpdateDialog">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "24px",
            marginTop: "16px"
          }}
        >
          <div
            style={{ display: "flex", cursor: "pointer" }}
            data-testid="previous-period-button"
            onClick={() => onClickNavi("prev")}
          >
            <ChevronLeftIcon
              type="button"
              style={{ marginLeft: "32px", color: "#007AFF", fontSize: "38px" }}
            />
            <span
              style={{ fontSize: "24px", fontWeight: 400, color: "#007AFF" }}
            >
              Previous{" "}
              {selectedView.charAt(0).toUpperCase() + selectedView.slice(1)}
            </span>
          </div>
          <div>
            <div
              style={{ fontSize: "24px", fontWeight: 500, lineHeight: "30px" }}
            >
              {selectedDateRangeText}
            </div>
          </div>
          <div
            style={{ display: "flex", cursor: "pointer" }}
            data-testid="next-period-button"
            onClick={() => onClickNavi("next")}
          >
            <span
              style={{ fontSize: "24px", fontWeight: 400, color: "#007AFF" }}
            >
              Next{" "}
              {selectedView.charAt(0).toUpperCase() + selectedView.slice(1)}
            </span>
            <ChevronRightIcon
              type="button"
              style={{
                marginRight: "32px",
                color: "#007AFF",
                fontSize: "38px"
              }}
            />
          </div>
        </div>
        {status?.data &&
          (status?.data.status == "PROCESSING" ||
            status?.data.status == "PENDING") && (
            <Container
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "0 32px 24px 32px !important"
              }}
            >
              <Typography
                data-testid="import-player-overview-status"
                sx={{
                  letterSpacing: "1.2px",
                  fontSize: "12px",
                  fontWeight: 400
                }}
              >
                IN PROGRESS...
              </Typography>
              <Grid container justifyContent="space-between">
                <Grid>
                  <Typography
                    data-testid="import-player-overview-completed-status"
                    sx={{ color: "#3B6CF8", fontWeight: 600, fontSize: "14px" }}
                  >
                    {status.data.progress}% Completed (
                    {(status.data.progress! * status.data.rowCount!) / 100} of{" "}
                    {status.data.rowCount!} Rows Events Imported)
                  </Typography>
                </Grid>
              </Grid>
              <BorderLinearProgress
                variant="determinate"
                value={(status.data.progress! * status.data.rowCount!) / 100}
              />
            </Container>
          )}
        <Calendar
          usageStatistics={false}
          height="900px"
          calendars={initialCalendars}
          template={template}
          events={initialEvents}
          useDetailPopup={true}
          useFormPopup={true}
          useMoreEventsPopup={false}
          view={selectedView}
          week={{
            eventView: ["time"],
            taskView: false
          }}
          month={{ visibleEventCount: 3 }}
          theme={theme}
          ref={calendarRef}
          onAfterRenderEvent={onAfterRenderEvent}
          onClickDayName={onClickDayName}
          onClickEvent={onClickEvent}
          onSelectDateTime={onClickSchedule}
          onClickMoreEventsBtn={(model) => {
            calendarRef.current?.getInstance()?.setDate(new Date(model.date));
            updateRenderRangeText();
            setView("day");
          }}
        />
      </div>
      <ReactTooltip
        opacity={1}
        border="2px solid #1ABC9C"
        id="tooltip"
        style={{
          backgroundColor: "#fff",
          color: "#222",
          width: "270px",
          height: "fit-content",
          borderRadius: "12px"
        }}
        place="top"
        render={({ activeAnchor }) => {
          const dayOptions: { weekday: "short" } = { weekday: "short" };
          const monthOptions: { month: "short" } = { month: "short" };
          const timeOptions: {
            hour: "numeric";
            minute: "numeric";
            hour12: true;
          } = {
            hour: "numeric",
            minute: "numeric",
            hour12: true
          };
          const title = activeAnchor?.getAttribute("data-title-attr");
          const isLiveStream = activeAnchor?.getAttribute("data-islive-attr");
          const startDate = new Date(
            //@ts-ignore
            activeAnchor?.getAttribute("data-startDate-attr")
          );
          const endDate = new Date(
            //@ts-ignore
            activeAnchor?.getAttribute("data-endDate-attr")
          );
          const timeZone = activeAnchor?.getAttribute("data-timeZone-attr");
          const recurrence = activeAnchor
            ?.getAttribute("data-recurrence-attr")
            .replace(/\s+for\s+\d+\s+times/, "");
          return (
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="tooltip-title">{title}</div>
                {isLiveStream == "true" && (
                  <img style={{ color: "red" }} src={liveStreamIcon} />
                )}
              </div>
              <div
                style={{
                  color: "#64748B",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "18px",
                  marginTop: "3px"
                }}
              >
                <div>
                  {startDate.toLocaleDateString("en-US", dayOptions)},{" "}
                  {startDate.toLocaleDateString("en-US", monthOptions)}{" "}
                  {startDate.getDate()} &#x25cf;{" "}
                  {startDate.toLocaleTimeString("en-US", timeOptions)} -{" "}
                  {endDate.toLocaleTimeString("en-US", timeOptions)}
                </div>
                <div>{timeZone}</div>
                <div>{recurrence}</div>
              </div>
            </div>
          );
        }}
      />
      {searchOpen && (
        <CalendarSearch
          isSearchOpen={searchOpen}
          toggleSearchOpen={setSearchOpen}
          reLoadCalendar={refetchCalendar}
        />
      )}
      {eventDialogOpen && (
        <CalendarEventCreateUpdate
          details={eventDialogDetails}
          button={`Save`}
          onClose={eventDialogClose}
          reLoadCalendar={refetchCalendar}
        />
      )}
      {eventViewDialogOpen && (
        <CalendarEventView
          details={eventDialogDetails}
          button={`Save`}
          onClose={eventDialogClose}
          hasPermissionToEdit={permissions.edit}
          hasPermissionToDelete={permissions.delete}
          reLoadCalendar={refetchCalendar}
          openEdit={() => {
            setEventDialogDetails({
              action: "edit",
              id: eventDialogDetails.id,
              recurrenceId: eventDialogDetails.recurrenceId,
              title: "Edit Event",
              isLiveStream: eventDialogDetails.isLiveStream,
              startDate: eventDialogDetails.startDate,
              endDate: eventDialogDetails.endDate
            });
            eventDialogClose();
            setEventDialogOpen(true);
          }}
        />
      )}
    </>
  );
};
