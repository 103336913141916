import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  ModelMedia,
  ModelScoringOption,
  getLookupCountryGetQueryKey,
  getSportSportIdGetQueryKey,
  lookupCountryGet,
  sportSportIdGet,
  useSportSportIdPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useQueries } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  styled,
  FormLabel,
  Button,
  IconButton,
  FormHelperText
} from "@mui/material";
import SportsPickerImage from "@assets/images/iconPicker.png";
import SportsPortraitPickerImage from "@assets/images/portraitImagePicker.png";
import SportsLandscapePickerImage from "@assets/images/landscapeImagePicker.png";
import { getBase64 } from "@utils/imageCompression";
import MediaContianerWithDelete from "@components/MediaContaierWithDelete";
import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { Add, DeleteOutline } from "@mui/icons-material";
import { SearchInput } from "@components/SearchInput";
import CountryList from "./CountryList";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

const FormInputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const StyledLabel = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #000000;
  opacity: 0.7;
  text-transform: uppercase;
`;

export const SportEdit = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const { sportId } = useParams();
  const [image, setImage] = useState(null);
  const [files, setFiles] = useState(null);
  const inputFileRef = useRef(null);
  const [portraitImage, setPortraitImage] = useState<File | ModelMedia | null>(
    null
  );
  const [landscapeImage, setLandscapeImage] = useState<
    File | ModelMedia | null
  >(null);
  const inputPortraitFileRef = useRef<HTMLInputElement>(null);
  const inputLandscapeFileRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [iconFieldTouched, setIconFieldTouched] = useState(false);
  const [portraitFieldTouched, setPortraitFieldTouched] = useState(false);
  const [landscapeFieldTouched, setLandscapeFieldTouched] = useState(false);
  const [search, setSearch] = useState("");

  const [sportQuery, countryQuery] = useQueries({
    queries: [
      {
        queryKey: getSportSportIdGetQueryKey(sportId as string),
        queryFn: () =>
          sportSportIdGet(sportId as string).then((res) => res.data),
        onError: () => {
          navigate("/not-found");
        }
      },
      {
        queryKey: getLookupCountryGetQueryKey(),
        queryFn: () => lookupCountryGet().then((res) => res.data)
      }
    ]
  });
  const { data: sport, isLoading: isSportLoading } = sportQuery;
  const { data: countries, isLoading: isCountriesLoading } = countryQuery;

  const defaultValues = useMemo<{
    name: string;
    icon: string;
    isIntervalBased: boolean;
    intervalSingular?: string;
    intervalPlural?: string;
    intervalAbbreviation?: string;
    scoringOptions?: ModelScoringOption[];
    countries: {
      countryId: string;
      countryName: string;
      selected: boolean;
      name: string;
      contestType?: string;
    }[];
    portraitImage?: File | null | string;
    landscapeImage?: File | null | string;
  }>(() => {
    if (!countries || !sport) return { name: "", countries: [] };
    return {
      name: sport.name,
      icon: sport.icon,
      isIntervalBased: !!sport.isIntervalBased,
      intervalSingular: sport.intervalSingular,
      intervalPlural: sport.intervalPlural,
      intervalAbbreviation: sport.intervalAbbreviation,
      scoringOptions: sport.scoringOptions,
      allowMultipleScoring: sport.scoringOptions[0]?.name,
      allowScoring: sport.scoringOptions && sport.scoringOptions.length > 0,
      countries: countries.reduce((acc, country) => {
        const sportCountry = sport?.countries.find(
          (c) => c.countryId === country.countryId
        );
        acc[country.countryId!] = {
          countryId: country.countryId!,
          countryName: country.name!,
          selected: sportCountry ? true : false,
          name: sportCountry ? sportCountry.name : "",
          contestType: sportCountry && sportCountry.isMatch ? "MATCH" : "GAME"
        };
        return acc;
      }, {})
    };
  }, [countries, sport]);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isDirty, isValid },
    trigger,
    reset,
    getValues
  } = useForm({
    mode: "onBlur",
    validationMode: "onBlur",
    defaultValues
  });
  useEffect(() => {
    reset(defaultValues);
    setImage(sport?.icon);
    if (sport?.icon) setValue("icon", sport.icon);
    if (sport?.portraitImage) {
      setPortraitImage(sport?.portraitImage);
      setValue("portraitImage", "exists");
    }
    if (sport?.landscapeImage) {
      setLandscapeImage(sport?.landscapeImage);
      setValue("landscapeImage", "exists");
    }
  }, [defaultValues, sport]);

  const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  };

  const debouncedSearch = useDebounce(search, 100);

  const renderCountryList = useMemo(() => {
    if (!defaultValues.countries) return null;
    return (
      <CountryList
        countryList={defaultValues.countries}
        control={control}
        search={debouncedSearch}
        disable={false}
      />
    );
  }, [defaultValues.countries, debouncedSearch]);

  const { mutate: save, isLoading: isSaving } = useSportSportIdPut();
  const saveHandler = async (formValues) => {
    const values = {
      name: formValues.name,
      icon: formValues.icon,
      isIntervalBased: sportHasIntervals,
      intervalSingular: sportHasIntervals ? formValues.intervalSingular : "",
      intervalPlural: sportHasIntervals ? formValues.intervalPlural : "",
      intervalAbbreviation: sportHasIntervals
        ? formValues.intervalAbbreviation
        : "",
      scoringOptions: allowScoring
        ? scoringOptions.map((opt) => {
            return {
              name: opt.name,
              value: opt.value.split(",").map(Number)
            };
          })
        : [],
      countries: Object.keys(formValues.countries)
        .filter((key) => !!formValues.countries[key].selected)
        .map((key) => {
          const country = formValues.countries[key];
          return {
            countryId: country.countryId,
            name: country.name,
            isMatch: country.contestType === "MATCH"
          };
        })
    };

    if (portraitImage && !portraitImage.mediaId) {
      setLoading(true);
      const promise = await uploadMediaUsingPresignedUrl(portraitImage!);
      values["portraitImageId"] = promise;
    }
    if (landscapeImage && !landscapeImage.mediaId) {
      setLoading(true);
      const promise = await uploadMediaUsingPresignedUrl(landscapeImage!);
      values["landscapeImageId"] = promise;
    }

    if (files) {
      try {
        const response = await getBase64(files);
        values["icon"] = response;
      } catch (error) {
        enqueueSnackbar("Failed to generate image data!", {
          variant: "error"
        });
        return;
      }
    }
    saveCall(values);
  };

  const saveCall = (values) => {
    save(
      {
        sportId: sportId as string,
        data: values
      },
      {
        onSuccess: () => {
          setLoading(false);
          enqueueSnackbar("Sport updated successfully!", {
            variant: "success"
          });
          navigate(`/sports/${sportId}`);
        },
        onError: () => {
          setLoading(false);
          enqueueSnackbar("Failed to update sport!", { variant: "error" });
        }
      }
    );
  };

  const onImageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field,
    type
  ) => {
    const tempFiles = (event.target as HTMLInputElement).files;
    field.onChange(event);
    if (type === "portrait" && tempFiles && tempFiles[0]) {
      setPortraitImage(tempFiles[0]);
    }
    if (type === "landscape" && tempFiles && tempFiles[0]) {
      setLandscapeImage(tempFiles[0]);
    }
    if (type === "icon") {
      if (tempFiles && tempFiles[0]) setFiles(tempFiles[0]);
    }
  };

  const [sportHasIntervals, setSportHasIntervals] = useState(false);
  const [allowScoring, setAllowScoring] = useState(false);
  const [allowMultipleScoring, setAllowMultipleScoring] = useState(false);
  const [scoringOptions, setScoringOptions] = useState<
    { name?: string; value: string }[]
  >([
    {
      value: ""
    }
  ]);
  useEffect(() => {
    if (sport) {
      if (sport.scoringOptions && sport.scoringOptions.length > 0) {
        setAllowScoring(true);
        if (sport.scoringOptions[0].name) {
          setAllowMultipleScoring(true);
        }
      }
      if (sport.isIntervalBased) setSportHasIntervals(true);
      if (sport.scoringOptions.length > 0) {
        const options = sport.scoringOptions.map((op) => {
          const val: { name?: string; value: string } = {
            value: op.value?.join(",") || ""
          };
          if (op.name) val.name = op.name;
          return val;
        });
        setScoringOptions([...options]);
      }
    }
  }, [sport]);
  return (
    <Container>
      <Toolbar title="Edit Sport" />
      <Form>
        <Loader
          isLoading={
            isCountriesLoading ||
            isSportLoading ||
            defaultValues.countries.length === 0 ||
            !defaultValues?.name
          }
        >
          <Grid data-testid="sport-edit-form" container spacing={3}>
            <Grid xs={12} md={12} data-testid="sport-icon">
              <Controller
                name="icon"
                control={control}
                rules={{
                  required: "Icon is required"
                }}
                render={({ field }) => (
                  <FormInputContainer>
                    <StyledFormLabel required={true}>
                      <Typography display="inline" variant="formLabel">
                        {"Sports Icon"}
                      </Typography>
                    </StyledFormLabel>
                    <Grid container spacing={3} xs={12} md={12}>
                      <Grid xs={12} md={2}>
                        {image || files ? (
                          <MediaContianerWithDelete
                            onConfirmDelete={() => {
                              setFiles(null);
                              setImage(null);
                              inputFileRef.current.value = "";
                            }}
                            index={0}
                          >
                            <img
                              src={image ?? URL.createObjectURL(files)}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain"
                              }}
                            />
                          </MediaContianerWithDelete>
                        ) : (
                          <img
                            src={SportsPickerImage}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain"
                            }}
                            onClick={() => {
                              setIconFieldTouched(true);
                              inputFileRef.current.click();
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <input
                      type="file"
                      onChange={(e) => onImageChange(e, field, "icon")}
                      ref={inputFileRef}
                      style={{ display: "none" }}
                      accept="image/*"
                    />
                    {iconFieldTouched && !files && (
                      <FormHelperText error>Icon is required</FormHelperText>
                    )}
                  </FormInputContainer>
                )}
              />
            </Grid>
            <Grid data-testid="sport-name" xs={6}>
              <FormInput
                control={control}
                name="name"
                type="text"
                label="Name"
                required={true}
                rules={{
                  required: "Name is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={12} data-testid="sport-portraitImage">
              <Controller
                name="portraitImage"
                control={control}
                rules={{
                  required: "Portrait Image is required"
                }}
                render={({ field }) => (
                  <FormInputContainer>
                    <StyledFormLabel required={true}>
                      <Typography display="inline" variant="formLabel">
                        {"Default Portrait Image"}
                      </Typography>
                    </StyledFormLabel>
                    <Grid container spacing={3} xs={12} md={12}>
                      <Grid xs={12} md={2}>
                        {portraitImage ? (
                          <MediaContianerWithDelete
                            onConfirmDelete={() => {
                              setPortraitImage(null);
                              inputPortraitFileRef.current.value = "";
                            }}
                            index={0}
                          >
                            <img
                              src={
                                portraitImage.mediaId
                                  ? portraitImage.baseUrl + portraitImage.path
                                  : URL.createObjectURL(portraitImage)
                              }
                              style={{
                                width: "100%",
                                height: "290px"
                              }}
                            />
                          </MediaContianerWithDelete>
                        ) : (
                          <img
                            src={SportsPortraitPickerImage}
                            style={{
                              width: "100%",
                              height: "290px"
                            }}
                            onClick={() => {
                              setPortraitFieldTouched(true);
                              inputPortraitFileRef.current?.click();
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <input
                      type="file"
                      onChange={(e) => onImageChange(e, field, "portrait")}
                      ref={inputPortraitFileRef}
                      style={{ display: "none" }}
                      accept="image/*"
                    />
                    {portraitFieldTouched && !portraitImage && (
                      <FormHelperText error>
                        Default Portrait Image is required
                      </FormHelperText>
                    )}
                  </FormInputContainer>
                )}
              />
            </Grid>
            <Grid xs={12} md={12} data-testid="sport-landscapeImage">
              <Controller
                name="landscapeImage"
                control={control}
                rules={{
                  required: "Portrait Image is required"
                }}
                render={({ field }) => (
                  <FormInputContainer>
                    <StyledFormLabel required={true}>
                      <Typography display="inline" variant="formLabel">
                        {"Default Landscape Image"}
                      </Typography>
                    </StyledFormLabel>
                    <Grid container spacing={3} xs={12} md={12}>
                      <Grid xs={12} md={2}>
                        {landscapeImage ? (
                          <MediaContianerWithDelete
                            onConfirmDelete={() => {
                              setLandscapeImage(null);
                              inputLandscapeFileRef.current.value = "";
                            }}
                            index={0}
                          >
                            <img
                              src={
                                landscapeImage.mediaId
                                  ? landscapeImage.baseUrl + landscapeImage.path
                                  : URL.createObjectURL(landscapeImage)
                              }
                              style={{
                                width: "290px",
                                height: "163px"
                              }}
                            />
                          </MediaContianerWithDelete>
                        ) : (
                          <img
                            src={SportsLandscapePickerImage}
                            style={{
                              width: "290px",
                              height: "163px"
                            }}
                            onClick={() => {
                              setLandscapeFieldTouched(true);
                              inputLandscapeFileRef.current?.click();
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <input
                      type="file"
                      onChange={(e) => onImageChange(e, field, "landscape")}
                      ref={inputLandscapeFileRef}
                      style={{ display: "none" }}
                      accept="image/*"
                    />
                    {landscapeFieldTouched && !landscapeImage && (
                      <FormHelperText error>
                        Default Landscape Image is required
                      </FormHelperText>
                    )}
                  </FormInputContainer>
                )}
              />
            </Grid>
            <Grid>
              <FormCheckbox
                control={control}
                name="isIntervalBased"
                label="Sport has intervals"
                onChange={() => {
                  setSportHasIntervals(!sportHasIntervals);
                }}
              />
            </Grid>
            {sportHasIntervals && (
              <Grid container direction="row" spacing="25px" xs={12} md={12}>
                <Grid xs={4}>
                  <FormInput
                    control={control}
                    name="intervalSingular"
                    type="text"
                    label="Singular Interval"
                    rules={{
                      required: "Singular Interval is Required"
                    }}
                    required={true}
                  />
                </Grid>
                <Grid xs={4}>
                  <FormInput
                    control={control}
                    name="intervalPlural"
                    type="text"
                    label="Plural Interval"
                    rules={{
                      required: "Plural Interval is Required"
                    }}
                    required={true}
                  />
                </Grid>
                <Grid xs={4}>
                  <FormInput
                    control={control}
                    name="intervalAbbreviation"
                    type="text"
                    label="Interval Abbreviation"
                    rules={{
                      required: "Interval Abbreviation is Required"
                    }}
                    required={true}
                  />
                </Grid>
              </Grid>
            )}
            {sportHasIntervals && (
              <Grid container direction="row" spacing="30px" xs={12}>
                <Grid>
                  <FormCheckbox
                    control={control}
                    // inverseValue={!allowScoring}
                    name="allowScoring"
                    label="Allow Scoring"
                    onChange={() => {
                      setAllowScoring(!allowScoring);
                    }}
                  />
                </Grid>
                {allowScoring && (
                  <Grid container direction="row" spacing="30px">
                    <Grid>
                      <FormCheckbox
                        control={control}
                        // inverseValue={allowMultipleScoring}
                        name="allowMultipleScoring"
                        label="Allow Multiple Scoring Options"
                        onChange={(e) => {
                          setAllowMultipleScoring(!allowMultipleScoring);
                          // if false then remove value from scoring options
                          if (!e.target.checked) {
                            console.log(getValues("scoringOptions[0].value"));
                            setScoringOptions([
                              {
                                value: getValues(
                                  "scoringOptions[0].value"
                                ).join(",")
                              }
                            ]);
                          }
                        }}
                      />
                    </Grid>
                    {allowMultipleScoring && (
                      <Grid alignSelf="center">
                        <Button
                          variant="text"
                          style={{
                            color: "#007AFF",
                            textTransform: "none"
                          }}
                          onClick={() => {
                            const oldOptions = scoringOptions;
                            oldOptions.push({
                              name: "",
                              value: ""
                            });
                            oldOptions.map((s, i) => {
                              trigger(`scoringOptions[${i}].name`);
                              trigger(`scoringOptions[${i}].value`);
                            });
                            setScoringOptions([...oldOptions]);
                          }}
                          startIcon={<Add />}
                        >
                          Add Scoring Option
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            )}

            {allowScoring &&
              scoringOptions.map((option, idx) => (
                <Grid
                  container
                  direction="row"
                  spacing="25px"
                  xs={12}
                  md={12}
                  key={idx}
                  data-testid="scoring-options-container"
                >
                  {allowMultipleScoring && (
                    <Grid xs={scoringOptions.length > 1 ? 5.5 : 6}>
                      <FormInput
                        control={control}
                        name={`scoringOptions[${idx}].name`}
                        enteredValue={scoringOptions[idx].name}
                        onChange={(e) => {
                          const scoringOp = scoringOptions;
                          scoringOp[idx]["name"] = e.target.value;
                          setScoringOptions([...scoringOp]);
                        }}
                        type="text"
                        label="Scoring Option"
                        rules={{
                          required: "Scoring Option is Required"
                        }}
                        required={true}
                      />
                    </Grid>
                  )}
                  <Grid xs={scoringOptions.length > 1 ? 5.5 : 6}>
                    <FormInput
                      control={control}
                      name={`scoringOptions[${idx}].value`}
                      enteredValue={scoringOptions[idx].value}
                      type="text"
                      label="Scoring Values"
                      onChange={(e) => {
                        const scoringOp = scoringOptions;
                        scoringOp[idx].value = e.target.value;
                        setScoringOptions([...scoringOp]);
                      }}
                      rules={{
                        required: "Scoring Values is Required",
                        pattern: {
                          value: /^(\d+,)*\d*$/,
                          message:
                            "Only numbers separated by commas are allowed"
                        }
                      }}
                      required={true}
                    />
                    <Grid marginLeft="10px">
                      <Typography
                        variant="permissionNames"
                        style={{
                          color: "#B3B3B3"
                        }}
                      >
                        Options separated by comma
                      </Typography>
                    </Grid>
                  </Grid>
                  {scoringOptions.length > 1 && (
                    <Grid xs={1} alignSelf="center">
                      <IconButton
                        onClick={() => {
                          scoringOptions.map((s, i) => {
                            trigger(`scoringOptions[${i}].name`);
                            trigger(`scoringOptions[${i}].value`);
                          });
                          const scOptions = scoringOptions;
                          scOptions.splice(idx, 1);
                          setScoringOptions([...scOptions]);
                        }}
                      >
                        <DeleteOutline color="error" fontSize="large" />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              ))}
            <Grid xs={12}>
              <StyledLabel>Participating Countries</StyledLabel>
              <HeaderUnderLine width="100%" />
              <Box sx={{ marginTop: "8px" }}>
                <SearchInput
                  placeholder="Search"
                  required={false}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Box>
            </Grid>
            {renderCountryList}
          </Grid>
        </Loader>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler)}
        isDisabled={
          !isValid ||
          isSaving ||
          (scoringOptions.length == sport?.scoringOptions.length && !isDirty) ||
          loading ||
          !portraitImage ||
          !landscapeImage ||
          (!image && !files)
        }
        isLoading={isSaving || loading}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/sports")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
