import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { SyntheticEvent, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ImportPlayerOverview } from "./ImportPlayerOverview";
import { ImportedRowsView } from "./ImportedRowsView";
import { FailedRowsView } from "./FailedRowsView";
import { useAdminUploadUploadIdGet } from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";

export const ImportPlayerView = () => {
  const navigate = useNavigate();
  const { uploadId } = useParams();
  const [tab, setTab] = useState("Import Overview");
  const onTabChange = (event: SyntheticEvent, value: unknown) => {
    setTab(value as string);
  };
  const onBackClick = () => {
    navigate("/import-players");
  };

  const { data: query, isLoading: isLoading } = useAdminUploadUploadIdGet(
    uploadId!
  );
  const data = query?.data;
  useMemo(() => {
    console.log(query);
  }, [query]);

  if (!data) {
    return null;
  }

  return (
    <Loader isLoading={isLoading}>
      <Container>
        <Toolbar
          title="View Athletes / Players Upload"
          backBtnClick={onBackClick}
          tabs={{
            tabs: ["Import Overview", "Imported Rows", "Failed Rows"],
            onTabChange: onTabChange,
            activeTab: tab
          }}
          badgeCounts={[
            0,
            data.importedRecords?.length || 0,
            data.failedRecords?.length || 0
          ]}
        />
        <Form>
          <Grid container spacing={3}>
            {tab === "Import Overview" && (
              <Grid xs={12}>
                <ImportPlayerOverview data={data} />
              </Grid>
            )}
            {tab === "Imported Rows" && (
              <Grid xs={12}>
                <ImportedRowsView importedRows={data.importedRecords!} />
              </Grid>
            )}
            {tab === "Failed Rows" && (
              <Grid xs={12}>
                <FailedRowsView failedRows={data.failedRecords!} />
              </Grid>
            )}
          </Grid>
        </Form>
      </Container>
    </Loader>
  );
};
