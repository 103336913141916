import { DataGridRenderCellTooltip } from "@components/DataGridRenderCellTooltip";
import { DataGridRenderList } from "@components/DataGridRenderList";
import { TableView } from "@components/TableView";
import { GridColDef } from "@mui/x-data-grid";
import { organizationAtom } from "@recoil/auth";
import { getAdminLevels } from "@services/Network";
import {
  ModelSport,
  useAdminSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

export const Levels = () => {
  const navigate = useNavigate();
  const [refreshKey] = React.useState(0);
  const [organizationId] = useRecoilState(organizationAtom);
  const { data: sports } = useAdminSportGet({
    organizationId: organizationId!
  });
  const LIST_COLUMNS: GridColDef[] = [
    { headerName: "Name", field: "name", minWidth: 150, flex: 1 },
    { headerName: "Abbr", field: "abbv", minWidth: 100, flex: 1 },
    {
      headerName: "Sport",
      field: "sports",
      minWidth: 150,
      flex: 1,
      renderCell: DataGridRenderCellTooltip("name", 1)
    },
    {
      headerName: "Gender",
      field: "genders",
      minWidth: 100,
      flex: 1,
      valueGetter: DataGridRenderList(" or ")
    },
    ...(organizationId
      ? [
          {
            headerName: "Shared By",
            field: "inheritedFrom",
            minWidth: 150,
            flex: 1,
            valueGetter: ({ row }) =>
              row.isInherited
                ? row.inheritedFrom
                  ? row.inheritedFrom.name
                  : "SportsGravy"
                : "-"
          }
        ]
      : [])
  ];
  const filterConfig = React.useMemo(
    () => ({
      field: "sportId",
      placeholderOption: { label: "All Sports", value: "" },
      options: [
        {
          label: "Filter by Sport",
          children:
            sports?.data?.map((sport: ModelSport) => ({
              label: sport.name!,
              value: sport.sportId
            })) || []
        }
      ]
    }),
    [sports]
  );

  const onAdd = () => navigate("/levels/create");
  const onEdit = (row) => navigate(`/levels/${row.levelId}/edit`);
  const onView = (row) => navigate(`/levels/${row.levelId}`);

  return (
    <>
      <TableView
        title="Levels"
        useGet={getAdminLevels}
        columns={LIST_COLUMNS}
        isDeleteDisabled={() => true}
        filterConfig={filterConfig}
        defaultSort={[{ field: "name", sort: "asc" }]}
        onAdd={onAdd}
        onEdit={onEdit}
        isEditDisabled={(row) => row.isInherited}
        onView={onView}
        refreshKey={refreshKey}
      />
    </>
  );
};
