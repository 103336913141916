import { Box, Button, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import SGLogo from "@assets/images/sg-logo.png";
import { useState } from "react";
import { AppStoreUrl, PlayStoreUrl } from "@utils/constants";

const StyledBox = styled(Box)`
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
`;

const StyledContainer = styled(Container)`
  @media (max-width: 465px) {
    padding: 0px 15px;
  }
`;

const StyledHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  border-bottom: 1px solid #e5e5e5;
  ${(props) => props.theme.breakpoints.down("md")} {
    height: 66px;
  }
  background-color: #fff;
  img {
    ${(props) => props.theme.breakpoints.up("md")} {
      width: 160px;
    }
    ${(props) => props.theme.breakpoints.down("md")} {
      width: 120px;
    }
  }
`;

const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  button {
    text-transform: none;
  }
`;

const StyledButton = styled(Button)`
  padding: 8px 16px !important;
`;

export const DesktopHeader = ({
  isMobile,
  mobileUrl
}: {
  isMobile: boolean;
  mobileUrl?: string;
}) => {
  const [isBlurred, setIsBlurred] = useState(false);
  const [didFallback, setDidFallback] = useState(false);
  window.addEventListener("blur", () => {
    setIsBlurred(true);
  });

  window.addEventListener("focus", () => {
    setIsBlurred(false);
  });
  const appStoreFallback = () => {
    if (isBlurred || didFallback) return;
    const userAgent = window.navigator.userAgent;
    if (/android/i.test(userAgent)) {
      window.location.href = PlayStoreUrl;
      setDidFallback(true);
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      if (window.confirm(`Open in "App Store"?`)) {
        window.location.href = AppStoreUrl;
        setDidFallback(true);
      }
    }
  };

  const handleClick = () => {
    const now = new Date().valueOf();
    setDidFallback(false);
    setIsBlurred(false);
    window.location.href = mobileUrl as string;
    setTimeout(() => {
      if (!document.hasFocus()) return;
      if (new Date().valueOf() - now > 1600) return;
      appStoreFallback();
    }, 1500);
  };
  return (
    <StyledBox>
      <StyledContainer>
        <StyledHeader>
          <img src={SGLogo} />
          <ButtonContainer>
            {!isMobile && (
              <>
                <Button variant="text">Log In</Button>
                <StyledButton
                  className="primary"
                  onClick={() => window.open("/sign-up/basic-info", "_blank")}
                >
                  Sign Up
                </StyledButton>
              </>
            )}
            {isMobile && (
              <StyledButton
                style={{ width: "120px" }}
                className="primary"
                onClick={handleClick}
              >
                Open App
              </StyledButton>
            )}
          </ButtonContainer>
        </StyledHeader>
      </StyledContainer>
    </StyledBox>
  );
};
