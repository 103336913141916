import { TableViewInfinite } from "@components/TableViewInfinite";
import { Loader } from "@components/crud/Loader";
import {
  organizationAtom,
  profileAtom,
  selectedFilterAtom,
  selectedReleaseAtom
} from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import { getQAFeatures } from "@services/Network";
import {
  ModelPerson,
  ModelRelease,
  useAdminReleaseAllGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { FEATURE_PLATFORMS_OPTIONS } from "@utils/constants";
import formatFullName from "@utils/formatFullName";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

export const QAFeatures = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const profileValue = useRecoilValue(profileAtom);
  const selectedFilterValue = useRecoilValue(selectedFilterAtom);
  const selectedReleaseValue = useRecoilValue(selectedReleaseAtom);
  const [featureId, setFeatureId] = useState(selectedFilterValue);
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    view: false
  });
  useEffect(() => {
    if (organizationId) navigate("/not-found");
  }, [organizationId]);
  console.log(featureId);
  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("tech.features", "ADD");
      const edit = await checkPermission("tech.features", "EDIT");
      const del = await checkPermission("tech.features", "VIEW");
      const permission = {
        create,
        edit,
        view: del
      };
      setPermissions(permission);
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, [organizationId]);

  const onEdit = (feature) => {
    navigate(`/qa-features/${feature.featureId}/edit`);
  };

  const onView = (feature) => {
    navigate(`/qa-features/${feature.featureId}`);
  };

  const { data: releasesData, isLoading: isLoadingReleases } =
    useAdminReleaseAllGet();
  const [releases, setReleases] = React.useState<ModelRelease[]>([]);

  useEffect(() => {
    if (releasesData?.data) {
      const releases = releasesData.data.releases as ModelRelease[];
      setReleases(releases);
      let testersOfAllReleases = [] as ModelPerson[];
      testersOfAllReleases = releases[0].testers.map((t) => t.person);
      // releases.map((release) => {
      //   release.testers?.map((tester) => {
      //     if (
      //       !testersOfAllReleases.find(
      //         (person) => person.personId === tester.person?.personId
      //       )
      //     ) {
      //       testersOfAllReleases.push(tester?.person);
      //     }
      //   });
      // });
      setTesters([...testersOfAllReleases]);
    }
  }, [releasesData]);

  console.log("RELEASE::", selectedReleaseValue);
  const releaseConfig = React.useMemo(() => {
    const options =
      releases?.map((release: ModelRelease) => ({
        label: release.name as string,
        value: release.releaseId as string,
        status: release.releaseStatus
      })) || [];

    return {
      field: "release",
      defaultValue:
        selectedReleaseValue &&
        options.find((opt) => opt.value === selectedReleaseValue)
          ? selectedReleaseValue
          : options.length > 0
          ? options[0].value
          : "",
      options: options,
      label: "Release"
    };
  }, [releases]);
  const [testers, setTesters] = React.useState<ModelPerson[]>([]);

  const [selectedTester, setSelectedTester] = useState<string>("");

  const testerConfig = React.useMemo(() => {
    const options =
      testers?.map((tester: ModelPerson) => ({
        label: formatFullName(tester) as string,
        value: tester.personId as string
      })) || [];

    if (options.find((opt) => opt.value === profileValue!.personId)) {
      setSelectedTester(profileValue!.personId!);
    } else {
      setSelectedTester("");
    }
    return {
      field: "testerId",
      placeholderOption: { label: "All", value: "" },
      options: options,
      label: "Tester"
    };
  }, [testers]);

  const filterConfig = {
    field: "parentId",
    defaultValue: selectedFilterValue,
    placeholderOption: { label: "All", value: "" },
    options: []
  };

  const platformConfig = {
    field: "platform",
    defaultValue: "MOB",
    label: "Platform",
    placeHolderOption: { label: "Mobile", value: "MOB" },
    options: [
      {
        label: "Mobile",
        value: "MOB"
      },
      {
        label: "Web",
        value: "WEB"
      }
    ]
  };

  const [releaseId, setReleaseId] = useState<string>();
  const LIST_COLUMNS = [
    {
      headerName: "Feature Name",
      field: "name",
      sortable: false,
      minWidth: 300,
      flex: 1,
      valueGetter: ({ row }) => row.name
    },
    {
      headerName: "Parent Name",
      field: "parentFeature",
      sortable: false,
      minWidth: 300,
      flex: 1,
      valueGetter: ({ row }) => (row?.parent ? row.parent.name : "")
    },
    {
      headerName: "Platforms",
      field: "platforms",
      minWidth: 100,
      flex: 1,
      sortable: false,
      valueGetter: ({ row, value }) => {
        if (selectedTester) {
          const filteredValue = row.releases.filter(
            (release) => release.releaseId === releaseId
          );
          const result = [] as string[];
          if (filteredValue && filteredValue.length > 0) {
            [
              { name: "ios", label: "iOS" },
              { name: "and", label: "AND" },
              { name: "web", label: "WEB" }
            ].map((plat) => {
              if (filteredValue[0][`${plat.name}TesterId`] === selectedTester)
                result.push(plat.label);
            });
            return result.join(", ");
          }
          return "";
        } else {
          return value
            .map(
              (val) =>
                FEATURE_PLATFORMS_OPTIONS.find((option) => option.value === val)
                  ?.label
            )
            .join(", ");
        }
      }
    }
  ];

  const isEditEnabled = () => {
    console.log("PERMI EDIT", permissions.edit);
    return permissions.edit;
  };

  useEffect(() => {
    if (releasesData?.data) {
      setTesters([
        ...(releasesData.data.releases
          ?.find((r) => r.releaseId === releaseId)
          ?.testers.map((t) => t.person) || [])
      ]);
    }
  }, [releaseId]);

  return (
    <>
      <Loader isLoading={isLoadingPermissions || isLoadingReleases}>
        <TableViewInfinite
          title="QA Features"
          hideFilter
          useGet={getQAFeatures}
          columns={LIST_COLUMNS}
          searchable={false}
          customFilter="FEATURE"
          getRowId={(row) => row.featureId}
          setId={setFeatureId}
          hideFooter
          filterConfig={filterConfig}
          hideLabelContainer
          hidePlatformFilter={false}
          platformConfig={platformConfig}
          releaseConfig={releaseConfig}
          testerConfig={testerConfig}
          testerId={selectedTester}
          setSelectedTester={setSelectedTester}
          setSelectedRelease={setReleaseId}
          addBtnText="Add Feature"
          defaultSort={[{ field: "name", sort: "asc" }]}
          onEdit={isEditEnabled() ? onEdit : undefined}
          onView={onView}
        />
      </Loader>
    </>
  );
};
