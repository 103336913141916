import { TableView } from "@components/TableView";
import { organizationAtom } from "@recoil/auth";
import { getSeasons } from "@services/Network";
import {
  ModelSport,
  useAdminSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { format, parseISO } from "date-fns";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const LIST_COLUMNS = [
  { headerName: "Name", field: "name", minWidth: 150, flex: 1 },
  {
    headerName: "Sport",
    field: "sport",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ value }) => value?.name
  },
  {
    headerName: "Start Date",
    field: "startDate",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => format(parseISO(row.startDate), "MMM d, yyyy")
  },
  {
    headerName: "End Date",
    field: "endDate",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => format(parseISO(row.endDate), "MMM d, yyyy")
  }
];

export const Seasons = () => {
  const navigate = useNavigate();
  const [refreshKey] = React.useState(0);
  const organizationId = useRecoilValue(organizationAtom);
  const { data: sports } = useAdminSportGet({
    organizationId: organizationId!
  });
  const filterConfig = React.useMemo(
    () => ({
      field: "sportId",
      placeholderOption: { label: "All Seasons", value: "" },
      options: [
        {
          label: "Filter by Sport",
          children:
            sports?.data?.map((sport: ModelSport) => ({
              label: sport.name!,
              value: sport.sportId
            })) || []
        }
      ]
    }),
    [sports]
  );

  const onAdd = () => navigate("/seasons/create");
  const onEdit = (row) => navigate(`/seasons/${row.seasonId}/edit`);
  const onView = (row) => navigate(`/seasons/${row.seasonId}`);

  return (
    <>
      <TableView
        title="Seasons"
        useGet={getSeasons}
        columns={LIST_COLUMNS}
        filterConfig={filterConfig}
        defaultSort={[{ field: "name", sort: "asc" }]}
        onAdd={onAdd}
        onEdit={onEdit}
        onView={onView}
        isDeleteDisabled={() => true}
        refreshKey={refreshKey}
      />
    </>
  );
};
