import { Editor } from "@components/Editor";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { Form } from "@components/crud/Form";
import { FormLabel, Grid, Typography, styled } from "@mui/material";
import { ModelDepartment } from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalizeEveryWord } from "@utils/capitalize";
import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";
const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));
export const JobTitleForm = ({
  form,
  disabled,
  departments,
  isLoadingDepartments,
  isActiveReadOnly
}: {
  form: UseFormReturn;
  disabled?: boolean;
  departments: ModelDepartment[];
  isLoadingDepartments?: boolean;
  isActiveReadOnly?: boolean;
}) => {
  const { control, setValue, getValues } = form;

  const [descValid, setDescValid] = useState(true);
  const validateDescriptionArray = (value) => {
    if (value && value.length >= 2) {
      setDescValid(true);
    } else if (value && value.length == 1 && value[0].insert !== "\n") {
      setDescValid(true);
    } else {
      setDescValid(false);
    }
  };
  return (
    <Form>
      <Grid container direction="column" spacing="25px">
        <Grid item container direction="row" spacing="24px">
          <Grid item xs={6} data-testid="jobTitle-name">
            <FormInput
              disabled={disabled}
              name="name"
              onChange={(e) => {
                setValue("name", capitalizeEveryWord(e.target.value));
              }}
              control={control}
              label="Name"
              required
              rules={{
                required: "Name is required"
              }}
              type="text"
            />
          </Grid>
          <Grid item xs={6} data-testid="jobTitle-department">
            <FormSelect
              name="departmentId"
              disabled={disabled}
              control={control}
              isLoading={isLoadingDepartments}
              label="Department"
              options={
                departments
                  ? departments.map((department) => ({
                      label: department.name!,
                      value: department.departmentId!
                    }))
                  : []
              }
              required
              rules={{
                required: "Department is required"
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          spacing="24px"
          data-testid="jobTitle-isActive"
        >
          <Grid item>
            <FormCheckbox
              name="isActive"
              control={control}
              label="is Active"
              disabled={disabled || !!isActiveReadOnly}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          spacing="10px"
          minHeight="300px"
          marginBottom="30px"
          data-testid="jobTitle-description"
        >
          <Grid item>
            <StyledFormLabel required>
              <Typography variant="formLabel">Description</Typography>
            </StyledFormLabel>
          </Grid>
          <Grid item>
            <Editor
              disabled={disabled}
              html={getValues().description ? getValues().description : null}
              onBlur={() => {
                validateDescriptionArray(getValues().description);
              }}
              onValueChange={(deltaOperations, hasMedia) => {
                console.log("DELTA::::", deltaOperations);
                setValue("description", deltaOperations, {
                  shouldValidate: true,
                  shouldDirty: true
                });
                setValue("hasMedia", hasMedia);
                validateDescriptionArray(deltaOperations);
              }}
              style={{
                height: "300px"
              }}
            />
          </Grid>
        </Grid>
        {!descValid && (
          <Grid item style={{ marginTop: "-10px" }}>
            <Typography style={{ color: "#E82C2C" }} variant="body2">
              Description is required
            </Typography>
          </Grid>
        )}
      </Grid>
    </Form>
  );
};
