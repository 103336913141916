import imageCompression from "browser-image-compression";
import { MAX_SIZE_ICON, compressionOptions } from "./constants";

export const getBase64 = async (file: File) => {
  if (file.size < MAX_SIZE_ICON) {
    return await imageCompression.getDataUrlFromFile(file);
  } else {
    const compressedFile = await imageCompression(file, compressionOptions);
    return await getBase64(compressedFile);
  }
};
