import { DataGridRenderCellTooltip } from "@components/DataGridRenderCellTooltip";
import { TableView } from "@components/TableView";
import { getCannedMessages } from "@services/Network";
import React from "react";
import { useNavigate } from "react-router-dom";

const LIST_COLUMNS = [
  { headerName: "Title", field: "title", minWidth: 150, flex: 1 },
  {
    headerName: "Message",
    field: "message",
    minWidth: 150,
    flex: 1,
    sortable: false
  },
  {
    headerName: "Sport",
    field: "sport",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ value }) => value?.name
  },
  { headerName: "Gender", field: "genders", minWidth: 150, flex: 1 },
  {
    headerName: "Levels",
    field: "levels",
    minWidth: 170,
    flex: 1,
    renderCell: DataGridRenderCellTooltip("name", 2)
  },
  {
    headerName: "Positions",
    field: "positions",
    minWidth: 170,
    flex: 1,
    renderCell: DataGridRenderCellTooltip("name", 2)
  },
  {
    headerName: "Skills",
    field: "skills",
    minWidth: 170,
    flex: 1,
    renderCell: DataGridRenderCellTooltip("name", 2)
  },
  {
    headerName: "Game Concepts",
    field: "concepts",
    minWidth: 170,
    flex: 1,
    renderCell: DataGridRenderCellTooltip("name", 2)
  },
  {
    headerName: "Game Systems",
    field: "systems",
    minWidth: 170,
    flex: 1,
    renderCell: DataGridRenderCellTooltip("name", 2)
  },
  {
    headerName: "Roles",
    field: "roles",
    minWidth: 170,
    flex: 1,
    renderCell: DataGridRenderCellTooltip("name", 2)
  }
];

export const CannedMessages = () => {
  const navigate = useNavigate();
  const [refreshKey] = React.useState(0);

  const onAdd = () => navigate("/canned-messages/create");
  const onEdit = (cannedMessage) =>
    navigate(`/canned-messages/${cannedMessage.cannedMessageId}/edit`);
  const onView = (cannedMessage) =>
    navigate(`/canned-messages/${cannedMessage.cannedMessageId}`);

  return (
    <>
      <TableView
        title="Canned Messages"
        useGet={getCannedMessages}
        columns={LIST_COLUMNS}
        hideFilter={true}
        defaultSort={[{ field: "title", sort: "asc" }]}
        onAdd={onAdd}
        onEdit={onEdit}
        onView={onView}
        isDeleteDisabled={() => true}
        refreshKey={refreshKey}
      />
    </>
  );
};
