import { GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import React from "react";
import { RenderTableView } from "@components/RenderTableView";
import { capitalize } from "@utils/capitalize";
export const UserTeamsView = ({
  teams
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  teams: any;
}) => {
  const navigate = useNavigate();
  const TEAM_COLUMNS: GridColDef[] = [
    {
      headerName: "Name",
      field: "teamName",
      minWidth: 100,
      flex: 1,
      cellClassName: "super-app-theme--cell",
      valueGetter: ({ row }) => row.role.team.name
    },
    {
      headerName: "Roles",
      field: "roleName",
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row }) => capitalize(row.role.alias)
    },
    {
      headerName: "Sub Roles",
      field: "subRoles",
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row }) => (row.role.parentId ? row.role.name : "")
    },
    {
      headerName: "Sports",
      field: "sports",
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row }) => row.role.team.sport.name
    },
    {
      headerName: "Season",
      field: "season",
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row }) => row.role.team.season.name
    },
    {
      headerName: "Level",
      field: "level",
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row }) => row.role.team.level.name
    }
  ];

  const onView = (team) => {
    console.log(team);
    navigate("/users/");
  };
  return (
    <>
      <RenderTableView
        sx={{
          "& .disabled-row--BANNED": {
            color: "#ABAFB5",
            fontWeight: "400"
          }
        }}
        title="Teams"
        rows={teams}
        columns={TEAM_COLUMNS}
        onView={onView}
        hideFilter
        getRowId={(row) => row?.teamId}
        searchable={false}
        //getRowClassName={(params) => params.row.status === 'BANNED' ? `disabled-row--${params.row.status}` : ``}
      />
    </>
  );
};
