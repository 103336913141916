/* eslint-disable no-useless-escape */
import { Button } from "@components/Button";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { StepProgressLine } from "@components/StepProgressLine";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { getCountries } from "@services/Network";
import Sentry from "@services/Sentry";
import {
  ModelMedia,
  useAdminOrganizationPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { OrganizationContactForm } from "./OrganizationContactForm";
import { OrganizationDetailsForm } from "./OrganizationDetailsForm";
import { OrganizationSportsForm } from "./OrganizationSportsForm";
import { organizationAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";
import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { cleanObject } from "@utils/cleanObject";
const steps = ["Organization Details", "Sports Details", "Contacts Details"];

export const OrganizationCreate = () => {
  const loggedInOrganizationId = useRecoilValue(organizationAtom);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (loggedInOrganizationId) {
      navigate(`/organizations/${loggedInOrganizationId}`);
    }
  }, [loggedInOrganizationId]);

  const [tab, setTab] = useState("Organization Details");
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [files, setFiles] = useState<(File | ModelMedia)[]>([]);
  const activeStepNumber = steps.findIndex((step) => step === tab);

  const {
    control,
    watch,
    setValue,
    getValues,
    trigger,
    handleSubmit,
    formState: { isValid }
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      abbreviation: "",
      address1: "",
      address2: "",
      allowGoverningBodyBySport: "",
      country: "",
      email: "",
      governingBodyId: "",
      hasPrimaryContact: true,
      hasSecondaryContact: false,
      isGovernedByOrg: "no",
      loadDataFromSportsGravy: "",
      locality: "",
      name: "",
      offering: "",
      phone: "",
      avatarId: null,
      postalCode: "",
      primaryContact: null,
      providerId: "",
      province: "",
      secondaryContact: null,
      sportsFields: [],
      sportsOffered: [],
      type: "",
      versionId: "",
      website: ""
    }
  });

  const {
    providerId,
    country,
    sportsOffered,
    allowGoverningBodyBySport,
    hasSecondaryContact,
    primaryContact,
    secondaryContact,
    type,
    isGovernedByOrg
  } = watch();

  const { data: countries, isFetching: isFetchingCountries } = getCountries({
    staleTime: Infinity
  });

  const selectAddressSuggestion = (place) => {
    const addressComponents = place?.address_components || [];
    const streetNumber = addressComponents.find((c) =>
      c.types.includes("street_number")
    );
    const route = addressComponents.find((c) => c.types.includes("route"));
    const address1 = `${streetNumber?.long_name} ${route?.long_name}`;
    const subpremise = addressComponents.find((c) =>
      c.types.includes("subpremise")
    );
    const country = addressComponents.find((c) => c.types.includes("country"));
    const state = addressComponents.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    const city = addressComponents.find(
      (c) => c.types.includes("locality") || c.types.includes("sublocality")
    );
    const zip = addressComponents.find((c) => c.types.includes("postal_code"));
    console.log(addressComponents);
    setValue("address1", address1);
    if (subpremise) setValue("address2", subpremise?.long_name);
    setValue("country", country?.short_name);
    setValue("province", state?.short_name);
    setValue("locality", city?.long_name);
    setValue("postalCode", zip?.long_name);
    trigger("address1");
    trigger("country");
    trigger("province");
    trigger("locality");
    trigger("postalCode");
  };

  const onBackClick = () => {
    setTab(steps[activeStepNumber - 1]);
    setTimeout(() => {
      trigger();
    }, 50);
  };

  const onCancelClick = () => {
    setIsConfirmationDialogOpen(true);
  };

  const onConfirmCancel = () => {
    setIsConfirmationDialogOpen(false);
    navigate("/organizations");
  };

  const onCancelCancel = () => {
    setIsConfirmationDialogOpen(false);
  };

  const onContinueClick = () => {
    setTab(steps[activeStepNumber + 1]);
  };

  const { mutateAsync, isLoading } = useAdminOrganizationPost();

  const onSaveClick = async (formValues) => {
    try {
      const filesPromises = files?.length
        ? await Promise.all(
            //@ts-ignore
            files.map((file) => {
              if (file instanceof File) {
                const promise = uploadMediaUsingPresignedUrl(file);
                console.log("PROMISE", promise);
                return promise;
              } else {
                return file.mediaId;
              }
            })
          )
        : undefined;
      const primaryContactId = formValues?.primaryContact?.personId;
      const secondaryContactId = formValues?.secondaryContact?.personId;
      const sports = formValues.sportsFields.map((field) => {
        return cleanObject(field);
      });
      if (formValues.primaryContact.user) delete formValues.primaryContact.user;
      if (formValues.secondaryContact.user)
        delete formValues.secondaryContact.user;
      const data = {
        abbreviation: formValues.abbreviation,
        country: formValues.country,
        email: formValues.email,
        lines: [formValues.address1, formValues.address2].filter(
          (line) => !!line
        ),
        governingBodyId: formValues.governingBodyId,
        locality: formValues.locality,
        name: formValues.name,
        offering: formValues.offering,
        phone: `${formValues.phone}`,
        postalCode: formValues.postalCode,
        primaryContact: formValues.primaryContact,
        primaryContactId,
        providerId: formValues.providerId,
        province: formValues.province,
        secondaryContact: formValues.secondaryContact,
        secondaryContactId,
        sports: sports,
        type: formValues.type,
        versionId: formValues.versionId,
        website: formValues.website,
        avatarId: filesPromises ? filesPromises[0] : formValues.avatarId
      };

      const response = await mutateAsync({ data });
      navigate(`/organizations/${response.data?.organizationId}`);
      enqueueSnackbar("Organization saved successfully.", {
        variant: "success"
      });
    } catch (e) {
      enqueueSnackbar("Something went wrong. Please try again.", {
        variant: "error"
      });
      Sentry.captureException(e);
    }
  };

  const getPrimaryAction = () => {
    if (activeStepNumber < steps.length - 1) {
      return onContinueClick;
    } else {
      return handleSubmit(onSaveClick);
    }
  };

  const selectPrimaryContact = (contact) => {
    setValue("primaryContact", contact);
  };

  const selectSecondaryContact = (contact) => {
    setValue("secondaryContact", contact);
  };
  const [isFileValid, setIsFileValid] = useState(true);
  const [isContactValid, setIsContactValid] = useState(false);
  return (
    <Container>
      <Toolbar title="Add Organization" />
      <Form>
        <Loader isLoading={isFetchingCountries}>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <StepProgressLine
                steps={[
                  "Organization Details",
                  "Sports Details",
                  "Contacts Details"
                ]}
                activeStepNumber={activeStepNumber}
                onEditClick={(active) => {
                  setTab(steps[active]);
                  setTimeout(() => {
                    trigger();
                  }, 50);
                }}
              />
            </Grid>
            {tab === "Organization Details" && (
              <Grid xs={12}>
                <OrganizationDetailsForm
                  disabled={false}
                  isEditing={false}
                  control={control}
                  filesValid={(val) => {
                    setIsFileValid(val);
                  }}
                  providerId={providerId}
                  country={country}
                  selectAddressSuggestion={selectAddressSuggestion}
                  countries={countries}
                  setValue={setValue}
                  onMediaUpload={(file) => {
                    setFiles(file);
                  }}
                  isFetchingCountries={isFetchingCountries}
                  type={type}
                  updatedFiles={files}
                />
              </Grid>
            )}
            {tab === "Sports Details" && (
              <Grid xs={12}>
                <OrganizationSportsForm
                  disabled={false}
                  control={control}
                  country={country}
                  allowGoverningBodyBySport={allowGoverningBodyBySport}
                  sportsOffered={sportsOffered}
                  isGovernedByOrg={isGovernedByOrg}
                  setValue={setValue}
                />
              </Grid>
            )}
            {tab === "Contacts Details" && (
              <Grid xs={12}>
                <OrganizationContactForm
                  disabled={false}
                  control={control}
                  getValues={getValues}
                  country={country}
                  setValue={setValue}
                  hasSecondaryContact={hasSecondaryContact}
                  selectPrimaryContact={selectPrimaryContact}
                  primaryContact={primaryContact}
                  selectSecondaryContact={selectSecondaryContact}
                  contactValid={(val) => {
                    setIsContactValid(val);
                  }}
                  secondaryContact={secondaryContact}
                />
              </Grid>
            )}
          </Grid>
        </Loader>
      </Form>
      <Footer
        additionalBtns={[
          activeStepNumber > 0 ? (
            <Button
              variant="admin-secondary"
              type="button"
              onClick={onBackClick}
              isLoading={isLoading || isFetchingCountries}
            >
              Back
            </Button>
          ) : null,
          <Button
            key={1}
            variant="admin-secondary"
            type="button"
            onClick={onCancelClick}
            isLoading={isLoading || isFetchingCountries}
          >
            Cancel
          </Button>,
          <Button
            key={2}
            variant="admin-primary"
            type="button"
            onClick={getPrimaryAction()}
            disabled={
              !isValid ||
              !isFileValid ||
              (activeStepNumber === steps.length - 1 && !isContactValid)
            }
            isLoading={isLoading || isFetchingCountries}
          >
            {activeStepNumber < steps.length - 1 ? "Continue" : "Save"}
          </Button>
        ]}
        isDisabled={!isValid}
        isLoading={isLoading || isFetchingCountries}
      />
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        onConfirm={onConfirmCancel}
        onCancel={onCancelCancel}
      />
    </Container>
  );
};
