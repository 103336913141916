import { FormInput } from "@components/FormInput";
import { Form } from "@components/crud/Form";
import { Grid } from "@mui/material";
import { capitalizeEveryWord } from "@utils/capitalize";
import React from "react";
import { UseFormReturn } from "react-hook-form";
export const DepartmentForm = ({
  form,
  disabled
}: {
  form: UseFormReturn;
  disabled?: boolean;
}) => {
  const { control, setValue } = form;

  return (
    <Form>
      <Grid container direction="column" spacing="25px">
        <Grid item container direction="row" spacing="24px">
          <Grid item xs={6} data-testid="department-name">
            <FormInput
              disabled={disabled}
              name="name"
              onChange={(e) => {
                setValue("name", capitalizeEveryWord(e.target.value));
              }}
              control={control}
              label="Name"
              required
              rules={{
                required: "Name is required"
              }}
              type="text"
            />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};
