import colors from "./colors";
import globals from "./globals";
import textField from "./textField";

const {
  primary,
  white,
  error,
  grey,
  text,
  info,
  transparent,
  divider,
  success,
} = colors;

const components = {
  MuiCssBaseline: {
    styleOverrides: {
      ...globals,
    },
  },
  MuiTextField: { ...textField },
  MuiAccordion: {
    styleOverrides: {
      root: {
        ".MuiAccordionSummary-content.Mui-expanded": {
          margin: "0",
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        color: text.general.primary,

        "&.primary": {
          border: "double 3px transparent",
          borderRadius: "12px",
          backgroundImage: `linear-gradient(${white.main}, ${white.main}), radial-gradient(circle at top left, ${error.main},${primary.main})`,
          backgroundOrigin: "border-box",
          backgroundClip: "padding-box, border-box",
          padding: "10px",
          width: "100%",
          height: "3rem",

          "&:disabled": {
            border: `3px solid ${text.general.disabled}`,
            backgroundImage: "none",
            color: text.general.disabled,
          },
        },

        "&.secondary": {
          width: "100%",
          border: `solid 3px ${primary.main}`,
          borderRadius: "12px",
          padding: "10px",
          fontWeight: "bold",

          "&:disabled": {
            borderColor: text.general.disabled,
            color: text.general.disabled,
          },
        },

        "&.cancel": {
          width: "100%",
          border: `solid 3px ${error.main}`,
          borderRadius: "12px",
          padding: "10px",
          fontWeight: "bold",
          color: error.main,

          "&:disabled": {
            borderColor: text.general.disabled,
            color: text.general.disabled,
          },
        },

        "&.admin-primary": {
          display: "flex",
          alignItems: "center",
          padding: "8px 20px",
          backgroundColor: info.main,
          borderRadius: "100px",
          color: white.main,
          textTransform: "none",
          boxShadow: "none",

          "&:hover": {
            backgroundColor: info.main,
            opacity: 0.8,
            boxShadow: "none",
          },

          "&:disabled": {
            backgroundColor: text.general.disabled,
          },
        },

        "&.admin-warning": {
          display: "flex",
          alignItems: "center",
          padding: "8px 20px",
          backgroundColor: error.main,
          borderRadius: "100px",
          color: white.main,
          textTransform: "none",
          boxShadow: "none",

          "&:hover": {
            backgroundColor: error.main,
            opacity: 0.8,
            boxShadow: "none",
          },

          "&:disabled": {
            backgroundColor: text.general.disabled,
          },
        },

        "&.admin-secondary": {
          display: "flex",
          alignItems: "center",
          padding: "8px 20px",
          backgroundColor: transparent.main,
          borderRadius: "100px",
          color: text.general.button,
          textTransform: "none",
          border: `1px solid ${divider}`,
          boxShadow: "none",
          cursor: "pointer",

          "&:hover": {
            backgroundColor: transparent.main,
            opacity: 0.6,
            boxShadow: "none",
          },
        },
        "&.admin-success": {
          display: "flex",
          alignItems: "center",
          padding: "8px 20px",
          backgroundColor: success.main,
          borderRadius: "100px",
          color: white.main,
          textTransform: "none",
          border: `1px solid ${divider}`,
          boxShadow: "none",
          cursor: "pointer",

          "&:hover": {
            backgroundColor: success.focus,
            opacity: 0.6,
            boxShadow: "none",
          },
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        "& fieldset": {
          border: `1px solid ${grey.main}`,
          borderRadius: "6px",
        },
        "&.MuiOutlinedInput-root": {
          "& fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, .25)",
            borderWidth: "1px",
          },
        },
        "&.MuiOutlinedInput-root.Mui-focused": {
          "& fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: primary.main,
            borderWidth: "1px",
          },
        },
        "&.MuiOutlinedInput-root.Mui-error": {
          "& fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: error.main,
            borderWidth: "1px",
          },
        },
        "&:hover.MuiOutlinedInput-root:.Mui-disabled": {
          "& fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: grey.main,
            borderWidth: "1px",
          },
        },
        "& .MuiChip-root": {
          height: 20,
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        "&.error": {
          backgroundColor: "rgba(241, 85, 108, .15)",
          border: "1px solid rgb(185 28 28)",
          color: "rgb(185 28 28)",
        },
        "&.success": {
          backgroundColor: "rgb(240 253 244)",
          border: "1px solid rgb(226,232,240)",
          color: "rgb(20 83 45)",
        },
        "&.info": {
          backgroundColor: grey[200],
          border: "none",
          borderRadius: "0px",
        },
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 600,
        },
      },
    },
  },
};

export default components;
