import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import SGLogo from "@assets/icons/SGLogo.svg";
import AppleStore from "@assets/icons/appStore.svg";
import GooglePlay from "@assets/icons/googlePlay.svg";

const StyledBox = styled(Box)`
  background-color: #fff;
  padding: 16px 0px;
  border-radius: 8px;
`;

const StyledHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
`;

const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  img {
    cursor: pointer;
  }
  ${(props) => props.theme.breakpoints.down("lg")} {
    img {
      width: 120px;
    }
    gap: 10px;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    img {
      width: 100px;
    }
    gap: 10px;
  }
`;

const StyledTypography = styled(Typography)`
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  ${(props) => props.theme.breakpoints.down("lg")} {
    font-size: 18px;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    font-size: 14px;
  }
`;

export const DesktopFooter = () => {
  return (
    <StyledBox className="desktop-footer">
      <StyledHeader>
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <img src={SGLogo} width="40px" />
          <StyledTypography>SportsGravy is better on the app</StyledTypography>
        </Box>
        <ButtonContainer className="btn-container">
          <>
            <img
              src={AppleStore}
              onClick={() => window.open("https://play.google.com/", "_blank")}
            />
            <img
              src={GooglePlay}
              onClick={() =>
                window.open("https://www.apple.com/app-store", "_blank")
              }
            />
          </>
        </ButtonContainer>
      </StyledHeader>
    </StyledBox>
  );
};
