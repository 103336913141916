import { Avatar, Box, Container, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MobileFooter } from "./components/MobileFooter";
import { useEffect, useRef, useState } from "react";
import { DesktopHeader } from "./components/DesktopHeader";
import { DesktopFooter } from "./components/DesktopFooter";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MoreVerIcon from "@mui/icons-material/MoreVert";
import {
  LookupOgResponse,
  ModelPost,
  useLookupOgPost,
  usePostBase64UnauthorizedGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import LikeIcon from "@assets/icons/likeIcon.svg";
import CommentIcon from "@assets/icons/commentIcon.svg";
import ShareIcon from "@assets/icons/shareIcon.svg";
import SendIcon from "@assets/icons/sendIcon.svg";
import CelebrateReaction from "@assets/icons/celebrate.svg";
import ConfusedReaction from "@assets/icons/confused.svg";
import InsightfulReaction from "@assets/icons/insightful.svg";
import LikeReaction from "@assets/icons/like.svg";
import LoveReaction from "@assets/icons/love.svg";
import NeutralReaction from "@assets/icons/neutral.svg";
import { DownloadAppPopup } from "./components/DownloadAppPopup";
import { FeedMediaGrid } from "@components/FeedMediaGrid";
import PDFViewer from "./components/PDFViewer";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import { useParams } from "react-router-dom";
import SGLogoLoader from "@assets/images/SGLogoLoader.gif";
interface StyledBoxProps {
  isMobile: boolean;
}

interface FeedProps {
  repost: boolean;
}

const StyledBox = styled(Box)<StyledBoxProps>`
  height: fit-content;
  min-height: 100vh;
  background-color: #f0f2f7;
  .desktop-footer {
    padding: 16px;
  }
  @media (max-width: 600px) {
    .desktop-footer p {
      font-size: 12px !important;
    }
    .btn-container img {
      width: 90px;
    }
  }
`;

const FeedBox = styled(Box)<FeedProps>`
  background-color: #fff;
  padding: 24px;
  margin-top: 24px;
  border: ${(props) =>
    props.repost ? "0.6px solid #939393CC" : "1px solid #0000001f"};
  border-radius: ${(props) => (props.repost ? "0px" : "6px 6px 0px 0px")};
  box-shadow: ${(props) =>
    props.repost ? "0px 4px 8px 0px #0000001A" : "none"};

  @media (max-width: 960px) {
    margin-top: 0px;
  }

  @media (max-width: 465px) {
    padding: 15px;
    margin-top: 8px;
    .author,
    .content,
    .see-more {
      font-size: 12px !important;
    }
    .date {
      font-size: 10px;
    }
  }
`;

const FeedActionContainer = styled(Box)`
  background-color: #f6f7f9;
  padding: 15px 24px;
  border-radius: 0px 0px 6px 6px;
  border: 1px solid #0000001f;
  margin-bottom: 24px;
  height: 74px;
  @media (max-width: 465px) {
    height: 60px;
    padding: 10px 15px;
  }
`;

const StyledActionBox = styled(Box)`
  width: 80px;
  @media (max-width: 465px) {
    width: 40px;
    img {
      width: 20px;
    }
  }
`;

const StyledLabel = styled(Typography)`
  color: #838383;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  @media (max-width: 465px) {
    font-size: 10px;
  }
`;

const LiveStreamBox = styled(Box)`
  margin-top: 12px;
  background-color: #f6f7f9;
  border-radius: 6px;
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    display: block;
    padding: 15px;
  }
  .time {
    position: relative;
    padding-left: 15px;
    top: -15px;
    font-size: 14px;
    line-height: 28px;
  }
  div {
    background-color: #000;
  }
  div .status {
    height: 22px;
    position: absolute;
    bottom: 20px;
    left: 16px;
    padding: 0 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;
  }
  img {
    display: block;
    margin: auto;
    height: 470px;
    max-width: 100%;
  }
  @media (max-width: 465px) {
    .title {
      font-size: 12px;
      padding: 8px;
    }
    .time {
      padding-left: 8px;
      font-size: 10px;
    }
    img {
      max-height: 300px;
    }
  }
  @media (max-width: 357px) {
    img {
      max-height: 250px;
    }
  }
  @media (max-width: 280px) {
    img {
      max-height: 200px;
    }
  }
`;

const StyledPlayIcon = styled(PlayArrowOutlinedIcon)`
  background-color: #000000;
  opacity: 0.75;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
`;

const StyledReactions = styled(Avatar)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
  border: 0.5px solid #c8cdf8;
  margin-left: -4px;
  @media (max-width: 465px) {
    height: 18px;
    width: 18px;
  }
`;

const LinkPreviewBox = styled(Box)`
  background-color: #f7f9fb;
  border-radius: 6px;
  margin-top: 12px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: 400px;
  }
  div {
    background-color: #f7f9fb;
    padding: 0px 15px 15px 15px;
  }
  div span:nth-child(1) {
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
  }
  div span:nth-child(3) {
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
  }
  @media (max-width: 465px) {
    div {
      padding: 0px 6px 6px 6px;
    }
    img {
      max-height: 300px;
    }
    div span:nth-child(1) {
      font-size: 12px;
      line-height: 20px;
    }
    div span:nth-child(3) {
      font-size: 9px;
      line-height: 20px;
    }
  }
`;

const StyledSGLogoLoader = styled(Box)`
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  img {
    width: 150px;
  }
  @media (max-width: 465px) {
    img {
      width: 100px;
    }
  }
`;

const dateFormat = (date) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
    hour12: true
  };
  //@ts-ignore
  return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
};

const livestreamDateFormat = (date) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
    hour12: true
  };
  //@ts-ignore
  return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
};

const getNumberOfLinesFromText = (text, fontSize = 16, containerWidth) => {
  const tempElement = document.createElement("div");
  tempElement.style.width = `${containerWidth}px`;
  tempElement.style.fontSize = `${fontSize}px`;
  tempElement.style.visibility = "hidden";
  tempElement.style.whiteSpace = "pre-wrap";
  document.body.appendChild(tempElement);
  tempElement.textContent = text;
  const textHeight = tempElement.clientHeight;
  const lineHeight = parseFloat(getComputedStyle(tempElement).lineHeight);
  const numberOfLines = Math.round(textHeight / lineHeight);
  document.body.removeChild(tempElement);
  return numberOfLines;
};

const getUniqueReactions = (reactions) => {
  const uniqueReactions = [
    ...new Set(reactions.map((reaction) => reaction.type))
  ];
  return uniqueReactions;
};

export const Post = () => {
  const textRef = useRef(null);
  const { compressedUrl } = useParams();
  const [showMobileFooter, setShowMobileFooter] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [urls, setUrls] = useState<string[]>([]);
  const [urlMetaData, setUrlMetadata] = useState<undefined | LookupOgResponse>(
    undefined
  );

  useEffect(() => {
    const checkMobile = () => {
      const userAgent = window.navigator.userAgent;
      setIsMobile(/Mobile/i.test(userAgent));
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  const formatBody = (body, userMentions, orgMentions?) => {
    body = body.trimEnd();
    const linkRegex = /(?:https?:\/\/|www\.)[^\s/$.?#].[^\s]*/g;
    const parts = body.split(/([@#][\w\d]+|(?:http|www)\S+|\n)/g);
    return parts.map((part, index) => {
      if (part.match(/[@][\w\d]+/)) {
        const user = userMentions.find(
          (user) => user.personId === part.slice(1)
        );
        const org = orgMentions.find(
          (org) => org.organizationId === part.slice(1)
        );
        if (user) {
          return (
            <span key={index}>
              <span style={{ color: "#007AFF" }}>
                @{user.firstName} {user.middleName} {user.lastName}
              </span>
            </span>
          );
        }
        if (org) {
          return (
            <span key={index}>
              <span key={index} style={{ color: "#007AFF" }}>
                @{org.name}
              </span>
            </span>
          );
        }
      } else if (part.match(/^#[\w\d]+/)) {
        return (
          <span key={index} style={{ color: "#007AFF" }}>
            {part}
          </span>
        );
      } else if (part.match(linkRegex)) {
        if (urls.length == 0) {
          const url =
            part.startsWith("http://") || part.startsWith("https://")
              ? part.replace(/^http:\/\//i, "https://")
              : "https://" + part;
          setUrls([url]);
        }
        return (
          <a style={{ color: "#007AFF" }} key={index} rel="noopener noreferrer">
            {part}
          </a>
        );
      } else if (part === "\n") {
        return <br key={index} />;
      }

      return part;
    });
  };

  const { mutate: lookup } = useLookupOgPost();
  const lookupHandler = async (url) => {
    if (url) {
      lookup(
        {
          data: {
            url: url
          }
        },
        {
          onSuccess: (data) => {
            setUrlMetadata(data.data);
          }
        }
      );
    }
  };

  useEffect(() => {
    lookupHandler(urls[0]);
  }, [urls]);

  const { data: post, isLoading: isLoading } = usePostBase64UnauthorizedGet(
    compressedUrl as string
  );

  const SinglePost = ({
    content,
    repost
  }: {
    content: ModelPost;
    repost: boolean;
  }) => {
    return (
      <FeedBox repost={repost}>
        <Grid container alignItems="center">
          <Grid>
            {content?.createdBy?.person?.avatarId ? (
              <Avatar
                variant="square"
                src={
                  content.createdBy.person.avatar?.baseUrl &&
                  content.createdBy.person.avatar?.path
                    ? content?.createdBy?.person?.avatar?.baseUrl +
                      content?.createdBy?.person?.avatar?.path
                    : ""
                }
              />
            ) : (
              <Avatar
                sx={{
                  bgcolor: "#" + content?.createdBy?.person?.colorCode,
                  color: "#fff !important",
                  padding: "14px",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "20px",
                  textAlign: "center"
                }}
                variant="square"
              >
                {`${content?.createdBy?.person?.firstName} ${content?.createdBy?.person?.lastName}`
                  .split(" ")
                  .map((name) => name[0])
                  .join("")
                  .toUpperCase()}
              </Avatar>
            )}
          </Grid>

          <Grid marginLeft={"11px"} xs container direction="column">
            <Typography className="author" fontSize={"14px"} fontWeight={600}>
              {`${content?.createdBy?.person?.firstName} ${content?.createdBy?.person?.middleName} ${content?.createdBy?.person?.lastName}`}
            </Typography>
            <Typography
              className="date"
              fontSize={"12px"}
              fontWeight={400}
              color={"#939393"}
            >
              {dateFormat(content.updatedAt)}
            </Typography>
          </Grid>
          {!repost && (isMobile ? <MoreVerIcon /> : <MoreHorizIcon />)}
        </Grid>
        {content.body != "" && (
          <Box sx={{ marginTop: "15px", position: "relative" }}>
            <span
              className="content"
              ref={textRef}
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: content?.media?.length === 0 ? 5 : 2,
                WebkitBoxOrient: "vertical",
                textAlign: "justify",
                fontSize: "16px",
                fontWeight: "400"
              }}
            >
              {formatBody(
                content.body,
                content.userMentions,
                content.organizationMentions
              )}
            </span>
            {(content?.media?.length === 0 &&
              getNumberOfLinesFromText(
                formatBody(
                  content.body,
                  content.userMentions,
                  content.organizationMentions
                ),
                window.innerWidth <= 465 ? 12 : 16,
                textRef?.current?.offsetWidth
              ) > 5) ||
            (content.media &&
              content?.media?.length > 0 &&
              getNumberOfLinesFromText(
                formatBody(
                  content.body,
                  content.userMentions,
                  content.organizationMentions
                ),
                window.innerWidth <= 465 ? 12 : 16,
                textRef?.current?.offsetWidth
              ) > 2) ? (
              <span
                className="see-more"
                style={{
                  color: "#666",
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  backgroundColor: "#fff",
                  fontSize: "16px",
                  fontWeight: "400"
                }}
              >
                ... see more
              </span>
            ) : null}
          </Box>
        )}
        {content.repostOf && (
          <SinglePost content={content.repostOf} repost={true} />
        )}
        {content.media &&
          content.media.length > 0 &&
          !content.media.some(
            (media) => media.mimeType === "application/pdf"
          ) && <FeedMediaGrid media={content.media} />}
        {content.media &&
          content.media.length > 0 &&
          content.media.some(
            (media) => media.mimeType === "application/pdf"
          ) && (
            <PDFViewer
              isMobile={isMobile}
              src={
                content.media.find(
                  (media) => media.mimeType == "application/pdf"
                )!
              }
            />
          )}
        {content.liveStream && (
          <LiveStreamBox>
            <Box style={{ position: "relative" }}>
              <img
                src={
                  content.liveStream.thumbnail?.baseUrl &&
                  content.liveStream.thumbnail?.path
                    ? content.liveStream.thumbnail?.baseUrl +
                      content.liveStream.thumbnail?.path
                    : ""
                }
              />
              {["LIVE", "COMPLETED", "INTERMISSION"].includes(
                content.liveStream.status as string
              ) && <StyledPlayIcon fontSize="large" />}
              {["LIVE", "INTERMISSION"].includes(
                content.liveStream.status as string
              ) && (
                <div
                  className="status"
                  style={{ backgroundColor: "#e82c2c", color: "#fff" }}
                >
                  LIVE
                </div>
              )}
              {["CANCELLED"].includes(content.liveStream.status as string) && (
                <div
                  className="status"
                  style={{ backgroundColor: "#FFEEEE", color: "#A11212" }}
                >
                  Cancelled
                </div>
              )}
            </Box>
            <span className="title">{content.liveStream.title}</span>
            {["NOT_STARTED", "DELAYED", "POSTPONED"].includes(
              content.liveStream.status as string
            ) && (
              <span className="time">
                {livestreamDateFormat(
                  content.liveStream.status == "NOT_STARTED"
                    ? content?.liveStream?.scheduledAt
                    : content.liveStream.delayedUntil
                )}
              </span>
            )}
          </LiveStreamBox>
        )}
        {content.media &&
          !content.liveStream &&
          content.media.length == 0 &&
          urlMetaData && (
            <LinkPreviewBox>
              {urlMetaData.image && <img src={urlMetaData.image} />}
              {(urlMetaData.title || urlMetaData.url) && (
                <Box>
                  {urlMetaData.title && <span>{urlMetaData.title}</span>}
                  <br />
                  {urlMetaData.url && <span>{urlMetaData.url}</span>}
                </Box>
              )}
            </LinkPreviewBox>
          )}
        {!repost && (
          <Box>
            {!repost &&
              //@ts-ignore
              (content?._count?.comments > 0 ||
                //@ts-ignore
                content?._count?.shares > 0 ||
                //@ts-ignore
                content?._count?.reactions > 0) && (
                <Grid
                  container
                  marginTop="12px"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box marginLeft={"4px"}>
                    {(content?._count?.reactions as number) > 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        {getUniqueReactions(content.reactions)
                          .slice(0, 5)
                          .map((reaction, index) => {
                            let reactionImage;
                            switch (reaction) {
                              case "LIKE":
                                reactionImage = LikeReaction;
                                break;
                              case "LOVE":
                                reactionImage = LoveReaction;
                                break;
                              case "CONFUSED":
                                reactionImage = ConfusedReaction;
                                break;
                              case "CELEBRATE":
                                reactionImage = CelebrateReaction;
                                break;
                              case "INSIGHTFUL":
                                reactionImage = InsightfulReaction;
                                break;
                              case "NEUTRAL":
                                reactionImage = NeutralReaction;
                                break;
                              default:
                                reactionImage = null;
                            }

                            return (
                              <Grid key={index}>
                                {reactionImage && (
                                  <StyledReactions
                                    src={reactionImage}
                                    sx={{
                                      padding: "3px"
                                    }}
                                  ></StyledReactions>
                                )}
                              </Grid>
                            );
                          })}
                        <StyledLabel marginLeft={1}>
                          {`${content?.reactions?.length}`}
                        </StyledLabel>
                      </Box>
                    )}
                  </Box>
                  <Box>
                    <Grid display={"flex"}>
                      {(content?._count?.comments as number) > 0 && (
                        <Grid>
                          <StyledLabel>
                            {content?._count?.comments === 1
                              ? "1 comment"
                              : `${content?._count?.comments} comments`}
                          </StyledLabel>
                        </Grid>
                      )}
                      {(content?._count?.shares as number) > 0 && (
                        <Grid marginLeft="10px">
                          <StyledLabel>
                            {content?._count?.shares === 1
                              ? "1 share"
                              : `${content?._count?.shares} shares`}
                          </StyledLabel>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Grid>
              )}
          </Box>
        )}
      </FeedBox>
    );
  };

  const FeedActionBar = () => {
    return (
      <FeedActionContainer>
        <Grid container spacing={2} justifyContent={"space-between"}>
          <Grid item>
            <StyledActionBox
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <img src={LikeIcon} alt="Like" />
              <Typography color={"#B3B3B3"}>like</Typography>
            </StyledActionBox>
          </Grid>
          <Grid item>
            <StyledActionBox
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <img src={CommentIcon} alt="Comment" />
              <Typography color={"#B3B3B3"}>comment</Typography>
            </StyledActionBox>
          </Grid>
          <Grid item>
            <StyledActionBox
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <img src={ShareIcon} alt="Share" />
              <Typography color={"#B3B3B3"}>share</Typography>
            </StyledActionBox>
          </Grid>
          <Grid item>
            <StyledActionBox
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <img src={SendIcon} alt="Send" />
              <Typography color={"#B3B3B3"}>send</Typography>
            </StyledActionBox>
          </Grid>
        </Grid>
      </FeedActionContainer>
    );
  };

  useEffect(() => {
    const preventZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    document.addEventListener("touchmove", preventZoom, { passive: false });

    return () => {
      document.removeEventListener("touchmove", preventZoom);
    };
  }, []);

  if (isLoading) {
    return (
      <StyledSGLogoLoader>
        <img src={SGLogoLoader} />
      </StyledSGLogoLoader>
    );
  }
  return (
    <>
      <StyledBox isMobile={isMobile}>
        <DesktopHeader
          mobileUrl={`sportsgravy://post?id=${compressedUrl}`}
          isMobile={isMobile}
        />
        <Container style={{ ...(isMobile && { padding: 0 }) }}>
          {post?.data?.post && (
            <Box
              onClick={() => {
                setShowPopup(true);
              }}
              sx={{
                boxShadow: "0px 4px 14px 0px #00000021",
                backgroundColor: "#eceef3"
              }}
            >
              <SinglePost content={post?.data?.post} repost={false} />
              <FeedActionBar />
            </Box>
          )}
          {isMobile && showMobileFooter && (
            <MobileFooter
              mobileUrl={`sportsgravy://post?id=${compressedUrl}`}
              toggleMobileFooter={setShowMobileFooter}
            />
          )}
          {!isMobile && <DesktopFooter />}
          {showPopup && (
            <DownloadAppPopup
              title="See what you’re missing"
              description={`More photos, videos and ways to connect with ${post?.data?.sharedBy?.firstName} ${post?.data?.sharedBy?.lastName} in the app.`}
              mobileUrl={`sportsgravy://post?id=${compressedUrl}`}
            />
          )}
        </Container>
      </StyledBox>
    </>
  );
};
