import { TableView } from "@components/TableView";
import { getLiveStreams } from "@services/Network";
import {
  useAdminTeamGet,
  useAdminTrainingProgramGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { parseISO } from "date-fns";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";
import { hasPermission } from "@services/Casbin";
import { Loader } from "@components/crud/Loader";
export const LiveStream = () => {
  const organizationId = useRecoilValue(organizationAtom);
  const navigate = useNavigate();
  const [permissionLoading, setPermissionLoading] = useState(false);
  const [permissions, setPermissions] = useState({
    edit: false
  });
  const { data: team } = useAdminTeamGet({
    organizationId: organizationId!,
    pageSize: 1000
  });
  const { data: trainingProgram } = useAdminTrainingProgramGet({
    organizationId: organizationId!,
    pageSize: 1000
  });

  const organizations = useRecoilValue(organizationsAtom);
  const organization = organizations.find(
    (org) => org.organizationId === organizationId
  );

  const shareWithOptions = useMemo(() => {
    const orgOption = [
      {
        label: "My Organization",
        value: organizationId!
      }
    ];
    const teamOptions =
      (team?.data.teams &&
        team?.data?.teams.map((team) => ({
          label: team.name!,
          value: team.teamId
        }))) ||
      [];

    const trainingProgramOptions =
      (trainingProgram?.data.trainingPrograms &&
        trainingProgram?.data?.trainingPrograms.map((trainingProgram) => ({
          label: trainingProgram.name!,
          value: trainingProgram.programId
        }))) ||
      [];

    return [...orgOption, ...teamOptions, ...trainingProgramOptions];
  }, [team, trainingProgram]);

  const statusoptions = [
    {
      label: "Not Started",
      value: "NOT_STARTED"
    },
    {
      label: "Live",
      value: "LIVE"
    },
    {
      label: "Completed",
      value: "COMPLETED"
    },
    {
      label: "Cancelled",
      value: "CANCELLED"
    },
    {
      label: "Delayed",
      value: "DELAYED"
    },
    {
      label: "Postponed",
      value: "POSTPONED"
    },
    {
      label: "Paused",
      value: "PAUSED"
    },
    {
      label: "Unavailable",
      value: "UNAVAILABLE"
    },
    {
      label: "Shut Down",
      value: "SHUT_DOWN"
    }
  ];
  useEffect(() => {
    setPermissionLoading(true);
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission("organization.post", "ON");
      setPermissions({
        edit: del
      });
      setPermissionLoading(false);
    };
    fetchPermissions();
  }, []);

  const COLUMNS = [
    {
      headerName: "Status",
      field: "status",
      minWidth: 120,
      sortable: false,
      renderCell: ({ value }) => {
        if (value === "LIVE") {
          return (
            <Typography style={{ color: "#E82C2C", fontWeight: "600" }}>
              {value}
            </Typography>
          );
        } else {
          return (
            <div className="MuiDataGrid-cellContent" title={`${value}`}>
              {statusoptions.find((option) => option.value === value)?.label}
            </div>
          );
        }
      }
    },
    { headerName: "Title", field: "title", minWidth: 150, flex: 1 },
    {
      headerName: "Shared With",
      field: "shared",
      minWidth: 190,
      flex: 1,
      valueGetter: (params) => {
        if (params.row?.teamId || params.row?.programId) {
          if (params.row.teamId) {
            const id = params.row.teamId;
            return (
              "Team - " +
              shareWithOptions.find((option) => option.value === id)?.label
            );
          }
          if (params.row.programId) {
            const id = params.row.programId;
            return (
              "Training Program - " +
              shareWithOptions.find((option) => option.value === id)?.label
            );
          }
        }
        if (!params.row?.teamId && !params.row?.programId) {
          return "Org - " + organizationId ? organization?.name : "SportsGravy";
        }
      }
    },
    {
      headerName: "Visibility",
      field: "isPublic",
      minWidth: 115,
      flex: 1,
      valueGetter: (params) => {
        if (!params.value) {
          return "Private";
        }
        return "Public";
      }
    },
    {
      headerName: "Sport",
      field: "sport",
      minWidth: 115,
      flex: 1,
      valueGetter: (params) => {
        return params.value.name;
      }
    },
    {
      headerName: "Schedule ",
      field: "scheduledAt",
      minWidth: 115,
      flex: 1,
      valueGetter: (params) => {
        return (
          parseISO(params.value).toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric"
          }) +
          " " +
          parseISO(params.value).toLocaleTimeString("en-US", {
            minute: "2-digit",
            hour: "2-digit"
          })
        );
      }
    },
    {
      headerName: "Location",
      field: "location",
      minWidth: 115,
      flex: 1,
      valueGetter: (params) => {
        return params.row.sportLocation
          ? params.row.sportLocation.name ||
              `${params.row.sportLocation.lines.join(", ")}, ${
                params.row.sportLocation.locality
              }, ${params.row.sportLocation.province}, ${
                params.row.sportLocation.country
              }, ${params.row.sportLocation.postalCode}`
          : params.value;
      }
    }
  ];

  const filterConfig = {
    field: "interval",
    placeholderOption: {
      label: "All",
      value: ""
    },
    options: [
      {
        label: "LIVE",
        value: "LIVE"
      },
      {
        label: "Today",
        value: "TODAY"
      },
      {
        label: "Future",
        value: "FUTURE"
      },
      {
        label: "Past",
        value: "PAST"
      }
    ]
  };

  const [refreshKey] = React.useState(0);

  const onAdd = () => {
    navigate("/live-stream/create");
  };

  const onEdit = (liveStream) => {
    navigate(`/live-stream/${liveStream.streamId}/edit`);
  };

  const onView = (liveStream) => {
    navigate(`/live-stream/${liveStream.streamId}`);
  };

  return (
    <Loader isLoading={permissionLoading}>
      <TableView
        title="Live Streams"
        getRowId={(row) => row.streamId}
        useGet={getLiveStreams}
        columns={COLUMNS}
        filterConfig={filterConfig}
        onAdd={onAdd}
        isEditDisabled={(row) =>
          !permissions.edit &&
          (row.status == "LIVE" || row.status == "COMPLETED")
        }
        onEdit={onEdit}
        onView={onView}
        isDeleteDisabled={() => true}
        refreshKey={refreshKey}
        //defaultSort={[{ sort: "asc", field: "title" }]}
        pinnedColumns={{ left: ["action", "status", "title"] }}
      />
    </Loader>
  );
};
