import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import {
  AdminDepartmentPostPutRequest,
  useAdminDepartmentDepartmentIdGet,
  useAdminDepartmentDepartmentIdPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { Footer } from "@components/crud/Footer";
import { enqueueSnackbar } from "notistack";
import { DepartmentForm } from "./departmentDetailsForm";

export const DepartmentEdit = () => {
  const navigate = useNavigate();
  const { departmentId } = useParams();
  const form = useForm({
    mode: "onBlur"
  });

  const {
    reset,
    formState: { isDirty, isValid },
    getValues
  } = form;

  const { data: data, isLoading: isLoading } =
    useAdminDepartmentDepartmentIdGet(departmentId!);

  const { mutate: save, isLoading: isSaving } =
    useAdminDepartmentDepartmentIdPut();
  const onSave = async () => {
    console.log("SUBMITTT", getValues());
    const values = {
      ...getValues()
    };
    save(
      {
        data: values as AdminDepartmentPostPutRequest,
        departmentId: departmentId!
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Department edited successfully!", {
            variant: "success"
          });

          navigate("/departments");
        },
        onError: () => {
          enqueueSnackbar("Failed to edit Department!", {
            variant: "error"
          });
        }
      }
    );
  };

  React.useEffect(() => {
    if (data?.data) {
      const department = data.data;
      reset({
        name: department.name
      });
    }
  }, [data]);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  return (
    <Container>
      <Toolbar title="Edit Department" />
      <Loader isLoading={isLoading}>
        <DepartmentForm form={form} />
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          onSave();
        }}
        isDisabled={!isDirty || !isValid}
        isLoading={isLoading || isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/departments")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
