import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import {
  ModelDepartment,
  useAdminDepartmentGet,
  useAdminJobTitileTitleIdGet,
  useAdminJobTitleTitleIdDelete
} from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { enqueueSnackbar } from "notistack";
import { JobTitleForm } from "./jobTitleForm";

export const JobTitleView = () => {
  const navigate = useNavigate();
  const { jobTitleId } = useParams();
  const form = useForm({
    mode: "onBlur"
  });

  const { reset } = form;

  const { data: departmentsData, isLoading: isLoadingDepartments } =
    useAdminDepartmentGet();

  const { data: data, isLoading: isLoading } = useAdminJobTitileTitleIdGet(
    jobTitleId!
  );
  const [departments, setDepartments] = React.useState<ModelDepartment[]>([]);

  React.useEffect(() => {
    if (data?.data) {
      const jobTitle = data.data;
      reset({
        name: jobTitle.name,
        description: jobTitle.description,
        departmentId: jobTitle.departmentId,
        isActive: jobTitle.isActive
      });
    }
  }, [data]);
  React.useEffect(() => {
    if (departmentsData?.data) {
      setDepartments(departmentsData.data.departments!);
    }
  }, [departmentsData]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminJobTitleTitleIdDelete();

  const onArticleDelete = async () => {
    try {
      await deleteAsync({ jobTitleId: jobTitleId as string });
      enqueueSnackbar("Job Title Deleted successfully", {
        variant: "success"
      });
      setOpenDeleteDialog(false);
      navigate("/job-titles");
    } catch (e) {
      enqueueSnackbar("Something went wrong! Unable to delete the Job Title.", {
        variant: "error"
      });
      setOpenDeleteDialog(false);
    }
  };

  return (
    <Container>
      <Toolbar
        title="View Job Title"
        backBtnClick={() => {
          navigate("/job-titles");
        }}
        editBtnClick={() => {
          navigate(`/job-titles/${jobTitleId}/edit`);
        }}
        deleteBtnClick={() => {
          setOpenDeleteDialog(true);
        }}
      />
      <Loader isLoading={isLoading || isDeleting}>
        <JobTitleForm
          form={form}
          departments={departments}
          disabled
          isLoadingDepartments={isLoadingDepartments}
        />
      </Loader>
      <ConfirmationDialog
        title="Delete Job Title?"
        body="Are you sure you want to delete this job title?"
        open={openDeleteDialog}
        close={() => setOpenDeleteDialog(false)}
        onCancel={() => setOpenDeleteDialog(false)}
        onConfirm={() => onArticleDelete()}
        cancelBtnText="No"
        confirmBtnText="Yes"
        confirmBtnVariant="admin-warning"
        icon="warning"
      />
    </Container>
  );
};
