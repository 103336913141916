import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ToolTip } from "./ToolTip";

const Container = styled(Box)`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const TooltipText = styled(Typography)`
  font-size: 12px;
  color: ${(props) => props.theme.palette.blue.main};
  margin-left: 8px;
`;

export const VerticalListTooltip = ({
  text,
  tooltipList,
  secondLineText
}: {
  text: string;
  secondLineText?: string;
  tooltipList: string[];
}) => (
  <Container>
    {text || "N/A"}
    {secondLineText && (
      <>
        <br />
        {secondLineText}
      </>
    )}
    {!!tooltipList.length && (
      <ToolTip
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [10, -10]
              }
            }
          ]
        }}
        title={tooltipList.map((item) => (
          <div key={item} data-testid={"TOOLTIP_TEXT_" + item}>
            {item}
          </div>
        ))}
      >
        <TooltipText>{`+ ${tooltipList.length}`}</TooltipText>
      </ToolTip>
    )}
  </Container>
);

VerticalListTooltip.defaultProps = {
  text: "",
  tooltipList: []
};
