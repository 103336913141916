import { Container } from "@components/crud/Container";
import { Grid, ButtonGroup, Button } from "@mui/material";
import { useRecoilState } from "recoil";
import { organizationAtom } from "@recoil/auth";
import React from "react";
import { SubscriptionBillingSettings } from "./SubscriptionsBillingSettings";
import { Form } from "@components/crud/Form";
import { LiveStreamingSetting } from "./LiveStreamingSetting";

export const MobileApp = () => {
  const [organizationId] = useRecoilState(organizationAtom);

  const buttonGroups = ["Subscription & Billing", "Live Streaming"];

  const [activeButton, setActiveButton] = React.useState(
    "Subscription & Billing"
  );

  return (
    <Container>
      <Grid container direction="column" spacing="15px">
        {!organizationId && (
          <Grid item style={{ marginLeft: "25px", marginTop: "16px" }}>
            <ButtonGroup
              variant="outlined"
              style={{ border: "rgba(215, 221, 229, 1)" }}
            >
              {buttonGroups.map((bg) => {
                return (
                  <Button
                    key={bg}
                    variant="outlined"
                    style={
                      activeButton === bg
                        ? {
                            backgroundColor: "#E8EEFF",
                            color: "#2B337A",
                            fontSize: "14px",
                            fontWeight: 600,
                            textTransform: "none"
                          }
                        : {
                            color: "#666666",
                            fontSize: "14px",
                            fontWeight: 500,
                            textTransform: "none"
                          }
                    }
                    onClick={() => {
                      setActiveButton(bg);
                    }}
                  >
                    {bg}
                  </Button>
                );
              })}
            </ButtonGroup>
          </Grid>
        )}
      </Grid>
      <Form>
        <Grid item container direction="column" spacing={3}>
          {activeButton === "Subscription & Billing" && (
            <SubscriptionBillingSettings />
          )}
          {activeButton === "Live Streaming" && <LiveStreamingSetting />}
        </Grid>
      </Form>
    </Container>
  );
};
