import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import {
  ModelLevel,
  useAdminLevelLevelIdDelete,
  useAdminLevelLevelIdGet,
  useAdminSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { GENDERS } from "@utils/constants";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";

export const LevelView = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [toDelete, setToDelete] = useState<ModelLevel | null>(null);
  const [permissions, setPermissions] = useState({
    delete: false
  });

  const { levelId } = useParams();
  const [organizationId] = useRecoilState(organizationAtom);

  const {
    data: level,
    isFetching: isLevelFetching,
    error: error
  } = useAdminLevelLevelIdGet(levelId as string);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error]);
  const { data: sports, isLoading: isSportLoading } = useAdminSportGet({
    organizationId: organizationId!
  });
  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId!
      })) || [],
    [sports]
  );
  const { control, reset } = useForm({
    mode: "onBlur"
  });

  useEffect(
    () =>
      reset(
        {
          name: level?.data?.name,
          abbv: level?.data?.abbv,
          description: level?.data?.description,
          sportId: level?.data?.sports?.map((sport) => sport.sportId),
          genders: level?.data?.genders,
          inheritedFrom:
            organizationId && level?.data?.isInherited
              ? level?.data?.inheritedFrom
                ? level?.data?.inheritedFrom.name
                : "SportsGravy"
              : undefined
        },
        {
          keepDirtyValues: true
        }
      ),
    [level, sportOptions]
  );
  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminLevelLevelIdDelete();

  const onConfirmDelete = async () => {
    if (!toDelete?.levelId) return;
    try {
      await deleteAsync({ levelId: toDelete.levelId });
      enqueueSnackbar("Level deleted successfully", { variant: "success" });
      setToDelete(null);
      navigate("/levels");
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete level.", {
        variant: "error"
      });
      setToDelete(null);
    }
  };

  const isDeleteDisabled = (level) => {
    return (
      (level?._count?.organizations == 0 && level?._count?.users == 0) ||
      level?.isInherited
    );
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission("admin.levels", "DELETE");
      setPermissions({
        delete: del
      });
    };
    fetchPermissions();
  }, []);
  return (
    <Container>
      <Toolbar
        title="View Level"
        backBtnClick={() => navigate("/levels")}
        editBtnClick={
          organizationId && level?.data?.isInherited
            ? undefined
            : () => navigate(`/levels/${levelId}/edit`)
        }
        {...(permissions.delete &&
          !isDeleteDisabled(level?.data) && {
            deleteBtnClick: () => setToDelete(level!.data)
          })}
      />
      <Form data-testid="level-view-form">
        <Loader isLoading={isLevelFetching || isSportLoading}>
          <Grid container spacing={3}>
            <Grid xs={12} md={6}>
              <FormInput
                control={control}
                name="name"
                type="text"
                label="Name"
                required={true}
                disabled={true}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <FormInput
                control={control}
                name="abbv"
                type="text"
                label="Abbreviation"
                required={true}
                disabled={true}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <FormInput
                control={control}
                name="description"
                type="text"
                label="Description"
                required={true}
                multiline={true}
                disabled={true}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <FormMultiSelect
                control={control}
                value="sportId"
                name="sportId"
                label="Sport"
                required={true}
                options={sportOptions}
                disabled={true}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <FormMultiSelect
                control={control}
                name="genders"
                value="gender"
                label="Gender"
                required={true}
                options={GENDERS}
                disabled={true}
              />
            </Grid>
            {organizationId && level?.data?.isInherited && (
              <Grid xs={12} md={6} data-testid="level-sharedBy">
                <FormInput
                  control={control}
                  name="inheritedFrom"
                  type="text"
                  label="Shared By"
                  disabled={true}
                />
              </Grid>
            )}
          </Grid>
        </Loader>
      </Form>
      <ConfirmationDialog
        open={!!toDelete}
        title="Delete Level"
        body={`Are you sure you want to delete ${toDelete?.name}?`}
        close={() => setToDelete(null)}
        onConfirm={onConfirmDelete}
        onCancel={() => setToDelete(null)}
        isConfirming={isDeleting}
        confirmBtnVariant="admin-warning"
        icon="warning"
      />
    </Container>
  );
};
