import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import { Grid } from "@mui/material";
import { SyntheticEvent, useState } from "react";
import { TeamsProgramsSettings } from "./TeamsProgramsSettings";
import { Form } from "@components/crud/Form";
import { organizationAtom } from "@recoil/auth";
import { useRecoilState } from "recoil";
import { LiveStreamingSetting } from "./LiveStreamingSetting";
import { SubscriptionBillingSettings } from "./SubscriptionsBillingSettings";
import { GeneralSettingsFso } from "./GeneralSettingsFso";
import { MobileApp } from "./MobileApp";
import { ProductEngineering } from "./ProductEngineering";
import { FeedSetting } from "./FeedSetting";
import { DashboardSetting } from "./DashboardSetting";

export const Settings = () => {
  const [organizationId] = useRecoilState(organizationAtom);
  const [tab, setTab] = useState(organizationId ? "General" : "Mobile App");
  const onTabChange = (event: SyntheticEvent, value: unknown) => {
    setTab(value as string);
  };
  return (
    <Container>
      <Toolbar
        title="Settings"
        tabs={{
          tabs: organizationId
            ? [
                "General",
                "Dashboard",
                "Teams/Programs",
                "Roles",
                "Live Streaming"
              ]
            : ["Mobile App", "Feed", "Dashboard", "Product & Engineering"],
          onTabChange: onTabChange,
          activeTab: tab
        }}
      />
      <Form>
        <Grid item container direction="column" spacing={3}>
          {tab === "General" && organizationId && <GeneralSettingsFso />}
          {tab === "Mobile App" && <MobileApp />}
          {tab === "Teams/Programs" && <TeamsProgramsSettings />}
          {tab === "Roles" && <></>}
          {tab === "Subscriptions and Billing" && (
            <SubscriptionBillingSettings />
          )}
          {tab === "Live Streaming" && <LiveStreamingSetting />}
          {tab === "Product & Engineering" && <ProductEngineering />}
          {tab === "Feed" && <FeedSetting />}
          {tab === "Dashboard" && <DashboardSetting />}
        </Grid>
      </Form>
    </Container>
  );
};
