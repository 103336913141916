import { RenderTableView } from "@components/RenderTableView";
import { GridColDef } from "@mui/x-data-grid";
import { ModelPerson } from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalize } from "@utils/capitalize";
export const ImportedRowsView = (props: { importedRows: ModelPerson[] }) => {
  const IMPORTED_ROWS_COLUMNS: GridColDef[] = [
    {
      headerName: "Athlete / Player First Name",
      field: "playerFirstName",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.firstName || ""
    },
    {
      headerName: "Athlete / Player Middle Name",
      field: "playerMiddleName",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.middleName || ""
    },
    {
      headerName: "Athlete / Player Last Name",
      field: "playerLastName",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.lastName || ""
    },
    {
      headerName: "Athlete / Player Date of Birth",
      field: "playerDateOfBirth",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) =>
        new Date(params.row.birthedAt).toISOString().split("T")[0] || ""
    },
    {
      headerName: "Athlete / Player Gender",
      field: "playerGender",
      minWidth: 130,
      flex: 1,
      sortable: false,
      valueGetter: (params) => capitalize(params.row.gender) || ""
    },
    {
      headerName: "Athlete / Player Level",
      field: "playerLevel",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.level || ""
    },
    {
      headerName: "Athlete / Player Email",
      field: "playerEmail",
      minWidth: 300,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.email || ""
    },
    {
      headerName: "Address Line 1",
      field: "addressLine1",
      minWidth: 200,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.addressPrimary.lines[0]
    },
    {
      headerName: "Address Line 2",
      field: "addressLine2",
      minWidth: 200,
      flex: 1,
      sortable: false
    },
    {
      headerName: "City",
      field: "city",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.addressPrimary.locality
    },
    {
      headerName: "State",
      field: "state",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.addressPrimary.province
    },
    {
      headerName: "Country",
      field: "country",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.addressPrimary.country
    },
    {
      headerName: "Zip Code",
      field: "zipCode",
      minWidth: 100,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.addressPrimary.postalCode
    },
    {
      headerName: "Parent / Guardian 1 First Name",
      field: "parent1FirstName",
      minWidth: 170,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.guardians[0].guardian.firstName
    },
    {
      headerName: "Parent / Guardian 1 Last Name",
      field: "parent1LastName",
      minWidth: 170,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.guardians[0].guardian.lastName
    },
    {
      headerName: "Parent / Guardian 1 Mobile Phone",
      field: "parent1Phone",
      minWidth: 170,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.guardians[0].guardian.phone
    },
    {
      headerName: "Parent / Guardian 1 Email",
      field: "parent1Email",
      minWidth: 300,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.guardians[0].guardian.email
    },
    {
      headerName: "Parent / Guardian 2 First Name",
      field: "parent2FirstName",
      minWidth: 170,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.guardians[1].guardian.firstName
    },
    {
      headerName: "Parent / Guardian 2 Last Name",
      field: "parent2LastName",
      minWidth: 170,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.guardians[1].guardian.lastName
    },
    {
      headerName: "Parent / Guardian 2 Mobile Phone",
      field: "parent2Phone",
      minWidth: 170,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.guardians[1].guardian.phone
    },
    {
      headerName: "Parent / Guardian 2 Email",
      field: "parent2Email",
      minWidth: 300,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.guardians[1].guardian.email
    }
  ];

  return (
    <>
      <RenderTableView
        title=""
        rows={props.importedRows}
        columns={IMPORTED_ROWS_COLUMNS}
        hideFilter
        getRowId={(row) => row?.personId}
        searchable={false}
        hideToolbar={true}
        hideFooter={true}
        hasActionColumn={false}
        pinnedColumns={{ left: [] }}
      />
    </>
  );
};
