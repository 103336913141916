import {
  ModelInvite,
  ModelPerson,
  ModelUser,
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useRecoilPersist } from "@utils/recoilPersist";
import { atom } from "recoil";
import { BasicFormInput } from "../types/basicFormInput";
import { DuplicatePerson } from "../types/duplicatePerson";

export type ConsentForm = {
  signature: string;
  children: {
    fullName: string;
    email: string;
    dob: string;
    address: string;
  }[];
  parent: {
    fullName: string;
    email: string;
    dob: string;
    address: string;
  };
};

export type KidInvite = {
  personId: string;
  selected: boolean;
  email: string;
  dob: string;
  relationship: string;
};

const persistEffectFactory = useRecoilPersist({
  key: "signup",
});

export const consentFormAtom = atom<ConsentForm | null>({
  key: "consentForm",
  default: null as ConsentForm | null,
  effects: [persistEffectFactory<ConsentForm | null>()],
});

export const kidInviteAtom = atom<KidInvite[]>({
  key: "kidInvite",
  default: [],
  effects: [persistEffectFactory<KidInvite[]>()],
});

export const inviteAtom = atom<ModelInvite | null>({
  key: "invite",
  default: null as ModelInvite | null,
  effects: [persistEffectFactory<ModelInvite | null>()],
});

export const passwordAtom = atom<string>({
  key: "password",
  default: "",
  effects: [persistEffectFactory<string>()],
});

export const signatureAtom = atom<string>({
  key: "signature",
  default: "",
  effects: [persistEffectFactory<string>()],
});

export const personAtom = atom<ModelPerson | null>({
  key: "personalInfo",
  default: null as ModelPerson | null,
  effects: [persistEffectFactory<ModelPerson | null>()],
});

export const signupTokenAtom = atom<string>({
  key: "signupToken",
  default: "",
  effects: [persistEffectFactory<string>()],
});

export const userAtom = atom<ModelUser | null>({
  key: "user",
  default: null as ModelUser | null,
  effects: [persistEffectFactory<ModelUser | null>()],
});

export const duplicatePendingAccountsAtom = atom<DuplicatePerson[]>({
  key: "duplicatePendingAccounts",
  default: [] as DuplicatePerson[],
  effects: [persistEffectFactory<DuplicatePerson[]>()],
});

export const pendingAccountAtom = atom<DuplicatePerson | null>({
  key: "pendingAccount",
  default: null as DuplicatePerson | null,
  effects: [persistEffectFactory<DuplicatePerson | null>()],
});

export const basicFormInputAtom = atom<BasicFormInput | null>({
  key: "basicFormInput",
  default: null as BasicFormInput | null,
  effects: [persistEffectFactory<BasicFormInput | null>()],
});

export const duplicateAccountAtom = atom<DuplicatePerson | null>({
  key: "duplicateAccount",
  default: null as DuplicatePerson | null,
  effects: [persistEffectFactory<DuplicatePerson | null>()],
});
