import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  useAdminLevelPost,
  useAdminSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { GENDERS } from "@utils/constants";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "../../recoil/auth";
import { capitalizeEveryWord } from "@utils/capitalize";

export const LevelCreate = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const organizationId = useRecoilValue(organizationAtom);

  const { data: sports, isLoading: isSportLoading } = useAdminSportGet({
    organizationId: organizationId!
  });
  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId!
      })) || [],
    [sports]
  );
  const {
    handleSubmit,
    control,
    formState: { isValid },
    reset,
    setValue
  } = useForm({
    mode: "onBlur"
  });
  useEffect(() => {
    if (sportOptions.length === 1) {
      setValue("sportId", [sportOptions[0].value]);
    }
  });
  const { mutate: save, isLoading: isSaving } = useAdminLevelPost();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    (formValues) => {
      const values = {
        ...formValues,
        ...(organizationId && { organizationId: organizationId })
      };
      if (values.sportId) {
        values.sports = values.sportId.map((sportId) => ({
          sportId
        }));
      }

      save(
        {
          data: values
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Level added successfully!", {
              variant: "success"
            });
            if (resetInsteadOfRoute) {
              reset();
            } else {
              navigate("/levels");
            }
          },
          onError: () => {
            enqueueSnackbar("Failed to add level!", { variant: "error" });
          }
        }
      );
    };

  return (
    <Container>
      <Toolbar title="Add Level" />
      <Form>
        <Grid data-testid="level-add-form" container spacing={3}>
          <Grid xs={12} md={6} data-testid="level-name">
            <FormInput
              control={control}
              name="name"
              type="text"
              label="Name"
              onChange={(e) => {
                setValue("name", capitalizeEveryWord(e.target.value));
              }}
              required={true}
              rules={{
                required: "Name is required"
              }}
            />
          </Grid>
          <Grid xs={12} md={6} data-testid="level-abbv">
            <FormInput
              control={control}
              name="abbv"
              type="text"
              label="Abbreviation"
              required={true}
              rules={{
                required: "Abbreviation is required",
                maxLength: {
                  value: 6,
                  message: "Abbreviation must be 6 characters or less"
                }
              }}
            />
          </Grid>
          <Grid xs={12} md={6} data-testid="level-description">
            <FormInput
              control={control}
              name="description"
              type="text"
              label="Description"
              required={true}
              multiline={true}
              rules={{
                required: "Description is required"
              }}
            />
          </Grid>
          <Grid data-testid="level-sport-input" xs={12} md={6}>
            <FormMultiSelect
              control={control}
              name="sportId"
              label="Sport"
              value="sportId"
              required={true}
              options={sportOptions}
              isLoading={isSportLoading}
              disabled={sportOptions.length === 1}
              rules={{
                required: "Sport is required"
              }}
            />
          </Grid>
          <Grid data-testid="level-gender-input" xs={12} md={6}>
            <FormMultiSelect
              control={control}
              name="genders"
              label="Gender"
              value="gender"
              required={true}
              options={GENDERS}
              rules={{
                required: "Gender is required"
              }}
            />
          </Grid>
        </Grid>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler(false))}
        saveAndNewBtnClick={handleSubmit(saveHandler(true))}
        isDisabled={!isValid || isSaving}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/levels")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
