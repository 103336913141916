import { Button } from "@components/Button";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@components/crud/Accordian";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import { Toolbar as MUIToolbar, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Grid from "@mui/material/Unstable_Grid2";
import {
  ModelImportTemplateProviderVersion,
  useAdminImportTmplProviderIdGet,
  useAdminImportTmplProviderIdVersionVersionIdDelete,
  useAdminImportTmplProviderIdVersionVersionIdGet,
  useLookupSGField
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

export const ImportTemplateProviderVersionView = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { providerId, versionId } = useParams();
  const [toDelete, setToDelete] =
    useState<ModelImportTemplateProviderVersion | null>(null);

  const { data: mapping, isFetched: mappingFetched } = useLookupSGField();
  const {
    data: importTemplateProvider,
    isFetched: importTemplateProviderFetched,
    error: error
  } = useAdminImportTmplProviderIdGet(providerId as string);
  const {
    data: importTemplateProviderVersion,
    isFetched: importTemplateProviderVersionFetched,
    error: error1
  } = useAdminImportTmplProviderIdVersionVersionIdGet(
    providerId as string,
    versionId as string
  );
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST" || error1?.code == "ERR_BAD_REQUEST")
      navigate("/not-found");
  }, [error, error1]);
  const { control, getValues, reset } = useForm();
  useEffect(
    () =>
      reset(
        {
          fileName: importTemplateProviderVersion?.data?.fileName || "",
          description: importTemplateProviderVersion?.data?.description || "",
          providerName: importTemplateProvider?.data?.name || "",
          version: importTemplateProviderVersion?.data?.version || "",
          fieldMap: importTemplateProviderVersion?.data?.fieldMap || {}
        },
        {
          keepDefaultValues: true
        }
      ),
    [importTemplateProviderVersion, importTemplateProvider]
  );

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminImportTmplProviderIdVersionVersionIdDelete();

  const onConfirmDelete = async () => {
    if (!toDelete?.versionId) return;
    try {
      await deleteAsync({
        providerId: providerId as string,
        versionId: toDelete?.versionId as string
      });
      enqueueSnackbar("Import Template Provider Version deleted successfully", {
        variant: "success"
      });
      navigate(`/import-template-providers/${providerId}`);
      setToDelete(null);
    } catch (error) {
      enqueueSnackbar(
        "Something went wrong! Unable to delete import template provider version.",
        {
          variant: "error"
        }
      );
      setToDelete(null);
    }
  };

  const isDeleteDisabled = (row) => {
    return row?._count?.organizations > 0;
  };
  const tableHeader = () => {
    return (
      <TableHead sx={{ backgroundColor: "#F8FAFC" }}>
        <TableRow>
          <TableCell>
            <TableSortLabel>
              <Typography variant="tableHeader">Available</Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel>
              <Typography variant="tableHeader">SportsGravy Fields</Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel>
              <Typography variant="tableHeader">
                Template File Columns
              </Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel>
              <Typography variant="tableHeader">Value is Required</Typography>
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const tableRowInput = ({ name, isMandatory, key }) => {
    const value = getValues(`fieldMap.${key}.header`);
    const options = [{ label: value, value: value }];
    return (
      <TableRow key={key}>
        <TableCell sx={{ paddingLeft: 4 }}>
          <FormCheckbox
            name={`fieldMap.${key}.available`}
            control={control}
            required={!!isMandatory}
            disabled={true}
          />
        </TableCell>
        <TableCell>
          <Typography variant="body1">{name}</Typography>
        </TableCell>
        <TableCell>
          <FormSelect
            name={`fieldMap.${key}.header`}
            options={options}
            control={control}
            required={!!isMandatory}
            disabled={true}
          />
        </TableCell>
        <TableCell sx={{ paddingLeft: 6 }}>
          <FormCheckbox
            name={`fieldMap.${key}.canBeEmpty`}
            control={control}
            inverseValue={true}
            disabled={true}
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Container>
      <Toolbar
        title="View Version for Import Template Provider"
        backBtnClick={() =>
          navigate(`/import-template-providers/${providerId}`)
        }
        {...(!isDeleteDisabled(importTemplateProviderVersion?.data) && {
          deleteBtnClick: () =>
            setToDelete(importTemplateProviderVersion!.data!)
        })}
      />
      <Form>
        <Loader
          isLoading={
            !importTemplateProviderVersionFetched ||
            !importTemplateProviderFetched ||
            !mappingFetched
          }
        >
          <Grid container spacing={3}>
            <Grid xs={12}>
              <FormInput
                control={control}
                disabled={true}
                name="providerName"
                type="text"
                label="Template Provider Name"
              />
            </Grid>
            <Grid xs={12} data-testid="importTpl_ver_view_name">
              <FormInput
                control={control}
                name="version"
                type="text"
                label="Version Name"
                required={true}
                disabled={true}
              />
            </Grid>
            <Grid xs={12} data-testid="importTpl_ver_view_desc">
              <FormInput
                control={control}
                name="description"
                type="text"
                label="Description"
                disabled={true}
                multiline
                InputProps={{
                  rows: 3
                }}
              />
            </Grid>
            <Grid xs={12}>
              <FormInput
                control={control}
                name="fileName"
                type="text"
                label="Template File"
                required={true}
                disabled={true}
              />
            </Grid>
            <Grid xs={12} container spacing={0}>
              {mappingFetched &&
                mapping?.data &&
                Object.keys(mapping?.data).map((key, index) => {
                  return (
                    <Grid xs={12} key={`${index}_${key}`}>
                      <Accordion
                        expanded={
                          importTemplateProviderVersion?.data?.fieldMap?.[key]
                        }
                      >
                        <AccordionSummary>
                          <MUIToolbar
                            sx={{
                              padding: "0 !important"
                            }}
                          >
                            <FormCheckbox
                              label={mapping.data[key].name}
                              labelTypographyProps={{
                                variant: "h6",
                                sx: { opacity: 0.6 }
                              }}
                              name={`fieldMap.${key}`}
                              disabled={true}
                              control={control}
                            />
                          </MUIToolbar>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Table>
                            {tableHeader()}
                            <TableBody
                              sx={{
                                "& tr": {
                                  borderBottom:
                                    "2px solid rgba(226, 232, 240, .75)"
                                },
                                "& tr:last-child": { borderBottom: 0 }
                              }}
                            >
                              {Object.keys(mapping.data[key].fields).map(
                                (fieldKey: string) =>
                                  tableRowInput(
                                    mapping.data[key].fields[fieldKey]
                                  )
                              )}
                            </TableBody>
                          </Table>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </Loader>
      </Form>
      <Footer
        additionalBtns={[
          <Button
            key="back"
            variant="admin-secondary"
            type="button"
            onClick={() => navigate(`/import-template-providers/${providerId}`)}
          >
            Back to List
          </Button>
        ]}
      />
      <ConfirmationDialog
        body={`Are you sure you want to delete ${toDelete?.version}?`}
        close={() => setToDelete(null)}
        confirmBtnVariant="admin-warning"
        icon="warning"
        isConfirming={isDeleting}
        onCancel={() => setToDelete(null)}
        onConfirm={onConfirmDelete}
        open={!!toDelete}
        title="Delete Version"
      />
    </Container>
  );
};
