import { TableView } from "@components/TableView";
import { getPositions } from "@services/Network";
import {
  ModelSport,
  useSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";
import { useNavigate } from "react-router-dom";

const LIST_COLUMNS = [
  {
    headerName: "Sport",
    field: "sport",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ value }) => value?.name
  },
  { headerName: "Name", field: "name", minWidth: 150, flex: 1 },
  { headerName: "Abbr", field: "abbreviation", minWidth: 80, flex: 1 },
  {
    headerName: "Parent Position",
    field: "parent",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ value }) => value?.name
  }
];

export const Positions = () => {
  const navigate = useNavigate();
  const [refreshKey] = React.useState(0);
  const { data: sports } = useSportGet();

  const filterConfig = React.useMemo(
    () => ({
      field: "sportId",
      placeholderOption: { label: "All Sports", value: "" },
      options: [
        {
          label: "Filter by Sport",
          children:
            sports?.data?.sports?.map((sport: ModelSport) => ({
              label: sport.name,
              value: sport.sportId
            })) || []
        }
      ]
    }),
    [sports]
  );

  const onAdd = () => navigate("/positions/create");
  const onEdit = (row) => navigate(`/positions/${row.positionId}/edit`);
  const onView = (row) => navigate(`/positions/${row.positionId}`);

  return (
    <>
      <TableView
        title="Positions"
        useGet={getPositions}
        columns={LIST_COLUMNS}
        filterConfig={filterConfig}
        defaultSort={[{ field: "sport", sort: "asc" }]}
        onAdd={onAdd}
        onEdit={onEdit}
        onView={onView}
        isDeleteDisabled={() => true}
        refreshKey={refreshKey}
      />
    </>
  );
};
