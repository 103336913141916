import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { FeedMediaViewer } from "./FeedMediaViewer";
import { styled } from "@mui/material/styles";
import imageThumbnail from "@assets/icons/imageThumbnail.svg";
import { Avatar, Box, Typography } from "@mui/material";
import { Container } from "./crud/Container";
import FeedIndividualMedia from "./FeedIndividualMedia";

const StyledRowGrid = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  cursor: "pointer",
  backgroundColor: "#DEDEDE"
});
const StyledGrid = styled(Grid)({
  backgroundColor: "#DEDEDE",
  cursor: "pointer"
});

export const FeedMediaGrid = (props: { media }) => {
  const [mediaViewer, setMediaViewer] = useState(Boolean);
  const [currentIndex, setCurrentIndex] = useState();
  const [media, setMedia] = useState(Boolean);

  const toggleMediaViewer = (media, index) => {
    setMediaViewer(true);
    setCurrentIndex(index);
    setMedia(media);
  };
  useEffect(() => {
    if (mediaViewer) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [mediaViewer]);
  const closeMediaViewer = () => {
    setMediaViewer(false);
  };
  const renderMedia = () => {
    let media = props.media;
    let remainingMedia = 0;
    const totalMedia = props.media.length;
    if (totalMedia > 5) {
      remainingMedia = totalMedia - 5;
      media = media.slice(0, 5);
    }
    const landscapeMedia = media.filter(
      (item) =>
        (item.metadata?.width > item.metadata?.height &&
          item.metadata?.orientation == 1) ||
        (item.metadata?.width < item.metadata?.height &&
          item.metadata?.orientation == 6)
    );
    const portraitMedia = media.filter(
      (item) =>
        (item.metadata?.width <= item.metadata?.height &&
          item.metadata?.orientation == 1) ||
        (item.metadata?.width >= item.metadata?.height &&
          item.metadata?.orientation == 6) ||
        ((item.metadata?.width == undefined ||
          item.metadata?.height == undefined) &&
          (item.path.includes(".jpg") || item.path.includes(".jpeg"))) ||
        item.path.includes(".mp4") ||
        item.path.includes(".m3u8")
    );
    if (totalMedia === 1) {
      if (portraitMedia.length == 1) {
        return (
          <StyledGrid xs={12} display={"flex"} justifyContent={"center"}>
            <FeedIndividualMedia
              onClick={() => {
                toggleMediaViewer(props.media, props.media[0].mediaId);
              }}
              src={props.media[0].baseUrl + props.media[0].path}
              style={{ height: "503px" }}
            />
          </StyledGrid>
        );
      }
      if (landscapeMedia.length == 1) {
        return (
          <StyledGrid xs={12} display={"flex"} alignItems={"center"}>
            <FeedIndividualMedia
              onClick={() => {
                toggleMediaViewer(props.media, props.media[0].mediaId);
              }}
              src={props.media[0].baseUrl + props.media[0].path}
              style={{ width: "100%" }}
            />
          </StyledGrid>
        );
      }
    } else if (totalMedia === 2) {
      if (landscapeMedia.length === 2) {
        return (
          <>
            <StyledGrid
              style={{ display: "flex", flexWrap: "wrap", height: "98%" }}
              direction={"row"}
              xs={12}
            >
              <FeedIndividualMedia
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                style={{ width: "100%", height: "50%", marginBottom: "3px" }}
              />
              <FeedIndividualMedia
                src={landscapeMedia[1].baseUrl + landscapeMedia[1].path}
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[1].mediaId);
                }}
                style={{ width: "100%", height: "50%" }}
              />
            </StyledGrid>
          </>
        );
      }
      if (portraitMedia.length === 2) {
        return (
          <>
            <StyledGrid
              style={{
                height: "100%",
                marginRight: "3px",
                width: "calc(50% - 3px)",
                textAlign: "center"
              }}
              direction={"column"}
              xs={6}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{ height: "505px" }}
              />
            </StyledGrid>
            <StyledGrid
              style={{
                height: "100%",
                width: "calc(50% - 0px)",
                textAlign: "center"
              }}
              direction={"column"}
              xs={6}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[1].mediaId);
                }}
                src={portraitMedia[1].baseUrl + portraitMedia[1].path}
                style={{ height: "505px" }}
              />
            </StyledGrid>
          </>
        );
      }
      if (portraitMedia.length === 1 && landscapeMedia.length === 1) {
        return (
          <>
            <StyledGrid height={"49%"} direction={"row"} xs={12}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                style={{ width: "100%", height: "100%" }}
              />
            </StyledGrid>
            <StyledGrid
              direction={"row"}
              xs={12}
              display={"grid"}
              justifyContent={"center"}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{ height: "250px" }}
              />
            </StyledGrid>
          </>
        );
      }
    } else if (totalMedia === 3) {
      if (landscapeMedia.length === 3) {
        return (
          <>
            <StyledGrid
              style={{ height: "50%", marginBottom: "2px" }}
              direction={"row"}
              xs={12}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                style={{ height: "100%", width: "100%" }}
              />
            </StyledGrid>
            <StyledGrid
              style={{ width: "50%", height: "49%" }}
              direction={"column"}
              xs={6}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[1].mediaId);
                }}
                src={landscapeMedia[1].baseUrl + landscapeMedia[1].path}
                style={{ height: "100%", width: "100%" }}
              />
            </StyledGrid>
            <StyledGrid
              style={{ width: "50%", height: "49%" }}
              direction={"column"}
              xs={6}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[2].mediaId);
                }}
                src={landscapeMedia[2].baseUrl + landscapeMedia[2].path}
                style={{ height: "100%", width: "99%", marginLeft: "3px" }}
              />
            </StyledGrid>
          </>
        );
      }
      if (portraitMedia.length === 3) {
        return (
          <>
            <StyledGrid
              style={{
                width: "calc(50% - 3px)",
                height: "100%",
                marginRight: "3px",
                textAlign: "center"
              }}
              direction={"column"}
              xs={6}
              justifyContent={"center"}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{
                  height: "100%"
                }}
              />
            </StyledGrid>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexWrap: "wrap",
                width: "50%",
                flexDirection: "column",
                rowGap: "3px"
              }}
            >
              <StyledGrid
                style={{
                  width: "calc(100% - 3px)",
                  height: "50%",
                  marginRight: "3px",
                  textAlign: "center"
                }}
              >
                <FeedIndividualMedia
                  onClick={() => {
                    toggleMediaViewer(props.media, portraitMedia[1].mediaId);
                  }}
                  src={portraitMedia[1].baseUrl + portraitMedia[1].path}
                  style={{ height: "100%" }}
                />
              </StyledGrid>
              <StyledGrid
                style={{
                  width: "calc(100% - 3px)",
                  height: "calc(50% - 3px)",
                  marginRight: "3px",
                  textAlign: "center"
                }}
              >
                <FeedIndividualMedia
                  onClick={() => {
                    toggleMediaViewer(props.media, portraitMedia[2].mediaId);
                  }}
                  src={portraitMedia[2].baseUrl + portraitMedia[2].path}
                  style={{ height: "100%" }}
                />
              </StyledGrid>
            </div>
          </>
        );
      }
      if (landscapeMedia.length === 1 && portraitMedia.length === 2) {
        return (
          <>
            <StyledGrid direction={"row"} xs={12}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                style={{ height: "250px", width: "100%" }}
              />
            </StyledGrid>
            <StyledGrid direction={"row"} xs={12}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{ height: "250px", width: "328px" }}
              />
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[1].mediaId);
                }}
                src={portraitMedia[1].baseUrl + portraitMedia[1].path}
                style={{ marginLeft: "3px", height: "250px", width: "328px" }}
              />
            </StyledGrid>
          </>
        );
      }
      if (portraitMedia.length === 1 && landscapeMedia.length === 2) {
        return (
          <>
            <StyledGrid direction={"row"} xs={12}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                style={{ height: "250px", width: "328px" }}
              />
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[1].mediaId);
                }}
                src={landscapeMedia[1].baseUrl + landscapeMedia[1].path}
                style={{ marginLeft: "3px", height: "250px", width: "328px" }}
              />
            </StyledGrid>
            <StyledRowGrid direction={"row"} xs={12}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{ height: "250px", width: "50%" }}
              />
            </StyledRowGrid>
          </>
        );
      }
    } else if (totalMedia === 4) {
      if (landscapeMedia.length === 4) {
        return (
          <>
            <StyledGrid direction={"column"} xs={6}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                style={{ height: "250px", width: "100%" }}
              />
            </StyledGrid>
            <StyledGrid direction={"column"} xs={6}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[1].mediaId);
                }}
                src={landscapeMedia[1].baseUrl + landscapeMedia[1].path}
                style={{ height: "250px", width: "100%" }}
              />
            </StyledGrid>
            <StyledGrid direction={"column"} xs={6}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[2].mediaId);
                }}
                src={landscapeMedia[2].baseUrl + landscapeMedia[2].path}
                style={{ height: "250px", width: "100%" }}
              />
            </StyledGrid>
            <StyledGrid direction={"column"} xs={6}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[3].mediaId);
                }}
                src={landscapeMedia[3].baseUrl + landscapeMedia[3].path}
                style={{ height: "250px", width: "100%" }}
              />
            </StyledGrid>
          </>
        );
      }
      if (portraitMedia.length === 4) {
        return (
          <>
            <StyledGrid
              style={{
                height: "50%",
                width: "calc(50% - 2px)",
                marginBottom: "3px",
                marginRight: "2px"
              }}
              display={"flex"}
              justifyContent={"center"}
              direction={"column"}
              xs={6}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{ height: "100%", width: "60%" }}
              />
            </StyledGrid>
            <StyledGrid
              style={{ height: "50%", width: "calc(50% - 0px)" }}
              display={"flex"}
              justifyContent={"center"}
              direction={"column"}
              xs={6}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[1].mediaId);
                }}
                src={portraitMedia[1].baseUrl + portraitMedia[1].path}
                style={{ height: "100%", width: "60%" }}
              />
            </StyledGrid>
            <StyledGrid
              display={"flex"}
              style={{
                height: "50%",
                marginBottom: "3px",
                marginRight: "3px",
                width: "calc(50% - 2px)"
              }}
              justifyContent={"center"}
              direction={"column"}
              xs={6}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[2].mediaId);
                }}
                src={portraitMedia[2].baseUrl + portraitMedia[2].path}
                style={{ height: "100%", width: "60%" }}
              />
            </StyledGrid>
            <StyledGrid
              display={"flex"}
              justifyContent={"center"}
              direction={"column"}
              style={{ height: "50%", width: "calc(50% - 2px)" }}
              xs={6}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[3].mediaId);
                }}
                src={portraitMedia[3].baseUrl + portraitMedia[3].path}
                style={{ height: "100%", width: "60%" }}
              />
            </StyledGrid>
          </>
        );
      }
      if (portraitMedia.length === 2 && landscapeMedia.length === 2) {
        return (
          <>
            <StyledRowGrid style={{ marginRight: "2px", width: "49%" }} xs={6}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                style={{ height: "250px", width: "100%" }}
              />
            </StyledRowGrid>
            <StyledRowGrid xs={6}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[1].mediaId);
                }}
                src={landscapeMedia[1].baseUrl + landscapeMedia[1].path}
                style={{ height: "250px", width: "100%" }}
              />
            </StyledRowGrid>
            <StyledRowGrid
              style={{ width: "49%", marginRight: "2px" }}
              direction={"column"}
              xs={6}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{ height: "250px" }}
              />
            </StyledRowGrid>
            <StyledRowGrid direction={"column"} xs={6}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[1].mediaId);
                }}
                src={portraitMedia[1].baseUrl + portraitMedia[1].path}
                style={{ height: "250px" }}
              />
            </StyledRowGrid>
          </>
        );
      }
      if (portraitMedia.length === 3 && landscapeMedia.length === 1) {
        return (
          <>
            <StyledRowGrid xs={12} marginBottom={"3px"}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                style={{ height: "250px", width: "100%", marginRight: "3px" }}
              />
            </StyledRowGrid>

            <StyledRowGrid xs={4}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{ height: "250px", width: "70%", marginRight: "3px" }}
              />
            </StyledRowGrid>
            <StyledRowGrid xs={4}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[1].mediaId);
                }}
                src={portraitMedia[1].baseUrl + portraitMedia[1].path}
                style={{ height: "250px", width: "70%", marginRight: "3px" }}
              />
            </StyledRowGrid>
            <StyledRowGrid xs={4}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[2].mediaId);
                }}
                src={portraitMedia[2].baseUrl + portraitMedia[2].path}
                style={{ height: "250px", width: "70%", marginRight: "3px" }}
              />
            </StyledRowGrid>
          </>
        );
      }
      if (portraitMedia.length === 1 && landscapeMedia.length === 3) {
        return (
          <>
            <StyledRowGrid xs={6}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                style={{ height: "250px", width: "100%" }}
              />
            </StyledRowGrid>
            <StyledRowGrid xs={6}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[1].mediaId);
                }}
                src={landscapeMedia[1].baseUrl + landscapeMedia[1].path}
                style={{ height: "250px", width: "100%", marginLeft: "3px" }}
              />
            </StyledRowGrid>
            <StyledRowGrid xs={6}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[2].mediaId);
                }}
                src={landscapeMedia[2].baseUrl + landscapeMedia[2].path}
                style={{ height: "250px", width: "100%" }}
              />
            </StyledRowGrid>
            <StyledRowGrid xs={6} marginBottom={"3px"}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{ height: "250px", width: "100%" }}
              />
            </StyledRowGrid>
          </>
        );
      }
    } else if (totalMedia >= 5) {
      if (landscapeMedia.length === 5) {
        return (
          <>
            <StyledGrid direction={"column"} xs={6}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                style={{ height: "250px", width: "100%" }}
              />
            </StyledGrid>
            <StyledGrid direction={"column"} xs={6}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[1].mediaId);
                }}
                src={landscapeMedia[1].baseUrl + landscapeMedia[1].path}
                style={{ height: "250px", width: "100%" }}
              />
            </StyledGrid>
            <StyledGrid direction={"column"} xs={4}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[2].mediaId);
                }}
                src={landscapeMedia[2].baseUrl + landscapeMedia[2].path}
                style={{ height: "250px", width: "99%" }}
              />
            </StyledGrid>
            <StyledGrid direction={"column"} xs={4}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[3].mediaId);
                }}
                src={landscapeMedia[3].baseUrl + landscapeMedia[3].path}
                style={{ height: "250px", width: "98%", marginLeft: "3px" }}
              />
            </StyledGrid>
            <StyledGrid direction={"column"} xs={4}>
              <FeedIndividualMedia
                remainingMediaCount={remainingMedia}
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[4].mediaId);
                }}
                src={landscapeMedia[4].baseUrl + landscapeMedia[4].path}
                style={{ height: "250px", width: "98%", marginLeft: "3px" }}
              />
            </StyledGrid>
          </>
        );
      }
      if (portraitMedia.length === 5) {
        return (
          <>
            <StyledRowGrid
              style={{
                height: "50%",
                width: "calc(50% - 3px)",
                marginBottom: "2px",
                marginRight: "2px"
              }}
              xs={6}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{ height: "100%", width: "70%" }}
              />
            </StyledRowGrid>
            <StyledRowGrid
              style={{
                height: "50%",
                marginBottom: "2px",
                width: "calc(50% - 1px)"
              }}
              xs={6}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[1].mediaId);
                }}
                src={portraitMedia[1].baseUrl + portraitMedia[1].path}
                style={{ height: "100%", width: "70%" }}
              />
            </StyledRowGrid>
            <StyledRowGrid
              style={{
                height: "50%",
                width: "calc(34% - 2px)",
                marginRight: "2px"
              }}
              xs={4}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[2].mediaId);
                }}
                src={portraitMedia[2].baseUrl + portraitMedia[2].path}
                style={{ height: "100%", width: "70%" }}
              />
            </StyledRowGrid>
            <StyledGrid
              style={{ height: "50%", width: "calc(33% - 0px)" }}
              display={"flex"}
              justifyContent={"center"}
              direction={"column"}
              xs={4}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[3].mediaId);
                }}
                src={portraitMedia[3].baseUrl + portraitMedia[3].path}
                style={{ height: "100%", width: "70%" }}
              />
            </StyledGrid>
            <StyledGrid
              display={"flex"}
              justifyContent={"center"}
              direction={"column"}
              style={{
                height: "50%",
                width: "calc(33% - 3px)",
                marginLeft: "2px"
              }}
              xs={4}
            >
              <FeedIndividualMedia
                remainingMediaCount={remainingMedia}
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[4].mediaId);
                }}
                src={portraitMedia[4].baseUrl + portraitMedia[4].path}
                style={{ height: "100%", width: "70%" }}
              />
            </StyledGrid>
          </>
        );
      }
      if (portraitMedia.length === 3 && landscapeMedia.length === 2) {
        return (
          <>
            <StyledRowGrid
              style={{ width: "calc(100% - 0px)" }}
              xs={12}
              marginBottom={"2px"}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                style={{ height: "250px", width: "50%", marginRight: "2px" }}
              />
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[1].mediaId);
                }}
                src={landscapeMedia[1].baseUrl + landscapeMedia[1].path}
                style={{ height: "250px", width: "50%" }}
              />
            </StyledRowGrid>

            <StyledRowGrid
              style={{ marginRight: "2px", width: "calc(34% - 2px)" }}
              xs={4}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{ height: "250px", width: "70%" }}
              />
            </StyledRowGrid>
            <StyledRowGrid
              style={{ marginRight: "2px", width: "calc(33% - 2px)" }}
              xs={4}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[1].mediaId);
                }}
                src={portraitMedia[1].baseUrl + portraitMedia[1].path}
                style={{ height: "250px", width: "70%" }}
              />
            </StyledRowGrid>
            <StyledRowGrid style={{ width: "calc(33% - 0px)" }} xs={4}>
              <FeedIndividualMedia
                remainingMediaCount={remainingMedia}
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[2].mediaId);
                }}
                src={portraitMedia[2].baseUrl + portraitMedia[2].path}
                style={{ height: "250px", width: "70%" }}
              />
            </StyledRowGrid>
          </>
        );
      }
      if (portraitMedia.length === 2 && landscapeMedia.length === 3) {
        return (
          <>
            <StyledRowGrid xs={12} marginBottom={"3px"}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                style={{ height: "250px", width: "100%", marginRight: "3px" }}
              />
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[1].mediaId);
                }}
                src={landscapeMedia[1].baseUrl + landscapeMedia[1].path}
                style={{ height: "250px", width: "100%" }}
              />
            </StyledRowGrid>

            <StyledRowGrid xs={4}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[2].mediaId);
                }}
                src={landscapeMedia[2].baseUrl + landscapeMedia[2].path}
                style={{ height: "250px", width: "100%", marginRight: "3px" }}
              />
            </StyledRowGrid>
            <StyledRowGrid xs={4}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{ height: "250px", width: "70%", marginRight: "3px" }}
              />
            </StyledRowGrid>
            <StyledRowGrid xs={4}>
              <FeedIndividualMedia
                remainingMediaCount={remainingMedia}
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[1].mediaId);
                }}
                src={portraitMedia[1].baseUrl + portraitMedia[1].path}
                style={{ height: "250px", width: "70%", marginRight: "3px" }}
              />
            </StyledRowGrid>
          </>
        );
      }
      if (portraitMedia.length === 1 && landscapeMedia.length === 4) {
        return (
          <>
            <StyledRowGrid xs={12} marginBottom={"3px"}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                style={{ height: "50%", width: "100%", marginRight: "3px" }}
              />
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[1].mediaId);
                }}
                src={landscapeMedia[1].baseUrl + landscapeMedia[1].path}
                style={{ height: "250px", width: "100%" }}
              />
            </StyledRowGrid>

            <StyledRowGrid xs={4}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[2].mediaId);
                }}
                src={landscapeMedia[2].baseUrl + landscapeMedia[2].path}
                style={{ height: "250px", width: "100%" }}
              />
            </StyledRowGrid>
            <StyledRowGrid xs={4}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[3].mediaId);
                }}
                src={landscapeMedia[3].baseUrl + landscapeMedia[3].path}
                style={{ height: "250px", width: "100%", marginLeft: "3px" }}
              />
            </StyledRowGrid>
            <StyledRowGrid xs={4}>
              <FeedIndividualMedia
                remainingMediaCount={remainingMedia}
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{ height: "250px", width: "95%", marginRight: "3px" }}
              />
            </StyledRowGrid>
          </>
        );
      }
      if (portraitMedia.length === 4 && landscapeMedia.length === 1) {
        return (
          <>
            <StyledRowGrid
              style={{ height: "50%", width: "100%" }}
              xs={12}
              marginBottom={"2px"}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                style={{ height: "100%", width: "100%" }}
              />
            </StyledRowGrid>
            <StyledRowGrid
              style={{
                height: "50%",
                width: "calc(25% - 2px)",
                marginRight: "2px"
              }}
              xs={3}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{ height: "100%", width: "100%" }}
              />
            </StyledRowGrid>
            <StyledRowGrid
              style={{
                height: "50%",
                width: "calc(25% - 2px)",
                marginRight: "2px"
              }}
              xs={3}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[1].mediaId);
                }}
                src={portraitMedia[1].baseUrl + portraitMedia[1].path}
                style={{ height: "100%", width: "100%" }}
              />
            </StyledRowGrid>
            <StyledRowGrid
              style={{
                height: "50%",
                width: "calc(25% - 2px)",
                marginRight: "2px"
              }}
              xs={3}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[2].mediaId);
                }}
                src={portraitMedia[2].baseUrl + portraitMedia[2].path}
                style={{ height: "100%", width: "100%" }}
              />
            </StyledRowGrid>
            <StyledRowGrid style={{ height: "50%", width: "25%" }} xs={3}>
              <FeedIndividualMedia
                remainingMediaCount={remainingMedia}
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[3].mediaId);
                }}
                src={portraitMedia[3].baseUrl + portraitMedia[3].path}
                style={{ height: "100%", width: "100%" }}
              />
            </StyledRowGrid>
          </>
        );
      }
    }
    if (props.media[0].mimeType == "application/pdf") {
      return (
        <FeedIndividualMedia
          mimeType={"application/pdf"}
          metaData={props.media[0].metadata}
          src={props.media[0].baseUrl + props.media[0].path}
          style={{ width: "100%", height: "100%" }}
        />
      );
    }
  };
  return (
    <>
      {mediaViewer && (
        <FeedMediaViewer
          close={closeMediaViewer}
          mediaFiles={media}
          currentIndex={currentIndex}
        />
      )}

      <Container flexDirection={"column"}>
        <Box
          sx={{
            position: "relative",
            top: "35px",
            left: "12px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#000000BF",
            width: "45px",
            height: "24px",
            borderRadius: "16px",
            padding: "4px 8px"
          }}
        >
          <Avatar
            variant="square"
            style={{ width: "16px", height: "16px" }}
            src={imageThumbnail}
          />
          <Typography
            data-testid="media-count"
            style={{
              fontWeight: "400",
              fontSize: "12px",
              color: "#fff",
              marginLeft: "5px"
            }}
          >
            {props.media.length}
          </Typography>
        </Box>

        <Grid container width={"100%"} height={"505px"}>
          {renderMedia()}
        </Grid>
      </Container>
    </>
  );
};
