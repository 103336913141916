import { Loader } from "@components/crud/Loader";
import { Grid, Typography } from "@mui/material";
import { useAdminSettingsPut } from "@sportsgravyengineering/sg-api-react-sdk";
import { organizationAtom } from "@recoil/auth";
import { useAdminSettingsGet } from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { enqueueSnackbar } from "notistack";
import { FormSelect } from "@components/FormSelect";

export const TeamsProgramsSettings = () => {
  const form = useForm({
    mode: "onBlur"
  });
  const [organizationId] = useRecoilState(organizationAtom);
  const { data: settings, isLoading: isLoading } = useAdminSettingsGet({
    organizationId: organizationId!,
    parentId: "org-teams-programs"
  });
  const { reset, control, getValues } = form;
  useEffect(() => {
    if (settings) {
      const settingsMap = settings.data.map((setting) => {
        return {
          settingId: setting.settingId,
          value:
            setting.organizationSettings && setting.organizationSettings.length
              ? setting.organizationSettings[0].value
              : setting.default
        };
      });
      const defaultValues = {
        selectedSettings: settingsMap
      };
      reset(defaultValues, {
        keepDefaultValues: false
      });
    }
  }, [settings]);
  const { mutate: save, isLoading: isSaving } = useAdminSettingsPut();
  const onSave = () => {
    const data = getValues().selectedSettings;
    save(
      {
        data: data,
        params: {
          organizationId: organizationId!
        }
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Saved Successfully!", {
            variant: "success"
          });
        },
        onError: () => {
          enqueueSnackbar("Failed to save !", {
            variant: "error"
          });
        }
      }
    );
  };
  return (
    <Grid item container direction="column" spacing="15px">
      <Loader isLoading={isSaving || isLoading}>
        {settings &&
          settings.data.map((setting, index) => {
            if (setting.type === "SINGLE_SELECT") {
              return (
                <Grid
                  item
                  container
                  direction="column"
                  spacing="10px"
                  key={setting.settingId}
                >
                  <Grid item>
                    <Typography
                      variant="permissionNames"
                      sx={{
                        color: "#00000",
                        fontSize: "12px",
                        font: "inter",
                        lineHeight: "14.52px",
                        letterSpacing: "10%",
                        fontWeight: 400
                      }}
                    >
                      {setting.name!.includes("teams")
                        ? "TEAMS"
                        : "TRAINING PROGRAMS"}
                    </Typography>
                  </Grid>
                  <Grid item container direction="row">
                    <Grid item xs={6}>
                      <FormSelect
                        control={control}
                        onChange={() => {
                          onSave();
                        }}
                        name={`selectedSettings[${index}].value`}
                        options={
                          setting.options as { label: string; value: string }[]
                        }
                        label={setting.description}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            }
          })}
      </Loader>
    </Grid>
  );
};
