import { GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import React from "react";
import { RenderTableView } from "@components/RenderTableView";
export const UserTrainingProgramsView = ({
  trainingPrograms
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trainingPrograms: any;
}) => {
  const navigate = useNavigate();
  const TRAINING_PROGRAM_COLUMNS: GridColDef[] = [
    {
      headerName: "Type",
      field: "type",
      minWidth: 100,
      flex: 1,
      cellClassName: "super-app-theme--cell",
      valueGetter: ({ row }) => row.role.trainingProgram.type
    },
    {
      headerName: "Name",
      field: "name",
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row }) => row.role.trainingProgram.name
    },
    {
      headerName: "Roles",
      field: "roles",
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row }) => row.role.alias
    },
    {
      headerName: "Sub Roles",
      field: "subRoles",
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row }) => (row.role.parentId ? row.role.name : "")
    },
    {
      headerName: "Title",
      field: "title",
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row }) =>
        row.role.metadata ? row.role.metadata["title"] || "" : ""
    },
    {
      headerName: "Sport",
      field: "sport",
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row }) => row.role.trainingProgram.sport.name
    }
  ];

  const onView = (trainingProgram) => {
    console.log(trainingProgram);
    navigate("/users/");
  };
  return (
    <>
      <RenderTableView
        sx={{
          "& .disabled-row--BANNED": {
            color: "#ABAFB5",
            fontWeight: "400"
          }
        }}
        title="Training Programs"
        rows={trainingPrograms}
        columns={TRAINING_PROGRAM_COLUMNS}
        onView={onView}
        hideFilter
        getRowId={(row) => row?.trainingProgramId}
        searchable={false}
        //getRowClassName={(params) => params.row.status === 'BANNED' ? `disabled-row--${params.row.status}` : ``}
      />
    </>
  );
};
