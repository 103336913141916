/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  FormLabel,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled
} from "@mui/material";
import React from "react";
import { Control, Controller } from "react-hook-form";
import { RRule } from "rrule";

const DAYS = [
  {
    key: "sunday",
    label: "S",
    value: RRule.SU
  },
  {
    key: "monday",
    label: "M",
    value: RRule.MO
  },
  {
    key: "tuesday",
    label: "T",
    value: RRule.TU
  },
  {
    key: "wednesday",
    label: "W",
    value: RRule.WE
  },
  {
    key: "thursday",
    label: "T",
    value: RRule.TH
  },
  {
    key: "friday",
    label: "F",
    value: RRule.FR
  },
  {
    key: "saturday",
    label: "S",
    value: RRule.SA
  }
];

const StyledToggleButton = styled(ToggleButton)({
  backgroundColor: "#E2E8F0",
  color: "#64748B",
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "#4F46E5"
  }
});
const FormInputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;
const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

export const FormToggleDays = (props: {
  name: string;
  control: Control<any, any>;
  rules?: any | undefined;
  label: string;
  required?: boolean | undefined;
  onChange?: (value) => void;
  disabled?: boolean;
  disableSelected?: boolean;
  disableFuture?: boolean;
  defaultValue?: any;
}) => {
  return (
    <>
      <Controller
        name={props.name}
        control={props.control}
        rules={props.rules}
        render={({ field }) => {
          return (
            <FormInputContainer data-testid="TOGGLE_DAYS">
              <StyledFormLabel required={props.required}>
                <Typography display="inline" variant="formLabel">
                  {props.label}
                </Typography>
              </StyledFormLabel>
              <ToggleButtonGroup
                arial-label="Days of the week"
                value={field.value}
                onChange={(event, value) => {
                  props.onChange!(value);
                }}
              >
                {DAYS.map((day) => (
                  <StyledToggleButton
                    key={day.key}
                    disabled={
                      props.disabled ||
                      (props.disableSelected &&
                        field.value.find(
                          (weekday) => weekday?.weekday === day.value.weekday
                        ))
                    }
                    value={day.value}
                    aria-label={day.key}
                    style={{
                      borderRadius: "50%",
                      height: 32,
                      textTransform: "unset",
                      marginRight: "10px"
                    }}
                  >
                    {day.label}
                  </StyledToggleButton>
                ))}
              </ToggleButtonGroup>
            </FormInputContainer>
          );
        }}
      />
    </>
  );
};
