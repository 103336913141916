import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormInput } from "@components/FormInput";
import { LoadingSpinner } from "@components/LoadingSpinner";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import MediaContianerWithDelete from "@components/MediaContaierWithDelete";
import {
  Box,
  Typography,
  styled,
  FormLabel,
  Button,
  IconButton,
  FormHelperText
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  useLookupCountryGet,
  useSportPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import SportsPickerImage from "@assets/images/iconPicker.png";
import SportsPortraitPickerImage from "@assets/images/portraitImagePicker.png";
import SportsLandscapePickerImage from "@assets/images/landscapeImagePicker.png";
import { getBase64 } from "@utils/imageCompression";
import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { Add, DeleteOutline } from "@mui/icons-material";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { SearchInput } from "@components/SearchInput";
import CountryList from "./CountryList";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

const FormInputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const LoadingContainer = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
`;

const StyledLabel = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #000000;
  opacity: 0.7;
  text-transform: uppercase;
`;

export const SportCreate = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [portraitImage, setPortraitImage] = useState<File | null>(null);
  const [landscapeImage, setLandscapeImage] = useState<File | null>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const inputPortraitFileRef = useRef<HTMLInputElement>(null);
  const inputLandscapeFileRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [iconFieldTouched, setIconFieldTouched] = useState(false);
  const [portraitFieldTouched, setPortraitFieldTouched] = useState(false);
  const [landscapeFieldTouched, setLandscapeFieldTouched] = useState(false);
  const [search, setSearch] = useState("");

  const { data: countries, isLoading: isCountriesLoading } =
    useLookupCountryGet();
  const countryOptions = useMemo(() => {
    if (!countries?.data) return [];
    return countries.data.reduce((acc, country) => {
      acc[country.countryId!] = {
        countryId: country.countryId,
        countryName: country.name as string,
        selected: false,
        name: "",
        contestType: "GAME"
      };
      return acc;
    }, {});
  }, [countries]);

  const {
    handleSubmit,
    control,
    formState: { isValid },
    reset,
    trigger
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: "",
      countries: countryOptions,
      icon: "",
      portraitImage: "",
      landscapeImage: ""
    }
  });

  useEffect(() => {
    if (countryOptions) {
      reset({ countries: countryOptions }), [countryOptions];
    }
  }, [countryOptions]);

  const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  };

  const debouncedSearch = useDebounce(search, 100);

  const renderCountryList = useMemo(() => {
    if (!countryOptions) return null;
    return (
      <CountryList
        countryList={countryOptions}
        control={control}
        search={debouncedSearch}
        disable={false}
      />
    );
  }, [countryOptions, debouncedSearch]);

  const { mutate: save, isLoading: isSaving } = useSportPost();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    async (formValues) => {
      const values = {
        name: formValues.name,
        isIntervalBased: sportHasIntervals,
        intervalSingular: formValues.intervalSingular,
        intervalPlural: formValues.intervalPlural,
        intervalAbbreviation: formValues.intervalAbbreviation,
        scoringOptions: allowScoring
          ? scoringOptions.map((opt) => {
              return {
                name: opt.name,
                value: opt.value.split(",").map(Number)
              };
            })
          : [],
        countries: Object.keys(formValues.countries)
          .filter((key) => !!formValues.countries[key].selected)
          .map((key) => {
            const country = formValues.countries[key];
            return {
              countryId: country.countryId,
              name: country.name,
              isMatch: country.contestType === "MATCH"
            };
          })
      };
      try {
        const response = await getBase64(file!);
        values["icon"] = response;
        setLoading(true);
        const filesPromises = await Promise.all(
          [landscapeImage, portraitImage].map((file) => {
            if (file instanceof File) {
              const promise = uploadMediaUsingPresignedUrl(file);
              return promise;
            }
          })
        );
        values["portraitImageId"] = filesPromises[1];
        values["landscapeImageId"] = filesPromises[0];
        save(
          {
            data: values
          },
          {
            onSuccess: () => {
              setLoading(false);
              enqueueSnackbar("Sport added successfully!", {
                variant: "success"
              });
              if (resetInsteadOfRoute) {
                reset();
                setFile(null);
                if (inputFileRef.current) {
                  inputFileRef.current.value = "";
                }
              } else {
                navigate("/sports");
              }
            },
            onError: () => {
              setLoading(false);
              enqueueSnackbar("Failed to add sport!", { variant: "error" });
            }
          }
        );
      } catch (error) {
        enqueueSnackbar("Failed to generate image data!", {
          variant: "error"
        });
      }
    };

  const onImageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field,
    type
  ) => {
    const tempFiles = (event.target as HTMLInputElement).files;
    field.onChange(event);
    if (type === "portrait" && tempFiles && tempFiles[0]) {
      setPortraitImage(tempFiles[0]);
    }
    if (type === "landscape" && tempFiles && tempFiles[0]) {
      setLandscapeImage(tempFiles[0]);
    }
    if (type === "icon") {
      if (tempFiles && tempFiles[0]) setFile(tempFiles[0]);
    }
  };

  const [sportHasIntervals, setSportHasIntervals] = useState(false);
  const [allowScoring, setAllowScoring] = useState(false);
  const [allowMultipleScoring, setAllowMultipleScoring] = useState(false);
  const [scoringOptions, setScoringOptions] = useState<
    { name?: string; value: string }[]
  >([
    {
      value: ""
    }
  ]);
  return (
    <Container>
      <Toolbar title="Add Sport" />
      <Form>
        {isCountriesLoading ? (
          <LoadingContainer>
            <LoadingSpinner />
          </LoadingContainer>
        ) : (
          <Grid data-testid="sport-add-form" container spacing={3}>
            <Grid data-testid="sport-icon" xs={12} md={12}>
              <Controller
                name="icon"
                control={control}
                rules={{
                  required: "Icon is required"
                }}
                render={({ field }) => (
                  <FormInputContainer>
                    <StyledFormLabel required={true}>
                      <Typography display="inline" variant="formLabel">
                        {"Sports Icon"}
                      </Typography>
                    </StyledFormLabel>
                    <Grid container spacing={3} xs={12} md={12}>
                      <Grid xs={12} md={2}>
                        {file ? (
                          <MediaContianerWithDelete
                            onConfirmDelete={() => {
                              setFile(null);
                              inputFileRef.current.value = "";
                            }}
                            index={0}
                          >
                            <img
                              src={URL.createObjectURL(file)}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain"
                              }}
                            />
                          </MediaContianerWithDelete>
                        ) : (
                          <img
                            src={SportsPickerImage}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain"
                            }}
                            onClick={() => {
                              setIconFieldTouched(true);
                              inputFileRef.current?.click();
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <input
                      type="file"
                      onChange={(e) => onImageChange(e, field, "icon")}
                      ref={inputFileRef}
                      style={{ display: "none" }}
                      accept="image/*"
                    />
                    {iconFieldTouched && !file && (
                      <FormHelperText error>Icon is required</FormHelperText>
                    )}
                  </FormInputContainer>
                )}
              />
            </Grid>
            <Grid data-testid="sport-name" xs={6}>
              <FormInput
                control={control}
                name="name"
                type="text"
                label="Name"
                required={true}
                rules={{
                  required: "Name is required"
                }}
              />
            </Grid>
            <Grid data-testid="sport-portraitImage" xs={12} md={12}>
              <Controller
                name="portraitImage"
                control={control}
                rules={{
                  required: "Portrait Image is required"
                }}
                render={({ field }) => (
                  <FormInputContainer>
                    <StyledFormLabel required={true}>
                      <Typography display="inline" variant="formLabel">
                        {"Default Portrait Image"}
                      </Typography>
                    </StyledFormLabel>
                    <Grid container spacing={3} xs={12} md={12}>
                      <Grid xs={12} md={2}>
                        {portraitImage ? (
                          <MediaContianerWithDelete
                            onConfirmDelete={() => {
                              setPortraitImage(null);
                              inputPortraitFileRef.current.value = "";
                            }}
                            index={0}
                          >
                            <img
                              src={URL.createObjectURL(portraitImage)}
                              style={{
                                width: "100%",
                                height: "290px"
                              }}
                            />
                          </MediaContianerWithDelete>
                        ) : (
                          <img
                            src={SportsPortraitPickerImage}
                            style={{
                              width: "100%",
                              height: "290px"
                            }}
                            onClick={() => {
                              setPortraitFieldTouched(true);
                              inputPortraitFileRef.current?.click();
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <input
                      type="file"
                      onChange={(e) => onImageChange(e, field, "portrait")}
                      ref={inputPortraitFileRef}
                      style={{ display: "none" }}
                      accept="image/*"
                    />
                    {portraitFieldTouched && !portraitImage && (
                      <FormHelperText error>
                        Default Portrait Image is required
                      </FormHelperText>
                    )}
                  </FormInputContainer>
                )}
              />
            </Grid>
            <Grid data-testid="sport-landscapeImage" xs={12} md={12}>
              <Controller
                name="landscapeImage"
                control={control}
                rules={{
                  required: "Portrait Image is required"
                }}
                render={({ field }) => (
                  <FormInputContainer>
                    <StyledFormLabel required={true}>
                      <Typography display="inline" variant="formLabel">
                        {"Default Landscape Image"}
                      </Typography>
                    </StyledFormLabel>
                    <Grid container spacing={3} xs={12} md={12}>
                      <Grid xs={12} md={2}>
                        {landscapeImage ? (
                          <MediaContianerWithDelete
                            onConfirmDelete={() => {
                              setLandscapeImage(null);
                              inputLandscapeFileRef.current.value = "";
                            }}
                            index={0}
                          >
                            <img
                              src={URL.createObjectURL(landscapeImage)}
                              style={{
                                width: "290px",
                                height: "163px"
                              }}
                            />
                          </MediaContianerWithDelete>
                        ) : (
                          <img
                            src={SportsLandscapePickerImage}
                            style={{
                              width: "290px",
                              height: "163px"
                            }}
                            onClick={() => {
                              setLandscapeFieldTouched(true);
                              inputLandscapeFileRef.current?.click();
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <input
                      type="file"
                      onChange={(e) => onImageChange(e, field, "landscape")}
                      ref={inputLandscapeFileRef}
                      style={{ display: "none" }}
                      accept="image/*"
                    />
                    {landscapeFieldTouched && !landscapeImage && (
                      <FormHelperText error>
                        Default Landscape Image is required
                      </FormHelperText>
                    )}
                  </FormInputContainer>
                )}
              />
            </Grid>
            <Grid>
              <FormCheckbox
                control={control}
                name="isIntervalBased"
                label="Sport has intervals"
                onChange={() => {
                  setSportHasIntervals(!sportHasIntervals);
                }}
              />
            </Grid>
            {sportHasIntervals && (
              <Grid container direction="row" spacing="25px" xs={12} md={12}>
                <Grid xs={4}>
                  <FormInput
                    control={control}
                    name="intervalSingular"
                    type="text"
                    label="Singular Interval"
                    rules={{
                      required: "Singular Interval is Required"
                    }}
                    required={true}
                  />
                </Grid>
                <Grid xs={4}>
                  <FormInput
                    control={control}
                    name="intervalPlural"
                    type="text"
                    label="Plural Interval"
                    rules={{
                      required: "Plural Interval is Required"
                    }}
                    required={true}
                  />
                </Grid>
                <Grid xs={4}>
                  <FormInput
                    control={control}
                    name="intervalAbbreviation"
                    type="text"
                    label="Interval Abbreviation"
                    rules={{
                      required: "Interval Abbreviation is Required"
                    }}
                    required={true}
                  />
                </Grid>
              </Grid>
            )}
            {sportHasIntervals && (
              <Grid container direction="row" spacing="30px" xs={12}>
                <Grid>
                  <FormCheckbox
                    control={control}
                    name="isScoringAllowed"
                    label="Allow Scoring"
                    onChange={() => {
                      setAllowScoring(!allowScoring);
                    }}
                  />
                </Grid>
                {allowScoring && (
                  <Grid container direction="row" spacing="30px">
                    <Grid>
                      <FormCheckbox
                        control={control}
                        name="isMultipleScoringAllowed"
                        label="Allow Multiple Scoring Options"
                        onChange={() => {
                          setAllowMultipleScoring(!allowMultipleScoring);
                        }}
                      />
                    </Grid>
                    {allowMultipleScoring && (
                      <Grid alignSelf="center">
                        <Button
                          variant="text"
                          style={{
                            color: "#007AFF",
                            textTransform: "none"
                          }}
                          onClick={() => {
                            const oldOptions = scoringOptions;
                            oldOptions.push({
                              name: "",
                              value: ""
                            });
                            oldOptions.map((s, i) => {
                              trigger(`scoringOptions[${i}].name`);
                              trigger(`scoringOptions[${i}].value`);
                            });
                            setScoringOptions([...oldOptions]);
                          }}
                          startIcon={<Add />}
                        >
                          Add Scoring Option
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            )}
            {allowScoring &&
              scoringOptions.map((option, idx) => (
                <Grid
                  container
                  direction="row"
                  spacing="25px"
                  xs={12}
                  md={12}
                  key={idx}
                  data-testid="scoring-options-container"
                >
                  {allowMultipleScoring && (
                    <Grid xs={scoringOptions.length > 1 ? 5.5 : 6}>
                      <FormInput
                        control={control}
                        name={`scoringOptions[${idx}].name`}
                        enteredValue={scoringOptions[idx].name}
                        onChange={(e) => {
                          const scoringOp = scoringOptions;
                          scoringOp[idx]["name"] = e.target.value;
                          setScoringOptions([...scoringOp]);
                        }}
                        type="text"
                        label="Scoring Option"
                        rules={{
                          required: "Scoring Option is Required"
                        }}
                        required={true}
                      />
                    </Grid>
                  )}
                  <Grid xs={scoringOptions.length > 1 ? 5.5 : 6}>
                    <FormInput
                      control={control}
                      name={`scoringOptions[${idx}].value`}
                      enteredValue={scoringOptions[idx].value}
                      type="text"
                      label="Scoring Values"
                      onChange={(e) => {
                        const scoringOp = scoringOptions;
                        scoringOp[idx].value = e.target.value;
                        setScoringOptions([...scoringOp]);
                      }}
                      rules={{
                        required: "Scoring Values is Required",
                        pattern: {
                          value: /^(\d+,)*\d*$/,
                          message:
                            "Only numbers separated by commas are allowed"
                        }
                      }}
                      required={true}
                    />
                    <Grid marginLeft="10px">
                      <Typography
                        variant="permissionNames"
                        style={{
                          color: "#B3B3B3"
                        }}
                      >
                        Options separated by comma
                      </Typography>
                    </Grid>
                  </Grid>
                  {scoringOptions.length > 1 && (
                    <Grid xs={1} alignSelf="center">
                      <IconButton
                        onClick={() => {
                          scoringOptions.map((s, i) => {
                            trigger(`scoringOptions[${i}].name`);
                            trigger(`scoringOptions[${i}].value`);
                          });
                          const scOptions = scoringOptions;
                          scOptions.splice(idx, 1);
                          setScoringOptions([...scOptions]);
                        }}
                      >
                        <DeleteOutline color="error" fontSize="large" />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              ))}
            <Grid xs={12}>
              <StyledLabel>Participating Countries</StyledLabel>
              <HeaderUnderLine width="100%" />
              <Box sx={{ marginTop: "8px" }}>
                <SearchInput
                  placeholder="Search"
                  required={false}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Box>
            </Grid>
            {renderCountryList}
          </Grid>
        )}
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler(false))}
        saveAndNewBtnClick={handleSubmit(saveHandler(true))}
        isDisabled={
          !isValid ||
          isSaving ||
          loading ||
          !portraitImage ||
          !landscapeImage ||
          !file
        }
        isLoading={isSaving || loading}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/sports")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
