import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { organizationAtom } from "@recoil/auth";
import {
  useAdminLevelLevelIdGet,
  useAdminLevelLevelIdPut,
  useAdminSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalizeEveryWord } from "@utils/capitalize";
import { GENDERS } from "@utils/constants";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

export const LevelEdit = () => {
  const navigate = useNavigate();
  const { levelId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const organizationId = useRecoilValue(organizationAtom);
  const {
    data: level,
    isFetching: isLevelFetching,
    error: error
  } = useAdminLevelLevelIdGet(levelId as string);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error]);

  const { data: sports, isLoading: isSportLoading } = useAdminSportGet({
    organizationId: organizationId!
  });

  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId!
      })) || [],
    [sports]
  );
  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid, isDirty },
    reset
  } = useForm({
    mode: "onBlur"
  });

  useEffect(
    () =>
      reset(
        {
          name: level?.data?.name,
          abbv: level?.data?.abbv,
          description: level?.data?.description,
          sportId: level?.data?.sports?.map((sport) => sport.sportId),
          genders: level?.data?.genders
        },
        {
          keepDirtyValues: true
        }
      ),
    [level, sportOptions]
  );

  const { mutate: save, isLoading: isSaving } = useAdminLevelLevelIdPut();
  const saveHandler = (formValues) => {
    const values = {
      ...formValues
    };
    if (values.sportId) {
      values.sports = values.sportId.map((sportId) => ({
        sportId
      }));
    }

    save(
      {
        levelId: levelId as string,
        data: values
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Level updated successfully!", {
            variant: "success"
          });
          navigate(`/levels`);
        },
        onError: () => {
          enqueueSnackbar("Failed to update level!", { variant: "error" });
        }
      }
    );
  };

  return (
    <Container>
      <Toolbar title="Edit Level" />
      <Form>
        <Loader isLoading={isLevelFetching || isSportLoading}>
          <Grid data-testid="level-edit-form" container spacing={3}>
            <Grid xs={12} md={6} data-testid="level-name">
              <FormInput
                control={control}
                name="name"
                type="text"
                label="Name"
                required={true}
                onChange={(e) => {
                  setValue("name", capitalizeEveryWord(e.target.value));
                }}
                rules={{
                  required: "Name is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="level-abbv">
              <FormInput
                control={control}
                name="abbv"
                type="text"
                label="Abbreviation"
                required={true}
                rules={{
                  required: "Abbreviation is required",
                  maxLength: {
                    value: 6,
                    message: "Abbreviation must be 6 characters or less"
                  }
                }}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="level-description">
              <FormInput
                control={control}
                name="description"
                type="text"
                label="Description"
                required={true}
                multiline={true}
                rules={{
                  required: "Description is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="level-sport-input">
              <FormMultiSelect
                control={control}
                name="sportId"
                label="Sport"
                value="sportId"
                required={true}
                options={sportOptions}
                isLoading={isSportLoading}
                disabled={sportOptions.length === 1}
                rules={{
                  required: "Sport is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="level-gender-input">
              <FormMultiSelect
                control={control}
                name="genders"
                label="Gender"
                value="gender"
                required={true}
                options={GENDERS}
                rules={{
                  required: "Gender is required"
                }}
              />
            </Grid>
          </Grid>
        </Loader>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler)}
        isDisabled={!isValid || isSaving || !isDirty}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/levels")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
