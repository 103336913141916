import { TableView } from "@components/TableView";
import { getJobTitles } from "@services/Network";

import React from "react";
import { useNavigate } from "react-router-dom";

const LIST_COLUMNS = [
  {
    headerName: "Name",
    field: "name",
    minWidth: 150,
    flex: 1,
    sortable: false
  },
  {
    headerName: "Department",
    field: "department",
    minWidth: 150,
    flex: 1,
    sortable: true,
    valueGetter: ({ row }) => row.department.name
  }
];

export const JobTitles = () => {
  const navigate = useNavigate();

  const [refreshKey] = React.useState(0);

  const onAdd = () => navigate("/job-titles/create");
  const onEdit = (row) => navigate(`/job-titles/${row.jobtitleId}/edit`);
  const onView = (row) => navigate(`/job-titles/${row.jobtitleId}`);

  return (
    <>
      <TableView
        title="Job Titles"
        useGet={getJobTitles}
        columns={LIST_COLUMNS}
        hideFilter={true}
        hideLabelContainer={true}
        searchable={false}
        getRowId={(row) => row.jobtitleId}
        onAdd={onAdd}
        onEdit={onEdit}
        onView={onView}
        isDeleteDisabled={() => true}
        refreshKey={refreshKey}
      />
    </>
  );
};
