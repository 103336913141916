/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, styled, Divider, Typography } from "@mui/material";
import { Loader } from "@components/crud/Loader";
import { Column, DataGridTable } from "@components/DataGridTable";
import { useNavigate } from "react-router-dom";
import { TimeFilter } from "@pages/dashboard/components/TimeFilter";
import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  adminMetricPneSprintOverviewGet,
  ModelSprintOverviewUserData
} from "@sportsgravyengineering/sg-api-react-sdk";
import NoDataIcon from "@assets/icons/no-data-icon.svg";

const StyledBoxValue = styled(Typography)`
  color: #1e293b;
  font-weight: 600;
  font-size: 2vw;
  line-height: 48px;

  @media (min-width: 1300px) {
    font-size: 32px;
  }
`;

const StyledTotalValue = styled(Typography)`
  font-weight: 700;
  font-size: 14px;
`;

const dateFormat = (iosDate: string): string => {
  const date = new Date(iosDate);
  const month = date.toLocaleString("default", { month: "short" });
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);
  return `${month} ${day}, ${year}'`;
};

export const SprintCardOverview = () => {
  const navigate = useNavigate();
  const [selectedTimeRange, setSelectedTimeRange] = useState<[any, any]>([
    null,
    null
  ]);
  const [timeFilter, setTimeFilter] = useState<
    "TODAY" | "YESTERDAY" | "CURRENT" | "PREVIOUS"
  >("TODAY");
  const [refreshKey, setRefreshKey] = useState(0);
  const handleCellClick = (resourceId: string) => {
    navigate(`?resourceId=${resourceId}`);
  };

  const query = useMemo(() => {
    const buildQuery = {} as {
      timeFilter: "TODAY" | "YESTERDAY" | "CURRENT" | "PREVIOUS";
    };

    buildQuery.timeFilter = timeFilter;

    return buildQuery;
  }, [timeFilter]);

  const { data: sco, isLoading: isLoading } = useQuery(
    ["adminMetricPneFeatureCostOverview", timeFilter],
    () => adminMetricPneSprintOverviewGet(query),
    {
      staleTime: 1000 * 60 * 10 * 60,
      cacheTime: 1000 * 60 * 10 * 60,
      refetchOnWindowFocus: true
    }
  );
  const COLUMNS: Column[] = [
    {
      field: "resource",
      headerName: "Resource",
      width: 300,
      sortable: false,
      align: "left",
      onClick: (params) => handleCellClick(params?.user?.jiraAccountId),
      renderCell: (params) => {
        if (!params.user) return <Typography>-</Typography>;
        return (
          <Typography
            style={{
              fontWeight: 600,
              fontSize: "14px",
              color: "#007AFF",
              cursor: "pointer"
            }}
          >
            {params?.user?.person?.firstName} {params?.user?.person?.lastName}
          </Typography>
        );
      }
    },
    {
      field: "readyCommited",
      headerName: "Ready / Commited",
      width: 100,
      sortable: false,
      borderLeft: "1px solid #E5E5E5",
      renderCell: (params) => {
        return <Typography>{params.ready}</Typography>;
      }
    },
    {
      headerName: "Today’s Plan",
      field: "todayPlan",
      width: 100,
      sortable: false,
      borderLeft: "1px solid #E5E5E5",
      renderCell: (params) => {
        return <Typography>{params.today}</Typography>;
      }
    },
    {
      headerName: "In Progress",
      field: "inProgress",
      width: 100,
      sortable: false,
      borderLeft: "1px solid #E5E5E5",
      renderCell: (params) => {
        return <Typography>{params.inProgress}</Typography>;
      }
    },
    {
      headerName: "Review",
      field: "review",
      width: 100,
      sortable: false,
      borderLeft: "1px solid #E5E5E5",
      renderCell: (params) => {
        return <Typography>{params.review}</Typography>;
      }
    },
    {
      headerName: "QA",
      field: "qa",
      width: 100,
      sortable: false,
      borderLeft: "1px solid #E5E5E5",
      renderCell: (params) => {
        return <Typography>{params.qa}</Typography>;
      }
    },
    {
      headerName: "Done",
      field: "done",
      width: 100,
      sortable: false,
      borderLeft: "1px solid #E5E5E5",
      renderCell: (params) => {
        return <Typography>{params.done}</Typography>;
      }
    }
  ];

  const TOTAL_VALUES = [
    {
      field: "readyCommited",
      renderCell: (rows: any) => {
        return (
          <StyledTotalValue>
            {rows.reduce((acc: number, row: any) => acc + row.ready, 0)}
          </StyledTotalValue>
        );
      }
    },
    {
      field: "todayPlan",
      renderCell: (rows: any) => {
        return (
          <StyledTotalValue>
            {rows.reduce((acc: number, row: any) => acc + row.today, 0)}
          </StyledTotalValue>
        );
      }
    },
    {
      field: "inProgress",
      renderCell: (rows: any) => {
        return (
          <StyledTotalValue>
            {rows.reduce((acc: number, row: any) => acc + row.inProgress, 0)}
          </StyledTotalValue>
        );
      }
    },
    {
      field: "review",
      renderCell: (rows: any) => {
        return (
          <StyledTotalValue>
            {rows.reduce((acc: number, row: any) => acc + row.review, 0)}
          </StyledTotalValue>
        );
      }
    },
    {
      field: "qa",
      renderCell: (rows: any) => {
        return (
          <StyledTotalValue>
            {rows.reduce((acc: number, row: any) => acc + row.qa, 0)}
          </StyledTotalValue>
        );
      }
    },
    {
      field: "done",
      renderCell: (rows: any) => {
        return (
          <StyledTotalValue>
            {rows.reduce((acc: number, row: any) => acc + row.done, 0)}
          </StyledTotalValue>
        );
      }
    }
  ];

  return (
    <Grid
      container
      direction="column"
      padding="0px"
      style={{
        border: "1px solid rgba(0, 0, 0, 0.23)",
        boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.08)",
        borderRadius: "10px",
        marginTop: "1px",
        width: "calc(100vw - 340px)",
        minHeight: isLoading || !sco || !sco?.data ? "400px" : "0px"
      }}
    >
      <Grid
        item
        padding="16px 24px 16px 24px"
        container
        direction="row"
        width="100%"
        justifyContent="space-between"
      >
        <Grid item container direction="column" spacing="2px" xs={6}>
          <Grid item>
            <Typography
              style={{ color: "#1E293B", fontWeight: 700, fontSize: "16px" }}
            >
              Sprint Card Overview
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              style={{
                color: "#64748B",
                fontWeight: "500",
                fontSize: "14px"
              }}
            >
              Quick Insights related to SportGravy
            </Typography>
          </Grid>
        </Grid>
        <Grid item alignSelf="center">
          <TimeFilter
            selectedTimeRange={selectedTimeRange}
            setRefreshKey={setRefreshKey}
            refreshKey={refreshKey}
            setSelectedTimeRange={setSelectedTimeRange}
            isQaMetric={false}
            defaultTimeFilter="TODAY"
            setSelectedTimeFilter={setTimeFilter}
            customTimeFilterOptions={[
              { label: "Today", value: "TODAY" },
              { label: "Yesterday", value: "YESTERDAY" },
              { label: "This Sprint", value: "CURRENT" },
              { label: "Last Sprint", value: "PREVIOUS" }
            ]}
          />
        </Grid>
      </Grid>

      <Grid item marginTop="-5px">
        <Divider />
      </Grid>
      <Loader isLoading={isLoading}>
        {(!sco || !sco?.data) && !isLoading ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "auto"
              }}
            >
              <img src={NoDataIcon} style={{ width: "64px" }} />
              <Typography
                style={{
                  color: "#64748b",
                  fontSize: "14px",
                  fontWeight: 500,
                  padding: "16px 24px"
                }}
              >
                No data available
              </Typography>
            </div>
          </>
        ) : (
          <Grid container direction="row" item>
            {sco &&
              sco.data.insights &&
              (
                sco.data.insights as Array<{
                  title: string;
                  value: string;
                  type?: string;
                  isCompleted?: boolean;
                }>
              ).map((con, index) => {
                return (
                  <Grid
                    item
                    container
                    direction="column"
                    key={con.title}
                    xs={index < 4 ? 3 : 2}
                    minHeight="121px"
                    padding="24px"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      border: "1px solid rgba(229, 229, 229, 1)"
                    }}
                  >
                    <Grid item>
                      <Typography
                        style={{
                          color: "#64748B",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "21px"
                        }}
                      >
                        {con.title}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {(con.type === "text" || !con.type) && (
                        <StyledBoxValue
                          color={
                            con.isCompleted !== undefined
                              ? con.isCompleted
                                ? "#000"
                                : "#E82C2C !important"
                              : "#000"
                          }
                        >
                          {con.value}
                        </StyledBoxValue>
                      )}
                      {con.type === "date" && (
                        <StyledBoxValue>{dateFormat(con.value)}</StyledBoxValue>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        )}
        {sco &&
        sco?.data &&
        sco?.data.userData &&
        (sco?.data.userData as Array<ModelSprintOverviewUserData>).length >
          0 ? (
          <DataGridTable
            rows={sco?.data.userData as Array<any>}
            columns={COLUMNS}
            totalValues={TOTAL_VALUES}
          />
        ) : (
          <></>
        )}
      </Loader>
    </Grid>
  );
};
