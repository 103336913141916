/* eslint-disable no-useless-escape */
import { Button } from "@components/Button";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import Sentry from "@services/Sentry";
import {
  ModelMedia,
  useAdminOrganizationOrganizationIdGet,
  useAdminOrganizationOrganizationIdPatch,
  useLookupCountryGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { OrganizationContactForm } from "./OrganizationContactForm";
import { OrganizationDetailsForm } from "./OrganizationDetailsForm";
import { OrganizationSportsForm } from "./OrganizationSportsForm";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";
import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { cleanObject } from "@utils/cleanObject";
import { DevTool } from "@hookform/devtools";
import { setRecoil } from "recoil-nexus";
export const OrganizationEdit = () => {
  const navigate = useNavigate();
  const { organizationId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const loggedInOrganizationId = useRecoilValue(organizationAtom);
  useEffect(() => {
    if (loggedInOrganizationId && organizationId !== loggedInOrganizationId) {
      navigate(`/organizations/${loggedInOrganizationId}`);
    }
  }, [loggedInOrganizationId]);
  const [tab, setTab] = useState("Organization Details");
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [files, setFiles] = useState<(File | ModelMedia)[]>([]);
  const organizations = useRecoilValue(organizationsAtom);
  // const setOrganizationsRecoil = useSetRecoilState(organizationsAtom);
  const onTabChange = (event: SyntheticEvent, value: unknown) => {
    if (!(!isValid || !isFileValid || !isContactValid)) setTab(value as string);
  };

  const {
    control,
    watch,
    getValues,
    setValue,
    trigger,
    handleSubmit,
    reset,
    formState: { isValid, isDirty }
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: "",
      abbreviation: "",
      type: "",
      offering: "",
      country: "",
      province: "",
      locality: "",
      address1: "",
      address2: "",
      postalCode: "",
      phone: "",
      email: "",
      website: "",
      versionId: "",
      providerId: "",
      isGovernedByOrg: "",
      loadDataFromSportsGravy: "",
      sportsOffered: [],
      sportsFields: [],
      allowGoverningBodyBySport: "",
      primaryContact: null,
      secondaryContact: null,
      hasPrimaryContact: true,
      hasSecondaryContact: false,
      avatarId: null
    }
  });

  const {
    providerId,
    country,
    sportsOffered,
    allowGoverningBodyBySport,
    hasSecondaryContact,
    primaryContact,
    secondaryContact,
    type,
    isGovernedByOrg
  } = watch();

  const { data: countriesResponse, isFetching: isFetchingCountries } =
    useLookupCountryGet({
      query: {
        staleTime: 1000 * 60 * 60 * 24
      }
    });
  const countries = useMemo(() => countriesResponse?.data, [countriesResponse]);

  const {
    data: organizationResponse,
    isFetching: isFetchingOrganization,
    error: error
  } = useAdminOrganizationOrganizationIdGet(organizationId as string);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error]);
  const organization = useMemo(
    () => organizationResponse?.data,
    [organizationResponse]
  );

  useEffect(() => {
    if (!countries || !organization) return;

    const sportsOffered =
      organization?.sports?.map((sport) => sport?.sportId) || [];

    const isGovernedByOrg =
      organization?.governingBodyId ||
      (organization?.sports &&
        organization.sports.some((sport) => sport.governingBodyId))
        ? "yes"
        : "no";
    const allowGoverningBodyBySport = organization?.sports?.some(
      (sport) => sport?.governingBody
    )
      ? "yes"
      : "no";

    const defaultValues = {
      abbreviation: organization?.abbreviation,
      address1: organization?.lines?.[0],
      address2: organization?.lines?.[1],
      allowGoverningBodyBySport,
      country: organization?.country,
      email: organization?.email,
      governingBodyId: organization?.governingBodyId,
      hasPrimaryContact: !!organization?.primaryContact,
      hasSecondaryContact: !!organization?.secondaryContact,
      isGovernedByOrg,
      loadDataFromSportsGravy: "",
      locality: organization?.locality,
      name: organization?.name,
      offering: organization?.offering,
      phone: organization?.phone,
      avatarId: organization?.avatarId,
      postalCode: organization?.postalCode,
      primaryContact: organization?.primaryContact,
      providerId: organization?.providerId,
      province: organization?.province,
      secondaryContact: organization?.secondaryContact,
      sportsFields: organization?.sports?.map((sport) => ({
        sportId: sport?.sportId,
        offeredFor: sport?.offeredFor,
        maleSingularInterval: sport?.maleSingularInterval,
        malePluralInterval: sport?.malePluralInterval,
        maleIntervalAbbreviation: sport?.maleIntervalAbbreviation,
        femaleSingularInterval: sport?.femaleSingularInterval,
        femalePluralInterval: sport?.femalePluralInterval,
        femaleIntervalAbbreviation: sport?.femaleIntervalAbbreviation,
        governingBodyId: sport?.governingBodyId,
        players: sport?.sport?.players || []
      })),
      sportsOffered,
      type: organization?.type,
      versionId: organization?.versionId,
      website: organization?.website
    };
    reset(defaultValues, {
      keepDirtyValues: true
    });
  }, [countries, organization]);

  const selectAddressSuggestion = (place) => {
    const addressComponents = place?.address_components || [];
    const streetNumber = addressComponents.find((c) =>
      c.types.includes("street_number")
    );
    const route = addressComponents.find((c) => c.types.includes("route"));
    const address1 = `${streetNumber?.long_name} ${route?.long_name}`;
    const subpremise = addressComponents.find((c) =>
      c.types.includes("subpremise")
    );
    const country = addressComponents.find((c) => c.types.includes("country"));
    const state = addressComponents.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    const city = addressComponents.find((c) => c.types.includes("locality"));
    const zip = addressComponents.find((c) => c.types.includes("postal_code"));

    setValue("address1", address1);
    if (subpremise) setValue("address2", subpremise?.long_name);
    setValue("country", country?.short_name);
    setValue("province", state?.short_name);
    setValue("locality", city?.long_name);
    setValue("postalCode", zip?.long_name);
    trigger("address1");
    trigger("country");
    trigger("province");
    trigger("locality");
    trigger("postalCode");
  };

  const onBackClick = () => {
    navigate("/organizations");
  };

  const onCancelClick = () => {
    setIsConfirmationDialogOpen(true);
  };

  const onConfirmCancel = () => {
    setIsConfirmationDialogOpen(false);
    if (!loggedInOrganizationId) {
      navigate("/organizations");
    }
    if (loggedInOrganizationId) {
      navigate(`/organizations/${organizationId}`);
    }
  };

  const onCancelCancel = () => {
    setIsConfirmationDialogOpen(false);
  };

  const { mutateAsync, isLoading } = useAdminOrganizationOrganizationIdPatch();

  const onSaveClick = async (formValues) => {
    try {
      const filesPromises = files?.length
        ? await Promise.all(
            //@ts-ignore
            files.map((file) => {
              if (file instanceof File) {
                const promise = uploadMediaUsingPresignedUrl(file);
                console.log("PROMISE", promise);
                return promise;
              } else {
                return file.mediaId;
              }
            })
          )
        : undefined;
      const primaryContactId = formValues?.primaryContact?.personId;
      const secondaryContactId = formValues?.secondaryContact?.personId;
      const sports = formValues.sportsFields.map((field) => {
        return cleanObject(field);
      });
      if (formValues.primaryContact.user) delete formValues.primaryContact.user;
      if (formValues.secondaryContact.user)
        delete formValues.secondaryContact.user;
      const data = {
        abbreviation: formValues.abbreviation,
        country: formValues.country,
        email: formValues.email,
        lines: [formValues.address1, formValues.address2].filter(
          (line) => !!line
        ),
        locality: formValues.locality,
        name: formValues.name,
        offering: formValues.offering,
        phone: `${formValues.phone}`,
        postalCode: formValues.postalCode,
        governingBodyId: formValues.governingBodyId,
        primaryContact: formValues.primaryContact,
        primaryContactId,
        providerId: formValues.providerId,
        province: formValues.province,
        secondaryContact: formValues.hasSecondaryContact
          ? formValues.secondaryContact
          : undefined,
        secondaryContactId: formValues.hasSecondaryContact
          ? secondaryContactId
          : undefined,
        sports: sports,
        type: formValues.type,
        versionId: formValues.versionId,
        website: formValues.website,
        avatarId: filesPromises ? filesPromises[0] : formValues.avatarId
      };
      const response = await mutateAsync({
        organizationId: organizationId as string,
        data
      });
      if (!loggedInOrganizationId) {
        navigate("/organizations");
      }
      if (loggedInOrganizationId) {
        navigate(`/organizations/${loggedInOrganizationId}`);
      }
      enqueueSnackbar("Organization saved successfully.", {
        variant: "success"
      });
      const updatedOrg = response.data;
      const updatedOrgsList = organizations.map((o) =>
        o.organizationId === organizationId ? updatedOrg : o
      );
      setRecoil(organizationsAtom, [...updatedOrgsList]);
      if (loggedInOrganizationId === organizationId)
        setRecoil(organizationAtom, organizationId);
      // setOrganizationsRecoil([...updatedOrgsList]);
    } catch (e) {
      enqueueSnackbar("Something went wrong. Please try again.", {
        variant: "error"
      });
      Sentry.captureException(e);
    }
  };

  const getPrimaryAction = () => {
    return handleSubmit(onSaveClick);
  };

  const selectPrimaryContact = (contact) => {
    setValue("primaryContact", contact, { shouldDirty: true });
  };

  const selectSecondaryContact = (contact) => {
    setValue("secondaryContact", contact, { shouldDirty: true });
  };
  const [isFileValid, setIsFileValid] = useState(true);
  const [isContactValid, setIsContactValid] = useState(true);
  return (
    <Container>
      <Toolbar
        title="Edit Organization"
        {...(!loggedInOrganizationId && { backBtnClick: onBackClick })}
        tabs={{
          tabs: ["Organization Details", "Sports Details", "Contact Details"],
          onTabChange: onTabChange,
          activeTab: tab
        }}
      />
      <Form>
        <Loader isLoading={isFetchingCountries || isFetchingOrganization}>
          <Grid container spacing={3}>
            {tab === "Organization Details" && (
              <Grid xs={12}>
                <OrganizationDetailsForm
                  disabled={false}
                  isEditing={true}
                  setValue={setValue}
                  filesValid={(val) => {
                    setIsFileValid(val);
                  }}
                  avatarId={organization?.avatarId}
                  control={control}
                  providerId={providerId}
                  country={country}
                  selectAddressSuggestion={selectAddressSuggestion}
                  countries={countries}
                  onMediaUpload={(file) => {
                    setFiles(file);
                  }}
                  isFetchingCountries={isFetchingCountries}
                  type={type}
                />
              </Grid>
            )}
            {tab === "Sports Details" && (
              <Grid xs={12}>
                <OrganizationSportsForm
                  organizationId={organizationId}
                  disabled={false}
                  control={control}
                  country={country}
                  allowGoverningBodyBySport={allowGoverningBodyBySport}
                  sportsOffered={sportsOffered}
                  isGovernedByOrg={isGovernedByOrg}
                  setValue={setValue}
                />
              </Grid>
            )}
            {tab === "Contact Details" && (
              <Grid xs={12}>
                <OrganizationContactForm
                  disabled={false}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  country={country}
                  hasSecondaryContact={hasSecondaryContact}
                  selectPrimaryContact={selectPrimaryContact}
                  primaryContact={primaryContact}
                  contactValid={(val) => {
                    setIsContactValid(val);
                  }}
                  selectSecondaryContact={selectSecondaryContact}
                  secondaryContact={secondaryContact}
                />
              </Grid>
            )}
          </Grid>
        </Loader>
        <DevTool control={control} />
      </Form>
      <Footer
        additionalBtns={[
          <Button
            key={0}
            variant="admin-secondary"
            type="button"
            onClick={onCancelClick}
            isLoading={isLoading || isFetchingCountries}
          >
            Cancel
          </Button>,
          <Button
            key={1}
            variant="admin-primary"
            type="button"
            onClick={getPrimaryAction()}
            disabled={
              !isValid ||
              !isFileValid ||
              (files.length === 0 && !isDirty) ||
              !isContactValid
            }
            isLoading={isLoading || isFetchingCountries}
          >
            Save
          </Button>
        ]}
        isDisabled={!isValid || !isFileValid}
        isLoading={isLoading || isFetchingCountries || isFetchingOrganization}
      />
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        onConfirm={onConfirmCancel}
        onCancel={onCancelCancel}
      />
    </Container>
  );
};
