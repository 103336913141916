/* eslint-disable @typescript-eslint/no-explicit-any */
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Container } from "../../components/crud/Container";
import { styled } from "@mui/material/styles";
import {
  LinearProgress,
  Typography,
  linearProgressClasses
} from "@mui/material";
import { Button } from "@components/Button";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { useEffect, useState } from "react";
import { ModelOrganizationUpload } from "@sportsgravyengineering/sg-api-react-sdk";
const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 12,
  borderRadius: 8,
  marginTop: "8px",
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    background: "linear-gradient(90deg, #1ABC9C 0%, #00987A 100%)"
  }
}));
const TableHeader = styled(Typography)(() => ({
  color: "#B3B3B3",
  fontSize: "13px",
  fontWeight: 400,
  fontFamily: "Inter"
}));
const TableValue = styled(Typography)(() => ({
  color: "#000",
  fontSize: "14px",
  fontWeight: 600,
  fontFamily: "Inter"
}));
export const ImportPlayerOverview = (props: {
  data: ModelOrganizationUpload;
}) => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const handleImportCancel = () => {
    setIsConfirmationDialogOpen(true);
  };
  const onConfirmCancel = () => {
    setIsConfirmationDialogOpen(false);
  };
  const onCancelCancel = () => {
    setIsConfirmationDialogOpen(false);
  };
  const dateFormat = (date) => {
    const options = {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true
    };
    return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
  };
  const [elapsedTime, setElapsedTime] = useState("");

  useEffect(() => {
    if (props.data.status != "COMPLETED") {
      const startTime = new Date(props.data.createdAt).getTime();
      const intervalId = setInterval(() => {
        const currentTime = new Date().getTime();
        const elapsedTime = currentTime - startTime;
        const hours = Math.floor(elapsedTime / 3600000);
        const minutes = Math.floor((elapsedTime % 3600000) / 60000);
        const seconds = Math.floor((elapsedTime % 60000) / 1000);
        setElapsedTime(
          `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
            2,
            "0"
          )}:${String(seconds).padStart(2, "0")}`
        );
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [props.data.createdAt]);
  return (
    <Container data-testid="import-player-overview">
      <Grid container columnSpacing={10}>
        <Grid>
          <TableHeader data-testid="import-player-overview-fileName-header">
            {" "}
            File Name:
          </TableHeader>
          <TableValue data-testid="import-player-overview-fileName-value">
            {props.data.fileName}
          </TableValue>
        </Grid>
        <Grid>
          <TableHeader data-testid="import-player-overview-sport-header">
            Sport:
          </TableHeader>
          <TableValue data-testid="import-player-overview-sport-value">
            {props.data.sport?.name}
          </TableValue>
        </Grid>
        {props.data.for == "TEAM" && (
          <Grid>
            <TableHeader data-testid="import-player-overview-season-header">
              {" "}
              Season:
            </TableHeader>
            <TableValue
              data-testid="import-player-overview-season-value"
              sx={{ maxWidth: "240px" }}
            >
              {props.data.season?.name}
            </TableValue>
          </Grid>
        )}
        <Grid>
          <TableHeader data-testid="import-player-overview-uploadedBy-header">
            {" "}
            Uploaded By:
          </TableHeader>
          <TableValue data-testid="import-player-overview-uploadedBy-value">
            {props.data.uploadedBy?.person?.firstName +
              " " +
              props.data.uploadedBy?.person?.lastName}
          </TableValue>
        </Grid>
        <Grid>
          <TableHeader data-testid="import-player-overview-uploadedDate-header">
            {" "}
            Upload Date/Time:
          </TableHeader>
          <TableValue data-testid="import-player-overview-uploadedDate-value">
            {dateFormat(props.data.createdAt)}
          </TableValue>
        </Grid>
      </Grid>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Typography
          data-testid="import-player-overview-status"
          sx={{ letterSpacing: "1.2px", fontSize: "12px", fontWeight: 400 }}
        >
          {props.data.status}
        </Typography>
        <Grid container justifyContent="space-between">
          <Grid>
            <Typography
              data-testid="import-player-overview-completed-status"
              sx={{ color: "#3B6CF8", fontWeight: 600, fontSize: "14px" }}
            >
              {Math.round(
                ((props.data?.importedRecords?.length +
                  props.data?.failedRowCount) /
                  props.data?.rowCount) *
                  100
              )}
              % Completed (
              {props.data.importedRecords.length + props.data.failedRowCount} of{" "}
              {props.data.rowCount} Rows Uploaded)
            </Typography>
          </Grid>
          {props.data.status != "COMPLETED" && (
            <Grid>
              <TableHeader sx={{ display: "inline" }}>
                Time Elapsed:{" "}
              </TableHeader>
              <TableValue sx={{ display: "inline" }}>{elapsedTime} </TableValue>
            </Grid>
          )}
        </Grid>
        <BorderLinearProgress
          variant="determinate"
          value={
            ((props.data?.importedRecords?.length +
              props.data?.failedRowCount) /
              props.data?.rowCount) *
            100
          }
        />
        {Math.round(
          ((props.data?.importedRecords?.length + props.data?.failedRowCount) /
            props.data?.rowCount) *
            100
        ) != 100 && (
          <div
            data-testid="import-player-overview-cancel-button"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "24px"
            }}
          >
            <Button variant="admin-primary" onClick={handleImportCancel}>
              Cancel
            </Button>
          </div>
        )}
      </Container>
      <Grid container sx={{ marginTop: "24px" }}>
        <Grid xs={3}>
          <TableHeader data-testid="import-player-overview-totalRows-header">
            Total Rows:
          </TableHeader>
          <TableValue data-testid="import-player-overview-totalRows-value">
            {props.data.rowCount}
          </TableValue>
        </Grid>
        <Grid xs={3}>
          <TableHeader data-testid="import-player-overview-importedRows-header">
            Imported Rows:
          </TableHeader>
          <TableValue data-testid="import-player-overview-importedRows-value">
            {props.data.importedRecords?.length}
          </TableValue>
        </Grid>
        <Grid xs={3}>
          <TableHeader data-testid="import-player-overview-failedRows-header">
            Failed Rows:
          </TableHeader>
          <TableValue data-testid="import-player-overview-failedRows-value">
            {props.data.failedRowCount}
          </TableValue>
        </Grid>
        <Grid xs={3}>
          <TableHeader data-testid="import-player-overview-totalPersonRecords-header">
            Total Person Records:
          </TableHeader>
          <TableValue data-testid="import-player-overview-totalPersonRecords-value">
            {props.data.importedRecords?.length}
          </TableValue>
        </Grid>
      </Grid>
      <Grid sx={{ marginTop: "24px" }} container>
        <Grid xs={3}>
          <TableHeader data-testid="import-player-overview-importedPersonRecords-header">
            Imported Person Records:
          </TableHeader>
          <TableValue data-testid="import-player-overview-importedPersonRecords-value">
            {props.data.importedRecords?.length}
          </TableValue>
        </Grid>
        <Grid xs={3}>
          <TableHeader data-testid="import-player-overview-failedPersonRecords-header">
            Failed Person Records :
          </TableHeader>
          <TableValue data-testid="import-player-overview-failedPersonRecords-value">
            {props.data.failedRecords?.length}
          </TableValue>
        </Grid>
        <Grid xs={3}>
          <TableHeader data-testid="import-player-overview-totalAthleteRecords-header">
            Total Athlete/Player Records :
          </TableHeader>
          <TableValue data-testid="import-player-overview-totalAthleteRecords-value">
            {props.data.rowCount}
          </TableValue>
        </Grid>
        <Grid xs={3}>
          <TableHeader data-testid="import-player-overview-importedAthleteRecords-header">
            {" "}
            Imported Athlete/Player Records:
          </TableHeader>
          <TableValue
            data-testid="import-player-overview-importedAthleteRecords-value"
            sx={{ maxWidth: "240px" }}
          >
            {props.data.importedRecords?.length}
          </TableValue>
        </Grid>
      </Grid>
      <Grid sx={{ marginTop: "24px" }} container>
        <Grid xs={3}>
          <TableHeader data-testid="import-player-overview-failedAthleteRecords-header">
            Failed Athlete/Player Records:
          </TableHeader>
          <TableValue data-testid="import-player-overview-failedAthleteRecords-value">
            {props.data.failedRowCount}
          </TableValue>
        </Grid>
        <Grid xs={3}>
          <TableHeader data-testid="import-player-overview-totalParentRecords-header">
            Total Parent/Guardian Records:
          </TableHeader>
          <TableValue data-testid="import-player-overview-totalParentRecords-value">
            {props.data.importedRecords?.length}
          </TableValue>
        </Grid>
        <Grid xs={3}>
          <TableHeader data-testid="import-player-overview-importedParentRecords-header">
            Imported Parent/Guardian Records:
          </TableHeader>
          <TableValue data-testid="import-player-overview-importedParentRecords-value">
            {props.data.importedRecords?.length}
          </TableValue>
        </Grid>
        <Grid xs={3}>
          <TableHeader data-testid="import-player-overview-failedParentRecords-header">
            Failed Parent/Guardian Records:
          </TableHeader>
          <TableValue data-testid="import-player-overview-failedParentRecords-value">
            {props.data.failedRecords?.length}
          </TableValue>
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Cancel Import?"
        body="Are you sure you want to cancel import? All unsaved changes will be lost."
        onConfirm={onConfirmCancel}
        onCancel={onCancelCancel}
        confirmBtnVariant="admin-warning"
        icon="warning"
      />
    </Container>
  );
};
