import { Loader } from "@components/crud/Loader";
import { TableView } from "@components/TableView";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import { getAdvertisers } from "@services/Network";
import { useSponsorshipLevelGet } from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const dateFormat = (date) => {
  const formatedDate = new Date(date);
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  //@ts-ignore
  return formatedDate.toLocaleDateString("en-US", options);
};

const LIST_COLUMNS = [
  {
    headerName: "Business Name",
    field: "businessName",
    minWidth: 175,
    flex: 1
  },
  {
    headerName: "Business Category",
    field: "businessCategory",
    minWidth: 250,
    flex: 1,
    valueGetter: ({ row }) => row.businessCategory.label
  },
  {
    headerName: "Sponsorship Level",
    field: "sponsorship",
    minWidth: 200,
    flex: 1,
    valueGetter: ({ row }) => row.sponsorship.name
  },
  {
    headerName: "Status",
    field: "status",
    minWidth: 50,
    flex: 1,
    valueFormatter: (params) => {
      if (params.value == "INACTIVE") {
        return "Inactive";
      }
      return `Active`;
    }
  },
  {
    headerName: "No of Ads",
    field: "noOfAds",
    minWidth: 50,
    flex: 1,
    valueGetter: ({ row }) => row.advertisements.length
  },
  {
    headerName: "Created on",
    field: "createdAt",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => dateFormat(row.createdAt)
  }
];

export const Advertisers = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const [refreshKey] = useState(0);
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false
  });
  const onAdd = () => navigate("/advertisers/create");
  const onEdit = (advertiser) =>
    navigate(`/advertisers/${advertiser.advertiserId}/edit`);
  const onView = (advertiser) =>
    navigate(`/advertisers/${advertiser.advertiserId}`);
  const { data: sponsorshipLevels } = useSponsorshipLevelGet({
    organizationId: organizationId!
  });

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("live-streaming.advertisers", "ADD");
      const edit = await checkPermission("live-streaming.advertisers", "EDIT");
      const permission = {
        create,
        edit
      };
      setPermissions(permission);
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, [organizationId]);

  const filterConfig = {
    field: "filter",
    placeholderOption: {
      label: "All",
      value: "all"
    },
    options: [
      ...(sponsorshipLevels?.data?.sponsorshipLevels.map(
        (sponsorshipLevel) => ({
          label: `${sponsorshipLevel.name} Sponsorships`,
          value: sponsorshipLevel.sponsorshipId
        })
      ) || []),
      {
        label: "Active Sponsorships",
        value: "ACTIVE"
      },
      {
        label: "Inactive Sponsorships",
        value: "INACTIVE"
      }
    ]
  };

  return (
    <>
      <Loader isLoading={isLoadingPermissions}>
        <TableView
          title="Advertisers"
          useGet={getAdvertisers}
          columns={LIST_COLUMNS}
          getRowId={(row) => row.advertiserId}
          filterConfig={filterConfig}
          defaultSort={[{ field: "name", sort: "asc" }]}
          onEdit={permissions?.edit ? onEdit : undefined}
          onAdd={permissions?.create ? onAdd || undefined : undefined}
          onView={onView}
          isDeleteDisabled={() => true}
          refreshKey={refreshKey}
        />
      </Loader>
    </>
  );
};
