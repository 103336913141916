import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { Timer } from "@components/Timer";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Grid } from "@mui/material";
import { profileAtom } from "@recoil/auth";
import {
  ModelFeatureTestcase,
  useAdminQAFeatureTestcaseTestcaseIdTestRunsPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import {
  FEATURE_PLATFORMS_OPTIONS,
  TESTCASE_STATUS_OPTIONS
} from "@utils/constants";
import formatFullName from "@utils/formatFullName";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";

const dateFormat = () => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
    hour12: true
  };
  //@ts-ignore
  return new Intl.DateTimeFormat("en-US", options).format(new Date());
};

const parseExpectedTime = (expectedTime) => {
  const [str1, str2] = expectedTime.split(" ");
  let minutes = 0;
  let seconds = 0;
  if (str1.endsWith("m")) {
    minutes = parseInt(str1);
  } else if (str1.endsWith("s")) {
    seconds = parseInt(str1);
  }

  if (str2 && str2.endsWith("s")) {
    seconds = parseInt(str2);
  }
  const date = new Date();
  date.setUTCHours(0);
  date.setUTCMinutes(minutes);
  date.setUTCSeconds(seconds);
  return date;
};
// const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
//   marginBottom: "0.25rem",

//   "& .MuiFormLabel-asterisk": {
//     color: theme.palette.error.main
//   }
// }));
export const RunTestCaseForm = ({
  testCase,
  platform,
  release,
  releaseList,
  onClose
}: {
  testCase: ModelFeatureTestcase;
  platform?: string;
  release: string;
  releaseList: { label: string; value: string }[];
  onClose: () => void;
}) => {
  const form = useForm({
    mode: "onBlur"
  });
  const {
    control,
    getValues,
    setValue,
    formState: { isValid, isDirty },
    reset
  } = form;
  const testerName = formatFullName(useRecoilValue(profileAtom)?.person);
  const userId = useRecoilValue(profileAtom)!.userId!;
  const isUiAutomated =
    testCase[`${platform?.toLowerCase()}DefaultUIStatus`] === "AUTOMATED";
  const isFnAutomated =
    testCase[`${platform?.toLowerCase()}DefaultFNStatus`] === "AUTOMATED";
  useEffect(() => {
    if (testCase) {
      reset({
        description: testCase.description,
        expectedResult: testCase.expectedResult,
        executedOn: dateFormat(),
        tester: testerName,
        platform
      });
      if (isUiAutomated) setValue("uiStatusText", "Automated Unit Test Exists");
      if (isFnAutomated) setValue("fnStatusText", "Automated Unit Test Exists");
    }
  }, [testCase]);
  const { mutate: save, isLoading: isSaving } =
    useAdminQAFeatureTestcaseTestcaseIdTestRunsPost();
  const onSave = () => {
    console.log("SUBMITTT", getValues());
    save(
      {
        data: {
          fnStatus: isFnAutomated ? "NOT_TESTED" : getValues().FNStatus,
          uiStatus: isUiAutomated ? "NOT_TESTED" : getValues().UIStatus,
          fnReason: getValues().fnReason,
          uiReason: getValues().uiReason,
          fnJiraLink: getValues().fnJiraLink,
          uiJiraLink: getValues().uiJiraLink,
          timeLogged: parseExpectedTime(getValues().timeLogged),
          platform: getValues().platform,
          testCaseId: testCase.testCaseId!,
          createdById: userId,
          executedOn: new Date()
        },
        params: {
          releaseId: release
        },
        testcaseId: testCase.testCaseId!
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Saved Successfully!", {
            variant: "success"
          });
          onClose();
        },
        onError: () => {
          enqueueSnackbar("Failed to save data!", {
            variant: "error"
          });
        }
      }
    );
  };
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [showUIonHoldFields, setShowUIOnHoldFields] = useState(false);
  const [showFNonHoldFields, setShowFNOnHoldFields] = useState(false);
  const [showUIJiraField, setSHowUIJiraField] = useState(false);
  const [showFNJiraField, setSHowFNJiraField] = useState(false);
  const [showTimer, setShowTimer] = useState(true);

  return (
    <Container data-testid="runtestCaseForm">
      <Form>
        <Grid container direction="column" spacing="25px">
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={12} data-testid="description">
              <FormInput
                name="description"
                control={control}
                label="Test Case Description"
                required
                disabled
                type="text"
                multiline
              />
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={12} data-testid="expectedResult">
              <FormInput
                name="expectedResult"
                control={control}
                label="Expected Result"
                required
                multiline
                disabled
                type="text"
              />
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={12} container direction="column">
              <Grid item data-testid="release">
                <FormSelect
                  name="releaseVersion"
                  label="Release"
                  required
                  disabled
                  options={releaseList}
                  value={release}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={6} data-testid="tester">
              <FormInput
                name="tester"
                control={control}
                label="Tester"
                required
                disabled
                type="text"
              />
            </Grid>
            <Grid item xs={6} data-testid="executedOn">
              <FormInput
                name="executedOn"
                control={control}
                label="Executed On"
                required
                disabled
                type="text"
              />
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing="15px">
            <Grid item xs={6} data-testid="platform">
              <FormSelect
                name="platform"
                control={control}
                label="Platform"
                options={FEATURE_PLATFORMS_OPTIONS}
                required
                rules={{
                  required: "Platform Is Required"
                }}
              />
            </Grid>
            <Grid item xs={2} data-testid="timeLogged">
              <FormInput
                name="timeLogged"
                control={control}
                label="Time Logged"
                required
                disabled={showTimer}
                rules={{
                  pattern: {
                    value: /^(?:\d{1,2}m\s{1}\d{1,2}s|\d{1,2}m|\d{1,2}s)$/,
                    message: "Expected Time should be in format 10m 20s"
                  },
                  required: "Time Logged is Required"
                }}
                type="text"
              />
            </Grid>
            {showTimer && (
              <Grid item xs={4} alignSelf="flex-end" data-testid="timer">
                <Timer
                  onStop={(timeLogged) => {
                    setValue("timeLogged", timeLogged, {
                      shouldValidate: true
                    });
                    setShowTimer(false);
                    console.log("IMAZ:", parseExpectedTime(timeLogged));
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={6} data-testid="uiStatus">
              {isUiAutomated ? (
                <FormInput
                  control={control}
                  name="uiStatusText"
                  disabled
                  label="UI Status"
                  type="text"
                />
              ) : (
                <FormSelect
                  options={TESTCASE_STATUS_OPTIONS}
                  control={control}
                  name="UIStatus"
                  label="UI Status"
                  required={true}
                  rules={{
                    required: "UI Status is required"
                  }}
                  onChange={(e) => {
                    if (e.target.value === "HOLD") setShowUIOnHoldFields(true);
                    else setShowUIOnHoldFields(false);
                    if (
                      e.target.value === "BLOCKED" ||
                      e.target.value === "FAILED"
                    )
                      setSHowUIJiraField(true);
                    else setSHowUIJiraField(false);
                  }}
                />
              )}
            </Grid>
            <Grid item xs={6} data-testid="fnStatus">
              {isFnAutomated ? (
                <FormInput
                  control={control}
                  name="fnStatusText"
                  disabled
                  label="FN Status"
                  type="text"
                />
              ) : (
                <FormSelect
                  options={TESTCASE_STATUS_OPTIONS}
                  control={control}
                  name="FNStatus"
                  label="FN Status"
                  required={true}
                  rules={{
                    required: "FN Status is required"
                  }}
                  onChange={(e) => {
                    if (e.target.value === "HOLD") setShowFNOnHoldFields(true);
                    else setShowFNOnHoldFields(false);
                    if (
                      e.target.value === "BLOCKED" ||
                      e.target.value === "FAILED"
                    )
                      setSHowFNJiraField(true);
                    else setSHowFNJiraField(false);
                  }}
                />
              )}
            </Grid>
          </Grid>

          <Grid item container direction="row" spacing="24px">
            <Grid item container xs={6}>
              {(showUIJiraField || showUIonHoldFields) &&
                (showUIJiraField ? (
                  <Grid item xs={12} data-testid="uiJiraLink">
                    <FormInput
                      name="uiJiraLink"
                      control={control}
                      label="Jira Link"
                      required
                      type="text"
                      rules={{
                        required: "Jira Link is required"
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} data-testid="uiReason">
                    <FormInput
                      name="uiReason"
                      multiline
                      rows={4}
                      control={control}
                      label="Reason"
                      required
                      type="text"
                      rules={{
                        required: "Reason is required"
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
            <Grid item container xs={6}>
              {(showFNJiraField || showFNonHoldFields) &&
                (showFNJiraField ? (
                  <Grid item xs={12} data-testid="fnJiraLink">
                    <FormInput
                      name="fnJiraLink"
                      control={control}
                      label="Jira Link"
                      required
                      type="text"
                      rules={{
                        required: "Jira Link is required"
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} data-testid="fnReason">
                    <FormInput
                      name="fnReason"
                      multiline
                      rows={4}
                      control={control}
                      label="Reason"
                      required
                      rules={{
                        required: "Reason is required"
                      }}
                      type="text"
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          onSave();
        }}
        isDisabled={!isDirty || !isValid}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => onClose()}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
