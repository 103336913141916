import { FixIcon } from "@components/Icons";
import { RenderTableView } from "@components/RenderTableView";
import { Chip, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { ModelOrganizationUploadFailedRecord } from "@sportsgravyengineering/sg-api-react-sdk";
import { useNavigate } from "react-router-dom";
export const FailedRowsView = (props: {
  failedRows: ModelOrganizationUploadFailedRecord[];
}) => {
  const navigate = useNavigate();
  const IMPORTED_ROWS_COLUMNS: GridColDef[] = [
    {
      headerName: "Status",
      field: "status",
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: () => {
        return (
          <Chip
            style={{
              fontSize: "10px",
              padding: "4px 8px",
              marginLeft: "-10px"
            }}
            label="Error"
            sx={{ background: "#FECACA", color: "#991B1B" }}
          />
        );
      }
    },
    {
      headerName: "Status Description",
      field: "error",
      minWidth: 150,
      flex: 1,
      sortable: false
    },
    {
      headerName: "Athlete / Player First Name",
      field: "player.firstName",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record.player.firstName || ""
    },
    {
      headerName: "Athlete / Player Middle Name",
      field: "player.middleName",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record.player.middleName || ""
    },
    {
      headerName: "Athlete / Player Last Name",
      field: "player.lastName",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record.player.lastName || ""
    },
    {
      headerName: "Athlete / Player Date of Birth",
      field: "player.birthedAt",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record.player.birthedAt || ""
    },
    {
      headerName: "Athlete / Player Gender",
      field: "player.gender",
      minWidth: 130,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record.player.gender || ""
    },
    {
      headerName: "Athlete / Player Level",
      field: "player.level",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record.player.level || ""
    },
    {
      headerName: "Athlete / Player Email",
      field: "player.email",
      minWidth: 300,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record.player.email || ""
    },
    {
      headerName: "Address Line 1",
      field: "player.address1",
      minWidth: 200,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record.player.address1 || ""
    },
    {
      headerName: "Address Line 2",
      field: "player.address2",
      minWidth: 200,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record.player.address2 || ""
    },
    {
      headerName: "City",
      field: "city",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record.player.city || ""
    },
    {
      headerName: "State",
      field: "state",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record.player.state || ""
    },
    {
      headerName: "Country",
      field: "country",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record.player.country || ""
    },
    {
      headerName: "Zip Code",
      field: "zipCode",
      minWidth: 100,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record.player.zip || ""
    },
    {
      headerName: "Parent / Guardian 1 First Name",
      field: "parent1FirstName",
      minWidth: 170,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record.parent1.firstName || ""
    },
    {
      headerName: "Parent / Guardian 1 Last Name",
      field: "parent1LastName",
      minWidth: 170,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record.parent1.lastName || ""
    },
    {
      headerName: "Parent / Guardian 1 Mobile Phone",
      field: "parent1Phone",
      minWidth: 170,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record.parent1.phone || ""
    },
    {
      headerName: "Parent / Guardian 1 Email",
      field: "parent1Email",
      minWidth: 300,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record.parent1.email || ""
    },
    {
      headerName: "Parent / Guardian 2 First Name",
      field: "parent2FirstName",
      minWidth: 170,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record.parent2.firstName || ""
    },
    {
      headerName: "Parent / Guardian 2 Last Name",
      field: "parent2LastName",
      minWidth: 170,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record.parent2.lastName || ""
    },
    {
      headerName: "Parent / Guardian 2 Mobile Phone",
      field: "parent2Phone",
      minWidth: 170,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record.parent2.phone || ""
    },
    {
      headerName: "Parent / Guardian 2 Email",
      field: "parent2Email",
      minWidth: 300,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record.parent2.email || ""
    }
  ];
  const onFix = (id) => {
    navigate(`edit/${id}`);
  };
  return (
    <>
      <RenderTableView
        title=""
        rows={props.failedRows}
        columns={IMPORTED_ROWS_COLUMNS}
        hideFilter
        getRowId={(row) => row?.recordId}
        searchable={false}
        hideToolbar={true}
        hideFooter={true}
        pinnedColumns={{ left: [] }}
        customActions={[
          (params) => (
            <IconButton onClick={() => onFix(params.row.recordId)}>
              <FixIcon />
            </IconButton>
          )
        ]}
      />
    </>
  );
};
