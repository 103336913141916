// import { FormRadioGroup } from "@components/FormRadioGroup";
import { Loader } from "@components/crud/Loader";
import { Grid, Typography } from "@mui/material";
// import { useAdminSettingsPut } from "@sportsgravyengineering/sg-api-react-sdk";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import {
  useAdminSettingsGet,
  useAdminSettingsPut,
  useLookupCountryCountryIdSubdivisionSubdivisionIdTimezoneGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState, useRecoilValue } from "recoil";
// import { enqueueSnackbar } from "notistack";
import { FormSelect } from "@components/FormSelect";
import { enqueueSnackbar } from "notistack";

export const GeneralSettingsFso = () => {
  const form = useForm({
    mode: "onBlur"
  });
  const [organizationId] = useRecoilState(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const organization = organizations.find(
    (org) => org.organizationId === organizationId
  );
  const { data: settings, isLoading: isLoading } = useAdminSettingsGet({
    organizationId: organizationId!,
    parentId: "general"
  });
  const { data: timezone, isLoading: isLoadingTimeZone } =
    useLookupCountryCountryIdSubdivisionSubdivisionIdTimezoneGet(
      organization!.country!,
      organization!.province!,
      {
        city: organization!.locality
      }
    );
  const { reset, control, getValues } = form;
  useEffect(() => {
    if (settings && timezone) {
      const settingsMap = settings.data.map((setting) => {
        return {
          settingId: setting.settingId,
          value:
            setting.organizationSettings && setting.organizationSettings.length
              ? setting.organizationSettings[0].value
              : timezone.data
        };
      });
      const defaultValues = {
        selectedSettings: settingsMap
      };

      reset(defaultValues, {
        keepDefaultValues: false
      });
    }
  }, [settings, timezone]);
  const { mutate: save, isLoading: isSaving } = useAdminSettingsPut();
  const onSave = () => {
    const data = getValues().selectedSettings;
    save(
      {
        data: data,
        params: {
          organizationId: organizationId!
        }
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Saved Successfully!", {
            variant: "success"
          });
        },
        onError: () => {
          enqueueSnackbar("Failed to save !", {
            variant: "error"
          });
        }
      }
    );
  };
  return (
    <Grid item container direction="column" spacing="15px">
      <Loader isLoading={isLoading || isSaving || isLoadingTimeZone}>
        <Grid item>
          <Typography
            variant="permissionNames"
            sx={{
              color: "#00000",
              fontSize: "12px",
              font: "inter",
              lineHeight: "14.52px",
              letterSpacing: "10%",
              fontWeight: 400
            }}
          >
            CALENDAR
          </Typography>
        </Grid>
        {settings &&
          settings.data.map((setting, index) => {
            if (setting.type === "SINGLE_SELECT") {
              return (
                <Grid item container direction="row" key={setting.settingId}>
                  <Grid item xs={6}>
                    <FormSelect
                      control={control}
                      disabled={
                        setting.settingId === "general.org-local-timezone"
                      }
                      onChange={() => {
                        onSave();
                      }}
                      name={`selectedSettings[${index}].value`}
                      options={
                        setting.options as { label: string; value: string }[]
                      }
                      label={setting.description}
                    />
                  </Grid>
                </Grid>
              );
            }
          })}
        {/* {settings && (
          <Grid item container direction="row">
            <Grid item xs={6}>
              <FormSelect
                control={control}
                name={"orgTimeZone"}
                options={Intl.supportedValuesOf("timeZone").map((key) => {
                  return {
                    label: key,
                    value: key
                  };
                })}
                label="Organization Local Time Zone"
              />
            </Grid>
          </Grid>
        )} */}
      </Loader>
    </Grid>
  );
};
