import { TableView } from "@components/TableView";
import { getDepartments } from "@services/Network";

import React from "react";
import { useNavigate } from "react-router-dom";

const LIST_COLUMNS = [
  {
    headerName: "Name",
    field: "name",
    minWidth: 150,
    flex: 1,
    sortable: false
  }
];

export const Departments = () => {
  const navigate = useNavigate();

  const [refreshKey] = React.useState(0);

  const onAdd = () => navigate("/departments/create");
  const onEdit = (row) => navigate(`/departments/${row.departmentId}/edit`);
  const onView = (row) => navigate(`/departments/${row.departmentId}`);

  return (
    <>
      <TableView
        title="Departments"
        useGet={getDepartments}
        columns={LIST_COLUMNS}
        hideFilter={true}
        hideLabelContainer={true}
        searchable={false}
        getRowId={(row) => row.departmentId}
        onAdd={onAdd}
        onEdit={onEdit}
        onView={onView}
        isDeleteDisabled={() => true}
        refreshKey={refreshKey}
      />
    </>
  );
};
