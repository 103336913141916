import { Button } from "@components/Button";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormInput } from "@components/FormInput";
import { NotificationCard } from "@components/NotificationCard";
import { PasswordInput } from "@components/PasswordInput";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  isKeepLoggedInAtom,
  isResetPasswordSuccessAtom,
  organizationsAtom,
  casbinModel,
  casbinPolicy,
  organizationAtom
} from "@recoil/auth";
import { canAccessDashboard, getEnforcer } from "@services/Casbin";
import { signInPost } from "@services/Network";
import {
  ModelOrganization,
  accessPolicyGet,
  organizationGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { EMAIL_REGEX } from "@utils/validation";
import { useResetSignup } from "hooks/useResetSignup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { getRecoil } from "recoil-nexus";

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const FormRow = styled(Box)`
  display: flex;
  margin-top: 1.5rem;
  ${(props) => props.theme.breakpoints.up("xs")} {
    flex-direction: column;
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    flex-direction: row;
  }
`;

const ButtonContainer = styled(Box)`
  margin-top: 3rem;
`;

const FormFieldContainer = styled(Box)`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
`;

const UtilitiesContainer = styled(Box)`
  display: flex;
  margin-top: 1rem;
  align-items: center;
  justify-content: space-between;
`;

const StyledLink = styled(Link)(({ theme }) => ({
  whiteSpace: "nowrap",
  textDecoration: "underline",
  color: theme.palette.primary.main
}));

const SignInContainer = styled(Box)`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const setIsKeepLoggedIn = useSetRecoilState(isKeepLoggedInAtom);
  const [isResetPasswordSuccess, setIsResetPasswordSuccess] = useRecoilState(
    isResetPasswordSuccessAtom
  );
  const resetSignup = useResetSignup();
  const setCasbinModel = useSetRecoilState(casbinModel);
  const setCasbinPolicy = useSetRecoilState(casbinPolicy);
  const [, setOrganizations] = useRecoilState(organizationsAtom);
  const [, setOrganization] = useRecoilState(organizationAtom);

  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: "",
      password: "",
      isKeepLoggedIn: false
    }
  });

  useEffect(() => {
    resetSignup();
    return () => {
      setIsResetPasswordSuccess(false);
    };
  }, []);

  const mutation = signInPost();

  const submitForm = async (formValues) => {
    const { email, password, isKeepLoggedIn } = formValues;
    mutation.mutate(
      {
        data: { email, password }
      },
      {
        onSuccess: async () => {
          setIsKeepLoggedIn(isKeepLoggedIn);

          const { data: policies } = await accessPolicyGet();
          setCasbinModel(policies.model as never);
          setCasbinPolicy(policies.policy);
          const {
            data: organizations
          }: {
            data: ModelOrganization[];
          } = await organizationGet();
          if ((await getEnforcer().getAllSubjects()).includes("SYSTEM")) {
            organizations.unshift({
              organizationId: undefined,
              name: "SportsGravy LLC"
            });
          }
          if (organizations.length === 0) navigate("/unauthorized");
          console.log("organizations", organizations);
          setOrganizations(organizations);
          const hasDashboardAccess = await canAccessDashboard();
          const updatedOrganizations = getRecoil(organizationsAtom);
          if (updatedOrganizations.length > 0) {
            setOrganization(updatedOrganizations[0].organizationId);
          }
          if (hasDashboardAccess) {
            navigate("/dashboard");
          } else {
            navigate("/unauthorized");
          }
        }
      }
    );
  };

  const handleSignUp = (event) => {
    event.preventDefault();
    navigate("/sign-up/basic-info");
  };

  return (
    <FormContainer>
      <Typography
        variant="h2"
        color="text.general.primary"
        sx={{ fontWeight: 400, mb: ".5rem" }}
      >
        Sign In
      </Typography>
      <Typography variant="body1" color="text.general.secondary">
        If you’re a sports organization, enter your credentials below to access
        the SportsGravy administration portal.
      </Typography>
      {location.state?.hasTimedOut && (
        <FormRow>
          <NotificationCard variant="info">
            Your session has timed out. Please sign in again.
          </NotificationCard>
        </FormRow>
      )}
      {mutation.isError && (
        <FormRow>
          <NotificationCard variant="error">
            Email and/or Password are incorrect. Please try again.
          </NotificationCard>
        </FormRow>
      )}
      {isResetPasswordSuccess && (
        <FormRow>
          <NotificationCard variant="success">
            You have successfully changed the password for your SportsGravy
            account. Sign In with your email and new password below.
          </NotificationCard>
        </FormRow>
      )}
      <form onSubmit={handleSubmit(submitForm)}>
        <FormRow>
          <FormFieldContainer>
            <FormInput
              name="email"
              type="text"
              required
              label="Email"
              control={control}
              rules={{
                required: "Email is required",
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Please enter a valid email address"
                }
              }}
            />
          </FormFieldContainer>
        </FormRow>
        <FormRow>
          <FormFieldContainer>
            <PasswordInput
              name="password"
              required
              label="Password"
              control={control}
              rules={{
                required: "Password is required"
              }}
            />
          </FormFieldContainer>
        </FormRow>
        <UtilitiesContainer>
          <FormCheckbox
            name="isKeepLoggedIn"
            label="Keep me logged in"
            control={control}
          />
          <StyledLink to="/forgot-password">Forgot Password?</StyledLink>
        </UtilitiesContainer>
        <ButtonContainer>
          <Button
            variant="primary"
            disabled={!isValid || mutation.isLoading}
            type="submit"
            isLoading={mutation.isLoading || mutation.isSuccess}
          >
            Continue
          </Button>
        </ButtonContainer>
      </form>
      <SignInContainer>
        <Typography variant="body1">
          Don't have an account?{" "}
          <StyledLink to="/sign-up/basic-info" onClick={handleSignUp}>
            Sign Up
          </StyledLink>
        </Typography>
      </SignInContainer>
    </FormContainer>
  );
};
