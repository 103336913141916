import {
  accessTokenAtom,
  isResetPasswordSuccessAtom,
  profileAtom,
  refreshTokenAtom
} from "@recoil/auth";
import {
  basicFormInputAtom,
  duplicateAccountAtom,
  duplicatePendingAccountsAtom,
  inviteAtom,
  pendingAccountAtom,
  personAtom,
  signupTokenAtom,
  userAtom
} from "@recoil/signup";
import {
  AdminFeedCommentActionGetParams,
  AdminFeedGetParams,
  AdminFeedPostActionGetParams,
  AdminRoleGetParams,
  Gender,
  ModelInvite,
  PostPostIdCommentGetParams,
  authRefreshPost,
  axios,
  profileGet,
  responseCodes,
  setBaseUrl,
  useAdminFeedActionPost,
  useAdminFeedCommentActionGet,
  useAdminFeedCommentCommentIdGet,
  useAdminFeedGet,
  useAdminFeedPost,
  useAdminFeedPostActionGet,
  useAdminFeedPostPostIdGet,
  useAdminGameConceptGet,
  useAdminGameSystemGet,
  useAdminImportTmplGet,
  useAdminImportTmplProviderIdVersionGet,
  useAdminLevelGet,
  useAdminOrganizationGet,
  useAdminOrganizationOrganizationIdGet,
  useAdminPositionGet,
  useAdminRoleGet,
  useAdminSeasonGet,
  useAdminSkillGet,
  useAdminSubroleGet,
  useAdminTeamGet,
  useAdminUserGet,
  useAuthForgotPasswordPut,
  useAuthSignInPost,
  useAuthSignUpBasicPost,
  useAuthSignUpConfirmPost,
  useAuthSignUpPersonalPost,
  useAuthSignUpPrimaryEmailPut,
  useAuthSignUpResendPersonCodePost,
  useAuthSignUpVerifyMergeCodePost,
  useAuthSignUpVerifyPersonCodePost,
  useCannedMessageGet,
  useLookupCountryGet,
  usePostPostIdCommentCommentIdReactionGet,
  usePostPostIdCommentGet,
  useSportCountryCountryIdGet,
  useSportGet,
  useAdminUserUserIdPatch,
  useAdminUserInvitePost,
  useAdminUserPost,
  useAdminTrainingProgramGet,
  useAdminSportGet,
  AdminSportGetParams,
  useAdminNotificationGet,
  useAdminNotificationNotificationIdDelete,
  useAdminNotificationUnreadGet,
  useAdminUploadGet,
  useAdminLiveStreamGet,
  useAdvertiserGet,
  useSponsorshipLevelGet,
  useAdminSportLocationGet,
  useAdminEventEventIdRecurrenceRecurrenceIdGet,
  useAdminFeatureTestcaseGet,
  useAdminHelpCategoryGet,
  useAdminFaqGet,
  useAdminFeatureGet,
  useAdminHelpArticleGet,
  useAdminDepartmentGet,
  useAdminJobTitleGet,
  useAdminDirectoryGet,
  useAdminQAFeatureGet,
  useAdminQAFeatureTestCaseGet,
  useAdminReleaseGet,
  useAdminMetricQaGet,
  useAdminMetricGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { format, parseISO } from "date-fns";
import Debug from "debug";
import { getRecoil, resetRecoil, setRecoil } from "recoil-nexus";
import { DuplicatePerson } from "../types/duplicatePerson";
import { getWithExpiry, storeWithExpiry } from "./LocalStorage";
const debug = Debug("network");
setBaseUrl(import.meta.env.VITE_APP_API_URL || "http://localhost:4000");

// define custom params serializer for axios
axios.defaults.paramsSerializer = (params) => {
  return Object.keys(params)
    .map((key) => {
      const value = params[key];
      if (value === undefined || value === null) {
        return "";
      }
      if (Array.isArray(value)) {
        return value
          .map(
            (val) => `${encodeURIComponent(key)}[]=${encodeURIComponent(val)}`
          )
          .join("&");
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .filter((param) => param.length)
    .join("&");
};

axios.interceptors.request.use((req) => {
  debug("intercepted request", req);
  const accessToken = getRecoil<string | undefined>(accessTokenAtom);
  const signupToken = getRecoil<string | undefined>(signupTokenAtom);
  const invite = getRecoil<ModelInvite | null>(inviteAtom);

  req.headers = req.headers || {};

  if (accessToken) {
    debug("setting access token", accessToken);
    req.headers["authorization"] = `Bearer ${accessToken}`;
  }
  // allow setting both since certain methods take the sign up token instead (especially during the sign up process)
  if (req.url?.includes("/auth/sign-up") && signupToken) {
    debug("setting sign up token", signupToken);
    req.headers["x-sportsgravy-token"] = signupToken;
  }

  // allow setting both since certain methods take the sign up token instead (especially during the sign up process)
  if (req.url?.includes("/auth/sign-up") && invite?.inviteId) {
    debug("setting invite id", invite.inviteId);
    req.headers["x-sportsgravy-invite"] = invite.inviteId;
  }

  debug("returning request", req);

  return req;
});

axios.interceptors.response.use(
  (res) => {
    debug("intercepted response", res);
    return res;
  },
  async function (err) {
    const originalRequest = err.config;

    if (err.response.status !== 401) {
      return Promise.reject(err);
    }

    const refreshToken = getRecoil<string | undefined>(refreshTokenAtom);
    if (originalRequest._retry || !refreshToken) {
      resetRecoil(profileAtom);
      resetRecoil(accessTokenAtom);
      resetRecoil(refreshTokenAtom);
    }

    debug("intercepted unauthenticated response", err);
    originalRequest._retry = true;

    try {
      const resp = await authRefreshPost({
        refreshToken
      });
      debug("refresh token response", resp);
      setRecoil<string | undefined>(
        accessTokenAtom,
        resp.data?.AuthenticationResult?.IdToken
      );
      setRecoil<string | undefined>(
        refreshTokenAtom,
        resp.data?.AuthenticationResult?.RefreshToken
      );
      debug("retrying original request", originalRequest);
      return axios(originalRequest);
    } catch (err) {
      resetRecoil(profileAtom);
      resetRecoil(accessTokenAtom);
      resetRecoil(refreshTokenAtom);
      return Promise.reject(err);
    }
  }
);

export const basicPost = () => {
  const mutation = useAuthSignUpBasicPost();
  const mutateFn = mutation.mutate;
  mutation.mutate = (req, options) => {
    options = options || {};
    const { onSuccess, onError } = options;
    options.onSuccess = (res, variables, context) => {
      setRecoil(personAtom, res.data);
      setRecoil(signupTokenAtom, res.headers["x-sportsgravy-token"]);
      if (onSuccess) {
        onSuccess(res, variables, context);
      }
    };
    options.onError = (e, variables, context) => {
      const error = e as unknown as {
        response: { data: { details: DuplicatePerson; code: string } };
      };
      const errorCode = error?.response?.data?.code;
      const errorDetails = error?.response?.data?.details;
      if (errorCode === responseCodes.AUTH_SIGNUP_PERSONAL_DUPLICATE) {
        setRecoil(pendingAccountAtom, errorDetails);
        setRecoil(basicFormInputAtom, req.data);
      }
      if (errorCode === responseCodes.AUTH_SIGNUP_DUPLICATE) {
        setRecoil(duplicateAccountAtom, errorDetails);
      }
      if (onError) {
        onError(e, variables, context);
      }
    };
    mutateFn(req, options);
  };
  return mutation;
};

export const personalPost = () => {
  const mutation = useAuthSignUpPersonalPost();
  const mutateFn = mutation.mutate;
  mutation.mutate = (req, options) => {
    resetRecoil(duplicateAccountAtom);
    resetRecoil(duplicatePendingAccountsAtom);
    resetRecoil(pendingAccountAtom);

    options = options || {};
    const { onSuccess, onError } = options;

    options.onSuccess = (res, variables, context) => {
      const person = getRecoil(personAtom);
      setRecoil(personAtom, { ...person, ...res.data });
      if (onSuccess) {
        onSuccess(res, variables, context);
      }
    };

    options.onError = (e, variables, context) => {
      const error = e as unknown as {
        response: {
          data: { details: DuplicatePerson | DuplicatePerson[]; code: string };
        };
      };

      const errorCode = error?.response?.data?.code;
      const errorDetails = error?.response?.data?.details;

      const person = getRecoil(personAtom);
      setRecoil(personAtom, {
        ...person,
        addressPrimary: req.data.addresses[0],
        phonePrimary: {
          phone: req.data.phone
        },
        birthedAt: req.data.birthedAt,
        gender: req.data.gender as Gender
      });

      if (
        errorCode === responseCodes.AUTH_SIGNUP_PERSONAL_DUPLICATE &&
        Array.isArray(errorDetails)
      ) {
        setRecoil(duplicatePendingAccountsAtom, errorDetails);
      }

      if (
        [
          responseCodes.AUTH_SIGNUP_DUPLICATE,
          responseCodes.AUTH_SIGNUP_DUPLICATE_ASSOCIATED_PHONE,
          responseCodes.AUTH_SIGNUP_DUPLICATE_PRIMARY_PHONE
        ].includes(errorCode) &&
        !Array.isArray(errorDetails)
      ) {
        setRecoil(duplicateAccountAtom, errorDetails);
        setRecoil(duplicatePendingAccountsAtom, [errorDetails]);
        setRecoil(pendingAccountAtom, errorDetails);
      }

      if (onError) {
        onError(e, variables, context);
      }
    };
    mutateFn(req, options);
  };
  return mutation;
};

export const accountPost = () => {
  const mutation = useAuthSignUpConfirmPost();
  const mutateFn = mutation.mutate;
  const mutateAsyncFn = mutation.mutateAsync;
  mutation.mutateAsync = async (variables, options) => {
    const res = await mutateAsyncFn(variables, options);
    setRecoil(userAtom, res.data);
    return res;
  };
  mutation.mutate = (data, options) => {
    options = options || {};
    const { onSuccess } = options;
    options.onSuccess = (res, variables, context) => {
      setRecoil(userAtom, res.data[0]);
      if (onSuccess) {
        onSuccess(res, variables, context);
      }
    };

    mutateFn(data, options);
  };
  return mutation;
};

export const signInPost = () => {
  const mutation = useAuthSignInPost();
  const mutateFn = mutation.mutate;
  mutation.mutate = (req, options) => {
    options = options || {};
    const { onSuccess } = options;
    options.onSuccess = async (res, variables, context) => {
      setRecoil(accessTokenAtom, res.data?.AuthenticationResult?.IdToken);
      setRecoil(refreshTokenAtom, res.data?.AuthenticationResult?.RefreshToken);

      const profile = await profileGet();
      setRecoil(profileAtom, profile.data);

      if (onSuccess) {
        onSuccess(res, variables, context);
      }
    };
    mutateFn(req, options);
  };
  return mutation;
};

export const resetPassword = () => {
  const mutation = useAuthForgotPasswordPut();
  const mutateFn = mutation.mutate;
  mutation.mutate = (data, options) => {
    options = options || {};
    const { onSuccess } = options;
    options.onSuccess = (res, variables, context) => {
      setRecoil(isResetPasswordSuccessAtom, true);
      if (onSuccess) {
        onSuccess(res, variables, context);
      }
    };
    mutateFn(data, options);
  };
  return mutation;
};

export const verifyMergeCode = () => {
  const mutation = useAuthSignUpVerifyMergeCodePost();
  const mutateFn = mutation.mutateAsync;
  mutation.mutateAsync = async (req, options) => {
    const res = await mutateFn(req, options);
    const duplicatePendingAccounts = getRecoil(duplicatePendingAccountsAtom);
    setRecoil(
      duplicatePendingAccountsAtom,
      duplicatePendingAccounts.filter(
        (account) => account.personId !== req.data.personId
      )
    );
    setRecoil(personAtom, res.data);
    return res;
  };
  return mutation;
};

// New SDK
export const primaryEmailPut = (email: string) => {
  const mutation = useAuthSignUpPrimaryEmailPut({
    mutation: {
      onSuccess: (res) => {
        setRecoil(personAtom, res.data);
      }
    }
  });
  mutation.mutate({
    data: {
      email: email
    },
    headers: {
      "x-sportsgravy-token": getRecoil(signupTokenAtom)
    }
  });
  return mutation;
};

export const resendPersonCode = () => {
  const mutation = useAuthSignUpResendPersonCodePost();
  return mutation;
};

export const verifyPersonCode = () => {
  const mutation = useAuthSignUpVerifyPersonCodePost({
    mutation: {
      onSuccess: (res) => {
        setRecoil(duplicateAccountAtom, null);
        setRecoil(duplicatePendingAccountsAtom, []);
        setRecoil(pendingAccountAtom, null);
        setRecoil(personAtom, res.data);
        setRecoil(signupTokenAtom, res.headers["x-sportsgravy-token"]);
      }
    }
  });
  return mutation;
};

export const getOrganizations = (params, options) => {
  const query = useAdminOrganizationGet(params, options);
  const data =
    query?.data?.data?.organizations?.map((org) => ({
      id: org.organizationId,
      createdAt: format(
        parseISO(org.createdAt as unknown as string),
        "MMM d, yyyy"
      ),
      ...org
    })) || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getOrganizationById = (params, options) => {
  const query = useAdminOrganizationOrganizationIdGet(params, options);
  const data = query?.data?.data || {};
  return { ...query, data };
};

export const getPositions = (params, options) => {
  const query = useAdminPositionGet(params, options);
  const data =
    query?.data?.data?.positions?.map((position) => {
      return {
        ...position,
        id: position.positionId
      };
    }) || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getRoles = (params: AdminRoleGetParams, options) => {
  const query = useAdminRoleGet(params, options);
  const data =
    query?.data?.data?.roles?.map((role) => {
      return {
        ...role,
        id: role.roleId
      };
    }) || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getGameConcepts = (params, options) => {
  const query = useAdminGameConceptGet(params, options);
  const data =
    query?.data?.data?.gameConcepts?.map((row) => {
      return {
        ...row,
        id: row.conceptId
      };
    }) || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getGameSystems = (params, options) => {
  const query = useAdminGameSystemGet(params, options);
  const data =
    query?.data?.data?.gameSystems?.map((row) => {
      return {
        ...row,
        id: row.systemId
      };
    }) || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getSeasons = (params, options) => {
  const query = useAdminSeasonGet(params, options);
  const data =
    query?.data?.data?.results?.map((row) => {
      return {
        ...row,
        id: row.seasonId
      };
    }) || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getTeams = (params, options) => {
  const query = useAdminTeamGet(params, options);
  const data =
    query?.data?.data?.teams?.map((row) => {
      return {
        ...row,
        id: row.teamId
      };
    }) || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getSkills = (params, options) => {
  const query = useAdminSkillGet(params, options);
  const data =
    query?.data?.data?.skills?.map((skill) => {
      return {
        ...skill,
        id: skill.skillId
      };
    }) || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getSubroles = (params, options) => {
  const query = useAdminSubroleGet(params, options);
  const data =
    query?.data?.data?.roles?.map((role) => {
      return {
        ...role,
        id: role.roleId
      };
    }) || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getSports = (params, options) => {
  const query = useSportGet(params, options);
  const data =
    query?.data?.data?.sports?.map((sport) => {
      return {
        ...sport,
        id: sport.sportId
      };
    }) || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getSportsByCountry = (params, options = {}) => {
  const query = useSportCountryCountryIdGet(params, options);
  const data =
    query?.data?.data?.map((sport) => ({ ...sport, id: sport.sportId })) || [];
  return { ...query, data };
};

export const getAdminLevels = (params, options) => {
  const query = useAdminLevelGet(params, options);
  const data =
    query?.data?.data?.levels?.map((level) => {
      return {
        ...level,
        id: level.levelId
      };
    }) || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getCountries = (params = {}) => {
  const storedCountries = getWithExpiry("countries");
  const query = useLookupCountryGet({
    query: {
      enabled: !(
        storedCountries &&
        Array.isArray(storedCountries) &&
        storedCountries.length
      ),
      onSuccess: (res) => {
        const ttl = 60 * 60 * 24 * 7; // 7 days
        storeWithExpiry("countries", res.data, ttl);
      },
      ...params
    }
  });

  return {
    ...query,
    data: storedCountries || query?.data?.data || [],
    isLoading: !storedCountries && query.isLoading
  };
};

export const getImportTemplateProviders = (params, options) => {
  const query = useAdminImportTmplGet(params, options);
  const data =
    query?.data?.data?.importTemplateProviders?.map((temp) => ({
      ...temp,
      id: temp.providerId
    })) || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getImportTemplateProviderVersionsFactory =
  (providerId: string) => (params, options) => {
    const query = useAdminImportTmplProviderIdVersionGet(
      providerId,
      params,
      options
    );
    const data =
      query?.data?.data?.versions?.map((temp) => ({
        ...temp,
        id: temp.versionId
      })) || [];
    const numRows = query?.data?.data?.numRows || 0;
    return { ...query, data, numRows };
  };

export const getUsers = (params, options) => {
  const query = useAdminUserGet(params, options);
  const data =
    query?.data?.data?.persons?.map((person) => {
      return {
        ...person,
        id: person.personId
      };
    }) || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getCannedMessages = (params, options) => {
  const query = useCannedMessageGet(params, options);

  const data =
    query?.data?.data?.results?.map((cannedMessages) => {
      return {
        ...cannedMessages,
        id: cannedMessages.cannedMessageId
      };
    }) || [];
  const numRows = query?.data?.data?.results?.length || 0;
  return { ...query, data, numRows };
};

export const getFeed = (params: AdminFeedGetParams, options, postId?) => {
  if (postId == undefined) {
    const query = useAdminFeedGet(params, options);

    const data = query.data?.data || [];

    return { ...query, data };
  }
  if (postId) {
    const query = useAdminFeedPostPostIdGet(postId.postId, options);
    const data = query.data?.data || [];
    return { ...query, data };
  }
};
export const getPostComment = (
  postId,
  params: PostPostIdCommentGetParams,
  options
) => {
  const query = usePostPostIdCommentGet(postId, params, options);

  const data = query.data?.data || [];

  return { ...query, data };
};
export const getCommentReplies = (postId, commentId, options) => {
  const query = usePostPostIdCommentCommentIdReactionGet(
    postId,
    commentId,
    options
  );

  const data = query.data?.data || [];

  return { ...query, data };
};
export const removePost = () => {
  const mutation = useAdminFeedActionPost();
  const mutateFn = mutation.mutate;

  mutation.mutate = (req, options) => {
    options = options || {};
    const { onSuccess, onError } = options;
    options.onSuccess = (res, variables, context) => {
      if (onSuccess) {
        onSuccess(res, variables, context);
      }
    };

    options.onError = (e, variables, context) => {
      if (onError) {
        onError(e, variables, context);
      }
    };

    mutateFn(req, options);
  };
  return mutation;
};
export const getCreatePostSport = (params?: AdminSportGetParams) => {
  if (!params?.organizationId) {
    const query = useSportGet();
    const data =
      query?.data?.data?.sports?.map((sport) => {
        return {
          ...sport,
          id: sport.sportId
        };
      }) || [];
    const numRows = query?.data?.data?.numRows || 0;
    return { ...query, data, numRows };
  }
  if (params.organizationId) {
    const query = useAdminSportGet(params);
    const data =
      query?.data?.data?.map((sport) => {
        return {
          ...sport,
          id: sport.sportId
        };
      }) || [];
    return { ...query, data };
  }
};
export const createPost = () => {
  const mutation = useAdminFeedPost();
  const mutateFn = mutation.mutate;

  mutation.mutate = (req, options) => {
    options = options || {};
    const { onSuccess, onError } = options;
    options.onSuccess = (res, variables, context) => {
      if (onSuccess) {
        onSuccess(res, variables, context);
      }
    };

    options.onError = (e, variables, context) => {
      if (onError) {
        onError(e, variables, context);
      }
    };

    mutateFn(req, options);
  };
  return mutation;
};
export const getReportPostList = (
  params: AdminFeedPostActionGetParams,
  options
) => {
  const query = useAdminFeedPostActionGet(params, options);
  const data = query?.data?.data?.results || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};
export const getReportCommentList = (
  params: AdminFeedCommentActionGetParams,
  options
) => {
  const query = useAdminFeedCommentActionGet(params, options);
  const data = query?.data?.data?.results || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};
export const getReportDetail = (type, commentId, options) => {
  if (type === "comment") {
    const query = useAdminFeedCommentCommentIdGet(commentId, options);
    const data = query?.data?.data || [];
    return { ...query, data };
  }
  if (type == "post") {
    const query = useAdminFeedPostPostIdGet(commentId, options);
    const data = query?.data?.data || [];
    return { ...query, data };
  }
};
export const reportAction = () => {
  const mutation = useAdminFeedActionPost();
  const mutateFn = mutation.mutate;

  mutation.mutate = (req, options) => {
    options = options || {};
    const { onSuccess, onError } = options;
    options.onSuccess = (res, variables, context) => {
      if (onSuccess) {
        onSuccess(res, variables, context);
      }
    };

    options.onError = (e, variables, context) => {
      if (onError) {
        onError(e, variables, context);
      }
    };

    mutateFn(req, options);
  };
  return mutation;
};
export const adminUserInvitePost = () => {
  const mutation = useAdminUserInvitePost();
  const mutateFn = mutation.mutate;
  mutation.mutate = (req, options) => {
    options = options || {};
    const { onSuccess, onError } = options;
    options.onSuccess = (res, variables, context) => {
      if (onSuccess) {
        onSuccess(res, variables, context);
      }
    };

    options.onError = (e, variables, context) => {
      const error = e as unknown as {
        response: { data: { details: DuplicatePerson; code: string } };
      };
      const errorCode = error?.response?.data?.code;
      const errorDetails = error?.response?.data?.details;
      console.log("err", errorCode, errorDetails);
      if (onError) {
        onError(e, variables, context);
      }
    };
    mutateFn(req, options);
  };
  return mutation;
};
export const adminUserPost = () => {
  const mutation = useAdminUserPost();
  const mutateFn = mutation.mutate;
  mutation.mutate = (req, options) => {
    options = options || {};
    const { onSuccess, onError } = options;
    options.onSuccess = (res, variables, context) => {
      console.log(res, variables, context);
      if (onSuccess) {
        onSuccess(res, variables, context);
      }
    };
    options.onError = (e, variables, context) => {
      const error = e as unknown as {
        response: { data: { details: DuplicatePerson; code: string } };
      };
      const errorCode = error?.response?.data?.code;
      const errorDetails = error?.response?.data?.details;
      console.log("err", errorCode, errorDetails);
      if (onError) {
        onError(e, variables, context);
      }
    };
    mutateFn(req, options);
  };
  return mutation;
};

export const adminUserEdit = () => {
  const mutation = useAdminUserUserIdPatch();
  const mutateFn = mutation.mutate;
  mutation.mutate = (req, options) => {
    options = options || {};
    const { onSuccess, onError } = options;
    options.onSuccess = (res, variables, context) => {
      console.log(res, variables, context);
      if (onSuccess) {
        onSuccess(res, variables, context);
      }
    };
    options.onError = (e, variables, context) => {
      const error = e as unknown as {
        response: { data: { details: DuplicatePerson; code: string } };
      };
      const errorCode = error?.response?.data?.code;
      const errorDetails = error?.response?.data?.details;
      console.log("err", errorCode, errorDetails);
      if (onError) {
        onError(e, variables, context);
      }
    };
    mutateFn(req, options);
  };
  return mutation;
};

export const getTrainingPrograms = (params, options) => {
  const query = useAdminTrainingProgramGet(params, options);
  const data =
    query?.data?.data?.trainingPrograms?.map((row) => {
      return {
        ...row,
        id: row.programId
      };
    }) || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};
export const getNotification = (params, options) => {
  const query = useAdminNotificationGet(params, options);
  const data =
    query?.data?.data?.map((row) => {
      return {
        ...row
      };
    }) || [];
  return { ...query, data };
};
export const getUnreadNotificationCount = (params, options) => {
  const query = useAdminNotificationUnreadGet(params, options);
  const unreadCount = query?.data?.data || "0";
  return { ...query, unreadCount };
};
export const deleteNotification = () => {
  const mutation = useAdminNotificationNotificationIdDelete();
  const mutateFn = mutation.mutate;

  mutation.mutate = (req, options) => {
    options = options || {};
    const { onSuccess, onError } = options;
    options.onSuccess = (res, variables, context) => {
      if (onSuccess) {
        onSuccess(res, variables, context);
      }
    };
    options.onError = (e, variables, context) => {
      if (onError) {
        onError(e, variables, context);
      }
    };

    mutateFn(req, options);
  };
  return mutation;
};

export const getOrganizationUploads = (params, options) => {
  const query = useAdminUploadGet(params, options);
  const data =
    query?.data?.data?.uploads?.map((upload) => ({
      id: upload.uploadId,
      createdAt: format(
        parseISO(upload.createdAt as unknown as string),
        "MMM d, yyyy"
      ),
      ...upload
    })) || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getLiveStreams = (params, options) => {
  const query = useAdminLiveStreamGet(params, options);
  const data = query?.data?.data.liveStreams || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getAdvertisers = (params, options) => {
  const query = useAdvertiserGet(params, options);
  const data = query?.data?.data.advertisers || [];
  return { ...query, data };
};

export const getSponsorshipLevel = (params, options) => {
  const query = useSponsorshipLevelGet(params, options);
  const data = query?.data?.data.sponsorshipLevels || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getSportLocations = (params, options) => {
  const query = useAdminSportLocationGet(params, options);
  const data = query?.data?.data.locations || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getCalendarEvent = (eventId: string, recurrenceId: string) => {
  if (eventId && recurrenceId) {
    const query = useAdminEventEventIdRecurrenceRecurrenceIdGet(
      eventId,
      recurrenceId
    );
    const data = query?.data?.data || [];
    return { ...query, data };
  }
  return undefined;
};

export const getFeatures = (params, options) => {
  const query = useAdminFeatureGet(
    {
      flatten: true,
      ...params
    },
    options
  );
  const data = query?.data?.data.features || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};
export const getFeatureTestcases = (params, options) => {
  const query = useAdminFeatureTestcaseGet(params, options);
  const data = query?.data?.data.testcases || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getHelpCategories = (params, options) => {
  const query = useAdminHelpCategoryGet(params, options);
  const data = query?.data?.data.helpCategory || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getFaqs = (params, options) => {
  const query = useAdminFaqGet(params, options);
  const data = query?.data?.data.faqs || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getHelpArticles = (params, options) => {
  const query = useAdminHelpArticleGet(params, options);
  const data = query?.data?.data.articles || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getDepartments = (params, options) => {
  const query = useAdminDepartmentGet(params, options);
  const data = query?.data?.data.departments || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};
export const getJobTitles = (params, options) => {
  const query = useAdminJobTitleGet(params, options);
  const data = query?.data?.data.jobTitles || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};
export const getDirectory = (params, options) => {
  const query = useAdminDirectoryGet(params, options);
  const data = query?.data?.data.persons || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getQAFeatures = (params, options) => {
  const query = useAdminQAFeatureGet(params, options);
  const data = query?.data?.data.features || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getQAFeatureTestcases = (params, options) => {
  const query = useAdminQAFeatureTestCaseGet(params, options);
  const data = query?.data?.data.testcases || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getReleases = (params, options) => {
  const query = useAdminReleaseGet(params, options);
  const data = query?.data?.data.releases || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getQATestcases = (params, options) => {
  const query = useAdminQAFeatureTestCaseGet(params, options);
  const data = query?.data?.data.testcases || [];
  const numRows = query?.data?.data?.numRows || 0;
  return { ...query, data, numRows };
};

export const getQaMetric = (params, options) => {
  const query = useAdminMetricQaGet(params, options);
  const data = query?.data?.data || [];
  return { ...query, data };
};

export const getAdminMetric = (params, options) => {
  const query = useAdminMetricGet(params, options);
  const data = query?.data?.data || [];
  return { ...query, data };
};
