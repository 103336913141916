import { TableViewInfinite } from "@components/TableViewInfinite";
import { getFeatureTestcases } from "@services/Network";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  organizationAtom,
  selectedFilterAtom,
  selectedPlatformAtom
} from "@recoil/auth";
import { hasPermission } from "@services/Casbin";

const renderNewlines = (text) => {
  return text.split("\n").map((line, index) => {
    return (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    );
  });
};

export const Testcases = () => {
  const navigate = useNavigate();
  const selectedPlatformValue = useRecoilValue(selectedPlatformAtom);
  console.log(selectedPlatformValue);
  const selectedFilterValue = useRecoilValue(selectedFilterAtom);
  const organizationId = useRecoilValue(organizationAtom);
  const [featureId, setFeatureId] = useState(selectedFilterValue);
  const [selectedPlatform, setSelectedPlatform] = useState(
    selectedPlatformValue === "IOS" || selectedPlatformValue === "AND"
      ? "MOB"
      : selectedPlatformValue || "MOB"
  );
  const [testcaseId, setTestcaseId] = useState("");
  const [viewOpen, setViewOpen] = useState(false);
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    view: false
  });
  const onAdd = () => {
    if (!featureId) navigate("/testcases/create");
    else navigate(`/testcases/${featureId}/create`);
  };

  const onEdit = (testcase) => {
    navigate(`/testcases/${testcase.testCaseId}/edit`);
  };
  const onView = (testcase) => {
    setTestcaseId(testcase.testCaseId);
    setViewOpen(true);
  };
  useEffect(() => {
    if (organizationId) navigate("/not-found");
  }, [organizationId]);
  useEffect(() => {
    if (viewOpen) {
      setViewOpen(false);
      const queryString = selectedPlatform
        ? `platform=${selectedPlatform}`
        : "";
      navigate(
        `/testcases/${testcaseId}${queryString ? `?${queryString}` : ""}`
      );
    }
  }, [viewOpen]);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("tech.test-cases", "ADD");
      const edit = await checkPermission("tech.test-cases", "EDIT");
      const del = await checkPermission("tech.test-cases", "VIEW");
      setPermissions({
        create,
        edit,
        view: del
      });
    };
    fetchPermissions();
  }, [organizationId]);
  const filterConfig = {
    field: "featureId",
    defaultValue: selectedFilterValue,
    placeholderOption: { label: "All", value: "" },
    options: []
  };

  const platformConfig = {
    field: "platform",
    defaultValue: "MOB",
    placeHolderOption: { label: "Mobile", value: "MOB" },
    options: [
      {
        label: "Mobile",
        value: "MOB"
      },
      {
        label: "Web",
        value: "WEB"
      }
    ]
  };

  const LIST_COLUMNS = [
    {
      headerName: "Test Case Description",
      field: "description",
      minWidth: 250,
      cellClassName: "multiple-line-cell",
      renderCell: (params) => (
        <div
          style={{
            padding: "20px 0",
            wordWrap: "break-word",
            wordBreak: "break-all"
          }}
        >
          {renderNewlines(params.value || "")}
        </div>
      )
    },
    {
      headerName: "Expected Result",
      field: "expectedResult",
      minWidth: 400,
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <div style={{ padding: "20px 0" }}>{renderNewlines(params.value)}</div>
      )
    },
    {
      headerName: "Feature",
      field: "feature.name",
      minWidth: 250,
      flex: 1,
      valueGetter: ({ row }) => row.feature.name
    },
    {
      headerName: "Is Active",
      field: "isActive",
      value: "date",
      minWidth: 115,
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) => (row.isActive ? "Yes" : "No")
    }
  ];
  return (
    <>
      <TableViewInfinite
        title="Test Cases"
        hideFilter
        useGet={getFeatureTestcases}
        isDeleteDisabled={() => true}
        columns={LIST_COLUMNS}
        searchable={false}
        customFilter="FEATURE"
        getRowId={(row) => row.testCaseId}
        getRowHeight={() => "auto"}
        hideFooter
        filterConfig={filterConfig}
        hideLabelContainer
        addBtnText="Add Test Case"
        onAdd={permissions.create ? onAdd : undefined}
        onEdit={onEdit}
        onView={onView}
        setId={setFeatureId}
        platformConfig={platformConfig}
        hidePlatformFilter={false}
        setSelectedPlatform={setSelectedPlatform}
        isEditDisabled={() => permissions.edit}
        platformId={selectedPlatform}
        sortingMode="client"
        includeChildren={true}
        disabledInfiniteScroll
      />
    </>
  );
};
