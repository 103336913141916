import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Toolbar,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useMediaBreakpointWidth } from "../hooks/useMediaBreakpointWidth";
import { Button } from "./Button";
import { FormInput } from "./FormInput";
import { FormSelect } from "./FormSelect";
import { FormToggleDays } from "./FormToggleDays";
import { useForm } from "react-hook-form";
import { Frequency, RRule, Weekday } from "rrule";
import { DevTool } from "@hookform/devtools";
const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    borderRadius: "24px",
    width: 750,
    height: 350,
    color: "#FFFF"
  }
}));

const ButtonContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  "& .MuiButtonBase-root": {
    width: "120px",
    marginRight: "16px"
  }
}));

interface CustomRecurrenceDialogProps {
  body?: string;
  cancelBtnText?: string;
  cancelBtnVariant?: string;
  close?: () => void;
  confirmBtnText?: string;
  confirmBtnVariant?: string;
  hideCancelBtn?: boolean;
  hideCloseIcon?: boolean;
  icon?: React.ReactNode | undefined;
  isConfirming?: boolean;
  onCancel?: () => void;
  onConfirm: (customText, interval, timeFrame, days) => Promise<void> | void;
  onOpen?: () => void;
  open: boolean;
  title: string;
  startDate?: Date;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customRepeatData?: any;
}

export const CustomRecurrenceDialog = ({
  cancelBtnText = "No",
  cancelBtnVariant = "admin-secondary",
  close,
  confirmBtnText = "Yes",
  confirmBtnVariant = "admin-primary",
  hideCancelBtn = false,
  isConfirming = false,
  onCancel,
  onConfirm,
  onOpen,
  open,
  title,
  startDate,
  customRepeatData
}: CustomRecurrenceDialogProps) => {
  console.log(startDate);
  const { control, setValue, getValues, reset } = useForm({
    mode: "onBlur",
    defaultValues: {
      repeatEvery: 1,
      timeFrame: "DAILY",
      repeatOn: `Every month on day ${
        startDate
          ? !isNaN(startDate.getDate())
            ? startDate.getDate()
            : ""
          : ""
      }`,
      days: [] as Weekday[]
    }
  });

  useEffect(() => {
    if (!customRepeatData) {
      setValue(
        "repeatOn",
        `Every ${
          getValues().repeatEvery > 1
            ? "" + getValues().repeatEvery + " months"
            : "month"
        } on day ${
          startDate
            ? !isNaN(startDate.getDate())
              ? startDate.getDate()
              : ""
            : ""
        }`
      );
    }
  }, [startDate]);
  const dialogBreakpoint = useMediaBreakpointWidth();

  const cancelClickHandler = () => {
    onCancel && onCancel();
    close && close();
  };

  const getShortDayName = (day) => {
    const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    return daysOfWeek[day];
  };
  const getDaysOfWeekText = (selectedDays): string => {
    selectedDays.sort((a, b) => a - b);
    if (selectedDays.length === 2) {
      if (selectedDays.includes(5) && selectedDays.includes(6)) {
        return "on weekends";
      }
      return `on ${getShortDayName(selectedDays[0])} and ${getShortDayName(
        selectedDays[1]
      )}`;
    } else if (selectedDays.length >= 3 && selectedDays.length < 7) {
      if (
        selectedDays.length === 5 &&
        selectedDays.every((day) => day >= 0 && day <= 4)
      ) {
        return "on weekdays";
      } else {
        const dayNames = selectedDays.map((day) => getShortDayName(day));
        const lastDay = dayNames.pop();
        return `on ${dayNames.join(", ")} and ${lastDay}`;
      }
    } else if (selectedDays.length === 7) {
      return "on all days";
    }
    return "";
  };
  const acceptClickHandler = async () => {
    const interval = getValues().repeatEvery;
    const timeFrame = getValues().timeFrame;
    const days: Weekday[] = getValues().days;
    console.log(days);
    let customText = "Repeats Every";
    switch (timeFrame) {
      case "DAILY":
        customText =
          customText + (interval > 1 ? " " + interval + " days" : " day");
        break;
      case "WEEKLY":
        customText =
          customText + (interval > 1 ? " " + interval + " weeks" : " week");
        customText =
          customText +
          (days.length > 1
            ? " " + getDaysOfWeekText(days.map((day) => day.weekday))
            : "");
        break;
      case "MONTHLY":
        customText =
          customText + (interval > 1 ? " " + interval + " months" : " month");
        break;
    }
    await onConfirm(customText, interval, timeFrame, days);
    close && close();
  };

  useEffect(() => {
    if (open) {
      onOpen && onOpen();
    }
  }, [open]);
  const [repeatOn, setRepeatOn] = useState("");
  const [isPlural, setPlural] = useState(false);
  const [disableWeekDay, setDisableWeekDay] = useState(true);

  useEffect(() => {
    if (customRepeatData) {
      const interval = !isNaN(customRepeatData.interval)
        ? parseInt(customRepeatData.interval)
        : 1;
      let freq = "DAILY";

      switch (customRepeatData.freq) {
        case Frequency.DAILY:
          freq = "DAILY";
          break;
        case Frequency.WEEKLY:
          freq = "WEEKLY";
          break;
        case Frequency.MONTHLY:
          freq = "MONTHLY";
          break;
      }
      const weekDays = [] as Weekday[];
      if (freq === "WEEKLY") {
        const rruleWeekdayMap = {
          6: RRule.SU,
          0: RRule.MO,
          1: RRule.TU,
          2: RRule.WE,
          3: RRule.TH,
          4: RRule.FR,
          5: RRule.SA
        };
        customRepeatData.byweekday?.map((wd) => {
          weekDays.push(RRule[rruleWeekdayMap[wd.weekday]]);
        });
      }
      const defaultValues = {
        repeatEvery: interval,
        timeFrame: freq,
        repeatOn: `Every ${
          interval > 1 ? "" + interval + " months" : "month"
        } on day ${
          startDate
            ? !isNaN(startDate.getDate())
              ? startDate.getDate()
              : ""
            : ""
        }`,
        days: weekDays
      };
      setRepeatOn(freq);
      if (interval > 1) setPlural(true);
      if (customRepeatData.byweekday?.length > 1) setDisableWeekDay(false);
      reset(defaultValues, {
        keepDirtyValues: false
      });
    }
  }, [customRepeatData]);
  useEffect(() => {
    if (!customRepeatData?.byweekday && repeatOn === "WEEKLY") {
      const dayOfWeek =
        startDate && !isNaN(startDate?.getDay()) ? startDate.getDay() : 1;
      const rruleWeekdayMap = {
        0: RRule.SU,
        1: RRule.MO,
        2: RRule.TU,
        3: RRule.WE,
        4: RRule.TH,
        5: RRule.FR,
        6: RRule.SA
      };
      const rruleWeekday = rruleWeekdayMap[dayOfWeek];
      const startDay = [RRule[rruleWeekday]];
      setValue("days", [...startDay]);
    }
  }, [repeatOn]);
  return (
    <StyledDialog open={open} maxWidth={dialogBreakpoint || "md"}>
      <Toolbar style={{ backgroundColor: "#4F46E5", height: 64 }}>
        <Typography
          sx={{
            flex: 1,
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "20px"
          }}
          variant="h6"
        >
          {title}
        </Typography>
        <IconButton
          edge="start"
          color="inherit"
          onClick={cancelClickHandler}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <Grid
        container
        spacing="25px"
        padding="20px"
        data-testid="PROGRAM_DATE_TIME_CUSTOM_CONTAINER"
      >
        <Grid item container direction="row" spacing="24px">
          <Grid item data-testid="PROGRAM_DATE_TIME_CUSTOM_NUMBER">
            <FormInput
              label="Repeat Every"
              control={control}
              name="repeatEvery"
              type="number"
              onChange={(e) => {
                if (parseInt(e.target.value) > 1) {
                  setPlural(true);
                } else {
                  setPlural(false);
                }
                if (parseInt(e.target.value) == 0) setValue("repeatEvery", 1);
                else setValue("repeatEvery", e.target.value);
                setValue(
                  "repeatOn",
                  `Every ${
                    getValues().repeatEvery > 1
                      ? "" + getValues().repeatEvery + " months"
                      : "month"
                  } on day ` + startDate?.getDate() || ""
                );
              }}
            />
          </Grid>
          <Grid
            item
            style={{ alignSelf: "flex-end" }}
            data-testid="PROGRAM_DATE_TIME_FREQUENCY"
          >
            <FormSelect
              name="timeFrame"
              control={control}
              options={[
                { label: isPlural ? "Days" : "Day", value: "DAILY" },
                { label: isPlural ? "Weeks" : "Week", value: "WEEKLY" },
                { label: isPlural ? "Months" : "Month", value: "MONTHLY" }
              ]}
              onChange={(e) => {
                setRepeatOn(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6} alignSelf="center">
            {repeatOn === "WEEKLY" && (
              <FormToggleDays
                name="days"
                control={control}
                disableSelected={disableWeekDay}
                label="Repeat On"
                onChange={(value) => {
                  setValue("days", value);
                  if (value.length > 1) {
                    setDisableWeekDay(false);
                  } else {
                    setDisableWeekDay(true);
                  }
                }}
              />
            )}
            {repeatOn === "MONTHLY" && (
              <FormInput
                label="Repeat On"
                control={control}
                name="repeatOn"
                type="text"
                disabled
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <DevTool control={control} />
      <ButtonContainer data-testid="PROGRAM_DATE_TIME_CUSTOM_BUTTONS">
        {!hideCancelBtn && (
          <Button variant={cancelBtnVariant} onClick={cancelClickHandler}>
            {cancelBtnText}
          </Button>
        )}
        <Button
          variant={confirmBtnVariant}
          onClick={acceptClickHandler}
          isLoading={isConfirming}
        >
          {confirmBtnText}
        </Button>
      </ButtonContainer>
    </StyledDialog>
  );
};
