import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import { useApiSelectOptions } from "@hooks/useApiSelectOptions";
import Grid from "@mui/material/Unstable_Grid2";
import {
  ModelGameSystem,
  ModelMedia,
  useAdminGameConceptGet,
  useAdminGameSystemSystemIdDelete,
  useAdminGameSystemSystemIdGet,
  useAdminPositionGet,
  useAdminSkillGet,
  useAdminSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Typography, FormLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import { organizationAtom } from "@recoil/auth";
import { useRecoilState } from "recoil";
import { hasPermission } from "@services/Casbin";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { useSnackbar } from "notistack";
import ReactPlayer from "react-player";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

const StyledGrid = styled(Grid)(() => ({
  "& video": {
    objectFit: "cover"
  }
}));

export const GameSystemView = () => {
  const navigate = useNavigate();
  const { systemId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [toDelete, setToDelete] = useState<ModelGameSystem | null>(null);
  const [permissions, setPermissions] = useState({
    delete: false
  });

  const [positions] = useState("");
  const [skills] = useState("");
  const [gameConcepts] = useState("");
  const [files, setFiles] = useState<ModelMedia[]>([]);
  const [organizationId] = useRecoilState(organizationAtom);

  const {
    data: gameSystem,
    isFetching: isGameSystemFetching,
    error: error
  } = useAdminGameSystemSystemIdGet(systemId as string);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error]);
  const { data: sports, isLoading: isSportLoading } = useAdminSportGet({
    organizationId: organizationId!
  });
  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId!
      })) || [],
    [sports]
  );
  const { options: positionOptions, isLoading: positionOptionsLoading } =
    useApiSelectOptions({
      api: useAdminPositionGet,
      dataField: "positions",
      labelField: "name",
      valueField: "positionId",
      params: {
        sportId: positions || gameSystem?.data?.sportId
      },
      options: {
        query: {
          enabled: !!positions || !!gameSystem?.data?.sportId
        }
      }
    });

  const { options: skillOptions, isLoading: skillOptionsLoading } =
    useApiSelectOptions({
      api: useAdminSkillGet,
      dataField: "skills",
      labelField: "name",
      valueField: "skillId",
      params: {
        sportId: skills || gameSystem?.data?.sportId
      },
      options: {
        query: {
          enabled: !!skills || !!gameSystem?.data?.sportId
        }
      }
    });
  const { options: gameConceptOptions, isLoading: gameConceptOptionsLoading } =
    useApiSelectOptions({
      api: useAdminGameConceptGet,
      dataField: "gameConcepts",
      labelField: "name",
      valueField: "conceptId",
      params: {
        sportId: gameConcepts || gameSystem?.data?.sportId
      },
      options: {
        query: {
          enabled: !!gameConcepts || !!gameSystem?.data?.sportId
        }
      }
    });

  const { control, reset } = useForm();

  useEffect(() => {
    if (!isGameSystemFetching && gameSystem?.data) {
      reset(
        {
          name: gameSystem?.data?.name,
          description: gameSystem?.data?.description,
          sportId: gameSystem?.data?.sportId,
          positions: gameSystem?.data?.positions?.map(
            (position) => position.positionId
          ),
          skills: gameSystem?.data?.skills?.map((skill) => skill.skillId),
          gameConcepts: gameSystem?.data?.gameConcepts?.map(
            (gameConcept) => gameConcept.conceptId
          )
        },
        {
          keepDirtyValues: true
        }
      );
      setFiles(gameSystem?.data?.media || []);
    }
  }, [gameSystem]);

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminGameSystemSystemIdDelete();

  const onConfirmDelete = async () => {
    if (!toDelete?.systemId) return;
    try {
      await deleteAsync({ systemId: toDelete.systemId });
      enqueueSnackbar("Game System deleted successfully", {
        variant: "success"
      });
      setToDelete(null);
      navigate("/game-systems");
    } catch (error) {
      enqueueSnackbar(
        "Something went wrong! Unable to delete the game system.",
        {
          variant: "error"
        }
      );
      setToDelete(null);
    }
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission("admin.game-systems", "DELETE");
      setPermissions({
        delete: del
      });
    };
    fetchPermissions();
  }, []);
  return (
    <Container>
      <Toolbar
        title="View Game System"
        backBtnClick={() => navigate("/game-systems")}
        editBtnClick={() => navigate(`/game-systems/${systemId}/edit`)}
        {...(permissions.delete && {
          deleteBtnClick: () => setToDelete(gameSystem!.data)
        })}
      />
      <Form>
        <Loader
          isLoading={
            isGameSystemFetching ||
            skillOptionsLoading ||
            positionOptionsLoading ||
            gameConceptOptionsLoading ||
            isSportLoading
          }
        >
          <Grid container spacing={3}>
            <Grid data-testid="gameSystem-name" xs={12} md={6}>
              <FormInput
                control={control}
                name="name"
                type="text"
                label="Name"
                required={true}
                disabled={true}
                rules={{
                  required: "Name is required"
                }}
              />
            </Grid>
            <Grid data-testid="gameSystem-sport" xs={12} md={6}>
              <FormSelect
                control={control}
                name="sportId"
                label="Sport"
                required={true}
                options={sportOptions}
                disabled={true}
                rules={{
                  required: "Sport is required"
                }}
              />
            </Grid>
            <Grid data-testid="gameSystem-description" xs={12} md={6}>
              <FormInput
                control={control}
                name="description"
                type="text"
                label="Description"
                required={true}
                multiline={true}
                disabled={true}
                rules={{
                  required: "Description is required"
                }}
              />
            </Grid>
            <Grid data-testid="gameSystem-position" xs={12} md={6}>
              <FormMultiSelect
                control={control}
                name="positions"
                label="Positions"
                value="positions"
                required={false}
                disabled={true}
                options={positionOptions}
              />
            </Grid>
            <Grid data-testid="gameSystem-skill" xs={12} md={6}>
              <FormMultiSelect
                control={control}
                name="skills"
                label="Skills"
                required={false}
                value="skills"
                disabled={true}
                options={skillOptions}
              />
            </Grid>
            <Grid data-testid="gameSystem-gameConcepts" xs={12} md={6}>
              <FormMultiSelect
                control={control}
                name="gameConcepts"
                label="Game Concepts"
                value="gameConcepts"
                required={false}
                disabled={true}
                options={gameConceptOptions}
              />
            </Grid>
            {files.length > 0 && (
              <Grid xs={12} md={12}>
                <StyledFormLabel>
                  <Typography display="inline" variant="formLabel">
                    {"Photo/Video Content"}
                  </Typography>
                </StyledFormLabel>
              </Grid>
            )}
            {files.map((item) => {
              return (
                <StyledGrid
                  xs={12}
                  md={2}
                  key={Math.random().toString()}
                  data-testid="gameSystem-media"
                >
                  {item?.mimeType?.startsWith("image") ? (
                    <img
                      src={`${item.baseUrl}${item?.path}`}
                      style={{
                        width: "190px",
                        height: "190px"
                      }}
                    />
                  ) : (
                    <ReactPlayer
                      playing={true}
                      height={"190px"}
                      width={"190px"}
                      url={`${item?.baseUrl}${item?.path}`}
                      controls
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%"
                      }}
                    />
                  )}
                </StyledGrid>
              );
            })}
          </Grid>
        </Loader>
      </Form>
      <ConfirmationDialog
        open={!!toDelete}
        title="Delete Game System"
        body={`Are you sure you want to delete ${toDelete?.name}?`}
        close={() => setToDelete(null)}
        onConfirm={onConfirmDelete}
        onCancel={() => setToDelete(null)}
        isConfirming={isDeleting}
        confirmBtnVariant="admin-warning"
        icon="warning"
      />
    </Container>
  );
};
