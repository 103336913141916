/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "@components/Button";
import { FormSelect } from "@components/FormSelect";
import { SearchInput } from "@components/SearchInput";
import {
  Delete,
  Edit,
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  Visibility
} from "@mui/icons-material";
import { Box, IconButton, Toolbar, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { styled } from "@mui/material/styles";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridRenderCellParams,
  GridRowIdGetter,
  GridRowOrderChangeParams,
  GridSortModel,
  GridValidRowModel,
  useGridApiRef
} from "@mui/x-data-grid-pro";
import React, { useEffect } from "react";
import { AddIcon, RowOrderingIcon } from "./Icons";
import { Container } from "./crud/Container";
import { Footer } from "./crud/Footer";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { organizationAtom, selectedPaginationAtom } from "../recoil/auth";
import { useLocation, useNavigate } from "react-router-dom";

const StyledToolbar = styled(Toolbar)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "2rem 0",

  "& h2": {
    fontSize: "32px",
    fontWeight: 300
  }
}));

const AddBtn = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "8px 20px",
  gap: "8px",
  width: "100px",
  height: "40px",
  backgroundColor: theme.palette.info.main,
  borderRadius: "100px",
  color: theme.palette.white.main,
  textTransform: "none",

  "&:hover": {
    backgroundColor: theme.palette.info.main,
    opacity: 0.8
  }
}));

const PaginationActionRow = styled("div")`
  align-items: center;
  flex-direction: row;
  display: flex;
  margin-left: 1rem;
`;

const PaginationButton = styled(IconButton)`
  font-size: 34px;
`;

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  "&.table-view": {
    borderRadius: "0px",

    "& .MuiDataGrid-pinnedColumns": {
      backgroundColor: theme.palette.white.main,
      boxShadow: "inset 0px 2px 0px rgba(0, 0, 0, .08)",
      marginLeft: "5px",

      "& .MuiDataGrid-cell": {
        borderBottom: "none",

        ":first-of-type": {
          padding: "0 3px"
        },

        ":nth-of-type(2)": {
          "&.action-cell": {
            padding: "0 10px"
          },
          padding: "0 14px"
        },

        ":nth-of-type(3)": {
          padding: "0 14px 0 18px"
        }
      }
    },

    "& .MuiDataGrid-pinnedColumnHeaders": {
      ...theme.typography.body1,
      "& .MuiDataGrid-columnHeader--sortable": {
        color: theme.palette.blue.main
      },
      backgroundColor: theme.palette.white.secondary,
      fontWeight: 600,
      boxShadow: "none",

      "& .MuiDataGrid-columnHeader": {
        ":first-of-type": {
          padding: "0 7px"
        }
      }
    },

    "& .MuiDataGrid-columnHeaders": {
      ...theme.typography.body1,
      "& .MuiDataGrid-columnHeader--sortable": {
        color: theme.palette.blue.main
      },
      backgroundColor: theme.palette.white.secondary,
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.15)",
      borderTop: `1px solid ${theme.palette.divider}`,
      borderRadius: "0px"
    },

    "& .MuiDataGrid-columnHeader": {
      "&. MuiDataGrid-columnHeaderTitle": {
        fontWeight: 600
      },

      ":first-of-type": {
        padding: "0 10px 0 14px"
      },

      "& .MuiDataGrid-columnSeparator": {
        visibility: "hidden"
      },

      "&:focus": {
        outline: "none"
      },
      "&:focus-within": {
        outline: "none"
      }
    },

    "& .MuiDataGrid-row": {
      boxShadow: "inset 0px -1px 0px #E2E8F0"
    },

    "& .MuiDataGrid-rowReorderCellContainer": {
      ":first-of-type": {
        padding: "0 0 0 14px !important"
      }
    },

    "& .MuiDataGrid-cell": {
      borderBottom: "none",

      ":first-of-type": {
        padding: "0 10px 0 14px"
      },

      "&:focus": {
        outline: "none"
      },
      "&:focus-within": {
        outline: "none"
      }
    },

    "& .MuiDataGrid-footerContainer": {
      justifyContent: "flex-start",
      backgroundColor: theme.palette.white.secondary
    },
    "& .MuiDataGrid-overlayWrapper": {
      fontSize: "16px",
      fontWeight: "600"
    },
    "& .multiple-line-cell": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center"
    }
  }
}));

const StyledGridOverlay = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  background: "#fff"
}));

const TablePaginationActions = ({
  count,
  page,
  rowsPerPage,
  onPageChange
}: {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}) => {
  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <PaginationActionRow>
      <PaginationButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPage fontSize="inherit" />
      </PaginationButton>
      <PaginationButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft fontSize="inherit" />
      </PaginationButton>
      <PaginationButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight fontSize="inherit" />
      </PaginationButton>
      <PaginationButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPage fontSize="inherit" />
      </PaginationButton>
    </PaginationActionRow>
  );
};

const DEFAULT_PAGE_SIZE = 10;
const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];

type FilterOption = {
  label: string;
  value?: string;
  children?: FilterOption[];
};

interface TableViewProps extends Omit<DataGridProProps, "rows"> {
  columns: any[];
  customActions?: any[];
  defaultSort?: GridSortModel;
  filterConfig?: {
    field: string;
    placeholderOption?: {
      value: string;
      label: string;
    };
    options: FilterOption[];
    defaultValue?: string;
    label?: string;
  };
  getRowId?: GridRowIdGetter<GridValidRowModel>;
  hasActionColumn?: boolean;
  hideFilter?: boolean;
  hideFooter?: boolean;
  hideLabelContainer?: boolean;
  hideSortButton?: boolean;
  hideSubmitFooter?: boolean;
  hideToolbar?: boolean;
  isDeleteDisabled?: (row: unknown) => boolean;
  isEditDisabled?: (row: never) => boolean;
  isSubmitting?: boolean;
  onAdd?: () => void;
  onCancel?: () => void;
  onDelete?: (row: GridRenderCellParams) => void;
  onEdit?: (row: GridRenderCellParams) => void;
  onFilterChange?: (value: string) => void;
  onRowOrderChange?: (params: GridRowOrderChangeParams) => void;
  onSortButtonClick?: () => void;
  onSubmit?: () => void;
  onView?: (row: GridRenderCellParams) => void;
  refreshKey?: number;
  rowReordering?: boolean;
  rows?: readonly any[];
  searchable?: boolean;
  title: string;
  useGet: (params, options) => any;
  params?: any;
  options?: any;
  formSelectType: string;
  backButton: boolean;
  action?: string;
  sortingMode?: "client" | "server";
  setAddLimit?: number | undefined;
  setRows?: any;
}

export const TableView = ({
  columns,
  customActions,
  defaultSort,
  filterConfig,
  getRowId,
  hasActionColumn,
  hideFilter,
  hideFooter,
  hideLabelContainer,
  hideSortButton,
  hideSubmitFooter,
  hideToolbar,
  isDeleteDisabled,
  isEditDisabled,
  isSubmitting,
  onAdd,
  onCancel,
  onDelete,
  onEdit,
  onFilterChange,
  onRowOrderChange,
  onSortButtonClick,
  onSubmit,
  onView,
  refreshKey,
  rowReordering,
  rows,
  searchable,
  title,
  useGet,
  formSelectType,
  backButton,
  action,
  sortingMode,
  setAddLimit,
  setRows,
  ...dataGridProps
}: TableViewProps) => {
  const organizationId = useRecoilValue(organizationAtom);
  const location = useLocation();
  const pageName = location.pathname.split("/")[1];
  const setPagination = useSetRecoilState(selectedPaginationAtom);
  const pagination = useRecoilValue(selectedPaginationAtom) || [];
  const [filter, setFilter] = React.useState(
    filterConfig?.defaultValue || filterConfig?.placeholderOption?.value || ""
  );
  const [pageSize, setPageSize] = React.useState(
    pagination?.find(
      (item) =>
        item.page === `${organizationId || "sportsgravy"}_${pageName}_${filter}`
    )?.pageSize || DEFAULT_PAGE_SIZE
  );
  const [pageNo, setPageNo] = React.useState(
    pagination?.find(
      (item) =>
        item.page === `${organizationId || "sportsgravy"}_${pageName}_${filter}`
    )?.pageNo || 0
  );
  const [textSearch, setTextSearch] = React.useState("");
  const [sortModel, setSortModel] = React.useState<GridSortModel>(
    defaultSort || []
  );
  const gridApiRef = useGridApiRef();

  React.useLayoutEffect(() => {
    if (gridApiRef.current) {
      gridApiRef.current.resetRowHeights();
    }
  }, [gridApiRef]);

  const query = React.useMemo(() => {
    const buildQuery = {
      pageNo,
      pageSize,
      organizationId: organizationId,
      action
    } as {
      pageNo: number;
      pageSize: number;
      sortField?: string;
      sortDirection?: string;
      textSearch?: string;
      organizationId?: string;
      action?: string;
    };

    if (filterConfig && filter !== filterConfig?.placeholderOption?.value) {
      buildQuery[filterConfig?.field] = filter;
    }

    if (textSearch) {
      buildQuery.textSearch = textSearch;
    }

    if (sortModel?.length && sortModel[0].sort && sortModel[0].field) {
      buildQuery.sortField = sortModel[0].field;
      buildQuery.sortDirection = sortModel[0].sort;
    }

    return buildQuery;
  }, [pageNo, pageSize, filter, textSearch, sortModel]);

  if (!dataGridProps?.initialState) {
    dataGridProps.initialState = {};
  }

  const { isFetching, data, numRows, refetch } = useGet(query, {
    query: {
      queryKey: [pageSize, pageNo, filter, textSearch, sortModel, refreshKey],
      refetchOnWindowFocus: false
    }
  });
  useEffect(() => {
    refetch();
  }, [query]);

  useEffect(() => {
    if (data) {
      if (setRows) setRows(data);
    }
  }, [data]);
  useEffect(() => {
    setPageNo(
      pagination?.find(
        (item) =>
          item.page ===
          `${organizationId || "sportsgravy"}_${pageName}_${filter}`
      )?.pageNo || 0
    );
    setPageSize(
      pagination?.find(
        (item) =>
          item.page ===
          `${organizationId || "sportsgravy"}_${pageName}_${filter}`
      )?.pageSize || DEFAULT_PAGE_SIZE
    );
  }, [filter]);
  const actionColumn: GridColDef = React.useMemo(
    () => ({
      field: "action",
      headerName: "Actions",
      headerClassName: "action-header",
      cellClassName: "action-cell",
      minWidth: 150,
      flex: 0.12,
      sortable: false,
      renderCell: (params) => {
        const deleteDisabled = isDeleteDisabled
          ? isDeleteDisabled(params.row)
          : true;
        const editDisabled = isEditDisabled
          ? isEditDisabled(params.row as never)
          : false;
        return (
          <Box>
            {onView && (
              <IconButton
                data-testid="onViewTable"
                onClick={() => onView(params.row)}
              >
                <Visibility />
              </IconButton>
            )}
            {onEdit && !editDisabled && (
              <IconButton
                data-testid="onEditTable"
                onClick={() => onEdit(params.row)}
                disabled={editDisabled}
              >
                <Edit />
              </IconButton>
            )}
            {customActions?.map((action, index) => action(params, index))}
            {onDelete && !deleteDisabled && (
              <IconButton
                data-testid="onDeleteTable"
                onClick={() => onDelete(params.row)}
                disabled={deleteDisabled}
              >
                <Delete />
              </IconButton>
            )}
          </Box>
        );
      }
    }),
    []
  );

  const onSortModelChange = (model) => {
    setSortModel(model);
  };

  const addClickHandler = () => {
    onAdd && onAdd();
  };

  const onFilter = (e) => {
    setFilter(e.target.value);
    onFilterChange && onFilterChange(e.target.value);
  };

  const saveClickHandler = () => {
    if (onSubmit) {
      onSubmit();
    }
  };
  const navigate = useNavigate();
  const handleBackToReportsClick = () => {
    navigate(`/reports`);
  };
  const SelectFieldOptions = React.useMemo(() => {
    const placeholderOption = filterConfig?.placeholderOption
      ? [filterConfig?.placeholderOption]
      : [];
    return [...placeholderOption, ...(filterConfig?.options || [])];
  }, [columns, filterConfig]);

  const gridColumns = React.useMemo(() => {
    const newColumns: GridColDef[] = [];
    if (!rowReordering) {
      newColumns.push({
        field: "_",
        headerName: "",
        sortable: false,
        resizable: false,
        valueGetter: () => "",
        minWidth: 0,
        maxWidth: 0,
        width: 0
      });
    }
    if (hasActionColumn) {
      newColumns.push(actionColumn);
    }
    newColumns.push(...columns);

    return newColumns;
  }, [columns, hasActionColumn]);

  if (!dataGridProps?.initialState?.pinnedColumns && !rowReordering) {
    // automatically pin the first column or first two columns if the first column is an action column
    dataGridProps.initialState.pinnedColumns = {
      left: gridColumns
        .slice(0, hasActionColumn ? 3 : 2)
        .map((column) => column.field)
    };
  }
  if (dataGridProps?.pinnedColumns?.left && !rowReordering) {
    dataGridProps.initialState.pinnedColumns = {
      left: gridColumns
        .slice(0, dataGridProps.pinnedColumns.left.length + 1)
        .map((column) => column.field)
    };
    delete dataGridProps.pinnedColumns;
  }
  function noRowsOverlay() {
    const overlay = document.querySelector(".MuiDataGrid-overlayWrapper");
    if (overlay) {
      //@ts-ignore
      overlay.style.height = "200px";
      //@ts-ignore
      overlay.style.marginTop = "20px";
    }

    return (
      <StyledGridOverlay data-testid={"noRecordExist"}>
        <Box>No records exist</Box>
      </StyledGridOverlay>
    );
  }
  return (
    <Container>
      {!hideToolbar && (
        <>
          <StyledToolbar>
            <Grid container flexGrow={1} alignItems={"center"} rowSpacing={2}>
              <Grid
                xs={12}
                md={"auto"}
                sx={{ flexGrow: { md: "1 !important" } }}
              >
                <Typography variant="h2">{title}</Typography>
              </Grid>
              {backButton && (
                <Grid sx={{ textAlign: "-webkit-right" }} xs={6}>
                  <Button
                    onClick={handleBackToReportsClick}
                    variant="admin-secondary"
                  >
                    Back to Reports
                  </Button>
                </Grid>
              )}
              <Grid
                xs={12}
                md={"auto"}
                container
                justifyContent={"flex-end"}
                alignItems={"center"}
                spacing={2}
              >
                {!isFetching &&
                  onAdd &&
                  (setAddLimit && numRows >= setAddLimit ? false : true) && (
                    <Grid>
                      <AddBtn
                        variant="admin-primary"
                        onClick={addClickHandler}
                        startIcon={<AddIcon sx={{ marginTop: "1.5px" }} />}
                      >
                        Add
                      </AddBtn>
                    </Grid>
                  )}
                {!hideSortButton && (
                  <Grid>
                    <Button variant="admin-primary" onClick={onSortButtonClick}>
                      Sort
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </StyledToolbar>
          {(!hideFilter || searchable) && (
            <StyledToolbar style={{ paddingTop: 0, marginTop: "-10px" }}>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing="20px"
                width="100%"
              >
                {!hideLabelContainer && (
                  <Grid style={{ marginRight: "-10px" }}>
                    <Typography variant="gridToolbarFilterLabel">
                      {filterConfig?.label || formSelectType}
                    </Typography>
                  </Grid>
                )}
                {!hideFilter && (
                  <Grid data-testid={"tableViewFilterConfig"}>
                    <FormSelect
                      name="view"
                      options={SelectFieldOptions}
                      value={filter}
                      onChange={onFilter}
                      sx={{
                        minWidth: 250
                      }}
                    />
                  </Grid>
                )}
                {searchable && (
                  <Grid flex={1}>
                    <SearchInput
                      placeholder="Search"
                      onChange={(event) => setTextSearch(event.target.value)}
                      sx={{
                        minWidth: 250,
                        height: "53px"
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </StyledToolbar>
          )}
        </>
      )}
      <StyledDataGrid
        data-testid={"tableViewDataGrid"}
        apiRef={gridApiRef}
        className="table-view"
        rows={rows || data}
        columns={gridColumns}
        loading={isFetching}
        onPaginationModelChange={({ pageSize, page }) => {
          setPageSize(pageSize);
          setPageNo(page);

          const updatedPagination = pagination.map((item) =>
            item.page ===
            `${organizationId || "sportsgravy"}_${pageName}_${filter}`
              ? { ...item, pageSize, pageNo: page }
              : item
          );

          const isPaginationAlreadyPresent = updatedPagination.find(
            (item) =>
              item.page ===
              `${organizationId || "sportsgravy"}_${pageName}_${filter}`
          );

          if (!isPaginationAlreadyPresent) {
            updatedPagination.push({
              page: `${organizationId || "sportsgravy"}_${pageName}_${filter}`,
              pageSize,
              pageNo: page
            });
          }
          if (
            JSON.stringify(pagination) !== JSON.stringify(updatedPagination)
          ) {
            setPagination(updatedPagination);
          }
        }}
        pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
        pagination
        paginationModel={{
          page: pageNo,
          pageSize
        }}
        paginationMode="server"
        components={{
          RowReorderIcon: RowOrderingIcon
        }}
        componentsProps={{
          pagination: {
            ActionsComponent: TablePaginationActions
          }
        }}
        rowCount={numRows}
        disableColumnMenu
        onSortModelChange={onSortModelChange}
        sortingMode={sortingMode}
        getRowId={getRowId}
        hideFooter={hideFooter}
        rowReordering={rowReordering}
        onRowOrderChange={onRowOrderChange}
        {...dataGridProps}
        {...(!isFetching &&
          data &&
          data.length == 0 && {
            slots: {
              noRowsOverlay: noRowsOverlay,
              noResultsOverlay: noRowsOverlay
            }
          })}
        hideFooterPagination={data?.length == 0}
      />
      {!hideSubmitFooter && (
        <Footer
          cancelBtnClick={onCancel}
          saveBtnClick={saveClickHandler}
          isLoading={!!isSubmitting}
          isDisabled={!!isSubmitting}
        />
      )}
    </Container>
  );
};

TableView.defaultProps = {
  isDeleteDisabled: () => false,
  hasActionColumn: true,
  searchable: true,
  hideFilter: false,
  hideFooter: false,
  hideSubmitFooter: true,
  rowReordering: false,
  isSubmitting: false,
  hideSortButton: true,
  formSelectType: "View",
  backButton: false,
  sortingMode: "server",
  setAddLimit: undefined
};
