import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  useCannedMessageMessageIdGet,
  useCannedMessageMessageIdPut,
  cannedMessageWrapperGet,
  Gender,
  useAdminSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "../../recoil/auth";

export const CannedMessageEdit = () => {
  const navigate = useNavigate();
  const { messageId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const organizationId = useRecoilValue(organizationAtom);
  const [positionOptions, setPositionOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [conceptOptions, setConceptOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [levelsOptions, setLevelsOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [skillsOptions, setSkillsOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [systemsOptions, setSystemsOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [roleOptions, setRoleOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [genderOptions] = useState<{ label: string; value: string }[]>([
    { label: Gender.FEMALE, value: Gender.FEMALE },
    { label: Gender.MALE, value: Gender.MALE }
  ]);

  const {
    data: cannedMessage,
    isFetching: isMessageFetching,
    error: error
  } = useCannedMessageMessageIdGet(messageId as string);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error]);
  const { data: sports, isLoading: isSportLoading } = useAdminSportGet({
    organizationId: organizationId!
  });
  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId
      })) || [],
    [sports]
  );
  useEffect(() => {
    if (!cannedMessage?.data?.sportId) {
      return;
    }
    loadOptions(cannedMessage?.data?.sportId);
  }, [cannedMessage]);

  const loadOptions = async (inputValue) => {
    const data = await cannedMessageWrapperGet({
      sportId: inputValue as string,
      organizationId
    });

    setPositionOptions([
      ...(data.data.positions?.map((position) => ({
        label: position.name!,
        value: position.positionId!
      })) || [])
    ]);
    setConceptOptions([
      ...(data.data.concepts?.map((concepts) => ({
        label: concepts.name!,
        value: concepts.conceptId!
      })) || [])
    ]);
    setLevelsOptions([
      ...(data.data.levels?.map((levels) => ({
        label: levels.name!,
        value: levels.levelId!
      })) || [])
    ]);
    setSkillsOptions([
      ...(data.data.skills?.map((skills) => ({
        label: skills.name!,
        value: skills.skillId!
      })) || [])
    ]);
    setSystemsOptions([
      ...(data.data.systems?.map((systems) => ({
        label: systems.name!,
        value: systems.systemId!
      })) || [])
    ]);
    setRoleOptions([
      ...(data.data.roles?.map((roles) => ({
        label: roles.name!,
        value: roles.roleId!
      })) || [])
    ]);
  };

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
    setValue,
    reset
  } = useForm({
    mode: "onBlur"
  });

  useEffect(
    () =>
      reset(
        {
          title: cannedMessage?.data?.title,
          message: cannedMessage?.data?.message,
          genders: cannedMessage?.data?.genders,
          sportId: cannedMessage?.data?.sportId,
          positions: cannedMessage?.data?.positions?.map(
            (position) => position.positionId
          ),
          levels: cannedMessage?.data?.levels?.map((level) => level.levelId),
          concepts: cannedMessage?.data?.concepts?.map(
            (concept) => concept.conceptId
          ),
          skills: cannedMessage?.data?.skills?.map((skill) => skill.skillId),
          systems: cannedMessage?.data?.systems?.map(
            (system) => system.systemId
          ),
          roles: cannedMessage?.data?.roles?.map((role) => role.roleId)
        },
        {
          keepDirtyValues: true
        }
      ),
    [
      cannedMessage,
      positionOptions,
      sportOptions,
      conceptOptions,
      skillsOptions,
      levelsOptions,
      systemsOptions,
      roleOptions
    ]
  );

  const { mutate: save, isLoading: isSaving } = useCannedMessageMessageIdPut();
  const saveHandler = (formValues) => {
    const values = {
      ...formValues
    };

    save(
      {
        messageId: messageId as string,
        data: values
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Canned message saved successfully!", {
            variant: "success"
          });
          navigate(`/canned-messages/${messageId}`);
        },
        onError: () => {
          enqueueSnackbar("Failed to save canned message!", {
            variant: "error"
          });
        }
      }
    );
  };

  return (
    <Container>
      <Toolbar title="Edit Canned Message" />
      <Form>
        <Loader isLoading={isSportLoading || isMessageFetching}>
          <Grid container spacing={3}>
            <Grid xs={12} md={12} data-testid="EDIT_CANNED_MESSAGE">
              <FormInput
                control={control}
                name="message"
                type="text"
                label="Message"
                required={true}
                rules={{
                  required: "Message is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={12}>
              <FormInput
                control={control}
                name="title"
                type="text"
                label="Title"
                required={true}
                rules={{
                  required: "Title is required"
                }}
              />
            </Grid>
            <Grid container xs={12} md={12}>
              <Grid xs={12} md={6} data-testid="CANNED_EDIT_SPORT">
                <FormSelect
                  control={control}
                  name="sportId"
                  label="Sport"
                  required={true}
                  options={sportOptions}
                  disabled={sportOptions.length === 1}
                  isLoading={isSportLoading}
                  onChange={(e) => {
                    setValue("positions", []);
                    loadOptions(e.target.value);
                  }}
                  rules={{
                    required: "Sport is required"
                  }}
                />
              </Grid>
            </Grid>

            <Grid xs={12} md={6}>
              <FormMultiSelect
                control={control}
                name="genders"
                label="Genders"
                required={false}
                options={genderOptions}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <FormMultiSelect
                control={control}
                name="levels"
                label="Levels"
                required={false}
                options={levelsOptions}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <FormMultiSelect
                control={control}
                name="positions"
                label="Positions"
                required={false}
                options={positionOptions}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <FormMultiSelect
                control={control}
                name="skills"
                label="Skills"
                required={false}
                options={skillsOptions}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <FormMultiSelect
                control={control}
                name="concepts"
                label="Concepts"
                required={false}
                options={conceptOptions}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <FormMultiSelect
                control={control}
                name="systems"
                label="Systems"
                required={false}
                options={systemsOptions}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <FormMultiSelect
                control={control}
                name="roles"
                label="Roles"
                required={false}
                options={roleOptions}
              />
            </Grid>
          </Grid>
        </Loader>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler)}
        isDisabled={!isValid || isSaving || !isDirty}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/canned-messages")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
