/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoadingSpinner } from "@components/LoadingSpinner";
import {
  Box,
  Chip,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Controller } from "react-hook-form";

const FormSelectContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  min-height: 44px;
`;

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

const LoadingContainer = styled(Box)`
  min-height: 44px;
  display: flex;
  align-items: center;
`;

const ErrorContainer = styled(Box)(({ theme }) => ({
  color: theme.palette.error.main,
  position: "absolute",
  top: "100%",
  left: 0,
  margin: 0,
  fontFamily: "Inter var, sans-serif",
  fontSize: "12px",
  fontWeight: 500,
  height: "30px"
}));

const StyledChip = styled(Chip)(() => ({
  "&.MuiChip-root.Mui-disabled": {
    pointerEvents: "none",
    opacity: 1
  }
}));

type SelectOption = {
  label: string;
  value?: string;
  children?: SelectOption[];
};

export const FormMultiSelect = (props: {
  name: string;
  options: SelectOption[];
  control?: any;
  rules: any | undefined;
  label?: string;
  required: boolean | undefined;
  isLoading: boolean | undefined;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  disabledOptions?: string[];
  disabledMenuOptions?: string[];
  preSelectedOptions?: string[];
  onChange: (event: SelectChangeEvent<any>) => void;
  onModify?: (event: SelectChangeEvent<any>) => void;
  onRemove: (value: string) => void;
}) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      render={({ field, fieldState }) => (
        <FormSelectContainer>
          <StyledFormLabel required={props.required}>
            <Typography display="inline" variant="formLabel">
              {props.label}
            </Typography>
          </StyledFormLabel>
          {props.isLoading ? (
            <LoadingContainer>
              <LoadingSpinner />
            </LoadingContainer>
          ) : (
            <Select
              data-testid={"MULTI_SELECT_" + props.value}
              error={!!fieldState.error}
              required={props.required}
              value={field.value || props.preSelectedOptions || []}
              onChange={(event) => {
                if (props.onModify) {
                  props.onModify(event);
                } else {
                  props.onChange(event);
                  field.onChange(event);
                }
              }}
              onBlur={field.onBlur}
              inputProps={{ shrink: "true" }}
              MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
              placeholder={props.placeholder}
              disabled={props.disabled}
              onClose={() => {
                field.onBlur();
              }}
              multiple
              renderValue={(selected) => {
                return (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => {
                      const label = props.options.find(
                        (option) => option.value === value
                      )?.label;

                      const onDelete = () => {
                        const newValue = [...field.value];
                        const removed = newValue.splice(
                          newValue.indexOf(value),
                          1
                        );
                        props.onRemove(removed[0]);
                        field.onChange({ target: { value: newValue } });
                      };

                      const onMouseDown = (event) => {
                        event.preventDefault();
                        event.stopPropagation();
                      };

                      return (
                        <StyledChip
                          data-testid={"CHIP_MULTI_SELECT_" + value}
                          disabled={
                            props.disabled ||
                            props.disabledOptions?.includes(value)
                          }
                          key={value}
                          label={label}
                          onDelete={onDelete}
                          onMouseDown={onMouseDown}
                        />
                      );
                    })}
                  </Box>
                );
              }}
            >
              {props.options
                .filter((item) => {
                  return !field?.value?.includes(item.value);
                })
                .map((item) => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                    disabled={
                      props.disabledMenuOptions &&
                      props.disabledMenuOptions.includes(item.value)
                    }
                  >
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          )}
          {!!fieldState.error && (
            <ErrorContainer className="helper-text">
              <Typography variant="body2">
                {fieldState.error.message}
              </Typography>
            </ErrorContainer>
          )}
        </FormSelectContainer>
      )}
    />
  );
};

FormMultiSelect.defaultProps = {
  rules: undefined,
  required: false,
  isLoading: false,
  disabled: false,
  onChange: () => {},
  onRemove: () => {}
};
