import { DataGridRenderCellTooltip } from "@components/DataGridRenderCellTooltip";
import { TableView } from "@components/TableView";
import { organizationAtom } from "@recoil/auth";
import { getSkills } from "@services/Network";
import {
  ModelSport,
  useAdminSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const LIST_COLUMNS = [
  {
    headerName: "Sport",
    field: "sport",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ value }) => value?.name
  },
  { headerName: "Name", field: "name", minWidth: 150, flex: 1 },
  {
    headerName: "Positions",
    field: "positions",
    minWidth: 150,
    flex: 1,
    renderCell: DataGridRenderCellTooltip("name", 2)
  },
  {
    headerName: "Parent Skill",
    field: "parent",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ value }) => value?.name,
    sortable: true
  }
];

export const Skills = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const [refreshKey] = React.useState(0);
  const { data: sports } = useAdminSportGet({
    organizationId: organizationId!
  });
  const filterConfig = React.useMemo(
    () => ({
      field: "sportId",
      placeholderOption: { label: "All Sports", value: "" },
      options: [
        {
          label: "Filter by Sport",
          children:
            sports?.data?.map((sport: ModelSport) => ({
              label: sport.name as string,
              value: sport.sportId as string
            })) || []
        }
      ]
    }),
    [sports]
  );

  const onAdd = () => navigate("/skills/create");
  const onEdit = (skill) => navigate(`/skills/${skill.skillId}/edit`);
  const onView = (skill) => navigate(`/skills/${skill.skillId}`);
  return (
    <>
      <TableView
        title="Skills"
        useGet={getSkills}
        columns={LIST_COLUMNS}
        filterConfig={filterConfig}
        defaultSort={[{ field: "sport", sort: "asc" }]}
        onAdd={onAdd}
        onEdit={onEdit}
        onView={onView}
        refreshKey={refreshKey}
      />
    </>
  );
};
