import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import MediaContianerWithDelete from "@components/MediaContaierWithDelete";
import {
  Box,
  Typography,
  styled,
  FormLabel,
  Autocomplete,
  TextField,
  InputAdornment,
  MenuItem,
  Grid as MuiGrid
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  AdminLiveStreamPostRequest,
  ModelMedia,
  ModelSetting,
  mediaGet,
  useAdminLiveStreamStreamIdGet,
  useAdminLiveStreamStreamIdPut,
  useAdminSettingsGet,
  useAdminSportGet,
  useAdminSportLocationGet,
  useAdminTeamGet,
  useAdminTrainingProgramGet,
  useConfigGet,
  useLookupCountryCountryIdSubdivisionGet,
  useTagSearchGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import ThumbnailPickerImage from "@assets/images/thumbnailPicker.png";
import { FormSelect } from "@components/FormSelect";
import { useRecoilValue } from "recoil";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { FormDatePicker } from "@components/FormDatePicker";
import { FormCheckbox } from "@components/FormCheckbox";
import { AddIcon } from "@components/Icons";
import { FormTimePicker } from "@components/FormTimePicker";
import { parseISO } from "date-fns";
import GoogleMap from "@components/GoogleMap";
import { getCountries } from "@services/Network";
import { Loader } from "@components/crud/Loader";
import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { LocationOnOutlined, Search } from "@mui/icons-material";
import { SearchInput } from "@components/SearchInput";
const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

const FormInputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;
const StyledDiv = styled("div")(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("md")]: {
    width: "100%"
  }
}));
export const LiveStreamEdit = () => {
  const { id } = useParams()!;
  const timeZoneOptions = Intl.supportedValuesOf("timeZone").map((key) => {
    return {
      label: key,
      value: key
    };
  });
  const organizationId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const organization = organizations.find(
    (org) => org.organizationId === organizationId
  );
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openChangeAddress, setopenChangeAddress] = useState(false);
  const [tagSearch, setTagSearch] = useState("a");
  const [preEvent, setPreEvent] = useState(false);
  const [postEvent, setPostEvent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sportId, setSportId] = useState();
  const [contestType, setContestType] = useState();
  const [teamId, setTeamId] = useState<string>();
  const [opponent, setOpponent] = useState<string>();
  const [isHomeTeam, setIsHomeTeam] = useState(false);
  const [programId, setProgramId] = useState<string>();

  const [isPublicDisabled, setIsPublicDisabled] = useState(false);
  const [isPostGameDisabled, setIsPostGameDisabled] = useState(false);
  const [status, setStatus] = useState("NOT_STARTED");
  const [nameCharacterCount, setNameCharacterCount] = useState(0);

  const [isScheduleToday, setIsScheduleToday] = useState(true);
  const [eventStartTime, setEventStartTime] = useState(new Date());
  const [eventEndTime, setEventEndTime] = useState(new Date());
  const [settingThumbnailId, setSettingThumbnailId] = useState<
    string | undefined
  >(undefined);
  const [thumbnailDefaultImages, setThumbnailDefaultImages] = useState<unknown>(
    []
  );

  const { data: sports, isLoading: sportOptionsLoading } = useAdminSportGet({
    organizationId: organizationId!
  });
  const { data: tags } = useTagSearchGet({ q: tagSearch });

  const { data: countries, isLoading: countryLoading } = getCountries({
    staleTime: Infinity
  });
  const { data: provinces, isLoading: provinceLoading } =
    useLookupCountryCountryIdSubdivisionGet(organization?.country as string, {
      query: {
        enabled: !!organization?.country
      }
    });
  const { data: liveStream, isLoading: liveStreamLoading } =
    useAdminLiveStreamStreamIdGet(id!);
  const { data: team, isLoading: teamLoading } = useAdminTeamGet({
    organizationId: organizationId!,
    pageSize: 1000
  });
  const { data: trainingProgram, isLoading: trainingProgramLoading } =
    useAdminTrainingProgramGet({
      organizationId: organizationId!,
      pageSize: 1000
    });

  const [sportLocationInputValue, setSportLocationInputValue] =
    useState<string>("");

  const [sportLocation, setSportLocation] = useState<string | undefined>(
    undefined
  );

  const { data: orgSportsLocations } = useAdminSportLocationGet({
    organizationId: organizationId!
  });
  const shareWithOptions = useMemo(() => {
    const orgOption = [
      {
        label: "My Organization",
        value: organizationId!
      },
      ...(contestType === "TRAINING_SESSION"
        ? [
            {
              label: "Training Program",
              value: "TRAINING_PROGRAM"
            }
          ]
        : [
            {
              label: "Team",
              value: "TEAM"
            }
          ])
    ];
    return [...orgOption];
  }, [teamId, programId, contestType]);

  const teamOptions = useMemo(() => {
    const teamOptions =
      (team?.data.teams &&
        team?.data?.teams.map((team) => ({
          label: team.name!,
          value: team.teamId,
          data: team
        }))) ||
      [];
    return [...teamOptions];
  }, [team]);

  const programOptions = useMemo(() => {
    const trainingProgramOptions =
      (trainingProgram?.data.trainingPrograms &&
        trainingProgram?.data?.trainingPrograms.map((trainingProgram) => ({
          label: trainingProgram.name!,
          value: trainingProgram.programId,
          data: trainingProgram
        }))) ||
      [];

    return [...trainingProgramOptions];
  }, [trainingProgram]);

  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId
      })) || [],
    [sports]
  );

  const contestTypeOptions = useMemo(() => {
    const options = [
      {
        label: "Game",
        value: "GAME"
      },
      {
        label: "Scrimmage",
        value: "SCRIMMAGE"
      },
      {
        label: "Practice",
        value: "PRACTICE"
      },
      ...(organization?.offering?.includes("TRAINING_PROGRAMS")
        ? [
            {
              label: "Training Session",
              value: "TRAINING_SESSION"
            }
          ]
        : []),
      {
        label: "Event",
        value: "EVENT"
      }
    ];
    if (sportId && sports?.data?.length) {
      const sport = sports?.data.find((sp) => sp.sportId === sportId);
      const isMatch = sport?.countries?.find(
        (c) => c.countryId === organization?.country || "US"
      )?.isMatch;
      if (isMatch) options[0].label = "Match";
    }
    return options;
  }, [sportId, sports]);

  useEffect(() => {
    if (teamId) {
      const teamName = teamOptions.find((t) => t.value === teamId)!.label;
      const title = `${
        isHomeTeam
          ? (organization?.abbreviation || "SG") + " " + teamName
          : opponent || ""
      } vs ${
        isHomeTeam
          ? opponent || ""
          : (organization?.abbreviation || "SG") + " " + teamName
      }`;
      setValue("title", title);
      setNameCharacterCount(title.length);
    } else if (programId) {
      const programName = programOptions.find(
        (t) => t.value === programId
      )!.label;
      const title = `${
        organization?.abbreviation || "SG"
      } ${programName} Training Session`;
      setValue("title", title);
      setNameCharacterCount(title.length);
    }
  }, [teamId, programId, isHomeTeam, opponent]);

  const hasOpposing = () => {
    if (
      !contestType ||
      contestType === "TRAINING_SESSION" ||
      contestType === "PRACTICE"
    )
      return false;
    return true;
  };

  const { data: settings, isLoading: isLoadingSettings } = !organizationId
    ? useConfigGet()
    : useAdminSettingsGet({
        organizationId: organizationId!,
        parentId: "org-live-stream"
      });

  const {
    data: settingTimeInterval,
    isLoading: isLoadingSettingsTimeInterval
  } = !organizationId
    ? useConfigGet()
    : useAdminSettingsGet({
        organizationId: organizationId!,
        parentId: "general"
      });
  useEffect(() => {
    if (settingTimeInterval && organizationId) {
      const settingsOrg = settingTimeInterval.data as ModelSetting[];
      const timeIntervalSetting = settingsOrg.find(
        (s) => s.settingId === "general.default-time-picker-interval"
      );
      const timeZoneSettings = settingsOrg.find(
        (s) => s.settingId === "general.org-local-timezone"
      );
      if (timeIntervalSetting) {
        const orgSetting =
          timeIntervalSetting.organizationSettings &&
          timeIntervalSetting.organizationSettings.length > 0
            ? timeIntervalSetting.organizationSettings.find(
                (o) => o.organizationId === organizationId
              )?.value
            : timeIntervalSetting.value || timeIntervalSetting.default;
        if (orgSetting) {
          const numericValue = parseInt(orgSetting.match(/\d+/)[0], 10);
          const unit = orgSetting.match(/[A-Z]+/)[0];

          if (unit === "MIN") {
            setTimeInterval(numericValue);
          } else if (unit === "H") {
            setTimeInterval(numericValue * 60);
          }
        }
      }
      if (timeZoneSettings) {
        const orgSetting =
          timeZoneSettings.organizationSettings &&
          timeZoneSettings.organizationSettings.length > 0
            ? timeZoneSettings.organizationSettings.find(
                (o) => o.organizationId === organizationId
              )?.value
            : timeZoneSettings.value || timeZoneSettings.default;
        if (orgSetting && !getValues().timeZone)
          setValue("timeZone", orgSetting);
      }
    }
  }, [settingTimeInterval]);

  const [allowPublicSharing, setAllowPublicSharing] = useState(false);
  const [allowPreGame, setAllowPreGame] = useState(false);
  const [allowPostGame, setAllowPostGame] = useState(false);
  const [timeInterval, setTimeInterval] = useState(15);
  useEffect(() => {
    if (settings && organizationId) {
      const settingsOrg = settings.data as ModelSetting[];
      const publicSharingSetting = settingsOrg.find(
        (s) => s.settingId === "org-live-stream.allow-public-sharing"
      );
      const preGameSetting = settingsOrg.find(
        (s) => s.settingId === "org-live-stream.allow-pre-game"
      );
      const postGameSetting = settingsOrg.find(
        (s) => s.settingId === "org-live-stream.allow-post-game"
      );
      const thumbnailImages = settingsOrg.find(
        (s) => s.settingId === "org-live-stream.default-live-stream-images"
      );
      if (publicSharingSetting) {
        const orgSetting =
          publicSharingSetting.organizationSettings &&
          publicSharingSetting.organizationSettings.length > 0
            ? publicSharingSetting.organizationSettings.find(
                (o) => o.organizationId === organizationId
              )
            : null;
        if (orgSetting) setAllowPublicSharing(Boolean(orgSetting.value));
      }
      if (preGameSetting) {
        const orgSetting =
          preGameSetting.organizationSettings &&
          preGameSetting.organizationSettings.length > 0
            ? preGameSetting.organizationSettings.find(
                (o) => o.organizationId === organizationId
              )
            : null;
        if (orgSetting) setAllowPreGame(Boolean(orgSetting.value));
      }
      if (postGameSetting) {
        const orgSetting =
          postGameSetting.organizationSettings &&
          postGameSetting.organizationSettings.length > 0
            ? postGameSetting.organizationSettings.find(
                (o) => o.organizationId === organizationId
              )
            : null;
        if (orgSetting) setAllowPostGame(Boolean(orgSetting.value));
      }
      if (thumbnailImages) {
        const orgSetting =
          thumbnailImages.organizationSettings &&
          thumbnailImages.organizationSettings.length > 0
            ? thumbnailImages.organizationSettings.find(
                (o) => o.organizationId === organizationId
              )
            : null;
        if (orgSetting && Array.isArray(orgSetting.value))
          setThumbnailDefaultImages(orgSetting.value);
      }
    }
  }, [settings]);

  const [file, setFile] = useState<File | ModelMedia>();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
    reset,
    setValue,
    getValues
  } = useForm({
    mode: "onBlur"
  });
  useEffect(() => {
    if (!liveStreamLoading && liveStream?.data) {
      const defaultValues = {
        title: liveStream?.data.title,
        organization: organization?.name || "SportsGravy LLC",
        shareTo: liveStream?.data.teamId
          ? "TEAM"
          : liveStream?.data.programId
          ? "PROGRAM"
          : organizationId,
        status: liveStream.data.status,
        sportId: liveStream.data.sportId,
        //@ts-ignore
        scheduledDate: parseISO(liveStream?.data?.scheduledAt),
        //@ts-ignore
        scheduledTime: parseISO(liveStream.data.scheduledAt),
        //@ts-ignore
        scheduledEndAt: parseISO(liveStream.data.scheduledEndAt),
        contestType: liveStream.data.contestType || "GAME",
        timeZone: liveStream.data.timeZone,
        preEvent: !!liveStream.data.preEventAt,
        postEvent: !!liveStream.data.postEventAt,
        teamId: liveStream.data.teamId,
        programId: liveStream.data.programId,
        isPublic: liveStream.data.isPublic,
        notes: liveStream.data.notes,
        tags: liveStream.data.tags,
        thumbnailId: liveStream.data.thumbnailId,
        opponent: liveStream.data.opponent,
        isHomeTeam: !!liveStream.data.isHomeTeam,
        createdBy:
          liveStream.data.createdBy?.person?.firstName +
          " " +
          liveStream.data.createdBy?.person?.lastName
      };
      setStatus(liveStream.data.status || "NOT_STARTED");
      setIsPublicDisabled(!!liveStream.data.isPublic);
      //@ts-ignore
      if (liveStream?.data?.preEventAt) {
        //@ts-ignore
        defaultValues["preEventAt"] = parseISO(liveStream?.data?.preEventAt);
        defaultValues["preEventEndAt"] = parseISO(
          liveStream?.data?.scheduledAt
        );
      }
      if (liveStream?.data?.postEventAt) {
        //@ts-ignore
        defaultValues["postEventAt"] = parseISO(
          liveStream?.data?.scheduledEndAt
        );
        defaultValues["postEventEndAt"] = parseISO(
          liveStream?.data?.postEventEndAt
        );
      }
      if (
        liveStream?.data?.streamedBy?.person?.firstName &&
        liveStream?.data?.streamedBy?.person?.lastName
      ) {
        defaultValues["streamedBy"] =
          liveStream?.data?.streamedBy?.person?.firstName +
          " " +
          liveStream?.data?.streamedBy?.person?.lastName;
      }

      if (orgSportsLocations?.data) {
        defaultValues["locationCheckbox"] = false;
        if (liveStream.data.sportLocationId) {
          setSportLocation(liveStream.data.sportLocationId);
          setSportLocationInputValue(liveStream.data.sportLocation?.name);
          defaultValues["location"] = liveStream.data.sportLocation?.name;
          if (liveStream.data.sportLocation?.name === organization?.name)
            defaultValues["locationCheckbox"] = true;
        } else {
          setSportLocation(undefined);
          setSportLocationInputValue(liveStream.data.location);
          defaultValues["location"] = liveStream.data.location;
        }
      }
      reset(defaultValues);
      setSportId(liveStream.data.sportId || "");
      setPostEvent(!!liveStream.data.postEventAt);
      setPreEvent(!!liveStream.data.preEventAt);
      setContestType(liveStream.data.contestType || "GAME");
      setOpponent(liveStream.data.opponent || "");
      setIsHomeTeam(!!liveStream.data.isHomeTeam);
      setTeamId(liveStream.data.teamId);
      setProgramId(liveStream.data.programId);
      if (
        liveStream.data.status !== "NOT_STARTED" &&
        !liveStream.data.postEventAt
      ) {
        setIsPostGameDisabled(true);
      }
      const scheduledAt = parseISO(liveStream.data.scheduledAt);
      setEventStartTime(
        new Date(
          new Date().setHours(
            scheduledAt.getHours(),
            scheduledAt.getMinutes(),
            0,
            0
          )
        )
      );

      const scheduledEndAt = parseISO(liveStream.data.scheduledEndAt);
      setEventEndTime(
        new Date(
          new Date().setHours(
            scheduledEndAt.getHours(),
            scheduledEndAt.getMinutes(),
            0,
            0
          )
        )
      );

      const isoDate = parseISO(liveStream.data.scheduledAt);
      const currentDate = new Date();
      const isSameDate =
        isoDate.getFullYear() === currentDate.getFullYear() &&
        isoDate.getMonth() === currentDate.getMonth() &&
        isoDate.getDate() === currentDate.getDate();
      setIsScheduleToday(isSameDate);
      if (liveStream.data.thumbnail) setFile(liveStream?.data?.thumbnail || []);
    }
  }, [liveStream, liveStreamLoading, orgSportsLocations]);

  const selectedTags = useMemo(() => {
    const selectedTags =
      (liveStream?.data.tags &&
        liveStream?.data?.tags.map((tag) => ({
          label: tag!
        }))) ||
      [];
    return [...selectedTags];
  }, [liveStream?.data.tags]);
  const tagsoptions = useMemo(() => {
    const tag =
      (tags?.data &&
        tags?.data?.map((tag) => ({
          label: tag.name!
        }))) ||
      [];
    return [...tag];
  }, [tags]);
  const getOrgAddress = () => {
    const province = provinces?.data.find(
      (province) => province.subdivisionId == organization?.province
    );
    const country = countries?.find(
      (country) => country?.countryId == organization?.country
    );
    if (organization?.lines && province && country) {
      return `${organization?.lines[0]}\n${
        organization?.lines[1] ? organization.lines[1] + "\n" : ""
      }${organization?.locality}\n${
        province.name
      } ${organization?.postalCode}\n${country.name}`;
    }
  };
  useEffect(() => {
    if (!provinceLoading && !countryLoading) {
      setValue("location", getOrgAddress());
    }
  }, [provinceLoading, countryLoading]);
  const { mutate: save, isLoading: isSaving } = useAdminLiveStreamStreamIdPut();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    async (formValues) => {
      for (const key in formValues) {
        if (formValues[key] === null) {
          delete formValues[key];
        }
      }
      if (preEvent === false) delete formValues["preEventAt"];
      if (postEvent === false) delete formValues["postEventAt"];
      const values: AdminLiveStreamPostRequest = {
        ...formValues,
        ...(organizationId && { organizationId: organizationId })
      };

      if (sportLocation) {
        delete values["location"];
        delete values["lat"];
        delete values["lng"];
        values["sportLocationId"] = sportLocation;
      } else {
        values["location"] = sportLocationInputValue;
      }

      if (file && !file.mediaId) {
        setLoading(true);
        const promise = await uploadMediaUsingPresignedUrl(file);
        values["thumbnailId"] = promise;
      } else if (settingThumbnailId && defaultThumbnailUrl)
        values["thumbnailId"] = settingThumbnailId;

      const scheduleEndTime = new Date(
        new Date(formValues.scheduledEndAt)
      ).getTime();
      const date = new Date(formValues.scheduledDate);
      if (getValues("preEvent")) {
        const preEventTime = new Date(
          new Date(formValues.preEventAt)
        ).getTime();
        values["preEventAt"] = new Date(
          new Date(formValues.scheduledDate).setTime(preEventTime)
        );
        values["preEventEndAt"] = values["scheduledAt"];
      }
      if (getValues("postEvent")) {
        const postEventTime = new Date(
          new Date(formValues.postEventAt)
        ).getTime();
        values["postEventAt"] = new Date(
          new Date(formValues.scheduledDate).setTime(postEventTime)
        );
        values["postEventAt"] = values["scheduledEndAt"];
      }
      values["scheduledAt"] = new Date(
        new Date(formValues.scheduledDate).setHours(
          new Date(formValues.scheduledTime).getHours(),
          new Date(formValues.scheduledTime).getMinutes(),
          0,
          0
        )
      );
      values["scheduledEndAt"] = new Date(
        new Date(date).setTime(scheduleEndTime)
      );
      try {
        save(
          {
            streamId: id!,
            data: values
          },
          {
            onSuccess: () => {
              setLoading(false);
              enqueueSnackbar("Live Stream edited successfully!", {
                variant: "success"
              });
              if (resetInsteadOfRoute) {
                reset();
                setFile(null);
                if (inputFileRef.current) {
                  inputFileRef.current.value = "";
                }
              } else {
                navigate("/live-stream");
              }
            },
            onError: () => {
              setLoading(false);
              enqueueSnackbar("Failed to add live stream!", {
                variant: "error"
              });
            }
          }
        );
      } catch (error) {
        enqueueSnackbar("Failed to generate image data!", {
          variant: "error"
        });
      }
    };

  const onImageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field
  ) => {
    const tempFiles = (event.target as HTMLInputElement).files;
    field.onChange(event);
    if (tempFiles) setFile(tempFiles[0]);
  };

  const selectAddressSuggestion = (address_components) => {
    const addressComponents = address_components || [];
    const premise = addressComponents.find((c) => c.types.includes("premise"));
    const streetNumber = addressComponents.find((c) =>
      c.types.includes("street_number")
    );
    const route = addressComponents.find((c) => c.types.includes("route"));
    const establishment = addressComponents.find((c) =>
      c.types.includes("establishment")
    );
    const plus_code = address_components.find((c) =>
      c.types.includes("plus_code")
    );
    const country = addressComponents.find((c) => c.types.includes("country"));
    const state = addressComponents.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    const city = addressComponents.find(
      (c) => c.types.includes("locality") || c.types.includes("sublocality")
    );
    const zip = addressComponents.find((c) => c.types.includes("postal_code"));
    return (
      (premise ? premise.long_name + " , " : "") +
      (streetNumber ? streetNumber.long_name + " " : "") +
      (route ? route.long_name + " , " : "") +
      (establishment ? establishment.long_name + " , " : "") +
      (plus_code ? plus_code.long_name + "," : "") +
      (city?.long_name ? city.long_name + " " : "") +
      (zip?.long_name ? zip.long_name + " , " : "") +
      (state?.long_name ? state.long_name + " , " : "") +
      (country?.long_name ? country.long_name : "")
    );
  };

  const closeAddressChange = () => {
    setopenChangeAddress(false);
  };
  const onAddressChanges = (changedAddress) => {
    setValue("location", selectAddressSuggestion(changedAddress), {
      shouldValidate: true,
      shouldDirty: true
    });
    setSportLocationInputValue(selectAddressSuggestion(changedAddress));
    setSportLocation(undefined);
    setValue("locationCheckbox", false);
  };

  const roundToNearestInterval = (date, interval) => {
    const minutes = date.getMinutes();
    const roundedMinutes = Math.ceil(minutes / interval) * interval;

    const roundedDate = new Date(date);
    if (roundedMinutes === 60) {
      // If rounded minutes are 60, it means we need to round up to the next hour
      roundedDate.setHours(roundedDate.getHours() + 1);
      roundedDate.setMinutes(0); // Reset minutes to 0
    } else {
      roundedDate.setMinutes(roundedMinutes); // Set rounded minutes
    }

    return roundedDate;
  };

  useEffect(() => {
    const roundTime = roundToNearestInterval(new Date(), timeInterval);
    setValue("scheduledTime", roundTime);
    setValue("preEventEndAt", roundTime);
  }, [timeInterval]);

  useEffect(() => {
    {
      const fetchMedia = async () => {
        if (settingThumbnailId) {
          try {
            const data = await mediaGet({
              mediaIds: [settingThumbnailId]
            });
            if (data.data.media && data.data.media.length > 0) {
              const media = data.data.media[0].media;
              if (media && media.baseUrl && media.path)
                setDefaultThumbnailUrl(media?.baseUrl + media?.path);
              else setDefaultThumbnailUrl(undefined);
            }
          } catch (error) {
            setDefaultThumbnailUrl(undefined);
          }
        }
      };
      if (settingThumbnailId) {
        fetchMedia();
      }
    }
  }, [settingThumbnailId]);

  useEffect(() => {
    if (
      getValues().shareTo &&
      sportId &&
      sports?.data &&
      !getValues().icon &&
      Array.isArray(thumbnailDefaultImages)
    ) {
      const defaultImage = thumbnailDefaultImages.find(
        (sp) => sp.sportId === sportId
      );
      if (defaultImage) {
        setSettingThumbnailId(defaultImage.mediaId);
      }
    }
  }, [sportId]);

  const [defaultThumbnailUrl, setDefaultThumbnailUrl] = useState<
    string | undefined
  >(undefined);

  return (
    <Loader
      isLoading={
        liveStreamLoading || isLoadingSettings || isLoadingSettingsTimeInterval
      }
    >
      <Container>
        <Toolbar
          title="Edit Live Stream"
          backBtnClick={() => navigate("/live-stream")}
        />
        <Form>
          <Grid data-testid="live-add-form" container spacing={3}>
            <Grid xs={12} md={12} data-testid="live-thumbnail">
              <Controller
                name="icon"
                control={control}
                render={({ field }) => (
                  <FormInputContainer>
                    <StyledFormLabel required>
                      <Typography display="inline" variant="formLabel">
                        {"Thumbnail"}
                      </Typography>
                    </StyledFormLabel>
                    <Grid container spacing={3} xs={12} md={12}>
                      <Grid xs={12} md={4}>
                        {file ? (
                          <MediaContianerWithDelete
                            disabled={status !== "NOT_STARTED"}
                            onConfirmDelete={() => {
                              inputFileRef.current.value = "";
                              setFile(null);
                            }}
                            index={0}
                          >
                            <img
                              src={
                                file.mediaId
                                  ? file.baseUrl + file.path
                                  : URL.createObjectURL(file)
                              }
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain"
                              }}
                            />
                          </MediaContianerWithDelete>
                        ) : (
                          <img
                            src={defaultThumbnailUrl || ThumbnailPickerImage}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain"
                            }}
                            onClick={() => {
                              inputFileRef.current?.click();
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <input
                      type="file"
                      onChange={(e) => {
                        onImageChange(e, field);
                      }}
                      ref={inputFileRef}
                      style={{ display: "none" }}
                      accept="image/*"
                    />
                  </FormInputContainer>
                )}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="liveSteam-organization">
              <FormInput
                control={control}
                name="organization"
                type="text"
                label="Organization"
                required={true}
                disabled
              />
            </Grid>

            <Grid xs={12} md={6} data-testid="liveSteam-sport">
              <FormSelect
                control={control}
                required
                name="sportId"
                label="Sport"
                options={sportOptions}
                isLoading={sportOptionsLoading}
                disabled
                onChange={(e) => {
                  setSportId(e.target.value);
                }}
                rules={{
                  required: "Sport is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="liveSteam-contestType">
              <FormSelect
                control={control}
                required
                name="contestType"
                label="Contest Type"
                options={contestTypeOptions}
                disabled
                // disabled={sportOptions.length == 1}
                onChange={(e) => {
                  if (
                    e.target.value === "TRAINING_SESSION" ||
                    contestType === "TRAINING_SESSION"
                  ) {
                    setValue("teamId", undefined);
                    setValue("programId", undefined);
                  }
                  setContestType(e.target.value);
                }}
                rules={{
                  required: "Contest Type is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="liveSteam-team-program">
              <FormSelect
                control={control}
                required
                name={
                  contestType === "TRAINING_SESSION" ? "programId" : "teamId"
                }
                label={
                  contestType === "TRAINING_SESSION"
                    ? "Training Program"
                    : "Team"
                }
                options={
                  contestType === "TRAINING_SESSION"
                    ? programOptions
                    : teamOptions
                }
                disabled
                onChange={(e) => {
                  if (contestType === "TRAINING_SESSION") {
                    setTeamId(undefined);
                    setValue("teamId", undefined);
                    setProgramId(e.target.value);
                    setValue("shareTo", "TRAINING_PROGRAM");
                  } else {
                    setProgramId(undefined);
                    setTeamId(e.target.value);
                    setValue("programId", undefined);
                    setValue("shareTo", "TEAM");
                  }

                  if (
                    sportId &&
                    sports?.data &&
                    !getValues().icon &&
                    Array.isArray(thumbnailDefaultImages)
                  ) {
                    const defaultImage = thumbnailDefaultImages.find(
                      (sp) => sp.sportId === sportId
                    );
                    if (defaultImage) {
                      setSettingThumbnailId(defaultImage.mediaId);
                    }
                  }
                }}
                rules={{
                  required:
                    contestType === "TRAINING_SESSION"
                      ? "Training Program is required"
                      : "Team is required"
                }}
              />
              {hasOpposing() && contestType !== "EVENT" && (
                <div>
                  <FormCheckbox
                    disabled={status !== "NOT_STARTED"}
                    control={control}
                    onChange={(e) => {
                      setIsHomeTeam(e.target.checked);
                    }}
                    name="isHomeTeam"
                    label="Home Team"
                  />
                </div>
              )}
            </Grid>

            {hasOpposing() && (
              <Grid xs={12} md={6} data-testid="liveSteam-opponent">
                <FormInput
                  control={control}
                  name="opponent"
                  type="text"
                  disabled={status !== "NOT_STARTED"}
                  onChange={(e) => {
                    setOpponent(e.target.value);
                    setValue(
                      "opponent",
                      e.target.value.charAt(0).toUpperCase() +
                        e.target.value.slice(1)
                    );
                  }}
                  label={`Opposing ${
                    contestType === "EVENT" ? "Organization" : "Team"
                  }`}
                  required={true}
                  rules={{
                    required: "Opponent is required",
                    maxLength: {
                      value: 50,
                      message: "Opponent Name must not exceed 50 characters"
                    }
                  }}
                />
                <div style={{ textAlign: "end" }}>
                  <Typography style={{ opacity: "50%" }}>
                    {"Character Count Remaining: " +
                      (50 - (opponent?.length || 0))}
                  </Typography>
                </div>
              </Grid>
            )}

            <Grid xs={12} md={6} data-testid="liveSteam-title">
              <FormInput
                control={control}
                name="title"
                type="text"
                label="Title"
                required={true}
                disabled={status !== "NOT_STARTED"}
                onChange={(e) => {
                  setValue(
                    "title",
                    e.target.value.charAt(0).toUpperCase() +
                      e.target.value.slice(1)
                  );
                  setNameCharacterCount(e.target?.value?.length || 0);
                }}
                rules={{
                  required: "Title is required",
                  maxLength: {
                    value: 70,
                    message: "Title must not exceed 70 characters"
                  }
                }}
              />
              <div style={{ textAlign: "end" }}>
                <Typography style={{ opacity: "50%" }}>
                  {"Character Count Remaining: " + (70 - nameCharacterCount)}
                </Typography>
              </div>
            </Grid>
            <Grid xs={12} md={hasOpposing() ? 12 : 6} container direction="row">
              <Grid xs={hasOpposing() ? 6 : 12} data-testid="liveSteam-shareTo">
                <FormSelect
                  control={control}
                  name="shareTo"
                  label="Share With"
                  required={!!organizationId}
                  disabled
                  // disabled={true}
                  options={shareWithOptions}
                  isLoading={teamLoading || trainingProgramLoading}
                  rules={
                    organizationId
                      ? {
                          required: "Share With is required"
                        }
                      : {}
                  }
                />
                {allowPublicSharing && (
                  <div style={{ marginTop: "14px" }}>
                    <FormCheckbox
                      control={control}
                      name="isPublic"
                      disabled={
                        !organizationId ||
                        isPublicDisabled ||
                        status !== "NOT_STARTED"
                      }
                      label="Make available to the public"
                    />
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid
              xs={12}
              md={6}
              sm={12}
              xl={3}
              data-testid="liveSteam-scheduledDate"
            >
              <FormDatePicker
                name="scheduledDate"
                required
                minDate={new Date()}
                disabled={status !== "NOT_STARTED"}
                control={control}
                onChange={(e) => {
                  const date = new Date(e);
                  const today = new Date();
                  const isToday = date.toDateString() === today.toDateString();
                  setIsScheduleToday(isToday);
                }}
                label="Event Date"
                rules={{
                  required: "Scheduled Date is required"
                }}
              />
            </Grid>
            <Grid
              xs={12}
              md={6}
              sm={12}
              xl={3}
              data-testid="liveSteam-scheduleTime"
            >
              <FormTimePicker
                name="scheduledTime"
                required
                disabled={status !== "NOT_STARTED"}
                timeSteps={{ minutes: timeInterval }}
                minTime={
                  isScheduleToday
                    ? new Date(new Date().getTime() + 5 * 60000)
                    : undefined
                }
                control={control}
                label="Event Start Time"
                rules={{
                  required: "Scheduled Time is required"
                }}
                onChange={(e) => {
                  const givenDate = new Date(e);
                  const futureDate = new Date(new Date().getTime() + 5 * 60000);
                  const isGreaterThanFuture = givenDate >= futureDate;
                  if (!isScheduleToday || isGreaterThanFuture) {
                    setValue("preEventEndAt", e);
                    setEventStartTime(e);
                  } else {
                    setValue(
                      "scheduledTime",
                      parseISO(new Date().toISOString())
                    );
                  }
                }}
              />
            </Grid>
            <Grid
              xs={12}
              md={6}
              sm={12}
              xl={3}
              data-testid="liveSteam-scheduleEndTime"
            >
              <FormTimePicker
                control={control}
                timeSteps={{ minutes: timeInterval }}
                required={true}
                name="scheduledEndAt"
                minTime={eventStartTime}
                disabled={status !== "NOT_STARTED"}
                label="Event End Time"
                rules={{
                  required: "Event End Time is required"
                }}
                onChange={(e) => {
                  const givenDate = new Date(e);
                  const isGreaterThanFuture = givenDate > eventStartTime;
                  if (isGreaterThanFuture) {
                    setValue("postEventAt", e);
                    setEventEndTime(e);
                  } else {
                    setValue("scheduledEndAt", null);
                    setValue("postEventAt", null);
                  }
                }}
              />
            </Grid>
            <Grid
              xs={12}
              md={6}
              sm={12}
              xl={3}
              data-testid="liveSteam-timeZone"
            >
              <FormSelect
                control={control}
                required
                name="timeZone"
                disabled={status !== "NOT_STARTED"}
                label="Event Time Zone"
                options={timeZoneOptions}
                rules={{
                  required: "Event Time Zone is required"
                }}
              />
            </Grid>
            <Grid xs={12} container direction={"row"}>
              <Grid
                container
                xs={12}
                md={6}
                sm={12}
                xl={12}
                flexDirection={"row"}
                spacing="0px"
                alignItems={"center"}
              >
                {allowPreGame && (
                  <Grid xl={3} md={12}>
                    <FormCheckbox
                      control={control}
                      name="preEvent"
                      label="Schedule Pre Game"
                      disabled={status !== "NOT_STARTED"}
                      onChange={(e) => {
                        setPreEvent(e.target.checked);
                        setValue("preEventEndAt", getValues("scheduledTime"));
                      }}
                    />
                  </Grid>
                )}
                {preEvent && (
                  <>
                    <Grid xl={3} md={12}>
                      <FormTimePicker
                        timeSteps={{ minutes: timeInterval }}
                        name="preEventAt"
                        control={control}
                        maxTime={eventStartTime}
                        disabled={status !== "NOT_STARTED"}
                        label="Start Time"
                        onChange={(e) => {
                          const givenDate = new Date(e);
                          const isGreaterThanFuture =
                            givenDate > eventStartTime;
                          if (isGreaterThanFuture) {
                            setValue("preEventAt", null);
                          }
                        }}
                        required={preEvent}
                        rules={{
                          required: "Pre Event Time is required"
                        }}
                      />
                    </Grid>
                    <Grid xl={3} md={12}>
                      <FormTimePicker
                        name="preEventEndAt"
                        control={control}
                        label="End Time"
                        required={preEvent}
                        disabled={true}
                        rules={{
                          required: "Pre Event End Time is required"
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid xs={12} container direction={"row"}>
              <Grid
                container
                xs={12}
                md={6}
                sm={12}
                xl={12}
                flexDirection={"row"}
                spacing="10px"
                alignItems={"center"}
              >
                {allowPostGame && (
                  <Grid xl={3} md={12}>
                    <FormCheckbox
                      control={control}
                      name="postEvent"
                      label="Schedule Post Game"
                      disabled={isPostGameDisabled}
                      onChange={(e) => {
                        setPostEvent(e.target.checked);
                        setValue("postEventAt", getValues("scheduledEndAt"));
                      }}
                    />
                  </Grid>
                )}
                {postEvent && (
                  <>
                    <Grid xl={3} md={12}>
                      <FormTimePicker
                        name="postEventAt"
                        control={control}
                        label="Start Time"
                        required={postEvent}
                        disabled={true}
                        rules={{
                          required: "Post Event Time is required"
                        }}
                      />
                    </Grid>
                    <Grid xl={3} md={12}>
                      <FormTimePicker
                        name="postEventEndAt"
                        timeSteps={{ minutes: timeInterval }}
                        control={control}
                        label="End Time"
                        minTime={eventEndTime}
                        required={postEvent}
                        onChange={(e) => {
                          const givenDate = new Date(e);
                          const isGreaterThanFuture = givenDate > eventEndTime;
                          if (!isGreaterThanFuture) {
                            setValue("postEventEndAt", null);
                          }
                        }}
                        rules={{
                          required: "Post Event End Time is required"
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <StyledDiv>
              <Grid xs={12} md={12} xl={12} data-testid="liveSteam-tags">
                <Controller
                  name="tags"
                  control={control}
                  render={() => (
                    <Box>
                      <StyledFormLabel>
                        <Typography display="inline" variant="formLabel">
                          {"Tags"}
                        </Typography>
                      </StyledFormLabel>
                      <Grid
                        spacing={3}
                        sx={{ padding: "0px 0px 12px 0px", marginTop: "4px" }}
                      >
                        <Autocomplete
                          defaultValue={selectedTags}
                          freeSolo
                          disabled={status !== "NOT_STARTED"}
                          disableClearable={true}
                          multiple
                          id="tags-outlined"
                          options={tagsoptions.concat(selectedTags)}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <Typography>{option.label}</Typography>
                            </li>
                          )}
                          filterSelectedOptions
                          renderInput={(params) => {
                            {
                              if (
                                Array.isArray(params.InputProps.startAdornment)
                              ) {
                                params.InputProps.startAdornment.splice(
                                  0,
                                  0,
                                  <InputAdornment position="start">
                                    <Search />
                                  </InputAdornment>
                                );
                              } else {
                                params.InputProps.startAdornment = (
                                  <InputAdornment position="start">
                                    <Search />
                                  </InputAdornment>
                                );
                              }
                              return <TextField {...params} />;
                            }
                          }}
                          onChange={(e, value) => {
                            setValue(
                              "tags",
                              value.map((v) => v.label)
                            );
                          }}
                          onInputChange={(event, newInputValue) => {
                            setTagSearch(newInputValue);
                          }}
                        />
                      </Grid>
                    </Box>
                  )}
                />
              </Grid>
              <Grid container xs={12} md={12} xl={12}>
                <Grid sx={{ padding: "12px" }} xl={12} md={12}>
                  <Controller
                    name="location"
                    rules={{ required: "Location is required" }}
                    control={control}
                    render={({ fieldState }) => (
                      <FormInputContainer>
                        <StyledFormLabel required>
                          <Typography display="inline" variant="formLabel">
                            {"Location"}
                          </Typography>
                        </StyledFormLabel>
                        <Autocomplete
                          disabled={status !== "NOT_STARTED"}
                          options={
                            orgSportsLocations?.data?.locations?.map(
                              (location) => ({
                                value: location.locationId as string,
                                label: location.name as string,
                                location: location
                              })
                            ) || []
                          }
                          inputValue={sportLocationInputValue}
                          renderInput={(params) => {
                            return (
                              <SearchInput
                                data-testid="SEARCH_LOCATION_INPUT"
                                {...params}
                                {...fieldState}
                                value={sportLocationInputValue}
                                onChange={(e) => {
                                  setSportLocationInputValue(e.target.value);
                                  if (e.target.value === "") {
                                    setSportLocation(undefined);
                                    setValue("locationCheckbox", false);
                                  }
                                  setValue("location", e.target.value, {
                                    shouldValidate: true,
                                    shouldDirty: true
                                  });
                                }}
                                clearable={
                                  sportLocationInputValue !== "" &&
                                  status === "NOT_STARTED"
                                }
                                onClearClick={() => {
                                  setSportLocationInputValue("");
                                  setSportLocation(undefined);
                                  setValue("locationCheckbox", false);
                                  setValue("location", "", {
                                    shouldValidate: true,
                                    shouldDirty: true
                                  });
                                }}
                                placeholder="Search Sport Location"
                              />
                            );
                          }}
                          renderOption={(props, option) => {
                            const address = `${option.location.lines?.join(
                              ", "
                            )}, ${option.location.locality}, ${
                              option.location.province
                            }, ${option.location.country}, ${
                              option.location.postalCode
                            }`;
                            return (
                              <MenuItem
                                data-testId={`LOCATION_OPTION_${option.value}`}
                                key={option.value}
                                id={option.value}
                                selected={
                                  sportLocation
                                    ? option.value === sportLocation
                                    : option.value === undefined
                                }
                                onClick={() => {
                                  setSportLocationInputValue(option.label);
                                  setSportLocation(option.value);
                                  if (option.label === organization?.name)
                                    setValue("locationCheckbox", true);
                                  else setValue("locationCheckbox", false);
                                  setValue("location", option.label, {
                                    shouldValidate: true,
                                    shouldDirty: true
                                  });
                                }}
                              >
                                <MuiGrid
                                  container
                                  direction="row"
                                  spacing="10px"
                                  alignItems="center"
                                >
                                  <MuiGrid item>
                                    <LocationOnOutlined htmlColor="grey" />
                                  </MuiGrid>
                                  <MuiGrid
                                    item
                                    xs={10}
                                    container
                                    direction="column"
                                    spacing="5px"
                                  >
                                    <MuiGrid item>
                                      <Typography
                                        style={{
                                          color: "#000000",
                                          fontSize: "13px",
                                          fontWeight: 400
                                        }}
                                      >
                                        {option.label}
                                      </Typography>
                                    </MuiGrid>
                                    <MuiGrid item>
                                      <Typography
                                        style={{
                                          color: "#64748B",
                                          fontSize: "12px"
                                        }}
                                      >
                                        {address || ""}
                                      </Typography>
                                    </MuiGrid>
                                  </MuiGrid>
                                </MuiGrid>
                              </MenuItem>
                            );
                          }}
                        />
                      </FormInputContainer>
                    )}
                  />

                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}
                  >
                    <FormCheckbox
                      control={control}
                      disabled={status !== "NOT_STARTED"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          const location =
                            orgSportsLocations?.data.locations?.find(
                              (l) => l.locationId === organization?.locationId
                            );
                          if (location) {
                            setSportLocation(location.locationId);
                            setSportLocationInputValue(location?.name || "");
                            setValue("location", location?.name || "");
                          }
                        } else {
                          setSportLocation(undefined);
                          setValue("location", "");
                          setSportLocationInputValue("");
                        }
                      }}
                      name="locationCheckbox"
                      label="Use Organization Address"
                    />
                    {status === "NOT_STARTED" && (
                      <>
                        <AddIcon
                          style={{
                            color: "#4F46E5",
                            width: "18px",
                            height: "18px",
                            marginRight: "4px"
                          }}
                        />
                        <Typography
                          style={{
                            cursor: "pointer",
                            width: "190px",
                            color: "#4F46E5",
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px"
                          }}
                          onClick={() => {
                            setopenChangeAddress(true);
                          }}
                        >
                          Add New Address
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </StyledDiv>
            <Grid xs={12} md={6} xl={6} data-testid="liveSteam-notes">
              <FormInput
                control={control}
                name="notes"
                type="text"
                disabled={status !== "NOT_STARTED"}
                label="Notes"
                multiline={true}
                rows={10}
              />
            </Grid>
          </Grid>
        </Form>
        <Footer
          cancelBtnClick={() => setOpenCancelDialog(true)}
          saveBtnClick={handleSubmit(saveHandler(false))}
          isDisabled={
            !isValid ||
            isSaving ||
            !isDirty ||
            loading ||
            (!file && !settingThumbnailId) ||
            !sportLocationInputValue
          }
          isLoading={isSaving || loading}
        />
        <ConfirmationDialog
          title="Are you sure you want to cancel?"
          body="All of your current changes will be lost."
          open={openCancelDialog}
          close={() => setOpenCancelDialog(false)}
          onCancel={() => setOpenCancelDialog(false)}
          onConfirm={() => navigate("/live-stream")}
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
        />
        {openChangeAddress && (
          <GoogleMap
            close={closeAddressChange}
            onAddressChange={onAddressChanges}
          />
        )}
      </Container>
    </Loader>
  );
};
