import { memo, useState } from "react";
import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Delete } from "@mui/icons-material";
import { ConfirmationDialog } from "./ConfirmationDialog";
const MediaContainerBox = styled("div")(() => ({
  width: "100%",
  height: "100%",
  position: "relative"
}));

const DeleteContainerBox = styled("div")(() => ({
  width: "100%",
  height: "100%",
  objectFit: "contain",
  position: "absolute",
  backgroundColor: "#FFFFFF44",
  alignItems: "flex-start",
  top: 0,
  display: "flex",
  justifyContent: "flex-end"
}));
const MediaContianerWithDelete = (props: {
  children;
  onConfirmDelete;
  index;
  disabled?;
}) => {
  const [isHovered, setHovered] = useState<boolean>(false);
  const [toDelete, setToDelete] = useState<boolean>(false);
  return (
    <MediaContainerBox
      data-testid="media-container"
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      {props.children}
      {isHovered && (
        <DeleteContainerBox
          style={props.children.props.style}
          data-testid="media-delete-box"
        >
          <IconButton
            disabled={props.disabled}
            onClick={() => setToDelete(true)}
          >
            <Delete />
          </IconButton>
        </DeleteContainerBox>
      )}
      <ConfirmationDialog
        open={!!toDelete}
        title="Delete image"
        body={`Are you sure you want to delete this media?`}
        close={() => setToDelete(false)}
        onConfirm={() => {
          setToDelete(false);
          setHovered(false);
          props.onConfirmDelete(props.index);
        }}
        onCancel={() => setToDelete(false)}
        confirmBtnVariant="admin-warning"
        icon="warning"
      />
    </MediaContainerBox>
  );
};

export default memo(MediaContianerWithDelete);
