/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from "react";
import { Button } from "@components/Button";
import { Backdrop, Box, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import CloseIcon from "@mui/icons-material/Close";
import { getCreatePostSport } from "@services/Network";
import { useForm } from "react-hook-form";
import { FormInput } from "./FormInput";
import { FormMultiSelect } from "./FormMultiSelect";
import { organizationAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";
import { DevTool } from "@hookform/devtools";
const PostBtn = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "8px 20px",
  gap: "8px",
  width: "100px",
  height: "40px",
  backgroundColor: theme.palette.info.main,
  borderRadius: "100px",
  color: theme.palette.white.main,
  textTransform: "none",

  "&:hover": {
    backgroundColor: theme.palette.info.main,
    opacity: 0.8
  }
}));
const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "16px",
  marginLeft: "125px",
  width: "100%",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "0%"
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px"
  }
}));
const StyledBoxHeader = styled(Box)({
  height: "64px",
  backgroundColor: "#4F46E5",
  borderRadius: "16px 16px 0px 0px"
});
const StyledFieldLabel = styled(Typography)({
  fontSize: "13px",
  fontWeight: "400",
  color: "#B3B3B3"
});
export const PostCommentAction = (props: {
  title;
  field;
  action;
  button;
  onClose;
  displayMessage?;
  id?;
  onClick?;
  setMessage?;
}) => {
  let sportOptions: { label: string; value: string | undefined }[] = [];
  let sportOptionsLoading = false;
  const organizationId = useRecoilValue(organizationAtom);
  const {
    control,
    getValues,
    setValue,
    formState: { isValid }
  } = useForm({
    mode: "onBlur"
  });
  if (props.action === "create") {
    const sport = getCreatePostSport({ organizationId: organizationId });
    sportOptions = useMemo(() => {
      const options =
        sport?.data.map((sport) => ({
          label: sport.name!,
          value: sport.sportId
        })) || [];
      options.unshift({
        label: "Any Sport",
        value: "ALL"
      });
      return options;
    }, [sport.data]);
    sportOptionsLoading = sport.isLoading;
  }
  useEffect(() => {
    if (
      props.action === "create" &&
      !sportOptionsLoading &&
      sportOptions &&
      sportOptions.length == 1
    ) {
      setValue("sportId", [sportOptions[0].value]);
    }
  }, [sportOptions]);
  const handlePostDeleteClick = () => {
    if (props.action == "delete") {
      props.setMessage({ message: getValues("message") });
      props.onClick();
    }
    if (props.action == "create") {
      let sports = getValues("sportId");
      if (sports.length === 1 && sports[0] === "ALL")
        sports = sportOptions.map((s) => s.value).filter((s) => s !== "ALL");
      props.onClick(getValues("message"), sports);
    }
  };

  const [disableSportOptions, setDisableSportOptions] = useState(false);
  return (
    <Backdrop
      sx={{
        overflow: "hidden",
        overflowY: "none",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={true}
    >
      <Container data-testid="post-comment-action">
        <StyledBox>
          <StyledBoxHeader>
            <Grid
              container
              style={{
                padding: "18px 20px 20px 32px",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Grid>
                <Typography
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                    fontStyle: "normal",
                    lineHeight: "20px",
                    fontWeight: "600"
                  }}
                >
                  {props.title}
                </Typography>
              </Grid>
              <Grid>
                <CloseIcon
                  data-testid="post-comment-close-icon"
                  style={{ cursor: "pointer" }}
                  onClick={props.onClose}
                />
              </Grid>
            </Grid>
          </StyledBoxHeader>
          <Box style={{ padding: "32px", paddingBottom: "1px" }}>
            {props.displayMessage && (
              <Typography style={{ color: "#B3B3B3", marginBottom: "25px" }}>
                {props.displayMessage}
              </Typography>
            )}
            <Grid container spacing={3}>
              <Grid xs={6}>
                <StyledFieldLabel>{props.field[0].field}</StyledFieldLabel>
                <Typography style={{ fontWeight: "600" }}>
                  {props.field[0].value}
                </Typography>
              </Grid>
              <Grid xs={6}>
                <StyledFieldLabel>{props.field[1]?.field}</StyledFieldLabel>
                <Typography style={{ fontWeight: "600" }}>
                  {props.field[1]?.value}
                </Typography>
              </Grid>
              {props.action == "create" && props.button == "Post" && (
                <Grid xs={6} data-testid="sportId">
                  <FormMultiSelect
                    //@ts-ignore
                    preSelectedOptions={
                      sportOptions.length == 1
                        ? [sportOptions[0].value]
                        : undefined
                    }
                    name="sportId"
                    disabledMenuOptions={
                      disableSportOptions
                        ? sportOptions.map((s) => s.value)
                        : []
                    }
                    control={control}
                    label="Select Sport"
                    options={sportOptions}
                    isLoading={sportOptionsLoading}
                    required={true}
                    onModify={(e) => {
                      if (e.target.value.includes("ALL")) {
                        setValue("sportId", ["ALL"]);
                        setDisableSportOptions(true);
                      } else {
                        setValue("sportId", e.target.value);
                        setDisableSportOptions(false);
                      }
                    }}
                    onRemove={(val) => {
                      if (val === "ALL") {
                        setDisableSportOptions(false);
                      }
                    }}
                    rules={{
                      required: "Sport is required"
                    }}
                  />
                </Grid>
              )}
              <Grid xs={12} data-testid="message">
                <FormInput
                  name="message"
                  control={control}
                  label="Message"
                  type="text"
                  rows={4}
                  multiline
                  required={true}
                  rules={{
                    required: "Message is required"
                  }}
                />
              </Grid>
            </Grid>
            <Grid container></Grid>
            <Grid
              data-testid="post-comment-action-button"
              style={{
                margin: "30px",
                display: "flex",
                gap: "16px",
                justifyContent: "flex-end"
              }}
            >
              <PostBtn onClick={props.onClose} variant="admin-secondary">
                Cancel
              </PostBtn>
              <PostBtn
                disabled={!isValid}
                onClick={handlePostDeleteClick}
                variant={
                  props.action == "create"
                    ? "admin-primary"
                    : props.action == "delete"
                    ? "admin-warning"
                    : ""
                }
              >
                {props.button}
              </PostBtn>
            </Grid>
            <DevTool control={control} />
          </Box>
        </StyledBox>
      </Container>
    </Backdrop>
  );
};
