import { TableView } from "@components/TableView";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { getTeams } from "@services/Network";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const LIST_COLUMNS = [
  { headerName: "Name", field: "name", minWidth: 150, flex: 1 },
  {
    headerName: "Sport",
    field: "sport",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ value }) => value?.name
  },
  {
    headerName: "Season",
    field: "season",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ value }) => value?.name
  },
  {
    headerName: "Level",
    field: "level",
    minWidth: 150,
    flex: 1,
    sortable: true,
    valueGetter: ({ value }) => value?.name
  },
  {
    headerName: "Players",
    field: "players",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => {
      const selectedPlayersInvite = row.invites?.filter(
        (item) => item.invitedFor![0]?.alias === "PLAYER"
      );
      const addedPlayers = row.userRoles?.filter(
        (item) => item.role?.alias === "PLAYER"
      );
      return selectedPlayersInvite.length + addedPlayers.length;
    }
  },
  {
    headerName: "Coaches",
    field: "coaches",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => {
      const selectedPlayersInvite = row.invites?.filter(
        (item) => item.invitedFor![0]?.alias === "COACH"
      );
      const addedPlayers = row.userRoles?.filter(
        (item) => item.role?.alias === "COACH"
      );
      return selectedPlayersInvite.length + addedPlayers.length;
    }
  },
  {
    headerName: "Managers",
    field: "managers",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => {
      const selectedPlayersInvite = row.invites?.filter(
        (item) => item.invitedFor![0]?.alias === "MANAGER"
      );
      const addedPlayers = row.userRoles?.filter(
        (item) => item.role?.alias === "MANAGER"
      );
      return selectedPlayersInvite.length + addedPlayers.length;
    }
  }
];

export const Teams = () => {
  const navigate = useNavigate();
  const orgId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const org = organizations.find((o) => o.organizationId === orgId);

  const [refreshKey] = React.useState(0);

  const onAdd = () => navigate("/teams/create");
  const onEdit = (row) => navigate(`/teams/${row.teamId}/edit`);
  const onView = (row) => navigate(`/teams/${row.teamId}`);

  return (
    <>
      <TableView
        title="Teams"
        useGet={getTeams}
        columns={LIST_COLUMNS}
        hideFilter
        hideLabelContainer
        defaultSort={[{ field: "name", sort: "asc" }]}
        onAdd={
          org?.offering?.includes("TEAMS")
            ? () => {
                onAdd();
              }
            : undefined
        }
        onEdit={onEdit}
        onView={onView}
        isDeleteDisabled={() => true}
        refreshKey={refreshKey}
      />
    </>
  );
};
