import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import { useApiSelectOptions } from "@hooks/useApiSelectOptions";
import Grid from "@mui/material/Unstable_Grid2";
import {
  useAdminPositionGet,
  useAdminPositionPost,
  useSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalizeEveryWord } from "@utils/capitalize";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export const PositionCreate = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [parentId, setParentId] = useState("");

  const { options: sportOptions, isLoading: sportOptionsLoading } =
    useApiSelectOptions({
      api: useSportGet,
      dataField: "sports",
      labelField: "name",
      valueField: "sportId"
    });
  const { options: parentOptions, isLoading: parentOptionsLoading } =
    useApiSelectOptions({
      api: useAdminPositionGet,
      dataField: "positions",
      labelField: "name",
      valueField: "positionId",
      params: {
        sportId: parentId
      },
      options: {
        query: {
          enabled: !!parentId
        }
      }
    });

  const {
    handleSubmit,
    control,
    formState: { isValid },
    setValue,
    reset
  } = useForm({
    mode: "onBlur"
  });

  const { mutate: save, isLoading: isSaving } = useAdminPositionPost();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    (formValues) => {
      save(
        {
          data: formValues
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Position added successfully!", {
              variant: "success"
            });
            if (resetInsteadOfRoute) {
              reset();
            } else {
              navigate("/positions");
            }
          },
          onError: () => {
            enqueueSnackbar("Failed to add position!", { variant: "error" });
          }
        }
      );
    };

  return (
    <Container>
      <Toolbar title="Add Position" />
      <Form>
        <Grid container spacing={2} data-testid="POSITION_CREATE_FORM">
          <Grid xs={12} md={6} data-testid="POSITION_CREATE_NAME">
            <FormInput
              control={control}
              name="name"
              type="text"
              label="Name"
              required={true}
              onChange={(e) => {
                setValue("name", capitalizeEveryWord(e.target.value));
              }}
              rules={{
                required: "Name is required"
              }}
            />
          </Grid>
          <Grid xs={12} md={6} data-testid="POSITION_CREATE_ABBR">
            <FormInput
              control={control}
              name="abbreviation"
              type="text"
              label="Abbreviation"
              required={true}
              rules={{
                required: "Abbreviation is required",
                maxLength: {
                  value: 6,
                  message: "Abbreviation must be 6 characters or less"
                }
              }}
            />
          </Grid>
          <Grid xs={12} md={6} data-testid="POSITION_CREATE_SPORT">
            <FormSelect
              control={control}
              name="sportId"
              label="Sport"
              required={true}
              isLoading={sportOptionsLoading}
              options={sportOptions}
              onChange={(e) => {
                setValue("parentId", "");
                setParentId(e.target.value);
              }}
              rules={{
                required: "Sport is required"
              }}
            />
          </Grid>
          <Grid xs={12} md={6} data-testid="POSITION_CREATE_PARENT">
            <FormSelect
              control={control}
              name="parentId"
              label="Parent Position"
              required={false}
              options={parentOptions}
              isLoading={!!parentId && parentOptionsLoading}
            />
          </Grid>
        </Grid>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler(false))}
        saveAndNewBtnClick={handleSubmit(saveHandler(true))}
        isDisabled={!isValid || isSaving}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/positions")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
