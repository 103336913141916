import { DataGridRenderCellTooltipTrainingDates } from "@components/DataGridRenderCellToolTipTrainingDates";
import { TableView } from "@components/TableView";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { getTrainingPrograms } from "@services/Network";
import { TrainingProgramTypeOptions } from "@utils/constants";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const LIST_COLUMNS = [
  { headerName: "Name", field: "name", minWidth: 150, flex: 1 },
  {
    headerName: "Type",
    field: "type",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ value }) =>
      TrainingProgramTypeOptions.find((option) => option.value === value)?.label
  },
  {
    headerName: "Sport",
    field: "sport",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ value }) => value?.name
  },
  {
    headerName: "Training Dates",
    field: "dates",
    minWidth: 250,
    flex: 1,
    sortable: false,
    renderCell: DataGridRenderCellTooltipTrainingDates()
  },
  {
    headerName: "Athletes",
    field: "athletes",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => {
      const selectedPlayersInvite = row.invites?.filter(
        (item) => item.invitedFor![0]?.alias === "PLAYER"
      );
      const addedPlayers = row.userRoles?.filter(
        (item) => item.role?.alias === "PLAYER"
      );
      return selectedPlayersInvite.length + addedPlayers.length;
    }
  },
  {
    headerName: "Coaches",
    field: "coaches",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => {
      const selectedPlayersInvite = row.invites?.filter(
        (item) => item.invitedFor![0]?.alias === "COACH"
      );
      const addedPlayers = row.userRoles?.filter(
        (item) => item.role?.alias === "COACH"
      );
      return selectedPlayersInvite.length + addedPlayers.length;
    }
  },
  {
    headerName: "Managers",
    field: "managers",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => {
      const selectedPlayersInvite = row.invites?.filter(
        (item) => item.invitedFor![0]?.alias === "MANAGER"
      );
      const addedPlayers = row.userRoles?.filter(
        (item) => item.role?.alias === "MANAGER"
      );
      return selectedPlayersInvite.length + addedPlayers.length;
    }
  }
];

export const Programs = () => {
  const navigate = useNavigate();
  const orgId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const org = organizations.find((o) => o.organizationId === orgId);
  const [refreshKey] = React.useState(0);

  const filterConfig = {
    field: "type",
    placeholderOption: { label: "All Types", value: "" },
    options: TrainingProgramTypeOptions
  };

  const onAdd = () => navigate("/training-programs/create");
  const onEdit = (row) => navigate(`/training-programs/${row.programId}/edit`);
  const onView = (row) => navigate(`/training-programs/${row.programId}`);

  return (
    <>
      <TableView
        title="Training Programs"
        useGet={getTrainingPrograms}
        columns={LIST_COLUMNS}
        filterConfig={filterConfig}
        defaultSort={[{ field: "name", sort: "asc" }]}
        onAdd={
          org?.offering?.includes("TRAINING_PROGRAM")
            ? () => {
                onAdd();
              }
            : undefined
        }
        onEdit={onEdit}
        onView={onView}
        isDeleteDisabled={() => true}
        refreshKey={refreshKey}
      />
    </>
  );
};
