import { TransferList } from "@components/TransferList";
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
import { GridColDef } from "@mui/x-data-grid-pro";
import {
  ModelPerson,
  ModelRole,
  TeamTrainingProgramCreateUserInput,
  TeamTrainingProgramUserMetadata
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useMemo } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { UserOverride } from "../components/UserOverride";
import { RenderTableView } from "@components/RenderTableView";
import { ActiveUserIcon, PendingUserIcon } from "@components/Icons";
import { Delete } from "@mui/icons-material";
import { ToolTip } from "@components/ToolTip";

export interface CoachOption extends TeamTrainingProgramCreateUserInput {
  label: string;
  id: string;
  value: string;
  parent?: CoachOption;
  status?: string;
}

interface CoachSelectionFormProps {
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<FieldValues, any, undefined>;
  isEditing?: boolean;
  isLoadingUsers?: boolean;
  userOptions: ModelPerson[];
  subRoleOptions: ModelRole[];
  coaches: string[];
  setCoaches: (value: string[]) => void;
  coachesWithValues: CoachOption[];
  setCoachesWithValues: (value: CoachOption[]) => void;
  roleId: string;
  setUserOptions?: (value: ModelPerson[]) => void;
}

export const CoachSelectionForm = (props: CoachSelectionFormProps) => {
  const { setValue } = props.form;

  const getUpdatedUserOptions = (
    userOptions: ModelPerson[],
    usersWithValues
  ) => {
    const includedUsers = usersWithValues.filter(
      (u) =>
        u.user &&
        u.user.person &&
        userOptions.findIndex((option) => option.personId === u.id) === -1
    );
    if (includedUsers.length) {
      props.setUserOptions!([
        ...userOptions,
        ...includedUsers.map((u) => {
          return u.user.person;
        })
      ]);
      return [
        ...userOptions,
        ...includedUsers.map((u) => {
          return u.user.person;
        })
      ];
    } else return userOptions;
  };
  const coachOptions = useMemo(() => {
    if (!props.disabled && props.userOptions) {
      const updatedUserOptions = getUpdatedUserOptions(
        props.userOptions,
        props.coachesWithValues
      );
      return updatedUserOptions.map((p) => ({
        id: p.personId!,
        value: p.personId!,
        label: `${p.firstName} ${p.lastName}`,
        ...p
      }));
    } else return [];
  }, [props.userOptions]);

  useEffect(() => {
    if (!props.disabled) {
      const prevCoachIds = props.coachesWithValues.map((c) => c.personId);
      const newCoachIds = props.coaches.filter(
        (c) => !prevCoachIds.includes(c)
      );
      if (props.coaches.length < props.coachesWithValues.length) {
        props.setCoachesWithValues([
          ...props.coachesWithValues.filter((coachWithValue) =>
            props.coaches.includes(coachWithValue.id)
          )
        ]);
      } else {
        props.setCoachesWithValues([
          ...props.coachesWithValues,
          ...newCoachIds.map((id) => {
            const user =
              props.userOptions.find((u) => u.personId === id) ||
              coachOptions.find((u) => u.personId === id);
            return {
              id: user!.personId!,
              value: user!.personId!,
              personId: user!.personId!,
              label: `${user?.firstName} ${user?.lastName}`,
              ...user,
              roleId: props.roleId,
              permissions: []
            };
          })
        ]);
      }
    }
    console.log("props.coaches", props.coachesWithValues);
  }, [props.coaches, props.userOptions]);

  const onDelete = (row) => {
    const index = props.coaches.indexOf(row.id);
    if (index !== -1) {
      props.setCoaches([
        ...props.coaches.slice(0, index),
        ...props.coaches.slice(index + 1)
      ]);
      props.setCoachesWithValues([
        ...props.coachesWithValues.slice(0, index),
        ...props.coachesWithValues.slice(index + 1)
      ]);
    }
  };

  const LIST_COLUMNS: GridColDef[] = [
    {
      field: "action",
      headerName: "",
      width: 50,
      renderCell: (params) => (
        <IconButton onClick={() => onDelete(params.row)}>
          <Delete />
        </IconButton>
      )
    },
    {
      headerName: "Name",
      field: "label",
      minWidth: 250,
      flex: 1,
      sortable: false
    },
    {
      headerName: "Role",
      field: "role",
      sortable: false,
      width: 120,
      flex: 1,
      renderCell: () => {
        return <>Coach</>;
      }
    },
    ...(props?.subRoleOptions?.length > 0
      ? [
          {
            headerName: "Sub Role",
            field: "coachSubRole",
            minWidth: 350,
            sortable: false,
            flex: 1,
            renderCell: (params) => (
              <Select
                value={params.row.roleId}
                onChange={(event) => {
                  //update the roleId in the coachsWithValues
                  props.setCoachesWithValues(
                    props.coachesWithValues.map((item) => {
                      if (item.personId === params.row.personId) {
                        return {
                          ...item,
                          roleId: event.target.value
                        };
                      }
                      return item;
                    })
                  );
                }}
                MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                style={{ width: "70%", height: 40 }}
              >
                {props?.subRoleOptions?.map((item) => (
                  <MenuItem key={item.roleId} value={item.roleId}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            )
          }
        ]
      : []),
    {
      headerName: "Title",
      field: "coachTitle",
      minWidth: 250,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Box>
          <TextField
            placeholder=""
            type={"text"}
            size="small"
            style={{ width: "100%" }}
            value={
              params.row.metadata
                ? (params.row.metadata as TeamTrainingProgramUserMetadata)[
                    "title"
                  ]
                : ""
            }
            //to allow spaces inside the input
            onKeyDown={(event) => {
              if (event.keyCode === 32) {
                event.stopPropagation();
              }
            }}
            onChange={(event) => {
              //update the roleId in the coachsWithValues
              props.setCoachesWithValues(
                props.coachesWithValues.map((item) => {
                  if (item.personId === params.row.personId) {
                    return {
                      ...item,
                      metadata: {
                        title: event.target.value
                      }
                    };
                  }
                  return item;
                })
              );
            }}
          />
        </Box>
      )
    }
  ];

  const LIST_COLUMNS_VIEW: GridColDef[] = [
    {
      headerName: "Name",
      field: "label",
      minWidth: 250,
      sortable: false,
      flex: 1
    },
    {
      headerName: "Role",
      field: "role",
      width: 120,
      sortable: false,
      flex: 1,
      renderCell: () => {
        return <>Coach</>;
      }
    },
    ...(props.subRoleOptions?.length > 0
      ? [
          {
            headerName: "Sub Role",
            field: "coachSubRole",
            minWidth: 200,
            sortable: false,
            flex: 1,
            renderCell: (params) => {
              const index = props?.subRoleOptions.findIndex(
                (subRole) => subRole.roleId === params.row.roleId
              );
              return (
                <>
                  {index !== -1 ? props?.subRoleOptions?.at(index)?.name : ""}
                </>
              );
            }
          }
        ]
      : []),
    {
      headerName: "Title",
      field: "coachTitle",
      minWidth: 200,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.metadata && params.row.metadata.title
            ? params.row.metadata.title
            : ""}
        </>
      )
    },
    {
      headerName: "Status",
      field: "status",
      minWidth: 100,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        if (params.row.status === "ACTIVE") {
          return (
            <>
              <ToolTip title="User Accepted" placement="right">
                <div {...props} data-testid="COACH_USER_ACCEPTED">
                  <ActiveUserIcon />
                </div>
              </ToolTip>
            </>
          );
        } else if (params.row.status === "PENDING")
          return (
            <>
              <ToolTip title="Invite Pending" placement="right">
                <div {...props} data-testid="COACH_USER_PENDING">
                  <PendingUserIcon />
                </div>
              </ToolTip>
            </>
          );
        else return <></>;
      }
    }
  ];

  return (
    <Grid container direction="column" spacing="25px">
      <Grid item container direction="row" spacing="24px">
        {!props.disabled && (
          <Grid item xs={12}>
            <TransferList
              name="coaches"
              label="Coaches"
              control={props.form.control}
              rules={{ required: "At least one coach is required" }}
              isLoading={props.isLoadingUsers}
              options={coachOptions}
              required={true}
              setValue={setValue}
              setState={props.setCoaches}
              state={props.coaches}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <RenderTableView
            title="Coaches"
            hideToolbar
            hasActionColumn={false}
            rows={props.coachesWithValues}
            columns={props.disabled ? LIST_COLUMNS_VIEW : LIST_COLUMNS}
            hideFilter
            sortingMode="client"
            getRowId={(row) => row?.id}
            searchable={false}
          />
        </Grid>
        {!props.disabled && (
          <Grid item xs={12}>
            <UserOverride
              title="Coach"
              masterList={props.coaches}
              setMasterList={props.setCoaches}
              userOptions={props.userOptions}
              setUserOptions={props.setUserOptions!}
              roleId={props.roleId}
              roleAlias="COACH"
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
