import { TableView } from "@components/TableView";
import { Chip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { getOrganizationUploads } from "@services/Network";
import {
  ModelOrganizationUpload,
  ModelOrganizationUploadStatus
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const ImportPlayer = () => {
  const navigate = useNavigate();
  const [refreshKey] = useState(0);
  const dateFormat = (date) => {
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true
    };
    return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
  };
  const IMPORT_PLAYER_COLUMNS: GridColDef<
    ModelOrganizationUpload & {
      id: string;
      createdAt: string;
    }
  >[] = [
    {
      headerName: "Status",
      field: "status",
      minWidth: 150,
      flex: 1,
      cellClassName: "super-app-theme--cell",
      renderCell: (params) => {
        const status = params.value as string as ModelOrganizationUploadStatus;
        return (
          <Chip
            style={{
              fontSize: "10px",
              padding: "4px 8px",
              marginLeft: "-14px",
              fontWeight: 600
            }}
            label={status}
            sx={{
              background:
                status === ("PROCESSING" || "PENDING")
                  ? "#FBF1BC"
                  : status === "COMPLETED"
                  ? "#BBF7D0"
                  : "#FECACA",
              color:
                status === ("PROCESSING" || "PENDING")
                  ? "#7B5B08"
                  : status === "COMPLETED"
                  ? "#166534"
                  : "#991B1B"
            }}
          />
        );
      },
      sortable: false
    },
    {
      headerName: "For",
      field: "for",
      minWidth: 100,
      flex: 1,
      cellClassName: "super-app-theme--cell",
      sortable: false
    },
    {
      headerName: "Sport",
      field: "sport",
      minWidth: 100,
      flex: 1,
      sortable: false,
      valueGetter: (value) => value.row.sport?.name
    },
    {
      headerName: "Season",
      field: "season",
      minWidth: 100,
      flex: 1,
      sortable: false,
      valueGetter: (value) => value.row.season?.name
    },
    {
      headerName: "Template File",
      field: "templateFile",
      minWidth: 120,
      flex: 1,
      sortable: false,
      valueGetter: (value) => value.row.fileName || "N/A"
    },
    {
      headerName: "Total Rows",
      field: "totalRows",
      minWidth: 100,
      flex: 1,
      sortable: false,
      valueGetter: (value) => value.row.rowCount
    },
    {
      headerName: "Imported Rows",
      field: "importedRows",
      minWidth: 130,
      flex: 1,
      sortable: false,
      valueGetter: (value) => value.row._count.importedRecords || 0
    },
    {
      headerName: "Failed Rows",
      field: "failedRows",
      minWidth: 110,
      flex: 1,
      sortable: false,
      valueGetter: (value) => value.row._count.failedRecords
    },
    {
      headerName: "Total Person Records",
      field: "totalPersonRecords",
      minWidth: 170,
      flex: 1,
      sortable: false,
      valueGetter: (value) => value.row.importedRecords?.length
    },
    {
      headerName: "Imported Person Records",
      field: "importedPersonRecords",
      minWidth: 200,
      flex: 1,
      sortable: false,
      valueGetter: (value) => value.row.importedRecords?.length
    },
    {
      headerName: "Failed Person Records",
      field: "failedPersonRecords",
      minWidth: 180,
      flex: 1,
      sortable: false,
      valueGetter: (value) => value.row.failedRecords?.length
    },
    {
      headerName: "Uploaded By",
      field: "uploadedBy",
      minWidth: 130,
      flex: 1,
      sortable: false,
      valueGetter: (value) =>
        value.row.uploadedBy?.person?.firstName +
        " " +
        value.row.uploadedBy?.person?.lastName
    },
    {
      headerName: "Uploaded Date/Time",
      field: "createdAt",
      minWidth: 230,
      flex: 1,
      sortable: false,
      valueGetter: (value) => dateFormat(value.row.createdAt)
    }
  ];

  const onAdd = () => {
    navigate("create");
  };

  const onView = (upload) => {
    navigate(`${upload.id}`);
  };

  return (
    <>
      <TableView
        sx={{
          "& .disabled-row--BANNED": {
            color: "#ABAFB5",
            fontWeight: "400"
          }
        }}
        title="Upload Athletes / Players"
        useGet={getOrganizationUploads}
        columns={IMPORT_PLAYER_COLUMNS}
        onAdd={onAdd}
        isEditDisabled={() => true}
        onView={onView}
        refreshKey={refreshKey}
        hideFilter={true}
        hideLabelContainer={true}
        pinnedColumns={{ left: ["action", "status", "for", "sport"] }}
      />
    </>
  );
};
