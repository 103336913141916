import { Box } from "@mui/system";
import { memo, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import pdfWorker from "pdfjs-dist/build/pdf.worker.min.js";
import ReactPlayer from "react-player";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import styled from "styled-components";
import { Container } from "./crud/Container";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  pdfWorker,
  import.meta.url
).toString();

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/"
};

const StyledPage = styled(Page)`
  &.pdf-page {
    background-color: #efefef !important;
    padding: 15px;
    canvas {
      margin-top: 15px;
      width: 100% !important;
      height: 450px !important;
      border-radius: 5px;
    }
  }
`;

const PageCount = styled(Box)`
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 1;
`;

const CurrentPage = styled(Box)`
  position: absolute;
  top: 10px;
  right: 20px;
`;

const BackButton = styled(ArrowBackIosIcon)`
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(0, -50%);
  cursor: pointer;
`;

const ForwardButton = styled(ArrowForwardIosIcon)`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
  cursor: pointer;
`;

const StyledBox = styled(Box)`
  position: relative;
`;

const StyledPlayIcon = styled(PlayArrowOutlinedIcon)`
  background-color: #000000;
  opacity: 0.75;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
`;

const FeedIndividualMedia = (props: {
  src: string;
  style;
  onClick?;
  metaData?;
  mimeType?;
  remainingMediaCount?;
}) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNextPage = () => {
    if (numPages !== undefined && pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  if (
    props.src.split(".").pop() === "jpg" ||
    props.src.split(".").pop() === "jpeg" ||
    props.src.split(".").pop() === "png" ||
    props.src.split(".").pop() === "jpe"
  ) {
    if (props.remainingMediaCount) {
      return (
        <div
          data-testid="feed-individual-media-view"
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "#000",
            position: "relative"
          }}
        >
          <img src={props.src} style={props.style} />
          <div
            onClick={props.onClick}
            style={{
              display: "flex",
              position: "absolute",
              transform: "translate(-50%, -50%)",
              color: "#fff",
              fontSize: "24px",
              backgroundColor: "#000",
              fontWeight: "bold",
              width: "100%",
              height: "100%",
              opacity: "0.5",
              top: "50%",
              left: "50%",
              textAlign: "center"
            }}
          >
            <div style={{ margin: "auto" }}>+{props.remainingMediaCount}</div>
          </div>
        </div>
      );
    } else {
      return (
        <img
          src={props.src}
          onClick={props.onClick}
          style={props.style}
          data-testid="feed-individual-media-view"
        />
      );
    }
  }

  if (
    props.src.split(".").pop() === "mp4" ||
    props.src.split(".").pop() === "m3u8"
  ) {
    return (
      <StyledBox style={props.style}>
        <ReactPlayer
          onClick={props.onClick}
          height={props.style.height}
          width={props.style.width || "100%"}
          url={props.src}
          style={props.style}
        />
        <StyledPlayIcon fontSize="large" />
      </StyledBox>
    );
  }

  if (props.mimeType == "application/pdf") {
    return (
      <Container
        data-testid="pdf-viewer"
        style={{
          backgroundColor: "#efefef",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative"
        }}
      >
        <PageCount style={{ fontWeight: 400, fontSize: "11px" }}>
          <Grid container>
            <Grid>
              <Typography style={{ fontWeight: 400, fontSize: "11px" }}>
                {props.metaData?.documentName} •{" "}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                style={{
                  marginLeft: "5px",
                  fontWeight: 400,
                  fontSize: "10px",
                  color: "#666"
                }}
              >
                {numPages}{" "}
                {numPages !== undefined && numPages > 1 ? "Pages" : "Page"}
              </Typography>
            </Grid>
          </Grid>
        </PageCount>
        <Document
          file={props.src}
          options={options}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <StyledPage
            className={"pdf-page"}
            scale={1}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            pageNumber={pageNumber}
            width={500}
            height={50}
            devicePixelRatio={5}
          />
        </Document>
        <CurrentPage>
          <Typography
            style={{ fontWeight: 400, fontSize: "10px", color: "#666" }}
          >
            Page {pageNumber}
          </Typography>
        </CurrentPage>
        {pageNumber != 1 && <BackButton onClick={handlePreviousPage} />}
        {pageNumber !== numPages && <ForwardButton onClick={handleNextPage} />}
      </Container>
    );
  }
};

export default memo(FeedIndividualMedia);
