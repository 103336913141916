/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  // Popover,
  Popper,
  TextFieldProps,
  Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
// import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { TransitionProps } from "@mui/material/transitions";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import { TreeView } from "@mui/x-tree-view/TreeView";
import {
  TreeItem,
  TreeItemProps,
  treeItemClasses
} from "@mui/x-tree-view/TreeItem";
import { SearchInput } from "@components/SearchInput";
import { ArrowDropDown, Close } from "@mui/icons-material";
import { Loader } from "./crud/Loader";
import {
  ModelFeature,
  useAdminFeatureGet,
  // useAdminQAFeatureGet,
  adminQAFeatureGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Control, Controller } from "react-hook-form";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));
const TransitionComponent = (props: TransitionProps) => {
  const style = {};

  return (
    <div style={style}>
      <Collapse {...props} />
    </div>
  );
};

// eslint-disable-next-line react/display-name
const CustomTreeItem = React.forwardRef(
  (props: TreeItemProps, ref: React.Ref<HTMLLIElement>) => (
    <TreeItem {...props} TransitionComponent={TransitionComponent} ref={ref} />
  )
);

const StyledTreeItem = styled(CustomTreeItem)(({ children }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3
    }
  },

  [`&.${treeItemClasses.root}`]: {
    padding: "5px",
    borderLeft: "1px solid rgba(229, 229, 229, 1)",
    borderBottom: !children ? "1px solid rgba(229, 229, 229, 1)" : "none",
    pointerEvents: "none"
  }
}));

export const FilterTreeSearchComponent = (props: {
  onFilterChange: (filteredValue) => void;
  onClearFilter?: () => void;
  selectedValue?: string | undefined;
  label?: string;
  placeHolder?: string;
  disableSelected?: boolean;
  name: string;
  control?: Control<any, any>;
  required: boolean | undefined;
  rules: any | undefined;
  disabled: boolean;
  setValue?;
  currentFeatureId?: string;
  platform?: string;
  TextProps?: TextFieldProps;
  style?: any;
  release?: string;
}) => {
  const { data: data, isLoading: isLoading } = useAdminFeatureGet({
    flatten: false,
    platform: props.platform ? props.platform : "ALL",
    sortField: "name",
    sortDirection: "asc",
    pageSize: 100
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [selectedNode, setSelectedNode] = React.useState<string>("");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const filterFeaturesByName = (searchString) => {
    const features = initialOptions;
    const filteredFeatures = [];

    const traverse = (feature: ModelFeature) => {
      if (feature.name?.toLowerCase()!.includes(searchString.toLowerCase())) {
        filteredFeatures.push(feature);
      }
      feature.children?.forEach((child) => {
        if (
          !filteredFeatures.some(
            (parent) => parent.featureId === feature.featureId
          )
        ) {
          traverse(child);
        }
      });
    };

    features.forEach(traverse);

    setOptions([...filteredFeatures]);
  };

  const filterFeaturesById = (nodeId) => {
    const features = initialOptions;
    const filteredFeatures = [];

    const traverse = (feature: ModelFeature) => {
      if (feature.featureId === nodeId) {
        filteredFeatures.push(feature);
      }
      feature.children?.forEach((child) => {
        if (
          !filteredFeatures.some(
            (parent) => parent.featureId === feature.featureId
          )
        ) {
          traverse(child);
        }
      });
    };

    features.forEach(traverse);

    setOptions([...filteredFeatures]);
  };

  const getAllFeatureIds = (features) => {
    const result = [];

    const traverse = (feature) => {
      result.push(feature.featureId);
      feature.children.forEach(traverse);
    };

    features.forEach(traverse);
    return result;
  };

  const findFeatureNameById = (features, featureId) => {
    for (const { featureId: id, name, children } of features) {
      if (id === featureId) {
        return name;
      }
      if (children && children.length > 0) {
        const childFeatureName = findFeatureNameById(children, featureId);
        if (childFeatureName) {
          return childFeatureName;
        }
      }
    }
    return null;
  };

  const [initialOptions, setInitialOptions] = useState<ModelFeature[]>([]);
  const [options, setOptions] = useState<ModelFeature[]>([]);

  useEffect(() => {
    if (data?.data?.features) {
      setInitialOptions(data.data.features);
      if (props.selectedValue) {
        const featureName = findFeatureNameById(
          data.data.features,
          props.selectedValue
        );
        setSelectedValue(featureName);
      }
    }
  }, [data]);

  useEffect(() => {
    setOptions(initialOptions);
  }, [initialOptions]);

  useEffect(() => {
    if (props.selectedValue) {
      setSelectedNode(props.selectedValue);
    }
  }, [props.selectedValue]);

  useEffect(() => {
    if (
      !selectedNode ||
      selectedValue !== findFeatureNameById(initialOptions, selectedNode)
    )
      filterFeaturesByName(selectedValue);
    else filterFeaturesById(selectedNode);
  }, [selectedValue, selectedNode]);

  const [releaseNodes, setReleaseNodes] = useState<
    { nodeId: string; parentId: string | undefined }[]
  >([]);

  const [releaseId, setReleaseId] = useState<string>();
  useEffect(() => {
    if (props.release) setReleaseId(props.release);
  }, [props.release]);

  // const { data: releases } = useAdminQAFeatureGet({
  //   release: releaseId ? releaseId : "1.0",
  //   pageSize: 100
  // });

  useEffect(() => {
    const fetchData = async () => {
      if (releaseId) {
        try {
          const data = await adminQAFeatureGet({
            release: releaseId,
            pageSize: 3000
          });
          if (data.data.features) {
            setReleaseNodes(
              data.data.features.map((feat) => ({
                nodeId: feat.featureId!,
                parentId: feat.parentId
              }))
            );
          }
        } catch (error) {
          setReleaseNodes([]);
        }
      }
    };

    fetchData();
  }, [releaseId]);

  const renderTree = (node) => {
    const isDisabled =
      (props.disableSelected ? node.featureId === selectedNode : false) ||
      (props.currentFeatureId
        ? node.featureId === props.currentFeatureId
        : false) ||
      (releaseNodes &&
        releaseNodes.length > 0 &&
        !releaseNodes.find(
          (releaseNode) => releaseNode.nodeId === node.featureId
        ));

    return (
      <StyledTreeItem
        nodeId={node.featureId}
        label={
          <Grid
            data-testid={"treeItem_" + node.featureId}
            style={
              isDisabled
                ? {
                    pointerEvents: "none",
                    width: "100%"
                  }
                : {
                    pointerEvents: "auto",
                    cursor: "pointer"
                  }
            }
          >
            <Typography
              className="feature-name"
              style={isDisabled ? { color: "#B3B3B3" } : {}}
            >
              {node.name}
            </Typography>
          </Grid>
        }
        key={node.featureId}
      >
        {node.children && node.children.length
          ? node.children.map((child) => renderTree(child))
          : null}
      </StyledTreeItem>
    );
  };
  const boxRef = useRef(null);
  const boxPosition = boxRef.current?.getBoundingClientRect();
  const spaceBelow = boxPosition
    ? window.innerHeight - boxPosition.bottom
    : 500;
  const maxHeight = Math.min(500, spaceBelow - 50);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.classList.contains("feature-name") &&
        !event.target.classList.contains("MuiTreeView-root") &&
        !event.target.closest(".search-input") &&
        !event.target.closest('svg[data-testid="closeIcon"]') &&
        event.target.tagName !== "path"
      ) {
        handleClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [anchorEl]);

  return (
    <>
      <Loader isLoading={isLoading}>
        <Grid container direction="row">
          <Grid item minWidth={props.control ? "100%" : "100%"} ref={boxRef}>
            {props.label && (
              <StyledFormLabel required={props.required}>
                <Typography display="inline" variant="formLabel">
                  {props.label}
                </Typography>
              </StyledFormLabel>
            )}
            {!props.control && (
              <SearchInput
                placeholder={props.placeHolder || "All"}
                onClick={handleClick}
                value={selectedValue}
                disabled={props.disabled}
                required={false}
                InputProps={{
                  endAdornment:
                    selectedValue === "" ? (
                      <InputAdornment position="end">
                        <ArrowDropDown />
                      </InputAdornment>
                    ) : (
                      <IconButton
                        disabled={props.disabled}
                        onClick={() => {
                          setSelectedValue("");
                          setSelectedNode("");
                          if (props.onClearFilter) props.onClearFilter();
                        }}
                      >
                        <Close />
                      </IconButton>
                    )
                }}
                onChange={(e) => {
                  setSelectedValue(e.target.value);
                }}
                style={props.style ? props.style : {}}
              />
            )}
            {props.control && (
              <Controller
                name={props.name}
                control={props.control}
                rules={props.rules}
                render={({ field }) => (
                  <SearchInput
                    onClick={handleClick}
                    value={field.value}
                    disabled={props.disabled}
                    InputProps={{
                      endAdornment:
                        selectedValue === "" ? (
                          <InputAdornment position="end">
                            <ArrowDropDown />
                          </InputAdornment>
                        ) : (
                          <IconButton
                            disabled={props.disabled}
                            onClick={() => {
                              field.onChange("");
                              setSelectedValue("");
                              setSelectedNode("");
                              if (props.onClearFilter) props.onClearFilter();
                            }}
                          >
                            <Close />
                          </IconButton>
                        )
                    }}
                    {...(props.TextProps || {})}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      setSelectedValue(e.target.value);
                    }}
                  />
                )}
              />
            )}
            <Popper
              id={id}
              open={open}
              anchorEl={anchorEl}
              sx={{ marginTop: "5px !important" }}
              style={{
                zIndex: 9999,
                backgroundColor: "#fff",
                width: `${
                  boxRef.current?.getBoundingClientRect()?.width || 500
                }px`,
                height: "auto",
                overflow: "auto",
                borderRadius: "5px",
                boxShadow:
                  "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px"
              }}
            >
              <Box
                sx={{
                  maxHeight: `${maxHeight}px`,
                  padding: "10px",
                  width: "100%"
                }}
              >
                <TreeView
                  aria-label="customized"
                  expanded={getAllFeatureIds(options)}
                  selected={selectedNode}
                  onNodeSelect={(e, id) => {
                    if (props.setValue) {
                      props.setValue(props.name, e.target.innerText);
                    }
                    props.onFilterChange(id);
                    setSelectedValue(e.target.innerText);
                    setSelectedNode(id);
                    handleClose();
                  }}
                  sx={{ overflowX: "hidden" }}
                >
                  {options.map((opt) => renderTree(opt))}
                </TreeView>
              </Box>
            </Popper>
          </Grid>
        </Grid>
      </Loader>
    </>
  );
};
FilterTreeSearchComponent.defaultProps = {
  rules: undefined,
  required: false,
  disabled: false,
  name: ""
};
