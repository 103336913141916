import CloseIcon from "@mui/icons-material/Close";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { useMediaBreakpointWidth } from "../hooks/useMediaBreakpointWidth";
import { Button } from "./Button";

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    padding: "24px",
    borderRadius: "24px",
    width: 600
  }
}));

const ButtonContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",

  "& .MuiButtonBase-root": {
    width: "120px",
    marginRight: "16px"
  }
}));

interface ConfirmationDialogProps {
  body: string;
  cancelBtnText?: string;
  cancelBtnVariant?: string;
  close?: () => void;
  confirmBtnText?: string;
  confirmBtnVariant?: string;
  hideCancelBtn?: boolean;
  hideCloseIcon?: boolean;
  icon?: React.ReactNode | undefined;
  isConfirming?: boolean;
  onCancel?: () => void;
  onConfirm: () => Promise<void> | void;
  onOpen?: () => void;
  open: boolean;
  title: string;
}

const getIconFromName = (name: string) => {
  switch (name) {
    case "warning":
      return <WarningAmberIcon sx={{ color: "warning.main" }} />;
  }
};

export const ConfirmationDialog = ({
  body,
  cancelBtnText = "No",
  cancelBtnVariant = "admin-secondary",
  close,
  confirmBtnText = "Yes",
  confirmBtnVariant = "admin-primary",
  hideCancelBtn = false,
  hideCloseIcon = false,
  icon,
  isConfirming = false,
  onCancel,
  onConfirm,
  onOpen,
  open,
  title
}: ConfirmationDialogProps) => {
  const dialogBreakpoint = useMediaBreakpointWidth();

  const cancelClickHandler = () => {
    onCancel && onCancel();
    close && close();
  };

  const acceptClickHandler = async () => {
    await onConfirm();
    close && close();
  };

  if (typeof icon === "string") {
    icon = getIconFromName(icon);
  }

  useEffect(() => {
    if (open) {
      onOpen && onOpen();
    }
  }, [open]);

  return (
    <StyledDialog
      className="confirmation-dialog"
      open={open}
      disableScrollLock={true}
      maxWidth={dialogBreakpoint || "md"}
    >
      <Grid container>
        {icon && (
          <Grid xs={1} sx={{ paddingTop: "16px" }}>
            {icon}
          </Grid>
        )}
        <Grid
          xs={icon && !hideCloseIcon ? 10 : icon || !hideCloseIcon ? 11 : 12}
        >
          <DialogTitle variant="h3">{title}</DialogTitle>
          <DialogContent>
            <Typography variant="body1">{body}</Typography>
          </DialogContent>
          <DialogActions>
            <ButtonContainer data-testid="CONFIRM_BUTTONS">
              {!hideCancelBtn && (
                <Button variant={cancelBtnVariant} onClick={cancelClickHandler}>
                  {cancelBtnText}
                </Button>
              )}
              <Button
                variant={confirmBtnVariant}
                onClick={acceptClickHandler}
                isLoading={isConfirming}
                disabled={isConfirming}
              >
                {confirmBtnText}
              </Button>
            </ButtonContainer>
          </DialogActions>
        </Grid>
        {!hideCloseIcon && (
          <Grid xs={1} sx={{ paddingTop: "16px" }}>
            <CloseIcon
              sx={{
                cursor: "pointer",
                "&:hover": {
                  color: "primary.main"
                }
              }}
              onClick={cancelClickHandler}
            />
          </Grid>
        )}
      </Grid>
    </StyledDialog>
  );
};
