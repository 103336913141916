import {
  FormLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  styled
} from "@mui/material";
import // useAdminSettingsPut,
"@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";
import { TimePicker } from "@mui/x-date-pickers-pro";
import { Box } from "@mui/system";
import {
  ModelMedia,
  mediaGet,
  useAdminSettingsGet,
  useAdminSettingsPut,
  useConfigGet,
  useConfigPut,
  useMediaGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import { useRecoilValue } from "recoil";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormSwitch } from "@components/FormSwitch";
import { MediaSelector } from "@components/MediaSelector";
import GamePickerImage from "@assets/images/landscapeImagePicker.png";
import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { FormSelect } from "@components/FormSelect";
import { FormInput } from "@components/FormInput";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

export const LiveStreamingSetting = () => {
  const organizationId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const [media, setMedia] = useState<ModelMedia[]>([]);
  const [mediaIds, setMediaIds] = useState<string[]>([]);
  const [imagesExist, setImagesExist] = useState<boolean>(false);
  const organization = organizations.find(
    (org) => org.organizationId === organizationId
  );
  const { data: settings, isLoading: loading } = !organizationId
    ? useConfigGet()
    : useAdminSettingsGet({
        organizationId: organizationId!,
        parentId: "org-live-stream"
      });
  const { mutate: save } = useConfigPut();
  const { mutate: FSOSave } = useAdminSettingsPut();
  const onSave = (key: string, value) => {
    const data = settings.data;
    const index = data?.findIndex((item) => item.key === key);
    const formattedValue = data.map((item) => {
      if (!item.order) {
        delete item.order;
      }
      if (!item.name) {
        delete item.name;
      }
      return item;
    });
    data[index].value = value;
    save(
      {
        data: formattedValue!
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Saved Successfully!", {
            variant: "success"
          });
        },
        onError: () => {
          enqueueSnackbar("Failed to save !", {
            variant: "error"
          });
        }
      }
    );
  };
  const form = useForm({
    mode: "onBlur"
  });
  const { reset, control, setValue, getValues } = form;
  const onFSOSave = () => {
    const data = getValues().selectedSettings;
    FSOSave(
      {
        data: data,
        params: {
          organizationId: organizationId!
        }
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Saved Successfully!", {
            variant: "success"
          });
        },
        onError: () => {
          enqueueSnackbar("Failed to save !", {
            variant: "error"
          });
        }
      }
    );
  };

  const onMediaUpload = async (sportId: string, files) => {
    const filesPromises = await Promise.all(
      files.map((file) => {
        if (file instanceof File) {
          const promise = uploadMediaUsingPresignedUrl(file);
          return promise;
        } else {
          return file.mediaId;
        }
      })
    );
    let fileUrl = "";
    if (filesPromises) {
      const response = await mediaGet({
        mediaIds: [filesPromises[0]]
      });
      if (response && response.data.media && response.data.media.length) {
        const media = response.data.media[0];
        if (media.media && media.media.baseUrl && media.media.path) {
          let path = media.media.path;
          if (media.media.state === "DRAFT") {
            path = path.replace("original/", "");
          }
          fileUrl = media.media.baseUrl + path;
        }
      }
    }

    const idx = getValues().selectedSettings.findIndex(
      (setting) =>
        setting.settingId === "org-live-stream.default-live-stream-images"
    );
    if (idx != -1) {
      const defaultImages = getValues(`selectedSettings[${idx}].value`) as {
        sportId: string;
        mediaId: string;
        url?: string;
      }[];
      if (defaultImages) {
        const sportFound = defaultImages.findIndex(
          (image) => image.sportId === sportId
        );
        if (sportFound !== -1) {
          defaultImages[sportFound].mediaId = filesPromises[0] as string;
          defaultImages[sportFound].url = fileUrl;
        } else {
          defaultImages.push({
            sportId,
            mediaId: filesPromises[0] as string,
            url: fileUrl
          });
        }
        setValue(`selectedSettings[${idx}].value`, defaultImages);
      } else {
        setValue(`selectedSettings[${idx}].value`, [
          { sportId, mediaId: filesPromises[0] as string, url: fileUrl }
        ]);
      }
      onFSOSave();
    }
  };

  const onMediaUploadFSGO = async (files) => {
    const filesPromises = await Promise.all(
      files.map((file) => {
        if (file instanceof File) {
          const promise = uploadMediaUsingPresignedUrl(file);
          return promise;
        } else {
          return file.mediaId;
        }
      })
    );
    onSave("live-stream.default-live-stream-image", filesPromises[0]);
  };

  const { data: mediaOutputs } = useMediaGet({
    mediaIds: mediaIds
  });

  useEffect(() => {
    if (mediaOutputs) {
      setMedia(mediaOutputs.data?.media?.map((m) => m.media!) || []);
      setImagesExist(true);
    }
  }, [mediaOutputs]);

  const [liveStreamCancellationTime, setLiveStreamCancellationTime] =
    useState(0);
  useEffect(() => {
    if (settings && organizationId) {
      const settingsMap = settings.data.map((setting) => {
        if (setting.type === "SELECT_IMAGE_MULTIPLE") {
          const imagesExist =
            setting.organizationSettings && setting.organizationSettings.length
              ? setting.organizationSettings[0].value
              : undefined;
          if (imagesExist)
            setMediaIds(imagesExist.map((image) => image.mediaId));
        }
        return {
          settingId: setting.settingId,
          value:
            setting.organizationSettings && setting.organizationSettings.length
              ? setting.organizationSettings[0].value
              : setting.default
        };
      });
      const defaultValues = {
        selectedSettings: settingsMap
      };
      reset(defaultValues, {
        keepDefaultValues: false
      });
    } else if (settings) {
      const imageExists = settings.data.find(
        (setting) => setting.key === "live-stream.default-live-stream-image"
      )?.value;
      if (imagesExist) setMediaIds([imageExists as string]);
      setLiveStreamCancellationTime(
        Number(
          settings?.data.find(
            (item) =>
              item.key === "live-stream.inactive-stream-cancellation-time"
          )?.value
        )
      );
    }
  }, [settings]);

  return (
    <>
      {!organizationId && (
        <Loader isLoading={loading}>
          <>
            <Box
              display="flex"
              alignItems="center"
              marginTop="15px"
              marginLeft="40px"
            >
              <Typography
                style={{
                  fontSize: "14px",
                  font: "inter",
                  lineHeight: "18px",
                  marginRight: "10px"
                }}
              >
                Show Go Live button
              </Typography>
              <TimePicker
                defaultValue={
                  new Date(
                    new Date().setHours(
                      0,
                      Number(
                        settings?.data.find(
                          (item) => item.key === "live-stream.go-live-button"
                        )?.value
                      )
                    )
                  )
                }
                sx={{ width: "100px", marginRight: "10px" }}
                views={["minutes"]}
                format="mm"
                onChange={(e) => {
                  const min = new Date(e.toString()).getMinutes();
                  onSave("live-stream.go-live-button", min);
                }}
              />
              <Typography
                style={{ fontSize: "14px", font: "inter", lineHeight: "18px" }}
              >
                before an event’s start time.
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              marginTop="15px"
              marginLeft="40px"
            >
              <Typography
                style={{
                  fontSize: "14px",
                  font: "inter",
                  lineHeight: "18px",
                  marginRight: "10px"
                }}
              >
                Automatically Cancel Live Streams after
              </Typography>
              <TextField
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Hr</InputAdornment>
                  )
                }}
                style={{
                  maxWidth: "84px"
                }}
                value={liveStreamCancellationTime}
                onChange={(e) => {
                  setLiveStreamCancellationTime(parseInt(e.target.value));
                  if (e.target.value) {
                    onSave(
                      "live-stream.inactive-stream-cancellation-time",
                      parseInt(e.target.value)
                    );
                  }
                }}
              />

              <Typography
                style={{
                  fontSize: "14px",
                  font: "inter",
                  lineHeight: "18px",
                  marginLeft: "10px"
                }}
              >
                of inactivity
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              marginTop="15px"
              marginLeft="40px"
            >
              <Typography
                style={{
                  fontSize: "14px",
                  font: "inter",
                  lineHeight: "18px",
                  marginRight: "10px"
                }}
              >
                Length of Pre Roll Advertising
              </Typography>
              <TimePicker
                sx={{ width: "120px", marginRight: "10px" }}
                defaultValue={
                  new Date(
                    new Date().setHours(
                      0,
                      //@ts-ignore
                      settings?.data
                        .find(
                          (item) =>
                            item.key === "live-stream.pre-roll-ad-length"
                        )
                        ?.value.split(":")[0],
                      //@ts-ignore
                      settings?.data
                        .find(
                          (item) =>
                            item.key === "live-stream.pre-roll-ad-length"
                        )
                        ?.value.split(":")[1]
                    )
                  )
                }
                views={["minutes", "seconds"]}
                format="mm:ss"
                onChange={(e) => {
                  const min = new Date(e.toString()).getMinutes();
                  const sec = new Date(e.toString()).getSeconds();
                  onSave("live-stream.pre-roll-ad-length", `${min}:${sec}`);
                }}
              />
            </Box>
            {settings && (
              <Grid
                item
                container
                spacing="15px"
                direction={"column"}
                marginLeft="2px"
              >
                <Grid item>
                  <StyledFormLabel>
                    <Typography display="inline" variant="formLabel">
                      Default Live Stream Image
                    </Typography>
                  </StyledFormLabel>
                </Grid>
                <Grid item>
                  <MediaSelector
                    setFilesToUpload={(file) => {
                      if (file.length && file[0] instanceof File)
                        onMediaUploadFSGO(file);
                    }}
                    maxImages={1}
                    uploadedFiles={
                      imagesExist && media && media.length === 1 ? media : []
                    }
                    imagePlaceHolder={GamePickerImage}
                  />
                </Grid>
              </Grid>
            )}
            <Box
              display="flex"
              alignItems="center"
              marginTop="15px"
              marginLeft="40px"
            >
              <Typography
                style={{
                  fontSize: "14px",
                  font: "inter",
                  lineHeight: "18px",
                  marginRight: "10px",
                  minWidth: "185px"
                }}
              >
                Paid Subscriber Skip Rule
              </Typography>
              <Box sx={{ width: "260px" }}>
                <FormSelect
                  name="paidSubscriberSkipRule"
                  options={[
                    { label: "1 Intermission", value: 1 },
                    {
                      label: "2 Intermissions",
                      value: 2
                    },
                    {
                      label: "3 Intermissions",
                      value: 3
                    }
                  ]}
                  value={
                    settings?.data.find(
                      (item) => item.key === "live-stream.skip-rule"
                    )?.value
                  }
                  onChange={(e) => {
                    onSave("live-stream.skip-rule", Number(e.target.value));
                  }}
                />
              </Box>
            </Box>
          </>
        </Loader>
      )}
      {organizationId && (
        <Loader isLoading={loading}>
          {settings &&
            settings.data.map((setting, index) => {
              if (setting.type === "BOOL") {
                return (
                  <Grid
                    item
                    container
                    direction="row"
                    spacing="24px"
                    key={setting.settingId}
                  >
                    <Grid item marginTop="10px">
                      <Typography
                        style={{
                          color: "#1E2941",
                          fontSize: "13px",
                          font: "inter",
                          lineHeight: "normal",
                          width: "212px"
                        }}
                      >
                        {setting.name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <FormSwitch
                        onChange={(e) => {
                          setValue(
                            `selectedSettings[${index}].value`,
                            e.target.checked
                          );
                          onFSOSave();
                        }}
                        name={`selectedSettings[${index}].value`}
                        control={control}
                      />
                    </Grid>
                  </Grid>
                );
              } else if (setting.type === "SELECT_IMAGE_MULTIPLE") {
                {
                  return (
                    <>
                      {organization!.sports?.map((sport) => {
                        const idx =
                          getValues() && getValues().selectedSettings
                            ? getValues().selectedSettings.findIndex(
                                (setting) =>
                                  setting.settingId ===
                                  "org-live-stream.default-live-stream-images"
                              )
                            : -1;
                        let defaultImage = [] as ModelMedia[];
                        if (idx != -1) {
                          const defaultImages = getValues(
                            `selectedSettings[${idx}].value`
                          ) as {
                            sportId: string;
                            mediaId: string;
                          }[];
                          if (defaultImages) {
                            const sportFound = defaultImages.findIndex(
                              (image) => image.sportId === sport.sportId
                            );
                            if (sportFound !== -1) {
                              defaultImage = media.filter(
                                (m) =>
                                  m.mediaId ===
                                  defaultImages[sportFound].mediaId
                              );
                            }
                          }
                        }

                        return (
                          <Grid
                            item
                            container
                            spacing="15px"
                            direction={"column"}
                            key={sport.sportId}
                          >
                            <Grid item>
                              <StyledFormLabel>
                                <Typography
                                  display="inline"
                                  variant="formLabel"
                                >
                                  {setting.name.replace(
                                    "{sport}",
                                    sport.sport?.name
                                  )}
                                </Typography>
                              </StyledFormLabel>
                            </Grid>
                            <Grid item>
                              <MediaSelector
                                setFilesToUpload={(file) => {
                                  if (file.length && file[0] instanceof File)
                                    onMediaUpload(sport.sportId!, file);
                                }}
                                maxImages={1}
                                uploadedFiles={imagesExist ? defaultImage : []}
                                imagePlaceHolder={GamePickerImage}
                              />
                            </Grid>
                          </Grid>
                        );
                      })}
                    </>
                  );
                }
              } else if (setting.type === "SINGLE_SELECT") {
                if (
                  setting.settingId ==
                  "org-live-stream.inactive-stream-cancellation-time"
                ) {
                  return (
                    <Grid
                      item
                      container
                      direction="row"
                      spacing="10px"
                      alignItems="center"
                      key={setting.settingId}
                    >
                      <Grid item>
                        <Typography
                          style={{
                            fontSize: "13px",
                            font: "inter",
                            lineHeight: "18px",
                            marginRight: "10px",
                            color: "#1E2941"
                          }}
                        >
                          Automatically Cancel Live Streams after
                        </Typography>
                      </Grid>
                      <Grid item style={{ maxWidth: "100px" }}>
                        <FormInput
                          name={`selectedSettings[${index}].value`}
                          control={control}
                          type="number"
                          label=""
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">Hr</InputAdornment>
                            )
                          }}
                          onChange={(e) => {
                            setValue(
                              `selectedSettings[${index}].value`,
                              parseInt(e.target.value)
                            );
                            if (e.target.value) {
                              onFSOSave();
                            }
                          }}
                        />
                      </Grid>

                      <Grid item>
                        <Typography
                          style={{
                            fontSize: "14px",
                            font: "inter",
                            lineHeight: "18px",
                            marginLeft: "10px"
                          }}
                        >
                          of inactivity
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                }
              }
            })}
        </Loader>
      )}
    </>
  );
};
