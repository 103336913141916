import { Backdrop, Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import SGLogo from "@assets/icons/SGLogo.svg";
import BrowserLogo from "@assets/icons/browserLogo.svg";
import { useEffect, useState } from "react";
import { AppStoreUrl, PlayStoreUrl } from "@utils/constants";

const StyledBox = styled(Box)`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 135px;
  background-color: #fff;
  border-radius: 12px 12px 0px 0px;
  padding: 15px;
`;

const StyledHeader = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
`;

const OptionsContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  row-gap: 15px;
`;

const Options = styled(Box)`
  display: flex;
  align-items: center;
`;

const StyledOptionText = styled(Typography)`
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  @media (max-width: 300px) {
    font-size: 10px;
  }
`;

const StyledButton = styled(Button)`
  padding: 4px 12px 4px 12px;
  border-radius: 6px;
  text-transform: none;
  font-wight: 500;
  font-size: 12px;
  line-height: 20px;
  @media (max-width: 300px) {
    font-size: 10px;
  }
`;

export const MobileFooter = ({
  toggleMobileFooter,
  mobileUrl
}: {
  toggleMobileFooter: (show: boolean) => void;
  mobileUrl: string;
}) => {
  useEffect(() => {
    const preventZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    document.addEventListener("touchmove", preventZoom, { passive: false });

    return () => {
      document.removeEventListener("touchmove", preventZoom);
    };
  }, []);

  const [isBlurred, setIsBlurred] = useState(false);
  const [didFallback, setDidFallback] = useState(false);
  window.addEventListener("blur", () => {
    setIsBlurred(true);
  });

  window.addEventListener("focus", () => {
    setIsBlurred(false);
  });
  const appStoreFallback = () => {
    if (isBlurred || didFallback) return;
    const userAgent = window.navigator.userAgent;
    if (/android/i.test(userAgent)) {
      window.location.href = PlayStoreUrl;
      setDidFallback(true);
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      if (window.confirm(`Open in "App Store"?`)) {
        window.location.href = AppStoreUrl;
        setDidFallback(true);
      }
    }
  };

  const handleClick = () => {
    const now = new Date().valueOf();
    setDidFallback(false);
    setIsBlurred(false);
    window.location.href = mobileUrl;
    setTimeout(() => {
      if (!document.hasFocus()) return;
      if (new Date().valueOf() - now > 1600) return;
      appStoreFallback();
    }, 1500);
  };

  return (
    <Backdrop
      sx={{
        overflow: "hidden",
        overflowY: "none",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={true}
    >
      <StyledBox>
        <StyledHeader>SportsGravy is better on the app</StyledHeader>
        <Box marginTop="15px">
          <OptionsContainer>
            <Options>
              <img style={{ width: "28px" }} src={SGLogo} />
              <StyledOptionText>SportsGravy App</StyledOptionText>
            </Options>
            <StyledButton
              variant="contained"
              style={{ color: "#fff" }}
              onClick={handleClick}
            >
              Open in SportsGravy
            </StyledButton>
          </OptionsContainer>
          <OptionsContainer style={{ margin: "15px 0" }}>
            <Options>
              <img
                style={{ width: "24px", marginRight: "4px" }}
                src={BrowserLogo}
              />
              <StyledOptionText>Browser</StyledOptionText>
            </Options>
            <StyledButton
              variant="contained"
              style={{ backgroundColor: "#c7c7c7", color: "#666666" }}
              onClick={() => toggleMobileFooter(false)}
            >
              Continue
            </StyledButton>
          </OptionsContainer>
        </Box>
      </StyledBox>
    </Backdrop>
  );
};
