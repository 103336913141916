// import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { memo, useEffect, useRef, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoadingSpinner } from "@components/LoadingSpinner";
import {
  ModelMedia
  // useMediaPost,
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Delete } from "@mui/icons-material";
import { ConfirmationDialog } from "./ConfirmationDialog";
import Hls from "hls.js";
import { isHLSVideo } from "@utils/checkVideoUrl";
import ReactPlayer from "react-player";

const LoadingContainer = styled(Box)`
  min-height: 44px;
  display: flex;
  align-items: center;
`;

const ImageContainer = styled("img")(() => ({
  width: "190px",
  height: "190px"
}));

const MediaContainerBox = styled("div")(() => ({
  width: "100%",
  height: "100%",
  position: "relative",
  "& video": {
    width: "190px",
    height: "190px",
    objectFit: "cover"
  }
}));

const DeleteContainerBox = styled("div")(() => ({
  width: "190px",
  height: "190px",
  objectFit: "contain",
  position: "absolute",
  backgroundColor: "#FFFFFF44",
  alignItems: "flex-start",
  top: 0,
  display: "flex",
  justifyContent: "flex-end"
}));

const MediaViewer = (props: {
  file: ModelMedia | File;
  onSave: (mediaId: string) => void;
  onRemove: (index: number) => void;
  index: number;
  disabled: boolean;
}) => {
  const [mediaURL, setMediaURL] = useState("");
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (props.file instanceof File) {
      setMediaURL(URL.createObjectURL(props.file as File));
    } else {
      setMediaURL(`${props.file?.baseUrl}${props.file?.path}`);
    }
  }, []);
  useEffect(() => {
    if (videoRef.current) {
      const tempUrl = (props.file as ModelMedia).mimeType
        ? mediaURL
        : URL.createObjectURL(props.file as File);

      if (isHLSVideo(tempUrl) && Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(tempUrl);
        hls.attachMedia(videoRef.current);
      } else {
        videoRef.current.setAttribute("src", tempUrl);
        videoRef.current.load();
      }
    }
  }, [videoRef.current, mediaURL]);

  return mediaURL ? (
    <MediaContianer
      disabled={props.disabled}
      index={props.index}
      onConfirmDelete={(index) => {
        props.onRemove(index);
      }}
    >
      {(props.file as ModelMedia).mimeType?.startsWith("image") ||
      props.file?.type?.startsWith("image") ? (
        <ImageContainer src={mediaURL} />
      ) : (props.file as ModelMedia).mimeType ? (
        <ReactPlayer
          playing={true}
          url={mediaURL}
          width="190px"
          height="190px"
          controls
        />
      ) : (
        <video
          width="190px"
          height="190px"
          controls
          autoPlay
          style={{ objectFit: "cover" }}
        >
          <source
            src={URL.createObjectURL(props.file as File)}
            type={props.file.type}
          />
        </video>
      )}
    </MediaContianer>
  ) : (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "gray",
        display: "flex",
        justifyContent: "center"
      }}
    >
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    </div>
  );
};

const MediaContianer = (props: {
  children;
  onConfirmDelete;
  index;
  disabled: boolean;
}) => {
  const [isHovered, setHovered] = useState<boolean>(false);
  const [toDelete, setToDelete] = useState<boolean>(false);
  return (
    <MediaContainerBox
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      {props.children}
      {isHovered && !props.disabled && (
        <DeleteContainerBox
          style={{
            height:
              props.children.type == "video" || props.children?.props?.playing
                ? 0
                : "190px"
          }}
        >
          <IconButton onClick={() => setToDelete(true)}>
            <Delete />
          </IconButton>
        </DeleteContainerBox>
      )}
      <ConfirmationDialog
        open={!!toDelete}
        title="Delete image"
        body={`Are you sure you want to delete this media?`}
        close={() => setToDelete(false)}
        onConfirm={() => {
          setToDelete(false);
          setHovered(false);
          props.onConfirmDelete(props.index);
        }}
        onCancel={() => setToDelete(false)}
        confirmBtnVariant="admin-warning"
        icon="warning"
      />
    </MediaContainerBox>
  );
};

export default memo(MediaViewer);
